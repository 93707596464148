import React, { useEffect, useState } from "react";
import Notes from "../Notes/Notes";
import Sidebar from "../Sidebar/Sidebar";

import styled from "@emotion/styled";
import { useNotes } from "../../Context/NotesContext";

import {
  getAllNotes,
  getAllNotesWithNewNote,
  getAllTemplates,
  getAllTrashNotes,
  getShareNote,
  postNote,
} from "../../Pages/Services/notesApi";
import NotesExtendedView from "../NotesExtendedView/NotesExtendedView";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../Loader";

const NotesContainerMain = styled.div({
  // width: "90%",
  // paddingLeft: "90px",
  // height: "calc(100vh - 226px)",
  width: "97%",
  height: "82vh",
  // position: "fixed",
  // position: "absolute",
  // left: "80px",
  // top: "80px",

  // for center
  // top: "50%",
  // left: "50%",
  // transform: " translate(-50%, -50%)",
  display: "flex",
  borderRadius: "12px",
  overflow: "hidden",
  boxShadow: "rgba(0, 0, 0, 0.05) 0px 1px 2px 0px",
});
function NotesContainer() {
  const { stateNotes, dispatchNotes } = useNotes();

  const { singleNote } = stateNotes;
  useEffect(() => {
    // getAllNotes(dispatchNotes);
    getAllTrashNotes(dispatchNotes);
    getAllTemplates(dispatchNotes);
  }, [dispatchNotes]);

  const [showNotesExtedned, setShowNotesExtended] = useState(false);

  const { id } = useParams();

  const shortCutNotes = localStorage.getItem("searchAddNote");

  const navigate = useNavigate();

  useEffect(() => {
    if (shortCutNotes === "true") {
      const tempLocation = localStorage.getItem("searchAddNoteLocation");
      if (tempLocation === "/") {
        getAllNotesWithNewNote(dispatchNotes);
        localStorage.setItem("searchAddNote", false);
      }
    } else {
      if (id !== "1") {
        getAllNotes(dispatchNotes, true);

        getShareNote(id, dispatchNotes, navigate);
        // navigate("../../notes/1");
      } else {
        getAllNotes(dispatchNotes, false);
      }
    }
  }, []);

  const addNoteHandler = () => {
    const NewNote = {
      parent: null,
      title: "",
      content: {
        blocks: [],
        time: Date.now(),
        version: "2.25.0",
      },
    };
    postNote(dispatchNotes, NewNote);
  };

  if (!singleNote) {
    addNoteHandler();
  }

  return (
    <NotesContainerMain>
      <Sidebar />
      {singleNote ? (
        <Notes
          setShowNotesExtended={setShowNotesExtended}
          showNotesExtedned={showNotesExtedned}
        />
      ) : (
        <div style={{ backgroundColor: "white", width: "100%" }}>
          <Loader />
        </div>
      )}
      {showNotesExtedned && (
        <NotesExtendedView
          showNotesExtedned={showNotesExtedned}
          setShowNotesExtended={setShowNotesExtended}
        />
      )}
    </NotesContainerMain>
  );
}

export default NotesContainer;
