import { Box, ClickAwayListener, styled, Tooltip } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as NotesSVG } from "../Assets/SVG/Notes.svg";
import { ReactComponent as PipelineSVG } from "../Assets/SVG/Pipeline.svg";
import { ReactComponent as TaskSVG } from "../Assets/SVG/Task.svg";
import GenerateGreetings from "./GenerateGreetings";
// import { parser } from "html-metadata-parser";
import { Add } from "@mui/icons-material";
import { Input } from "@mui/material";

import mainShortCutContext from "../Context/Context";
import { createOrUpdateKey } from "../Pages/Services/tableApi";

const StyledInput = styled("input")((theme) => ({
  background: "transparent",
  border: "none",
  fontSize: "24px",
  lineHeight: "29px",
  width: "150px",
  borderBottom: "1px solid",
  "&:focus": {
    outline: "none",
  },
  fontFamily: "Inter",
  // color: theme.fontColor,
  color: "white",
}));

const PageBox = styled(Box)({
  width: "164px",
  height: "200px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  background: "rgba(3, 46, 97, 0.24)",
  backdropFilter: "blur(12px)",
  transition: "all .2s ease-in-out",

  borderRadius: "16px",
  ":hover": {
    background: "#0073D4",
    cursor: "pointer",
    transform: "translate(0, -10px)",
  },
});

const ShortCutBox = styled(Box)({
  display: "flex",
  width: "450px",
  height: "75px",
  flexWrap: "wrap",
  gap: "16px",
  justifyContent: "center",
  alignItems: "flex-start",
  columnGap: "40px",
});

export const ShortCut = styled(Box)({
  width: "48px",
  height: "48px",
  backdropFilter: "blur(2px)",
  border: "1px solid rgba(255, 255, 255, 0.16)",
  borderRadius: "50%",
  /* background: transparent; */
  display: "grid",
  placeItems: "center",
  cursor: "pointer",
});

const AddShortCutBox = styled(Box)({
  width: "140px",
  height: "max-content",
  paddingBottom: "0.5rem",

  background: "#FFFFFF",
  border: "1px solid #F4F4F4",
  borderRadius: "8px",
  ".MuiBox-root": {
    ":focus-visible": {
      outline: "none",
    },
  },

  display: "flex",
  flexDirection: "column",
  gap: "4px",
  fontFamily: "Inter",

  position: "absolute",
  zIndex: "2",
  top: "60px",
  left: "-45px",
});

const ShortCutInput = styled(Input)({
  border: "none",
  display: "block",
  padding: "8px 16px",
  width: "100%",
  height: "32px",
  lineHeight: "32px",
  "input::placeholder": {
    opacity: 0.5,
    fontFamily: "Inter",
  },
  input: {
    fontFamily: "Inter",
  },
  ".MuiInput-root": {
    ":focus-visible": {
      outline: "none",
    },
    fontFamily: "Inter",
    ".MuiInput-input": {
      ":focus-visible": {
        outline: "none",
      },
    },
  },
  ".MuiInputBase-root": {
    fontFamily: "Inter",
  },
  ".MuiInput-root": {
    fontFamily: "Inter",
  },
});

function Time({ appShortCut, setAppShortCut }) {
  const [userName, setUserName] = useState(
    localStorage.getItem("user_name") ?? ""
  );
  const [textInput, setTextInput] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);

  const preShortCutData = {
    url: "",
    name: "",
    metaData: "",
  };
  const { showOrHide } = useContext(mainShortCutContext);
  const [createShortCut, setCreateShortCut] = useState(preShortCutData);
  const refInput = useRef(null);
  // const dropDownArr = ["Events", "Opportunity", "Task", "Lead", "Notes"];
  const pages = [
    {
      svg: (
        <TaskSVG
          width={"2rem"}
          height={"2rem"}
          stroke={"white"}
          strokeWidth={1.4}
        />
      ),
      page: "Tasks",
      url: "/tasks",
    },
    {
      svg: (
        <NotesSVG
          width={"2rem"}
          height={"2rem"}
          stroke={"white"}
          strokeWidth={1.4}
        />
      ),

      page: "Notes",
      url: "/notes/1",
    },
    {
      svg: (
        <PipelineSVG
          width={"2rem"}
          height={"2rem"}
          stroke={"white"}
          strokeWidth={1.4}
        />
      ),
      page: "Pipeline",
      url: "/pipeline",
    },
  ];

  const [shortCuts, setShortCuts] = useState(
    localStorage.getItem("shortCut")
      ? JSON.parse(localStorage.getItem("shortCut"))
      : []
  );

  useEffect(() => {
    setShortCuts(
      localStorage.getItem("shortCut")
        ? JSON.parse(localStorage.getItem("shortCut"))
        : []
    );
  }, [appShortCut, localStorage.getItem("shortCut")]);

  // const removeShortCut = () => {};

  useEffect(() => {
    if (refInput.current !== null) {
      refInput.current.focus();
    }
  }, [textInput]);

  const getUrlsMetaData = async (shortCutAll, userInputUrl) => {
    let _shortCuts = [...shortCutAll];

    _shortCuts = _shortCuts.map((data) => {
      const urlMetadata = data?.metaData
        ? data.metaData
        : {
            url: `https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${userInputUrl}&size=20`,
          };

      data = { ...data, metaData: urlMetadata };
      return data;
    });

    localStorage.setItem("shortCut", JSON.stringify(_shortCuts));

    const JSONdataParams = {
      shortcut: localStorage.getItem("ShowOrHide") || false,
      shortCutData: _shortCuts,
      timeFormat: localStorage.getItem("TimeFormat") || "12",
    };
    await createOrUpdateKey(
      JSON.stringify({
        key: `settings`,
        value: JSONdataParams,
      })
    );

    setShortCuts(_shortCuts);
  };

  useEffect(() => {
    if (shortCuts?.length) {
      getUrlsMetaData(shortCuts);
    }
  }, []);

  return (
    <Box
      color="#444647"
      padding="30px"
      // marginTop="150px"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap="40px"
      position="relative"
    >
      <Box fontSize="24px" lineHeight="29px" fontWeight="400" color="white">
        <GenerateGreetings greeting={true} />!{" "}
        <span
          style={{
            fontWeight: "600",
          }}
          onClick={() => {
            setTextInput(true);
          }}
          onBlur={() => setTextInput(false)}
        >
          {textInput ? (
            <StyledInput
              ref={refInput}
              type="text"
              value={userName}
              onChange={(e) => setUserName(e.target.value)}
            />
          ) : (
            userName
          )}
          .{" "}
        </span>
        What plans for today?
      </Box>
      <Box display="flex" alignItems="center" gap="20px">
        {pages.map(({ page, svg, url }, index) => {
          return (
            <Link to={url} style={{ textDecoration: "none" }} key={index}>
              <PageBox className="animate__animated animate__zoomIn">
                <Box>{svg}</Box>
                <Box
                  fontSize="20px"
                  fontWeight="400"
                  fontFamily="inter"
                  color="white"
                  lineHeight="24px"
                  height="24px"
                >
                  {page}
                </Box>
              </PageBox>
            </Link>
          );
        })}
      </Box>
      <ShortCutBox>
        {showOrHide &&
          shortCuts?.map(
            ({ metaData, url, name }, index) =>
              metaData && (
                <span
                  key={index}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                    position: "relative",
                    alignItems: "center",
                  }}
                >
                  <ShortCut
                    bgcolor="white"
                    position="relative"
                    onClick={() =>
                      window.open(
                        url.includes("https://") ? url : `https://${url}`,
                        "_blank"
                      )
                    }
                    display="flex"
                    gap="4px"
                    flexDirection="column"
                  >
                    <img
                      src={metaData.url ?? ""}
                      style={{
                        position: "absolute",
                      }}
                    />
                  </ShortCut>
                  <Box
                    width={"65px"}
                    overflow={"hidden"}
                    textOverflow={"ellipsis"}
                    whiteSpace={"nowrap"}
                    color={"white"}
                    textAlign={"center"}
                  >
                    <span
                      style={{
                        color: "white",
                        fontFamily: "Inter",
                      }}
                    >
                      {name}
                    </span>
                  </Box>
                </span>
              )
          )}

        {showOrHide && (
          <ClickAwayListener
            onClickAway={() => {
              setShowDropDown(false);
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              gap="8px"
              alignItems="center"
              position="relative"
            >
              <Tooltip
                className="iconHover"
                PopperProps={{
                  disablePortal: true,
                  sx: {
                    "& .MuiTooltip-tooltip": {
                      width: "60px",
                      height: "24px",
                      background: "rgba(3, 46, 97, 0.72)",
                      borderRadius: "4px",
                      fontFamily: "Inter",
                      textAlign: "center",
                      display: showDropDown && "none",
                    },
                  },
                }}
                placement="bottom"
                title="Add New Shortcut"
              >
                <ShortCut
                  bgcolor="rgba(255, 255, 255, 0.16)"
                  onClick={() => setShowDropDown(!showDropDown)}
                >
                  <Add
                    style={{ color: "white", width: "24px", height: "24px" }}
                  />
                </ShortCut>
              </Tooltip>

              {showDropDown && (
                <AddShortCutBox>
                  <ShortCutInput
                    placeholder="Name"
                    value={createShortCut.name}
                    disableUnderline={true}
                    onChange={(e) =>
                      setCreateShortCut({
                        ...createShortCut,
                        name: e.target.value,
                      })
                    }
                    autoFocus
                  />
                  <ShortCutInput
                    placeholder="Url"
                    value={createShortCut.url}
                    disableUnderline={true}
                    onChange={(e) =>
                      setCreateShortCut({
                        ...createShortCut,
                        url: e.target.value,
                      })
                    }
                    onKeyDown={(e) => {
                      if (
                        e.key === "Enter" &&
                        createShortCut.name &&
                        createShortCut.url
                      ) {
                        let __shortCuts = [...shortCuts];

                        const getExistingMetaDataThroughUrl =
                          __shortCuts.filter(
                            (data) => data.url === createShortCut.url
                          );

                        let metaData = "";
                        if (getExistingMetaDataThroughUrl?.length) {
                          metaData = getExistingMetaDataThroughUrl[0].metaData;
                        }

                        __shortCuts = [
                          ...__shortCuts,
                          { ...createShortCut, metaData: metaData },
                        ];
                        setShortCuts(__shortCuts);
                        // localStorage.setItem(
                        //   "shortCut",
                        //   JSON.stringify(__shortCuts)
                        // );

                        getUrlsMetaData(
                          __shortCuts,
                          createShortCut?.url?.includes("https://")
                            ? createShortCut?.url
                            : `https://${createShortCut?.url}`
                        );
                        setCreateShortCut(preShortCutData);
                        setShowDropDown(false);
                      }
                    }}
                  />
                </AddShortCutBox>
              )}
            </Box>
          </ClickAwayListener>
        )}
      </ShortCutBox>
    </Box>
  );
}

export default Time;
