import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import { TypoGraphy } from "../Assets/StyledComponents/ExpandViewStyledComponents";
import { ReactComponent as ExpandViewTableFieldSVG } from "../Assets/SVG/ExpandViewTableField.svg";
import EditOrViewCell from "./EditOrViewCell";
import Lock from "@mui/icons-material/Lock";
import FilterColumn from "./Table/FilterColumn";
import mainShortCutContext from "../Context/Context";
import { ClickAwayListener, Popover } from "@mui/material";
import styled from "@emotion/styled";

export const RelatedTablePopOver = styled(Popover)({
  ".MuiPaper-root": {
    // background: "none",
    width: "260px",
    height: "260px",
    borderRadius: "12px",
    boxShadow:
      "20px 28px 24px rgba(0, 0, 0, 0.1), inset -2px -2px 4px rgba(0, 0, 0, 0.06)",
  },
});

let customComponentData;
const RenderComponents = ({
  row,
  activeColumnHeader,
  rowId,
  tableName,
  onUpdateSuccessOfRow,
  rowData,
  setRowData,
  searchView,
  SearchTableData,
  name,
  error,
  handleError,
  referenceData,
  onHoverHeader,
  setOnHoverHeader,
  handleAddOrRemoveExpandColumnField,
  expandViewTableFieldHeader,
  setExpandViewTableFieldHeader,
  relatedTableLoader,
  setRelatedTableLoader,
  filterColumns,
  expandViewFirst,
  editTask,
  taskView,
  leadView,
  SearchDrawer,
  subFields,
  tilesData,
  taskRelatedData,
  MainTaskRelatedNameData,
  taskViewOnly,
}) => {
  const { idFields } = useContext(mainShortCutContext);
  const [isOpen, setIsOpen] = useState({});
  const init = async () => {
    // customComponentData = await getKeyData(
    //   JSON.stringify({
    //     key: `Expand${tableName}Headers`,
    //   })
    // );
    if (activeColumnHeader?.length) {
      customComponentData = activeColumnHeader;
      customComponentData = customComponentData?.filter(
        ({ isVisible, selected }) => isVisible || selected
      );
      // if (tableName === "Task") {
      //   // customComponentData = await getKeyData(
      //   //   JSON.stringify({
      //   //     key: `ExpandGrid${tableName}Headers`,
      //   //   })
      //   // );
      //   customComponentData = customComponentData?.data?.value?.filter(
      //     ({ isVisible, selected }) => isVisible || selected
      //   );

      //   // customComponentData = localStorage.getItem(`ExpandGrid${tableName}Headers`)
      //   //   ? JSON.parse(
      //   //       localStorage.getItem(`ExpandGrid${tableName}Headers`)
      //   //     )?.filter(({ isVisible, selected }) => isVisible || selected)
      //   //   : activeColumnHeader;
      // }
      if (searchView === "Tiles") {
        // customComponentData = await getKeyData(
        //   JSON.stringify({
        //     key: `Expand${localStorage.getItem(
        //       "manageHeader"
        //     )}${tableName}Headers`,
        //   })
        // );
        // customComponentData = customComponentData?.data?.value?.filter(
        //   ({ isVisible, selected }) => isVisible || selected
        // );
        // customComponentData =
        //   localStorage.getItem(
        //     `Expand${localStorage.getItem("manageHeader")}${tableName}Headers`
        //   ) &&
        //   JSON.parse(
        //     localStorage.getItem(
        //       `Expand${localStorage.getItem("manageHeader")}${tableName}Headers`
        //     )
        //   )?.filter(({ isVisible, selected }) => isVisible || selected);

        let allSubField = { ...subFields };

        const activeSubFields = Object.keys(allSubField)?.length
          ? Object.keys(allSubField)
              .map((field) =>
                allSubField[field]?.filter(
                  ({ isVisible, selected }) => isVisible || selected
                )
              )
              .flat()
          : [];
        if (localStorage.getItem("manageHeader") === "Grid") {
          // activeSubFields = activeSubFields.filter(({ metric_display_name }) => !customComponentData.map(({ metric_display_name }) => metric_display_name).includes(metric_display_name))
          // customComponentData = activeSubFields
          //   ? customComponentData?.concat(activeSubFields)
          //   : customComponentData;
        }

        if (localStorage.getItem("manageHeader") !== "Grid") {
          // let allSubField = { ...subFields };

          // const activeSubFields = Object.keys(allSubField)?.length
          //   ? Object.keys(allSubField)
          //       .map((field) =>
          //         allSubField[field]?.filter(
          //           ({ isVisible, selected }) => isVisible || selected
          //         )
          //       )
          //       .flat()
          //   : [];

          let relatedHeader =
            localStorage.getItem(`Expand${tableName}RelatedList`) &&
            JSON.parse(localStorage.getItem(`Expand${tableName}RelatedList`));

          const activeRelatedHeader = relatedHeader
            ?.filter((data) => data.selected)
            .map((header) => {
              return {
                // sobject: tableName,
                metric_name: header.objectApiName,
                metric_display_name: header.label,
                metric_related_listId: header.relatedListId,
                metric_related_fieldApiName: header.fieldApiName,
                metric_datatype: "string",
                parentSobject: "",
                values: [],
                editable: false,
                related: true,
                nullable: true,
                selected: header.selected,
                creatable: false,
              };
            });
          customComponentData = activeRelatedHeader
            ? customComponentData?.concat(activeRelatedHeader.flat())
            : customComponentData;

          // customComponentData = activeSubFields
          //   ? customComponentData?.concat(activeSubFields)
          //   : customComponentData;
        }
      }
    } else if (searchView && SearchTableData) {
      customComponentData = SearchTableData?.data[name]?.filter(
        (data) => data.isVisible
      );
    }
    customComponentData = [
      ...new Set(
        customComponentData?.filter((d) => d).map((o) => JSON.stringify(o))
      ),
    ].map((s) => JSON.parse(s));

    customComponentData = customComponentData.map((data, index) => {
      if (data.related) {
        return {
          ...data,
          popOverId: index,
          popOverContent: (
            <FilterColumn
              header={getHeaderOrActiveHeader(row, data, true)}
              activeHeader={getHeaderOrActiveHeader(row, data, false)}
              filterColumns={(
                header,
                dataHeader,
                dragHandleIndividualTableHeader
              ) => {
                filterColumns(
                  header,
                  dataHeader,
                  dragHandleIndividualTableHeader,
                  data.metric_name
                );
              }}
              tableName={tableName}
              addOrRemoveFieldInColumn={(
                field,
                headerFlag,
                tableName,
                relatedTableHeader,
                data
              ) => {
                handleAddOrRemoveExpandColumnField(
                  field,
                  headerFlag,
                  tableName,
                  relatedTableHeader,
                  data
                );
              }}
              // HandleExpandViewHeader={true}
              expandViewRelated={false}
              // relatedFields={
              //   localStorage.getItem(`Expand${tableName}RelatedList`)
              //     ? JSON.parse(
              //         localStorage.getItem(
              //           `Expand${tableName}RelatedList`
              //         )
              //       )
              //     : relatedFields
              // }
              relatedTableHeader={true}
              rowId={rowId}
              key={`${data.metric_display_name}_${index}`}
            />
          ),
        };
      } else return data;
    });
  };
  // useEffect(() => {
  //   if (row && activeColumnHeader?.length) {
  //     init();
  //   }
  // }, []);

  const getHeaderOrActiveHeader = (row, header, headerOrActiveHeader) => {
    let relatedTableHeader;
    let relatedActiveTableHeader;
    const allFieldsAndTableData =
      tilesData ?? JSON.parse(localStorage.getItem(`${tableName}TilesApiData`));

    relatedTableHeader = allFieldsAndTableData?.filter((data) => {
      if (data.hasOwnProperty(localStorage.getItem("manageHeader"))) {
        return data;
      }
    });

    const relatedTableAllFields =
      relatedTableHeader?.[0][localStorage.getItem("manageHeader")]
        .tile_related_table_fields;

    if (headerOrActiveHeader) {
      return relatedTableAllFields[header.metric_name];
    } else {
      relatedActiveTableHeader = relatedTableAllFields[
        header.metric_name
      ]?.filter((data) => data.selected !== false);
      return relatedActiveTableHeader;
    }
  };

  init();

  const handlePopoverOpen = (popOverId, e) => {
    e.currentTarget.id === expandViewTableFieldHeader
      ? setExpandViewTableFieldHeader(null)
      : setExpandViewTableFieldHeader(e.currentTarget.id);
    setIsOpen({ ...isOpen, [popOverId]: e.currentTarget });
  };

  const handlePopoverClose = (id) => {
    setExpandViewTableFieldHeader(null);
    setIsOpen({ ...isOpen, [id]: null });
  };

  return (
    <Box
      display={"flex"}
      gap={"15px"}
      flexDirection={"column"}
      width={
        expandViewFirst ? "100%" : taskView ? "100%" : leadView ? "100%" : "88%"
      }
    >
      {customComponentData &&
        customComponentData.map((data, index) => {
          const dataCell = {
            value:
              searchView && searchView !== "Tiles" && searchView !== "TaskView"
                ? row && tableName && data.metric_name
                  ? data.metric_datatype === "reference"
                    ? referenceData
                      ? referenceData &&
                        tableName !== "Task" &&
                        tableName !== "Tasks" &&
                        referenceData[tableName][data.metric_name]
                      : taskRelatedData &&
                        taskRelatedData[data.parentSobject]?.Id ===
                          row[tableName][data.metric_name]
                      ? taskRelatedData[data.parentSobject]?.Name
                      : row[tableName][data.metric_name]
                    : row[tableName][data.metric_name]
                  : ""
                : row && data?.child_field && data?.call_parent
                ? row[data.call_parent]
                  ? row[data.call_parent][data.metric_name]
                  : null
                : row && data.metric_datatype === "reference"
                ? row[data.parentSobject]?.Name &&
                  row[data.parentSobject]?.Name != ""
                  ? row[data.parentSobject]?.Name
                  : MainTaskRelatedNameData &&
                    Object.keys(MainTaskRelatedNameData)?.length
                  ? MainTaskRelatedNameData[data.metric_name]
                  : row[data.metric_name]
                : row[data.metric_name],
            id: rowId,
            isEditable: data.editable,
            field: data.metric_name,
            row: row,
            required: data.nullable,
            display: data.metric_display_name,
            creation: data.creatable,
            name: name,
            tableName: data?.child_field ? data.sobject : tableName,
            relatedListId: data.metric_related_listId
              ? data.metric_related_listId
              : null,
            related: data.related ? data.related : false,
            fieldType: data.metric_datatype,
            child_field: data?.child_field ? data.child_field : "",
          };
          if (data.metric_name === "Id" && !idFields) return;
          if (searchView && searchView !== "Tiles") {
            return (
              <Box
                display={"flex"}
                flexDirection={
                  searchView === "TaskView" || tableName === "Task"
                    ? "row"
                    : "column"
                }
                gap={"5px"}
                key={`${index}_Box`}
                width={(searchView === true || editTask) && "max-content"}
              >
                <TypoGraphy
                  width={
                    searchView === "TaskView" || tableName === "Task"
                      ? "125px"
                      : "auto"
                  }
                  style={{
                    fontSize: searchView === "TaskView" && "14px",
                    flex: searchView === "TaskView" && 1,
                  }}
                >
                  {!data.editable && searchView === "TaskView" ? (
                    <Lock
                      sx={{
                        fontSize: "14px",
                        color: "#9E9E9E",
                      }}
                    />
                  ) : (
                    <></>
                  )}
                  {data.metric_display_name.includes("ID")
                    ? data.metric_display_name.split("ID")[0]
                    : data.metric_display_name.split("Id")[0]}
                  {!data.nullable && <Box>{"*"}</Box>}
                </TypoGraphy>
                <Box
                  width={
                    taskView
                      ? "100%"
                      : !editTask
                      ? SearchDrawer
                        ? "460px"
                        : "auto"
                      : "auto"
                  }
                >
                  {EditOrViewCell(
                    dataCell,
                    data,
                    tableName,
                    onUpdateSuccessOfRow,
                    rowData,
                    setRowData,
                    searchView,
                    error,
                    handleError,
                    null,
                    null,
                    false,
                    taskView,
                    editTask,
                    taskViewOnly
                  )}
                </Box>
              </Box>
            );
          } else {
            return (
              <Box
                display={"flex"}
                flexDirection={"column"}
                gap={"5px"}
                key={`${index}Box`}
                id={`${index}`}
                width={"100%"}
                sx={{
                  cursor: data.related && "pointer",
                }}
                onMouseEnter={(event) => {
                  if (data.related && !expandViewTableFieldHeader) {
                    setOnHoverHeader(event.currentTarget.id);
                  }
                }}
                onMouseLeave={() => {
                  if (!expandViewTableFieldHeader) {
                    setOnHoverHeader(null);
                    // setExpandViewTableFieldHeader(null);
                  }
                }}
              >
                <Box
                  width={"100%"}
                  display={"flex"}
                  justifyContent="space-between"
                  position={"relative"}
                  key={`${index}InnerBox`}
                >
                  <TypoGraphy>
                    {data?.child_field
                      ? `${data.child_field.split("Id")[0]}:${
                          data.metric_display_name.split("ID")[0]
                        }`
                      : data.metric_display_name.split("ID")[0]}
                    {!data.editable ? (
                      <Lock
                        sx={{
                          fontSize: "14px",
                          color: "#9E9E9E",
                        }}
                      />
                    ) : (
                      <></>
                    )}
                    {!data.nullable ? <Box>{"*"}</Box> : <></>}
                  </TypoGraphy>
                  {data.related ? (
                    <Box
                      id={`${index}`}
                      display={"flex"}
                      gap={"5px"}
                      key={`${index}HeaderBox`}
                      sx={{
                        //"visible"
                        visibility:
                          parseInt(onHoverHeader) === parseInt(index)
                            ? "visible"
                            : "hidden",
                      }}
                    >
                      <ExpandViewTableFieldSVG
                        id={`${index}`}
                        style={{
                          height: "16px",
                          cursor: "pointer",
                        }}
                        onClick={(e) => handlePopoverOpen(data.popOverId, e)}
                      />
                    </Box>
                  ) : (
                    <></>
                  )}
                  {data.related && (
                    <RelatedTablePopOver
                      id={"simple-popover"}
                      open={Boolean(isOpen[data.popOverId])}
                      anchorEl={isOpen[data.popOverId]}
                      onClose={() => handlePopoverClose(data.popOverId)}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      key={data.popOverId}
                      // onBackdropClick={() => {
                      //   setOnHoverHeader(null);
                      //   setExpandViewTableFieldHeader(null);
                      //   setAnchorEl(null);
                      // }}
                    >
                      {data?.popOverContent ?? <></>}
                    </RelatedTablePopOver>
                  )}
                </Box>
                <Box
                  width={
                    taskView
                      ? "100%"
                      : !editTask
                      ? SearchDrawer
                        ? "460px"
                        : "auto"
                      : "auto"
                  }
                >
                  {EditOrViewCell(
                    dataCell,
                    data,
                    tableName,
                    onUpdateSuccessOfRow,
                    rowData,
                    setRowData,
                    null,
                    null,
                    null,
                    relatedTableLoader,
                    setRelatedTableLoader,
                    expandViewFirst,
                    taskView,
                    editTask,
                    taskViewOnly
                  )}
                </Box>
              </Box>
            );
          }
        })}
    </Box>
  );
};

export default RenderComponents;
