import styled from "@emotion/styled";
import { Box } from "@mui/system";
import { ReactComponent as LoaderSVG } from "../Assets/SVG/Loader.svg";

const CustomLoader = styled("ul")(({ theme }) => ({
  ".loader__item": {
    width: "12px",
    height: "12px",
    margin: "4px",
    background: theme.themeColor,
    listStyle: "none",
    animation: "animation-loading 1.2s infinite",

    "&:nth-child(1)": {
      animationDelay: "-0.3s",
    },
    "&:nth-child(2)": {
      animationDelay: "-0.15s",
    },
  },
  "@keyframes animation-loading": {
    "0%": {
      transform: "scale(0)",
    },
    "40%": {
      transform: "scale(1)",
    },
    "80%": {
      transform: "scale(0)",
    },
    "100%": {
      transform: "scale(0)",
    },
  },
}));

function Loader({
  handleWidthOrHeight,
  handleMargin,
  handleSearchMargin,
  leadView,
}) {
  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      height={"100%"}
    // width={handleMargin && "30px"}
    >
      {/* <CustomLoader
        class="loader"
        style={{ display: "flex", fontSize: leadView && "0px" }}
      >
        <li
          class="loader__item"
          style={{
            width: handleWidthOrHeight
              ? "6px"
              : handleSearchMargin
              ? "8px"
              : leadView
              ? "7px"
              : "",
            height: handleWidthOrHeight
              ? "6px"
              : handleSearchMargin
              ? "8px"
              : leadView
              ? "7px"
              : "",
            margin: handleMargin
              ? "0px 1px"
              : handleSearchMargin
              ? "0px 3px"
              : leadView
              ? "3px"
              : "",
          }}
        ></li>
        <li
          class="loader__item"
          style={{
            width: handleWidthOrHeight
              ? "6px"
              : handleSearchMargin
              ? "8px"
              : leadView
              ? "7px"
              : "",
            height: handleWidthOrHeight
              ? "6px"
              : handleSearchMargin
              ? "8px"
              : leadView
              ? "7px"
              : "",
            margin: handleMargin
              ? "0px 1px"
              : handleSearchMargin
              ? "0px 3px"
              : leadView
              ? "3px"
              : "",
          }}
        ></li>
        <li
          class="loader__item"
          style={{
            width: handleWidthOrHeight
              ? "6px"
              : handleSearchMargin
              ? "8px"
              : leadView
              ? "7px"
              : "",
            height: handleWidthOrHeight
              ? "6px"
              : handleSearchMargin
              ? "8px"
              : leadView
              ? "7px"
              : "",
            margin: handleMargin
              ? "0px 1px"
              : handleSearchMargin
              ? "0px 3px"
              : leadView
              ? "3px"
              : "",
          }}
        ></li>
      </CustomLoader> */}
      <LoaderSVG
        width={
          handleWidthOrHeight
            ? "20px"
            : handleSearchMargin
              ? "25px"
              : leadView
                ? "23px"
                : "50px"
        }
        height={
          handleWidthOrHeight
            ? "20px"
            : handleSearchMargin
              ? "25px"
              : leadView
                ? "23px"
                : "50px"
        }
      />
    </Box>
  );
}

export default Loader;
