import { AddBox, CloseRounded } from "@mui/icons-material";
import { ClickAwayListener, Divider, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import AccountContext from "../Context/new_Account_Context";
import {
  searchRecordsForMainTables,
  searchRecordsFromAllTheTables,
} from "../Pages/Services/tableApi";
import {
  MainLeadBoxCovertTypoGraphy,
  SearchDataBox,
  SearchDataScrollBox,
} from "./ExpandView/LeadView";
import SimpleSearchDropDown from "./ExpandView/SimpleSearchDropDown";

let checkTableNameExists = [];
function ReferenceSearchDropDown({
  setEditMode,
  editMode,
  rawData,
  onChangeHandler,
  searchBox = true,
  taskRelatedTo = false,
  overflow,
  searchView,
  tableName,
  pipelineReferenceFilter,
  selectedReferenceValues,
  removeFilterValues,
  setShowMode,
}) {
  const [searchLoader, setSearchLoader] = useState(false);
  const [searchRecords, setSearchRecords] = useState([]);
  const [searchApiResponse, setSearchApiResponse] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const initialRecordsLimit = {
    Opportunity: 5,
    Account: 5,
    Lead: 5,
    Contact: 5,
    Event: 5,
  };
  const [limit, setLimit] = useState(initialRecordsLimit);

  const FilterValueDropDownStyle = {
    display: "flex",
    flexWrap: "wrap",
    border: "1px solid #bcbcbc",
    borderRadius: "8px",
    width: "320px",
    minHeight: "33px",
    gap: "4px",
    padding: "0.25rem 0.25rem",
    alignItems: "center",
  };

  const handleSearchApi = async (value, table, rawData, loadMore) => {
    //debugger;
    setSearchLoader(true);
    setSearchApiResponse(false);
    let dataParams = {
      search_value: value,
    };

    dataParams = taskRelatedTo
      ? { ...dataParams, limit: 200, offset: 0 }
      : {
          ...dataParams,
          table_name: table,
          field_name: rawData.metric_name,
          offset: loadMore ? limit[table] : undefined,
        };

    const JSONdataParams = JSON.stringify(dataParams);
    const res = taskRelatedTo
      ? await searchRecordsForMainTables(JSONdataParams)
      : await searchRecordsFromAllTheTables(JSONdataParams);
    if (res?.data?.status === 200) {
      //debugger;
      !res.data.data.length && setSearchApiResponse(true);
      setSearchRecords(res.data.data);
      !taskRelatedTo &&
        setLimit({
          ...limit,
          [table]: limit[table] + 200,
        });
      if (loadMore) {
        let __tempSearchRecords = [...searchRecords];
        const indexOfFirstMatchTableRecord = __tempSearchRecords.findIndex(
          (data) => data.attributes.type === table
        );
        __tempSearchRecords.splice(
          indexOfFirstMatchTableRecord + 1,
          0,
          res.data.data
        );
        setSearchRecords(__tempSearchRecords.flat());
        checkTableNameExists.length = 0;
      }
    }
    setSearchLoader(false);
  };

  const handleShowMoreData = (tableName) => {
    let _tempLimit = { ...limit };
    _tempLimit = { ..._tempLimit, [tableName]: limit[tableName] + 5 };
    setLimit(_tempLimit);
  };
  const toggleDrawer = useContext(AccountContext);

  return (
    <ClickAwayListener onClickAway={() => setEditMode(false)}>
      <Box style={pipelineReferenceFilter && FilterValueDropDownStyle}>
        {pipelineReferenceFilter &&
          Object.keys(selectedReferenceValues)?.map((data, index) => (
            <MainLeadBoxCovertTypoGraphy>
              {selectedReferenceValues[data]}
              <CloseRounded
                color="none"
                fontSize="16px"
                sx={{
                  cursor: "pointer",
                }}
                id={`${index}`}
                onClick={() => removeFilterValues(data)}
              />
            </MainLeadBoxCovertTypoGraphy>
          ))}
        {searchBox && (
          <AddBox
            onClick={() => {
              setEditMode(!editMode);
            }}
          />
        )}
        <Box
          sx={
            !taskRelatedTo
              ? {
                  position: "absolute",
                  background: "white",
                  border: "1px solid #E6E9EB",
                  borderRadius: "8px",
                  boxShadow: "0 3px 10px rgb(0 0 0 / 10%)",
                  top: !pipelineReferenceFilter && "100%",
                  left: !pipelineReferenceFilter && "0%",
                  marginTop: pipelineReferenceFilter
                    ? editMode
                      ? "38px"
                      : "0px"
                    : "4px",
                  zIndex: "9",
                  bottom: pipelineReferenceFilter ? "-60px" : "auto",
                }
              : {
                  width: "300px",
                }
          }
        >
          {editMode ? (
            <SimpleSearchDropDown
              setEditMode={setEditMode}
              name={rawData.sobject}
              handleChange={(value, tableName) => {
                //debugger
                setSearchValue(value);
                if (value?.length > 1)
                  handleSearchApi(value, tableName, rawData);
              }}
              rawData={rawData}
              key={rawData.metric_display_name}
              searchLoader={searchLoader}
              taskRelatedTo={taskRelatedTo}
              handleCss={true}
            />
          ) : (
            <></>
          )}

          <SearchDataScrollBox
            sx={{
              maxHeight: searchRecords?.length
                ? "180px"
                : Object.keys(searchRecords)?.length
                ? "220px"
                : "max-content",
              fontSize: "14px",
              background: taskRelatedTo && "white",
              position: taskRelatedTo && "absolute",
              top: taskRelatedTo && !overflow && "100%",
              bottom: overflow && "125%",
              boxShadow: taskRelatedTo && "0 3px 10px rgb(0 0 0 / 10%)",
              zIndex: 2,
            }}
          >
            {!taskRelatedTo && searchRecords?.length ? (
              searchRecords.map((searchData, index) => {
                let showMoreFlag;
                let tableNameFlag;
                const length = searchRecords.filter(
                  (data) => data.attributes.type === searchData.attributes.type
                )?.length;
                if (checkTableNameExists.includes(searchData.attributes.type)) {
                  showMoreFlag = length > 4 ? true : false;
                  tableNameFlag = false;
                } else {
                  tableNameFlag = true;
                  checkTableNameExists.push(searchData.attributes.type);
                }
                if (pipelineReferenceFilter && !editMode) return;

                return (
                  <>
                    {tableNameFlag && (
                      <SearchDataBox color={"#6A727A"}>
                        {searchData.attributes.type}
                      </SearchDataBox>
                    )}
                    <Tooltip
                      className="iconHover"
                      placement="bottom"
                      title={searchData.Name}
                      PopperProps={{
                        disablePortal: true,
                        sx: {
                          "& .MuiTooltip-tooltip": {
                            width: "auto",
                            height: "16px",
                            background: "rgba(3, 46, 97, 0.72)",
                            borderRadius: "4px",
                            fontFamily: "Inter",
                          },
                        },
                      }}
                    >
                      <SearchDataBox
                        id={`${searchData.Id}`}
                        sx={{
                          //background: index === 0 && "#F6F8F9",
                          "&:hover": {
                            background: "#F6F8F9",
                          },
                        }}
                        onClick={(e) => {
                          const id = e.target.id;
                          onChangeHandler(id, searchData.Name);
                          pipelineReferenceFilter && setEditMode(false);
                          pipelineReferenceFilter && setSearchRecords([]);
                        }}
                      >
                        {searchData.Name}
                      </SearchDataBox>
                    </Tooltip>
                    {showMoreFlag &&
                    index === length &&
                    limit[searchData?.attributes?.type] <= length ? (
                      <SearchDataBox
                        id={`${searchData?.attributes?.type}`}
                        onClick={(e) => {
                          const tableName = e.target.id;
                          handleSearchApi(
                            searchValue,
                            tableName,
                            searchData,
                            true
                          );
                        }}
                        sx={{ fontSize: "12px" }}
                      >
                        {"Show More"}
                      </SearchDataBox>
                    ) : (
                      <></>
                    )}
                  </>
                );
              })
            ) : Object.keys(searchRecords)?.length ? (
              Object.keys(searchRecords)?.map((table) => {
                if (table === "Event") return;
                return (
                  <>
                    {searchRecords[table]?.length ? (
                      <SearchDataBox color={"#6A727A"}>{table}</SearchDataBox>
                    ) : (
                      ""
                    )}
                    {editMode &&
                      searchRecords[table].map((tableRecords, index) => {
                        return (
                          <>
                            {index < limit[table] && (
                              <SearchDataBox
                                id={`${tableRecords.Id}`}
                                sx={{
                                  background: index === 0 && "#F6F8F9",
                                }}
                                onClick={(e) => {
                                  const secondParams =
                                    tableRecords?.attributes?.type ===
                                      "Opportunity" ||
                                    tableRecords?.attributes?.type === "Account"
                                      ? "WhatId"
                                      : "WhoId";
                                  const id = e.target.id;
                                  onChangeHandler(
                                    id,
                                    secondParams,
                                    false,
                                    e.target.innerText
                                  );
                                }}
                              >
                                {tableRecords.Name}
                              </SearchDataBox>
                            )}
                            {index === limit[table] + 1 &&
                              limit[table] + 1 !==
                                searchRecords[table]?.length && (
                                <SearchDataBox
                                  id={`${tableRecords?.attributes?.type}`}
                                  onClick={(e) => {
                                    const tableName = e.target.id;
                                    handleShowMoreData(tableName);
                                  }}
                                  sx={{ fontSize: "12px" }}
                                >
                                  {"Show More"}
                                </SearchDataBox>
                              )}
                          </>
                        );
                      })}
                  </>
                );
              })
            ) : !searchApiResponse &&
              searchView === true &&
              rawData.metric_name === "AccountId" ? (
              <>
                <Divider />
                <SearchDataBox
                  style={{
                    width: "auto",
                    display: "flex",
                    alignTtems: "center",
                    //background: rgba(0,0,0,0);
                    height: "35px",
                    // width: 100%;
                    padding: "0 15px",
                    //appearance: none;
                    border: 0,
                    marginBottom: "4px",
                    borderTop: "1px",
                  }}
                  sx={{
                    "&:hover": {
                      background: "#f6f8f9",
                    },
                  }}
                  onClick={toggleDrawer(
                    "right",
                    true,
                    "Account",
                    "newAccFromOpp",
                    tableName
                  )}
                >
                  {"Create New account"}
                </SearchDataBox>
              </>
            ) : (
              editMode &&
              searchApiResponse && (
                <SearchDataBox
                  style={{
                    width: "auto",
                    padding: "0 14px",
                  }}
                  fontSize={"14px"}
                >
                  {"No Records Found"}
                </SearchDataBox>
              )
            )}
          </SearchDataScrollBox>
        </Box>
      </Box>
    </ClickAwayListener>
  );
}

export default ReferenceSearchDropDown;
