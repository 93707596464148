import styled from "@emotion/styled";
import { Box } from "@mui/material";
import React from "react";
import { SideBarSubContentBox } from "../SettingView";

const PlanBox = styled(Box)({
  position: "relative",
  padding: "20px",
  border: "1px solid #E6E9EB",
  borderRadius: "8px",
});

function BillingAndUsage() {
  return (
    <SideBarSubContentBox>
      <PlanBox
        sx={{
          marginTop: "1rem",
        }}
      >
        Free Plan
      </PlanBox>
    </SideBarSubContentBox>
  );
}

export default BillingAndUsage;
