import React from "react";
import { StyledAlert, StyledSnackBar } from "./Navbar";

function Toaster({ open, close, error }) {
  return (
    <StyledSnackBar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      autoHideDuration={3000}
      open={open}
      onClose={close}
      sx={{
        ".MuiPaper-root": {
          background: "#ff0000",
          width: "300px",
          maxHeight: "100px",
          height: "max-content",
        },
      }}
    >
      <StyledAlert onClose={close} severity={"error"}>
        {error}
      </StyledAlert>
    </StyledSnackBar>
  );
}

export default Toaster;
