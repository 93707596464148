const initialState = {
  opportunityFieldData: {},
  accountFieldData: {},
  contactFieldData: {},
  leadFieldData: {},
  eventFieldData: {},
  initialData: {},
};

const getTableFieldReducer = (state = initialState, action) => {
  switch (action.type) {
    case "opportunity_table_Field":
      return {
        ...state,
        opportunityFieldData: action.payload,
      };
    case "account_table_Field":
      return {
        ...state,
        accountFieldData: action.payload,
      };
    case "event_table_Field":
      return {
        ...state,
        eventFieldData: action.payload,
      };
    case "contact_table_Field":
      return {
        ...state,
        contactFieldData: action.payload,
      };
    case "lead_table_Field":
      return {
        ...state,
        leadFieldData: action.payload,
      };
    default:
      return state;
  }
};
export default getTableFieldReducer;
