import React, { useEffect, useRef, useState } from "react";
import { useNotes } from "../../Context/NotesContext";

import styled from "@emotion/styled";

import { TbDots } from "react-icons/tb";
import { AiOutlineCloudUpload, AiOutlineCloud } from "react-icons/ai";
import { GiClockwiseRotation } from "react-icons/gi";
import {
  HiOutlineDocumentDuplicate,
  HiOutlineExclamationCircle,
  HiOutlineTrash,
  HiOutlineBookmark,
  HiBookmark,
  HiOutlineDownload,
} from "react-icons/hi";

import Editor from "../Editor";
import DeleteModal from "../Sidebar/DeleteModal";
import TemplateModal from "./TemplateModal";

import {
  restoreNote,
  editNote,
  postNote,
  trashNote,
  syncNote,
  editNoteTitle,
  downloadPdf,
  unsyncNote,
} from "../../Pages/Services/notesApi";
import ShareModule from "./ShareModule";
import NotesInput from "./NotesInput";
import { ClickAwayListener, Tooltip } from "@mui/material";

import { useCallback } from "react";

const MainNotes = styled.div({
  backgroundColor: "#FFFFFF",
  width: "100%",
  height: "100%",
  display: "flex",
  flex: "1 1 auto",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
});

const NotesHeader = styled.div({
  width: "95%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

const NotesOptions = styled.div({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  fontWeight: "400",
  ":hover": { color: "#00A1E0" },
});

const SalesforceOptions = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  maxWidth: "38rem",
  gap: "16px",
  fontWeight: "600",
  color: "#80888F",
});

const NoteHeaderIcons = styled.div({
  zIndex: "5",
  cursor: "pointer",
  fontSize: "150%",
  position: "relative",
  display: "flex",
  alignItems: "center",
  fontWeight: "400",

  "& .headerIcons": {
    display: "none",
    top: "30px",
    right: "1px",
    position: "absolute",
    backgroundColor: "#44648A",
    color: "white",
    padding: "4px 8px",
    borderRadius: "8px",
    fontSize: "13px",
    width: "110px",
  },

  ":hover": {
    "& .headerIcons": {
      display: "block",
    },
  },
});

const NoteHeaderOptions = styled.div({
  zIndex: "4",
  position: "absolute",
  top: "30px",
  right: "-8px",
  // backgroundColor: "#FAFAFA",

  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "space-between",

  padding: "8px",

  backgroundColor: "#FFFFFF",
  width: "185px",
  height: "148px",
  borderRadius: "8px",
  boxShadow:
    "rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px",

  "& .NoteOptionsIcons": {
    color: "rgb(169, 178, 188)",

    fontSize: "18px",
    paddingRight: "4px",
  },
});

const NoteOptionsContent = styled.div({
  cursor: "pointer",
  fontSize: "14px",
  fontWeight: "400",
  width: "90%",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: "8px",
  color: "black",
  borderRadius: "8px",

  ":hover": {
    backgroundColor: "#F2F7FC",

    color: "#00A1E0",

    "& .NoteOptionsIcons": {
      color: "#00A1E0",
    },
  },
});

const NotesTitle = styled.input({
  width: "80%",
  wordWrap: "break-word",
  border: "0",
  outline: "none",
  color: "black",
  fontSize: "xx-large",
  fontWeight: "600",
});

const NotesEditor = styled.div({
  marginTop: "1rem",
  width: "90%",
  height: "80%",
});

const TrashNotes = styled.div({
  backgroundColor: "#FCEFEF",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  padding: "8px 0",
});

const TrashNotesHeader = styled.div({
  paddingLeft: "12px",
  marginRight: "2rem",
  fontWeight: "500",
});

const TrashNoteButtonContainer = styled.div({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  paddingRight: "8px",
});

const TrashButtonSecondary = styled.button({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  gap: "4px",
  border: "2px solid transparent",
  borderRadius: "8px",
  margin: "0 5px",
  padding: "4px 12px",
  backgroundColor: "#FCEFEF",
});

const TrashButtonPrimary = styled.button({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  gap: "4px",
  border: "2px solid transparent",
  margin: "0 5px",
  padding: "4px 12px",

  backgroundColor: "#FFE2E2",
  color: "#C23934",
  borderRadius: "8px",

  ":hover": {
    backgroundColor: "#C23934",
    color: "#FFFFFF",
  },
});

const SearchInputContainer = styled.div({
  padding: "8px 0",
  width: "100%",
});

const SalesForceObjectHover = styled.div({
  width: "100%",
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  position: "relative",

  "& .editHover": {
    display: "none",
    padding: "0 8px",
  },

  ":hover": {
    "& .editHover": {
      display: "block",
    },
  },

  "& .extraSalesforceObject": {
    position: "absolute",
    top: "35px",
    right: "10px",
    zIndex: "3",
    width: "10rem",

    margin: "0 4px",
    padding: "6px 12px",
    borderRadius: "16px",
    backgroundColor: "#F3F6F8",
    color: "#898C8D",
  },
});

const SalesforceSync = styled.div({
  position: "relative",
  display: "flex",
  fontSize: "25px",
  cursor: "pointer",

  "& .salesforceSyncHover": {
    textAlign: "center",
    position: "absolute",
    display: "none",
    width: "7rem",
    top: "1rem",
    right: "-3rem",
    fontSize: "13px",
    backgroundColor: "#44648A",
    color: "white",
    padding: "4px 12px",
    borderRadius: "8px",
    fontWeight: "400",
  },

  ":hover": {
    "& .salesforceSyncHover": {
      display: "block",
    },
  },
});

function Notes({ setShowNotesExtended, showNotesExtedned }) {
  const inputRef = useRef();
  const { stateNotes, dispatchNotes } = useNotes();

  const { singleNote } = stateNotes;

  const {
    id,
    title,
    content,
    is_trash,
    is_template,
    template,
    salesforce_array,
    salesforce_url,
    children,
  } = singleNote;

  const [previousId, setPreviousId] = useState("temp");

  const [oldTitle, setTitle] = useState("");

  useEffect(() => {
    setTitle(title);
    inputRef?.current?.focus();

    // eslint-disable-next-line
  }, [id, template]);

  // function debounce(func, timeout = 300) {
  //   let timer;
  //   return (...args) => {
  //     clearTimeout(timer);
  //     timer = setTimeout(() => {
  //       func.apply(this, args);
  //     }, timeout);
  //   };
  // }

  useEffect(() => {
    if (localStorage.getItem("searchAddNote") === "true") {
      const NewNote = {
        parent: null,
        title: "",
        content: {
          blocks: [],
          time: Date.now(),
          version: "2.25.0",
        },
      };

      const tempLocation = localStorage.getItem("searchAddNoteLocation");

      if (tempLocation !== "/") {
        postNote(dispatchNotes, NewNote);
      }
      localStorage.setItem("searchAddNote", false);
    }
  }, [localStorage.getItem("searchAddNote")]);

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  // const titleChangeHandler = (newTitle) => {
  //   const newSingleNote = { ...singleNote, title: newTitle };
  //   editNoteTitle(dispatchNotes, newSingleNote);
  // };

  const handleChange = (newTitle) => {
    const newSingleNote = { ...singleNote, title: newTitle };
    editNoteTitle(dispatchNotes, newSingleNote);
  };

  // const debounceTitle = debounce((newTitle) => titleChangeHandler(newTitle));
  const optimizedFn = useCallback(debounce(handleChange), [id]);

  const [showModal, setShowModal] = useState(false);

  const [showTemplate, setShowTemplate] = useState(false);

  const [showShareModule, setShowShareMolule] = useState(false);

  const [showNoteOptions, setShowNoteOptions] = useState(false);

  const duplicateNoteHandler = () => {
    const NewNote = {
      parent: null,
      title: title + " Copy",
      children: children,
      content: content,
    };
    postNote(dispatchNotes, NewNote);
  };

  const TemplateHandlerFunction = () => {
    const newSingleNote = { ...singleNote, is_template: !is_template };
    editNote(dispatchNotes, newSingleNote);
  };

  const [showSearchInput, setShowSearchInput] = useState(false);
  const [selectedTags, setSelectedTage] = useState([]);
  const [showExtraTags, setShowExtraTags] = useState(false);

  useEffect(() => {
    salesforce_array ? setSelectedTage(salesforce_array) : setSelectedTage([]);

    setShowNotesExtended(false);

    if (previousId !== id) {
      setPreviousId(id);
    }
  }, [id]);

  const notesSearchRef = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        showSearchInput &&
        notesSearchRef.current &&
        !notesSearchRef.current.contains(e.target)
      ) {
        setShowSearchInput(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showSearchInput]);

  const extraTagsRef = useRef();
  const noteHeaderOptionsRef = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        showExtraTags &&
        extraTagsRef.current &&
        !extraTagsRef.current.contains(e.target)
      ) {
        setShowExtraTags(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showExtraTags]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (
        showNoteOptions &&
        noteHeaderOptionsRef.current &&
        !noteHeaderOptionsRef.current.contains(e.target)
      ) {
        setShowNoteOptions(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [showNoteOptions]);

  const syncNoteHandler = (newSingleNote) => {
    syncNote(dispatchNotes, newSingleNote);
  };
  const unsyncNoteHandler = () => {
    unsyncNote(dispatchNotes, singleNote);
  };

  return (
    <MainNotes>
      {is_trash && (
        <TrashNotes>
          <TrashNotesHeader>This note is in trash </TrashNotesHeader>

          <TrashNoteButtonContainer>
            <TrashButtonSecondary
              onClick={() => restoreNote(dispatchNotes, id)}
            >
              <GiClockwiseRotation />
              Restore
            </TrashButtonSecondary>
            <TrashButtonPrimary onClick={() => setShowModal(true)}>
              <HiOutlineExclamationCircle />
              Delete Permanently
            </TrashButtonPrimary>
          </TrashNoteButtonContainer>
        </TrashNotes>
      )}
      <NotesHeader>
        <Tooltip
          className="iconHover"
          PopperProps={{
            sx: {
              "& .MuiTooltip-tooltip": {
                width: "auto",
                height: "auto",
                background: "rgba(3, 46, 97, 0.72)",
                borderRadius: "4px",
                fontFamily: "Inter",
                textAlign: "center",
                fontSize: "12px",
              },
            },
          }}
          placement="bottom"
          title={title}
        >
          <h4
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              maxWidth: "18rem",
              height: "1.2em",
              whiteSpace: "nowrap",
            }}
          >
            {title}
          </h4>
        </Tooltip>

        {!is_trash && (
          <SalesforceOptions>
            <SearchInputContainer ref={notesSearchRef}>
              {showSearchInput === false && selectedTags?.length === 0 && (
                <NotesOptions
                  onClick={() => setShowSearchInput((prev) => !prev)}
                >
                  Link to Salesforce
                </NotesOptions>
              )}

              {showSearchInput === false && selectedTags?.length !== 0 && (
                <SalesForceObjectHover>
                  <NotesOptions
                    className="editHover"
                    onClick={() => setShowSearchInput(true)}
                  >
                    Edit
                  </NotesOptions>
                  {selectedTags?.length > 1 ? (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        padding: "0 8px",
                      }}
                    >
                      {selectedTags?.slice(0, 1).map((item) => (
                        <Tooltip
                          className="iconHover"
                          PopperProps={{
                            sx: {
                              "& .MuiTooltip-tooltip": {
                                width: "auto",
                                height: "16px",
                                background: "rgba(3, 46, 97, 0.72)",
                                borderRadius: "4px",
                                fontFamily: "Inter",
                                textAlign: "center",
                                fontSize: "12px",
                              },
                            },
                          }}
                          placement="bottom"
                          title={item.name}
                        >
                          <div
                            key={item.id}
                            onClick={() => {
                              dispatchNotes({
                                type: "SET_SALESFORCE_OBJECT",
                                payload: {
                                  salesforceId: item.id,
                                  type: item.type,
                                },
                              });

                              setShowNotesExtended(true);
                            }}
                            style={{
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              maxWidth: "12rem",
                              height: "1.2em",
                              whiteSpace: "nowrap",
                              margin: "0 4px",
                              padding: "4px 12px",
                              borderRadius: "16px",
                              backgroundColor: "#F3F6F8",
                              color: "#898C8D",
                            }}
                          >
                            {item.name}
                          </div>
                        </Tooltip>
                      ))}
                      <div
                        onClick={() => {
                          setShowExtraTags((prev) => !prev);
                        }}
                        style={{
                          margin: "0 4px",
                          padding: "4px 12px",
                          borderRadius: "16px",
                          backgroundColor: "#F3F6F8",
                          color: "#898C8D",
                        }}
                      >
                        +{selectedTags?.length - 1}
                      </div>

                      {showExtraTags && (
                        <div
                          className="extraSalesforceObject"
                          ref={extraTagsRef}
                        >
                          {selectedTags
                            ?.slice(1, selectedTags?.length)
                            .map((item) => (
                              <Tooltip
                                className="iconHover"
                                PopperProps={{
                                  sx: {
                                    "& .MuiTooltip-tooltip": {
                                      width: "auto",
                                      height: "16px",
                                      background: "rgba(3, 46, 97, 0.72)",
                                      borderRadius: "4px",
                                      fontFamily: "Inter",
                                      textAlign: "center",
                                      fontSize: "12px",
                                    },
                                  },
                                }}
                                placement="bottom"
                                title={item.name}
                              >
                                <div
                                  key={item.id}
                                  onClick={() => {
                                    dispatchNotes({
                                      type: "SET_SALESFORCE_OBJECT",
                                      payload: {
                                        salesforceId: item.id,
                                        type: item.type,
                                      },
                                    });

                                    setShowNotesExtended(true);
                                  }}
                                  style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    maxWidth: "12rem",
                                    height: "1.2em",
                                    whiteSpace: "nowrap",
                                    margin: ".5rem",
                                  }}
                                >
                                  {item.name}
                                </div>
                              </Tooltip>
                            ))}
                        </div>
                      )}
                    </div>
                  ) : (
                    selectedTags?.map((item) => (
                      <Tooltip
                        className="iconHover"
                        PopperProps={{
                          sx: {
                            "& .MuiTooltip-tooltip": {
                              width: "auto",
                              height: "16px",
                              background: "rgba(3, 46, 97, 0.72)",
                              borderRadius: "4px",
                              fontFamily: "Inter",
                              textAlign: "center",
                              fontSize: "12px",
                            },
                          },
                        }}
                        placement="bottom"
                        title={item.name}
                      >
                        <div
                          key={item.id}
                          onClick={() => {
                            dispatchNotes({
                              type: "SET_SALESFORCE_OBJECT",
                              payload: {
                                salesforceId: item.id,
                                type: item.type,
                              },
                            });

                            setShowNotesExtended(true);
                          }}
                          style={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            maxWidth: "12rem",
                            height: "1.2em",
                            whiteSpace: "nowrap",
                            margin: "0 4px",
                            padding: "4px 12px",
                            borderRadius: "16px",
                            backgroundColor: "#F3F6F8",
                            color: "#898C8D",
                          }}
                        >
                          {item.name}
                        </div>
                      </Tooltip>
                    ))
                  )}
                </SalesForceObjectHover>
              )}

              {showSearchInput === true && (
                <div>
                  <NotesInput
                    selectedTags={selectedTags}
                    setSelectedTage={setSelectedTage}
                    setShowSearchInput={setShowSearchInput}
                    setShowNotesExtended={setShowNotesExtended}
                    syncNoteHandler={syncNoteHandler}
                    unsyncNoteHandler={unsyncNoteHandler}
                  />
                </div>
              )}
            </SearchInputContainer>

            <NotesOptions onClick={() => setShowShareMolule(true)}>
              Share
            </NotesOptions>
            {selectedTags.length !== 0 && (
              <NotesOptions>
                {salesforce_url?.length !== 0 ? (
                  <SalesforceSync
                    onClick={() => {
                      window.open(salesforce_url, "_blank").focus();
                    }}
                  >
                    <AiOutlineCloud />
                    <p className="salesforceSyncHover">View in Salesforce</p>
                  </SalesforceSync>
                ) : (
                  <div />
                )}

                {/* //////////////////////// */}
              </NotesOptions>
            )}

            <NoteHeaderIcons>
              <NotesOptions>
                <TbDots onClick={() => setShowNoteOptions((prev) => !prev)} />
              </NotesOptions>

              <div className="headerIcons">Duplicate, delete & more</div>

              {showNoteOptions && (
                <ClickAwayListener
                  onClickAway={() => {
                    if (showNoteOptions) {
                      setShowNoteOptions(false);
                    }
                  }}
                >
                  <NoteHeaderOptions>
                    <NoteOptionsContent
                      onClick={() => {
                        downloadPdf(id);
                        setShowNoteOptions(false);
                      }}
                    >
                      <HiOutlineDownload className="NoteOptionsIcons" />

                      <div>Download</div>
                    </NoteOptionsContent>

                    {is_template ? (
                      <NoteOptionsContent
                        onClick={() => {
                          TemplateHandlerFunction();
                          setShowNoteOptions(false);
                        }}
                      >
                        <HiBookmark className="NoteOptionsIcons" />

                        <div>Remove as Template</div>
                      </NoteOptionsContent>
                    ) : (
                      <NoteOptionsContent
                        onClick={() => {
                          TemplateHandlerFunction();
                          setShowNoteOptions(false);
                        }}
                      >
                        <HiOutlineBookmark className="NoteOptionsIcons" />
                        <div>Add as Template</div>
                      </NoteOptionsContent>
                    )}

                    <NoteOptionsContent
                      onClick={() => {
                        duplicateNoteHandler();
                        setShowNoteOptions(false);
                      }}
                    >
                      <HiOutlineDocumentDuplicate className="NoteOptionsIcons" />
                      <div>Duplicate</div>
                    </NoteOptionsContent>

                    <NoteOptionsContent
                      onClick={() => {
                        trashNote(dispatchNotes, id);
                        setShowNoteOptions(false);
                      }}
                    >
                      <HiOutlineTrash className="NoteOptionsIcons" />
                      <div>Trash</div>
                    </NoteOptionsContent>
                  </NoteHeaderOptions>
                </ClickAwayListener>
              )}
            </NoteHeaderIcons>
          </SalesforceOptions>
        )}
      </NotesHeader>

      <NotesTitle
        placeholder="Untitled"
        ref={inputRef}
        type="text"
        value={oldTitle}
        onChange={(e) => {
          setTitle(e.target.value);
          // debounceTitle(e.target.value);
          optimizedFn(e.target.value);
        }}
        autoFocus
      />

      {content?.blocks?.length === 0 && (
        <div style={{ width: "80%", marginTop: "1rem" }}>
          Select from{" "}
          <span
            style={{ color: "#00A1E0", cursor: "pointer" }}
            onClick={() => setShowTemplate(true)}
          >
            Templates
          </span>
        </div>
      )}

      {content && previousId === id && (
        <NotesEditor>
          <Editor
            content={content}
            id={id}
            template={template}
            showNotesExtedned={showNotesExtedned}
          />
        </NotesEditor>
      )}

      <DeleteModal
        showModal={showModal}
        onClose={() => setShowModal(false)}
        item={singleNote}
      />

      <TemplateModal
        id={id}
        showTemplate={showTemplate}
        onClose={() => setShowTemplate(false)}
      />

      <ShareModule
        showShareModule={showShareModule}
        onClose={() => setShowShareMolule(false)}
      />
    </MainNotes>
  );
}

export default Notes;
