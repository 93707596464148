import { Divider } from "@mui/material";
import React from "react";
import { SideBarSubContentBox, SideBarSubContentHeader } from "../SettingView";

function PlansSubscription() {
  return (
    <SideBarSubContentBox>
      <SideBarSubContentHeader>Plans</SideBarSubContentHeader>
      <Divider
        sx={{
          marginTop: "1rem",
        }}
      ></Divider>
    </SideBarSubContentBox>
  );
}

export default PlansSubscription;
