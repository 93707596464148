import {
  Box,
  Checkbox,
  ClickAwayListener,
  Divider,
  Input,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import { ExpandMoreRounded, CloseRounded, Add } from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";
import useComponentVisible from "../../Hooks/useComponentVisible";
import { ReactComponent as SearchSVG } from "../../Assets/SVG/Search.svg";
import { ReactComponent as PinSVG } from "../../Assets/SVG/Pin.svg";
import { ReactComponent as PinnedPinSVG } from "../../Assets/SVG/PinnedPin.svg";
import { ReactComponent as RowSizeManipulateSVG } from "../../Assets/SVG/RowSizeManipulate.svg";
import SimpleDropDown from "../SimpleDropDown";
import DatePickers from "../Date";

const TableFilter = styled("div")({
  display: "flex",
  flexFlow: "column",
  // width: "180px",
  position: "relative",
  zIndex: 2,
  // margin: "0px 5px",
});

export const DefaultValue = styled("div")({
  width: "90%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  cursor: "pointer",
});

export const ColumnItems = styled(Box)({
  position: "absolute",
  maxHeight: "428px",
  overflow: "hidden",
  display: "block",
  top: "40px",
  left: "0",
  marginTop: "0.2rem",
  background: "#ffffff",
  border: "1px solid #d0d0d0",
  boxSizing: "border-box",
  border: "1px solid #F5F5F5",
  boxShadow: "12px  8px 24px rgba(0, 0, 0, 0.1)",
  borderRadius: "8px",
  minWidth: "150px",
  width: "100%",
  "&::-webkit-scrollbar": {
    width: "0.5em",
    marginRight: "4px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(181,188,194,0.7)",
    borderRadius: "10px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(181,188,194,0.7)",
    borderRadius: "10px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "rgba(181,188,194,0.9)",
  },
});

const Legend = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "1px 5px",
  // zoom: "0.9",
});

const DropDownBox = styled(Box)({
  textOverflow: "ellipsis",
  overflow: "hidden",
  width: "100%",
  display: "flex",
  alignItems: "center",
  whiteSpace: "nowrap",
  padding: "0px 12px",
  height: "31px",
  fontSize: "14px",
});

export const SearchText = styled(Input)({
  width: "100%",
  fontSize: "14px",
  padding: "8px 1rem",
  position: "sticky",
  top: 0,
  background: "white",
  fontFamily: "Inter",
});

export const ViewBox = styled(Box)({
  border: "none",
  borderRadius: "8px",
  padding: "0.3rem 0.5rem",
  fontSize: "14px",
  cursor: "pointer",
  overflow: "hidden",
  width: "180px",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
});

export const DropDownValueEllipsisBox = styled(Box)({
  overflow: "hidden",
  width: "100%",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  fontWeight: "400",
  marginLeft: "12px",
});

function FilterColumn({
  header,
  handleData,
  handlePinnedData,
  searching,
  value,
  dropDownClose = false,
  checkable = false,
  widthAdjustment,
  allTilesDropDown,
  widthOfDate,
  filterRows,
  name,
  tableName,
  taskSortValue,
  taskFilterLoader,
  handleChangeForDuplicate,
  handleDefaultNewTile,
  fromSimpleDropDown = false,
  setFromSimpleDropDown,
  onChange,
  avoidViewBox,
  setEditMode,
  taskFilterLeftAlignmentValue,
  widthOfFilterType,
  noTargetText = false,
}) {
  const activeTab = localStorage.getItem("activeTab");

  const width = {
    Opportunities: "210px",
    Contacts: "185px",
    Events: "169px",
    Leads: "164px",
    Accounts: "186px",
  };

  var dropdown = fromSimpleDropDown;
  // const [optionState, setOptionState] = useState(false);
  const [currentHeaders, setCurrentHeaders] = useState([]);
  const [searchHeader, setSearchHeaders] = useState([]);
  const [search, setSearch] = useState("");
  const [svgColor, setSvgColorChange] = useState(false);
  const [pinVisibility, setPinVisibility] = useState(null);
  const [filterOptionState, setFilterOptionState] = useState(false);
  const [openDate, setOpenDate] = useState(false);
  const {
    isComponentVisible: optionState,
    setIsComponentVisible: setOptionState,
    ref,
  } = useComponentVisible(false);

  const pickListDropOpen =
    optionState ||
    widthAdjustment === "handleBox" ||
    widthAdjustment === "handleTiles" ||
    filterOptionState ||
    fromSimpleDropDown;

  let shouldPopClose = true;
  useEffect(() => {
    //debugger;
    if (header) {
      let tempHeader = [...header];
      name === "sortPinnedTiles" &&
        tempHeader.sort((a, b) => {
          if (a.selected === b.selected) return 0;
          if (a.selected > b.selected) return -1;
          return 1;
        });
      tempHeader = tempHeader.filter((d) => d);
      setCurrentHeaders([...tempHeader]);
    }
  }, [header]);

  useEffect(() => {
    // debugger;
    let tempHeaders = [...currentHeaders];
    if (search.length) {
      fromSimpleDropDown
        ? (tempHeaders = tempHeaders.filter((data) => {
            if (data.toLowerCase().includes(search.toLowerCase())) return true;
          }))
        : (tempHeaders = tempHeaders.filter((data) => {
            if (
              data?.metric_display_name &&
              data?.metric_display_name
                .toLowerCase()
                .includes(search.toLowerCase())
            )
              return true;
          }));
    }
    setSearchHeaders(tempHeaders);
  }, [currentHeaders, search]);
  //debugger;
  return (
    <ClickAwayListener
      onClickAway={() => {
        setOptionState(false);
      }}
    >
      <TableFilter>
        {widthAdjustment === "handleBox" ? (
          <></>
        ) : widthAdjustment === "handleTiles" ? (
          <></>
        ) : widthAdjustment === "RowResize" ? (
          <RowSizeManipulateSVG
            onClick={() => {
              setOptionState(!optionState);
            }}
            onMouseOver={() => {
              setSvgColorChange(true);
            }}
            onMouseLeave={() => {
              setSvgColorChange(false);
            }}
            stroke={svgColor ? "#00A1E0" : "#798EA3"}
            cursor={"pointer"}
            marginLeft={"8px"}
          />
        ) : (
          !fromSimpleDropDown && (
            <ViewBox
              onClick={() => {
                widthAdjustment === "task"
                  ? setFilterOptionState(!filterOptionState)
                  : setOptionState(!optionState);
              }}
              bgcolor={optionState ? "#EDF2FA" : "#f6f6f6"}
              sx={{
                pointerEvents: taskFilterLoader ? "none" : "pointer",
                borderRadius: widthAdjustment === "main" ? "8px" : "4px",
                width:
                  widthAdjustment === "main"
                    ? width[activeTab]
                    : widthAdjustment === "filterName"
                    ? "251px"
                    : widthAdjustment === "filterType"
                    ? widthOfFilterType
                    : widthAdjustment === "filterValue"
                    ? widthOfDate
                      ? "auto"
                      : "278px"
                    : "auto",
                cursor: "pointer",
              }}
            >
              <Tooltip
                title={
                  widthAdjustment === "main"
                    ? value.length >= 16
                      ? value
                      : ""
                    : widthAdjustment === "filterName"
                    ? ""
                    : widthAdjustment === "filterType"
                    ? value.length >= 5
                      ? value
                      : ""
                    : widthAdjustment === "filterValue"
                    ? widthOfDate
                      ? ""
                      : ""
                    : ""
                }
                PopperProps={{
                  disablePortal: true,
                }}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <DropDownValueEllipsisBox>
                    {!checkable
                      ? value
                      : value && value.map((data) => `${data},`)}
                  </DropDownValueEllipsisBox>
                  <ExpandMoreRounded
                    style={
                      optionState || filterOptionState || fromSimpleDropDown
                        ? { transform: "scaleY(-1)" }
                        : { transform: "scaleY(1)" }
                    }
                  />
                </Box>
              </Tooltip>
            </ViewBox>
          )
        )}

        {pickListDropOpen ? (
          <ClickAwayListener
            onClickAway={() => {
              setFilterOptionState(false);
              //setFromSimpleDropDown(fromSimpleDropDown);
              fromSimpleDropDown && setEditMode(false);
            }}
          >
            <ColumnItems
              role="button"
              tabIndex={0}
              onMouseDown={() => {
                shouldPopClose = false;
                setTimeout(() => {
                  shouldPopClose = true;
                }, 200);
              }}
              onBlur={(event) => {
                event.stopPropagation();
                // if (
                //   fromSimpleDropDown &&
                //   !event.currentTarget.contains(event.relatedTarget)
                // ) {
                //   setFilterOptionState(false);
                //   //e.stopPropagation();
                //   //debugger;
                //   //setFromSimpleDropDown(fromSimpleDropDown);
                //   fromSimpleDropDown && setEditMode(false);
                // }
              }}
              style={
                widthAdjustment === "handleBox"
                  ? {
                      left: "0px",
                      top: "0px",
                      minWidth: "200px",
                    }
                  : allTilesDropDown
                  ? {
                      top: 0,
                      maxHeight: "398px",
                      height: "max-content",
                      width: "200px",
                      overflowY: "scroll",
                    }
                  : widthAdjustment === "RowResize"
                  ? {
                      top: "20px",
                      width: "200px",
                      paddingBottom: "16px",
                    }
                  : widthAdjustment === "task"
                  ? {
                      minWidth: "200px",
                      // left: "-141px",
                      position: "absolute",
                      padding: "8px 0px",
                      left: taskFilterLeftAlignmentValue,
                    }
                  : fromSimpleDropDown
                  ? {
                      width: "194px",
                    }
                  : {}
              }
            >
              {searching && (
                <>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    padding={"0px 12px"}
                  >
                    <Box height={"17px"}>
                      <SearchSVG />
                    </Box>

                    <SearchText
                      size="normal"
                      value={search}
                      placeholder="Search"
                      disableUnderline
                      onChange={(e) => setSearch(e.target.value)}
                      autoFocus
                    />
                    <Box height={"17px"}>
                      {search && (
                        <CloseRounded
                          color="none"
                          fontSize="16px"
                          onClick={() => {
                            // debugger;
                            search && setSearch("");
                          }}
                          sx={{
                            cursor: "pointer",
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      background: "#E6E6E6",
                    }}
                  >
                    <Divider />
                  </Box>
                </>
              )}
              {(widthAdjustment === "handleBox" ||
                widthAdjustment === "handleTiles" ||
                widthAdjustment === "RowResize" ||
                (widthAdjustment === "filterName" && fromSimpleDropDown) ||
                widthAdjustment === "task") && (
                <Typography
                  sx={{
                    fontSize: "12px",
                    lineHeight: "15px",
                    color: "#798EA3",
                    marginLeft: "12px",
                  }}
                  fontFamily={"Inter"}
                  padding={
                    widthAdjustment === "RowResize"
                      ? "8px 0px 0px 12px"
                      : widthAdjustment === "task"
                      ? "4px 0px 0px 12px"
                      : "8px 0px 2px 8px"
                  }
                  letterSpacing={"1px"}
                  fontSize={widthAdjustment === "task" ? "11px" : "14px"}
                  height={widthAdjustment === "RowResize" && "25px"}
                  color={
                    widthAdjustment === "RowResize" ||
                    (widthAdjustment === "task" && "#ABADB3")
                  }
                >
                  {widthAdjustment === "handleBox"
                    ? "Action Tiles"
                    : widthAdjustment === "RowResize"
                    ? "Select row height"
                    : widthAdjustment === "task"
                    ? "VIEW"
                    : "My Tiles"}
                </Typography>
              )}
              <Box
                maxHeight={allTilesDropDown ? "100%" : "205px"}
                margin={widthAdjustment === "RowResize" ? "0px" : "4px -4px"}
                marginRight={widthAdjustment === "RowResize" ? "0px" : "6px"}
                sx={{
                  paddingLeft: "12px",
                  overflowY: searchHeader.length > 6 ? "scroll" : "hidden",
                  scrollBehavior: "smooth",
                  "&::-webkit-scrollbar": {
                    width: "0.5em",
                    marginRight: "4px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "rgba(181,188,194,0.7)",
                    borderRadius: "10px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "rgba(181,188,194,0.7)",
                    borderRadius: "10px",
                  },
                  "&::-webkit-scrollbar-thumb:hover": {
                    backgroundColor: "rgba(181,188,194,0.9)",
                  },
                }}
              >
                {searchHeader &&
                  searchHeader.map((data, index) => {
                    if (
                      widthAdjustment === "filterName" &&
                      !data?.filterable &&
                      !fromSimpleDropDown
                    )
                      return;
                    if (data.metric_datatype === "id") return;
                    return (
                      <Legend
                        id={`${index}`}
                        // title={data?.metric_display_name?.split("ID")[0] || ""}
                        onMouseEnter={(event) => {
                          searchHeader.length - 1 !== index &&
                            setPinVisibility(event.currentTarget.id);
                        }}
                        onMouseLeave={() =>
                          searchHeader.length - 1 !== index &&
                          setPinVisibility(null)
                        }
                        sx={{
                          background:
                            (localStorage.getItem(
                              `${tableName}RowSizeIndicator`
                            ) ?? "Medium") === data?.metric_display_name
                              ? "#F2F8FC"
                              : widthAdjustment === "task" &&
                                value === data?.metric_display_name
                              ? "#eff5fc"
                              : widthAdjustment === "task" &&
                                data?.metric_display_name === "Custom" &&
                                new Date(value).getDate() &&
                                "#eff5fc",
                        }}
                      >
                        <DropDownBox
                          sx={{
                            paddingLeft: "12px",
                            "&:hover": {
                              cursor: "pointer",
                              borderRadius: "8px",
                              backgroundColor: "#F2F7FC",
                              color: "#00A1E0",
                            },
                          }}
                          className={data?.metric_name}
                          color={
                            (widthAdjustment === "handleTiles" ||
                              widthAdjustment === "handleBox") &&
                            data?.metric_display_name === "New tile" &&
                            searchHeader.length - 1 === index
                              ? "#00A1E0"
                              : ""
                          }
                          onClick={(e) => {
                            //debugger;
                            e.stopPropagation();
                            let val = "";
                            if (!noTargetText) {
                              val = e.target.innerText;
                            } else {
                              val = data?.metric_name;
                            }
                            if (
                              widthAdjustment === "handleBox" ||
                              widthAdjustment === "RowResize"
                            ) {
                              handleData(val);
                              data?.metric_display_name === "New tile"
                                ? handleChangeForDuplicate &&
                                  handleChangeForDuplicate(true)
                                : handleChangeForDuplicate &&
                                  handleChangeForDuplicate(false);
                              data?.metric_display_name === "New tile" &&
                                widthAdjustment === "handleBox" &&
                                handleDefaultNewTile();
                              widthAdjustment === "RowResize" &&
                                setOptionState(false);
                            } else if (widthAdjustment === "task") {
                              val !== "Custom" &&
                                handleData(
                                  val,
                                  widthAdjustment === "task" && "view"
                                );
                              val !== "Custom" &&
                                setFilterOptionState(!filterOptionState);
                              val === "Custom" && setOpenDate(!openDate);
                            } else if (widthAdjustment === "handleTiles") {
                              val === "New tile" &&
                                searchHeader.length - 1 === index &&
                                handleData(val);
                            } else if (fromSimpleDropDown) {
                              onChange(val);
                            } else {
                              //debugger;
                              const parentDataType = data?.filter_datatype;
                              const value =
                                parentDataType === "picklist" ||
                                parentDataType === "multipicklist"
                                  ? e.target.value || e.target.innerText
                                  : e.target.innerText;

                              if (!dropDownClose) {
                                setOptionState(false);
                              }
                              handleData(
                                value,
                                parentDataType,
                                data?.call_parent
                                  ? `${data.call_parent}.${data?.metric_name}`
                                  : data?.metric_name,
                                data?.metric_datatype,
                                ""
                              );
                            }
                          }}
                          style={{
                            height: widthAdjustment === "task" && "30px",
                          }}
                        >
                          {(widthAdjustment === "handleBox" ||
                            widthAdjustment === "handleTiles") && (
                            <Box
                              sx={{
                                transform:
                                  widthAdjustment === "handleTiles" ||
                                  widthAdjustment === "handleBox"
                                    ? "scale(0.8)"
                                    : "scale(0.6)",
                                paddingTop:
                                  widthAdjustment === "handleTiles" ||
                                  widthAdjustment === "handleBox"
                                    ? "0px"
                                    : "4px",
                                paddingRight:
                                  widthAdjustment === "handleTiles" ||
                                  widthAdjustment === "handleBox"
                                    ? "8px"
                                    : "4px",
                              }}
                            >
                              {data?.metric_display_name === "New tile" &&
                              searchHeader.length - 1 === index ? (
                                <Add sx={{ marginLeft: "-8px" }} />
                              ) : (
                                "#"
                              )}
                            </Box>
                          )}
                          {checkable && (
                            <Checkbox
                              name={data?.metric_name}
                              checked={data?.isVisible}
                              value={data?.metric_display_name}
                              sx={{
                                transform: "scale(0.8)",
                              }}
                            />
                          )}

                          {/* ----------- */}
                          <Tooltip
                            className="iconHover"
                            PopperProps={{
                              sx: {
                                "& .MuiTooltip-tooltip": {
                                  width: "auto",
                                  height: "16px",
                                  background: "rgba(3, 46, 97, 0.72)",
                                  borderRadius: "4px",
                                  fontFamily: "Inter",
                                  textAlign: "center",
                                  fontSize: "12px",
                                },
                              },
                            }}
                            placement="bottom"
                            title={
                              fromSimpleDropDown
                                ? data
                                : `${data.metric_display_name.split("ID")[0]}`
                            }
                          >
                            <Box
                              sx={{
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                width: "100%",
                                marginBottom:
                                  (widthAdjustment === "handleTiles" ||
                                    widthAdjustment === "handleBox") &&
                                  data?.metric_display_name === "New tile" &&
                                  searchHeader.length - 1 === index
                                    ? "4px"
                                    : "",
                              }}
                              id={
                                fromSimpleDropDown
                                  ? `${data}`
                                  : `${data?.filter_datatype}`
                              }
                            >
                              {fromSimpleDropDown
                                ? data
                                : data?.call_parent
                                ? `${data.call_parent}.${
                                    data.metric_display_name.split("ID")[0]
                                  }`
                                : `${data.metric_display_name.split("ID")[0]}`}
                            </Box>
                          </Tooltip>

                          {/* ----- */}
                        </DropDownBox>
                        {widthAdjustment === "handleTiles" &&
                          data?.selected &&
                          searchHeader.length - 1 !== index && (
                            <PinnedPinSVG
                              id={data?.metric_display_name}
                              onClick={(event) => {
                                event.currentTarget.id !== "Overview" &&
                                  event.currentTarget.id !== "Grid" &&
                                  handlePinnedData(
                                    data?.selected,
                                    event.currentTarget.id
                                  );
                              }}
                              key={`${index}_pins`}
                              style={{
                                width: "15px",
                              }}
                            />
                          )}
                        {widthAdjustment === "handleTiles" &&
                          !data?.selected &&
                          searchHeader.length - 1 !== index && (
                            <PinSVG
                              id={data?.metric_display_name}
                              onClick={(event) => {
                                event.currentTarget.id !== "Overview" &&
                                  event.currentTarget.id !== "Grid" &&
                                  handlePinnedData(
                                    data?.selected,
                                    event.currentTarget.id
                                  );
                              }}
                              key={`${index}_pin`}
                              style={{
                                visibility:
                                  parseInt(pinVisibility) === parseInt(index)
                                    ? "visible"
                                    : "hidden",
                                borderRadius:
                                  parseInt(pinVisibility) === parseInt(index)
                                    ? "4px"
                                    : "",
                                padding:
                                  parseInt(pinVisibility) === parseInt(index)
                                    ? "4px"
                                    : "",
                              }}
                            />
                          )}
                      </Legend>
                    );
                  })}
              </Box>
              {widthAdjustment === "task" &&
                value !== "Custom" &&
                value !== "Completed" &&
                !new Date(value).getDate() && (
                  <Box display={"flex"} flexDirection={"column"} gap={"4px"}>
                    <Typography
                      fontFamily={"Inter"}
                      padding={"4px 0px 0px 12px"}
                      fontSize={"11px"}
                      letterSpacing={"1px"}
                      color={"#ABADB3"}
                    >
                      {"SORT"}
                    </Typography>
                    <SimpleDropDown
                      value={taskSortValue}
                      options={[
                        "None",
                        "Priority",
                        "Due date asc",
                        "Due date desc",
                      ]}
                      onChange={(value) => {
                        handleData(
                          value === "None" ? "" : value,
                          widthAdjustment === "task" && "sort"
                        );
                      }}
                      taskDropDown={true}
                    />
                  </Box>
                )}
              {openDate && (
                <DatePickers
                  value={value}
                  handleChange={(x) => {
                    setOpenDate(false);
                    setFilterOptionState(false);
                    handleData(x, widthAdjustment === "task" && "view");
                  }}
                  setEditMode={() => {
                    setOpenDate(false);
                  }}
                  hideStack={false}
                  expandViewCell={true}
                  taskView={true}
                  taskFilter={value}
                />
              )}
            </ColumnItems>
          </ClickAwayListener>
        ) : (
          <></>
        )}
      </TableFilter>
    </ClickAwayListener>
  );
}

export default FilterColumn;
