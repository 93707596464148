const initialState = {
  active: false,
};

const toggleWishModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ON":
      return {
        ...state,
        active: action.payload,
      };
    case "OFF":
      return {
        ...state,
        active: action.payload,
      };
    default:
      return state;
  }
};
export default toggleWishModalReducer;
