import React, { useEffect, useState } from "react";
import Footer from "./Components/Footer";
import Navbar from "./Components/Navbar";
import Home from "./Pages/Home";
import Pipeline from "./Pages/Pipeline";
import Task from "./Pages/Task";
import "./Assets/CSS/main.css";
import { Box, styled } from "@mui/material";
import { Routes, Route, useLocation } from "react-router-dom";
import Login from "./Pages/Login";
import Protected from "./Components/ProtectedRoute";
import { Navigate } from "react-router-dom";
import NotesContainer from "./Components/NotesContainer/notesContainer";
import {
  createOrUpdateKey,
  getImageData,
  getKeyData,
} from "./Pages/Services/tableApi";
import Loader from "./Components/Loader";
import { ShortCutProvider } from "./Context/Context";
import { ReactComponent as LoaderSVG } from "./Assets/SVG/Loader.svg";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { parseCookies } from "./utils";

const MainBody = styled(Box)(({ theme }) => ({
  // background: theme.themeColor,
  color: theme.fontColor,
  backgroundSize: "cover",
  backgroundPosition: "center",
  width: "100vw",
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  gap: "16px",
}));
// const switchContext = React.createContext(false);

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [imageData, setImageData] = useState(null);
  const [showHideShortcut, setShowHideShortCut] = useState(
    localStorage.getItem("ShowOrHide") === "true" ? true : false
  );

  const [showHideFields, setShowHideFields] = useState(
    localStorage.getItem("idFieldVisibility") === "true" ? true : false
  );

  const [showHideBookMark, setShowHideBookMark] = useState(
    localStorage.getItem("ShowOrHideBookMark") === "true" ? true : false
  );
  const [appShortCut, setAppShortCut] = useState(false);
  const location = useLocation();

  const getShortCutData = async () => {
    const getShortCut = await getKeyData(
      JSON.stringify({
        key: `settings`,
      })
    );
    if (getShortCut?.data?.status === 404) {
      const JSONdataParams = {
        shortcut: false,
        shortCutData: [],
        timeFormat: "12",
      };
      await createOrUpdateKey(
        JSON.stringify({
          key: `settings`,
          value: JSONdataParams,
        })
      );
      setShowHideShortCut(JSONdataParams.shortcut);
      localStorage.setItem("ShowOrHide", JSONdataParams.shortcut);
      localStorage.setItem("TimeFormat", JSONdataParams.timeFormat);
      localStorage.setItem(
        "shortCut",
        JSON.stringify(JSONdataParams.shortCutData)
      );
    } else {
      if (getShortCut?.data?.value) {
        setShowHideShortCut(getShortCut?.data?.value.shortcut);
        localStorage.setItem("ShowOrHide", getShortCut?.data?.value.shortcut);
        localStorage.setItem("TimeFormat", getShortCut?.data?.value.timeFormat);
        localStorage.setItem(
          "shortCut",
          typeof getShortCut?.data?.value?.shortCutData === "object"
            ? JSON.stringify(getShortCut?.data?.value.shortCutData)
            : JSON.stringify([])
        );
      } else {
        setShowHideShortCut(false);
      }
    }
  };

  const showHandleChange = async () => {
    const JSONdataParams = {
      shortcut: !showHideShortcut,
      shortCutData: localStorage.getItem("shortCut")
        ? JSON.parse(localStorage.getItem("shortCut"))
        : [],
      timeFormat: localStorage.getItem("TimeFormat") || "12",
    };
    await createOrUpdateKey(
      JSON.stringify({
        key: `settings`,
        value: JSONdataParams,
      })
    );
    localStorage.setItem("ShowOrHide", !showHideShortcut);
    setShowHideShortCut(!showHideShortcut);
  };
  const showHandleChangeFields = () => {
    localStorage.setItem("idFieldVisibility", !showHideFields);
    setShowHideFields(!showHideFields);
  };
  const bookmarkShowHandleChange = () => {
    localStorage.setItem("ShowOrHideBookMark", !showHideBookMark);
    setShowHideBookMark(!showHideBookMark);
  };

  useEffect(() => {
    const access_token = localStorage.getItem("access_token");
    if (!access_token) {
      setIsLoggedIn(false);
    }
    // const data = document.cookie;
    const cookies = parseCookies();
    if (!cookies?.name) {
      getImageResponse();
    } else {
      const imageData = JSON.parse(cookies?.name);
      setImageData(imageData);
    }
  }, []);
  useEffect(() => {
    if (localStorage.getItem("access_token")) {
      getShortCutData();
    }
  }, [isLoggedIn]);

  const getImageResponse = async () => {
    const imageResponse = await getImageData();
    if (imageResponse?.data?.status === 200) {
      const {
        image_link,
        location,
        photographer_name,
        photographer_user_link,
      } = imageResponse.data.data;
      const data = {
        url: `url("${image_link}&w=auto")`,
        location: location,
        pgName: photographer_name,
        pgProfileUrl: photographer_user_link,
      };
      setImageData(data);
      document.cookie = `name=${JSON.stringify(data)};expires=${new Date(
        Date.now() + 86400e3
      ).toUTCString()};`;
      document.cookie = `name=${JSON.stringify(data)};expires=${new Date(
        Date.now() + 86400e3
      ).toUTCString()};`;
    } else {
      setImageData({
        url: `url("timo-vijn-yjiZAbTDkik-unsplash 1.png")`,
      });
    }
  };

  return imageData ? (
    <ShortCutProvider
      value={{
        showOrHide: {
          showOrHide: showHideShortcut,
          idFields: showHideFields,
        },
        idFields: showHideFields,
      }}
    >
      <MainBody
        style={{
          background: `linear-gradient(0deg, rgba(17, 67, 95, 0.3), rgba(17, 67, 95, 0.3)), ${imageData.url}`,
          justifyContent: location.pathname === "/" && "space-between",
          backgroundSize: "cover",
        }}
      >
        {showHideBookMark && (
          <Box
            sx={{
              width: "100%",
              backgroundColor: "#fff",
              display: "flex",
              flexDirection: "row",
              padding: "8px",
              height: "40px",
              zIndex: 21,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                height: "100%",
                padding: "0 12px",
                padding: "0 12px",
                overflow: "hidden",
              }}
            >
              <Box maxWidth={"150px"}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    padding: "4px 8px",
                    height: "26px",
                    color: "#1a1d1f",
                    textDecoration: "none",
                  }}
                >
                  <img src="chrome://favicon/size/16@2x/chrome://apps" />
                  <Box
                    sx={{
                      width: "100%",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      display: "block",
                      marginLeft: "8px",
                      fontSize: "90%",
                      lineHeight: "16px",
                    }}
                  >
                    App
                  </Box>
                </Box>
              </Box>
              <Box maxWidth={"150px"}>
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  padding={"4px 8px"}
                  height={"26px"}
                  color={"#1a1d1f"}
                  sx={{
                    textDecoration: "none",
                  }}
                >
                  <img src="chrome://favicon/size/16@2x/chrome://apps" />
                  <Box
                    sx={{
                      width: "100%",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      display: "block",
                      marginLeft: "8px",
                      fontSize: "90%",
                      lineHeight: "16px",
                    }}
                  >
                    test
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        )}

        {location.pathname !== "/login" && (
          <Navbar
            isLoggedIn={isLoggedIn}
            showOrHide={showHandleChange}
            showOrHideFields={showHandleChangeFields}
            setAppShortCut={() => setAppShortCut(!appShortCut)}
            appShortCut={appShortCut}
            setBookmark={bookmarkShowHandleChange}
          />
        )}

        <Box
          style={{
            height: location.pathname === "/" ? "800px" : "85vh",
            display: location.pathname === "/tasks" && "block",
            margin: location.pathname === "/tasks" && "auto",
            paddingLeft:
              location.pathname !== "/" &&
              location.pathname !== "/login" &&
              location.pathname !== "/tasks" &&
              location.pathname !== "/tasks" &&
              "90px",
          }}
        >
          <Routes>
            <Route
              path="/pipeline"
              element={
                <Protected isLoggedIn={isLoggedIn}>
                  <Pipeline showOrHideFields={showHideFields} />
                </Protected>
              }
            />
            <Route
              path="/notes/:id"
              element={
                <Protected isLoggedIn={isLoggedIn}>
                  <NotesContainer />
                </Protected>
              }
            />
            <Route
              path="/tasks/:taskId"
              element={
                <Protected isLoggedIn={isLoggedIn}>
                  <Task />
                </Protected>
              }
            />
            <Route
              path="/tasks"
              element={
                <Protected isLoggedIn={isLoggedIn}>
                  <Task />
                </Protected>
              }
            />
            <Route
              path="/login"
              element={
                localStorage.getItem("access_token") ||
                localStorage.getItem("refresh_token") ? (
                  <Navigate to="/" />
                ) : (
                  <Login setIsLoggedIn={setIsLoggedIn} />
                )
              }
            />
            <Route
              path="/"
              element={
                <Home
                  setIsLoggedIn={setIsLoggedIn}
                  isLoggedIn={isLoggedIn}
                  appShortCut={appShortCut}
                  setAppShortCut={() => setAppShortCut(!appShortCut)}
                />
              }
            />
          </Routes>
        </Box>

        <Footer
          imageData={imageData}
          location={location}
          showOrHide={showHandleChange}
          showOrHideFields={showHandleChangeFields}
          appShortCut={appShortCut}
          setAppShortCut={() => setAppShortCut(!appShortCut)}
          setBookmark={bookmarkShowHandleChange}
        />
      </MainBody>

      <ToastContainer
        position="top-left"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
      />
    </ShortCutProvider>
  ) : (
    <MainBody
      style={{
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Loader />
    </MainBody>
  );
}

export default App;
