const initialState = {
  opportunityData: [],
  accountData: [],
  contactData: [],
  leadData: [],
  eventData: [],
  initialData: [],
};

const getTableDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "opportunity_table_data":
      return {
        ...state,
        opportunityData: action.payload,
      };
    case "account_table_data":
      return {
        ...state,
        accountData: action.payload,
      };
    case "contact_table_data":
      return {
        ...state,
        contactData: action.payload,
      };
    case "lead_table_data":
      return {
        ...state,
        leadData: action.payload,
      };
    case "event_table_data":
      return {
        ...state,
        eventData: action.payload,
      };
    default:
      return state;
  }
};
export default getTableDataReducer;
