import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDateTimePicker } from "@mui/x-date-pickers/StaticDateTimePicker";
// import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import styled from "@emotion/styled";
import { Button, Chip, Popover, Stack } from "@mui/material";
import { InputContainer, CustomDatePicker } from "./Date";
import { Item } from "./Table/ViewCell";
import { PopOverBox } from "./Date";
import { BoxButton } from "./Date";

const DateTimePickerComponent = styled(StaticDateTimePicker)({
  margin: "4px",
  fontWeight: 400,
  height: 340,
  fontFamily: "Inter",
  ".PrivatePickersToolbar-root": {
    display: "none",
  },
  ".MuiPickersDay-root:focus": {
    backgroundColor: "rgb(0, 161, 224)",
    color: "white",
  },
});

export default function CustomDateTimePicker({
  value,
  handleChange,
  handleUpdate,
  setEditMode,
  hideStack = true,
  expandViewCell,
}) {
  const [newDateTime, setNewDateTime] = useState(value);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    setAnchorEl(document.getElementById("date-time-tag"));
  }, []);

  const open = Boolean(anchorEl);

  const val = new Date(newDateTime);
  const date = val.toDateString();
  const time = val.toLocaleTimeString(navigator.language, {
    hour: "2-digit",
    minute: "2-digit",
  });

  return (
    <>
      {hideStack ? (
        <Stack
          aria-describedby={"simple-popover"}
          width="max-content"
          id="date-time-tag"
        >
          <Item>{value ? `${date} ${time}` : "-"}</Item>
        </Stack>
      ) : !expandViewCell ? (
        <Stack
          aria-describedby={"simple-popover"}
          width="100%"
          id="date-time-tag"
          marginTop={"12px"}
        >
          {value && <Chip label={`${date} ${time}`} />}
        </Stack>
      ) : (
        <Stack aria-describedby={"simple-popover"} id="date-time-tag"></Stack>
      )}

      <PopOverBox
        anchorEl={anchorEl}
        open={open}
        id={"simple - popover"}
        onClose={() => {
          setEditMode(false);
        }}
      >
        <CustomDatePicker>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePickerComponent
              value={value}
              onChange={(newValue) => {
                setNewDateTime(newValue);
              }}
              showToolbar={false}
              renderInput={(params) => (
                <InputContainer {...params} value={value} />
              )}
            />
            <BoxButton>
              <Button
                sx={{
                  backgroundColor: "#00A1E0",
                }}
                variant="contained"
                size="small"
                onClick={() => setEditMode(false)}
              >
                Cancel
              </Button>
              {!hideStack && (
                <Button
                  sx={{
                    backgroundColor: "#00A1E0",
                  }}
                  variant="contained"
                  size="small"
                  onClick={() => {
                    handleChange(new Date());
                  }}
                >
                  Today
                </Button>
              )}
              <Button
                sx={{
                  backgroundColor: "#00A1E0",
                }}
                variant="contained"
                size="small"
                onClick={() => {
                  if (newDateTime) {
                    handleChange(new Date(newDateTime).toISOString());
                  } else {
                    setEditMode(false);
                  }
                }}
              >
                Done
              </Button>
            </BoxButton>
          </LocalizationProvider>
        </CustomDatePicker>
      </PopOverBox>
    </>
  );
}
