import styled from "@emotion/styled";
import {
  Box,
  Button,
  Popover,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Add } from "@mui/icons-material";
import SimpleFilterDropDown from "../FilterOpportunity/SimpleFilterDropDown";
import { DefaultValue } from "./FilterColumn";
import {
  customHeader,
  customWidthForFilterType,
} from "../FilterOpportunity/FilterDropDownHeader";
import FilterDropDownComponents from "../FilterOpportunity/FilterDropDownComponents";
import { ReactComponent as FilterSVG } from "../../Assets/SVG/Filter.svg";
import { ReactComponent as DeleteSVG } from "../../Assets/SVG/Delete.svg";
import { getKeyData, handleFilterInTable } from "../../Pages/Services/tableApi";

const OpportunityFilter = styled(Box)({
  width: "max-content",
  height: "max-content",
  position: "relative",
  zIndex: 2,
});

const FilterBox = styled(Popover)({
  ".MuiPaper-root": {
    background: "#FFFFFF",
    border: "1px solid #eff5f8",
    boxShadow:
      "0 35px 31px rgba(64,96,114,.11)",
    borderRadius: "8px",
    width: "auto",
    minWidth: "760px",
    overflow: "visible",
    padding: "1rem 1rem 1rem 24px",
    display: "flex",
    flexDirection: "column",
    gap: "6px",
    margin: "2px 0px",
    maxHeight: "max-content",
    scrollBehavior: "smooth",

    "&::-webkit-scrollbar": {
      width: "0.5em",
      marginRight: "4px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(181,188,194,0.7)",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(181,188,194,0.7)",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "rgba(181,188,194,0.9)",
    },
  },
});

function FilterOpportunity({
  header,
  tableName,
  filterRowData,
  filter,
  initialFilter,
  setCachingFilter,
  tableRecordsData,
}) {
  //debugger;
  const tableObject = {
    Opportunity: "Opportunities",
    Account: "Accounts",
    Lead: "Leads",
    Event: "Events",
    Contact: "Contacts",
  };
  const activeTable =
    localStorage.getItem("activeTab") || tableObject[tableName];
  const [DropDownVal, setDropDownVal] = useState(
    localStorage.getItem("checkOpportunity") || `All ${activeTable}`
  );
  // const [DropDownVal, setDropDownVal] = useState(`All ${tableName}`);
  const [filterData, setFilterData] = useState([]);
  const [lastFilterCall, setLastFilterCall] = useState(null);
  const [avoidFirstTableRecordCall, setAvoidFirstRecordCall] = useState(false);
  const [multiTagFromFilter, setMultiTagFromFilter] = useState([]);

  const InitialFilterData = {
    filterName: "",
    filterType: "",
    metric_name: "",
    metric_value: "",
    metric_datatype: "",
    filter_datatype: "",
    operation: "",
  };

  const handleFilter = async () => {
    const JSONdataParams = JSON.stringify({
      key: `${tableName}Filter`,
    });
    const response = await getKeyData(JSONdataParams);
    if (response.data.status === 200) {
      const Opportunity = response.data.value && response.data.value[0];
      localStorage.setItem(
        `checkOpportunity`,
        Opportunity.all_user ||
          Opportunity.only_user ||
          Opportunity.my_team_user
      );
      localStorage.setItem("filterData", JSON.stringify(response.data.value));
    }
    const ExistsFilter = JSON.parse(localStorage.getItem("filterData"));
    if (ExistsFilter.length) {
      const filterData = ExistsFilter.filter((e, index) => {
        const data = {
          ...e,
          metric_value:
            e.operation === "is_empty" || e.operation === "is_not_empty"
              ? ""
              : (e.metric_datatype === "date" ||
                  e.metric_datatype === "time" ||
                  e.metric_datatype === "datetime") &&
                e.metric_value === ""
              ? `Enter ${e.metric_datatype}`
              : e.metric_value,
        };
        return data;
      });
      // const OpportunityValue = localStorage.getItem("checkOpportunity");
      // setDropDownVal(OpportunityValue || DropDownVal);
      setFilterData(filterData);
    }
  };

  useEffect(() => {
    handleFilter();
  }, [filter]);

  useEffect(() => {
    if (avoidFirstTableRecordCall) {
      const newFilterData = filterData.map((e, index) => {
        const data = {
          ...e,
          metric_value:
            e.operation === "is_empty" || e.operation === "is_not_empty"
              ? undefined
              : e.metric_value === `Enter ${e.metric_datatype}`
              ? ""
              : e.metric_value,
        };
        return data;
      });
      let OpportunityName;
      DropDownVal === `All ${activeTable}` ||
      DropDownVal === "All Opportunities"
        ? (OpportunityName = `All ${activeTable}`)
        : DropDownVal === `My ${activeTable}`
        ? (OpportunityName = `My ${activeTable}`)
        : (OpportunityName = `My team's ${activeTable}`);
      if (OpportunityName === `All ${activeTable}`) {
        if (!newFilterData[0].all_user) {
          newFilterData.splice(0, 1, {
            all_user: `All ${activeTable}`,
          });
        }
      }
      if (OpportunityName === `My ${activeTable}`) {
        if (!newFilterData[0].only_user) {
          newFilterData.splice(0, 1, {
            only_user: `My ${activeTable}`,
          });
        }
      }
      if (OpportunityName === `My team's ${activeTable}`) {
        if (!newFilterData[0].my_team_user) {
          newFilterData.splice(0, 1, {
            my_team_user: `My team's ${activeTable}`,
          });
        }
      }
      const dataParams = {
        key: `${tableName}Filter`,
        value: newFilterData,
      };

      filterRowData(dataParams);
      setAvoidFirstRecordCall(false);
    }
    // else if (filterData.length) {
    //   setAvoidFirstRecordCall(true);
    // }
    if (lastFilterCall === 1) {
      const dataParams = {
        key: `${tableName}Filter`,
        value:
          localStorage.getItem("checkOpportunity") === `All ${activeTable}`
            ? [
                {
                  all_user: `All ${activeTable}`,
                },
              ]
            : localStorage.getItem("checkOpportunity") === `My ${activeTable}`
            ? [
                {
                  only_user: `My ${activeTable}`,
                },
              ]
            : [
                {
                  my_team_user: `My team's ${activeTable}`,
                },
              ],
      };
      filterRowData(dataParams);
      setAvoidFirstRecordCall(false);
      setLastFilterCall(null);
      // setCachingFilter(false);
    }
  }, [filterData, DropDownVal]);

  const handleChange = (value) => {
    localStorage.setItem("checkOpportunity", value);
    setDropDownVal(value);
    setAvoidFirstRecordCall(true);
  };

  const handleFilterNameChange = (
    value,
    dataType,
    metric_name,
    metric_datatype,
    days,
    index
  ) => {
    //debugger;
    const newFilterData = [...filterData];
    newFilterData[index] = {
      filterName: value,
      filterType: "is",
      metric_datatype: metric_datatype,
      filter_datatype: dataType,
      metric_name: metric_name,
      operation: "is",
    };
    if (dataType === "string") {
      newFilterData[index].metric_value = "";
    }
    if (
      metric_datatype === "date" ||
      metric_datatype === "datetime" ||
      metric_datatype === "time"
    ) {
      newFilterData[index].metric_value = `Enter ${metric_datatype}`;
    }
    if (dataType === "picklist" || dataType === "multipicklist") {
      const data = header.filter((data) => data.metric_display_name === value);

      newFilterData[index].metric_value = [];
      newFilterData[index].values = data[0].values;
    }
    if (dataType === "numeric") {
      newFilterData[index].metric_value = "";
    }
    if (dataType === "checkbox") {
      newFilterData[index].metric_value = "";
    }
    setFilterData(newFilterData);
    setAvoidFirstRecordCall(true);
  };
  const handleFilterTypeChange = (
    value,
    dataType,
    metric_name,
    metric_datatype,
    days,
    index
  ) => {
    const newFilterData = [...filterData];
    newFilterData[index].filterType = value;
    if (value === "is empty" || value === "is not empty") {
      newFilterData[index].metric_value = filterData[index].metric_value;
      if (newFilterData[index].filterDateValue) {
        newFilterData[index].filterDateValue = filterData[index].metric_value;
      }
    } else {
      newFilterData[index].metric_value = filterData[index].metric_value;
      if (newFilterData[index].filterDateValue) {
        newFilterData[index].filterDateValue = filterData[index].metric_value;
      }
    }
    newFilterData[index].operation = metric_name;
    setFilterData(newFilterData);
    setAvoidFirstRecordCall(true);
  };
  const handleFilterValueChange = (
    value,
    dataType,
    metric_name,
    handleDateType,
    day,
    index,
    Name
  ) => {
    const values = [
      "Yesterday",
      "Today",
      "Tomorrow",
      "Last Week",
      "This Week",
      "Next Week",
      "Last 90 days",
      "Next 90 days",
      "Last Quarter",
      "This Quarter",
      "Next Quarter",
      "Last Year",
      "This Year",
      "Next Year",
      "Number of days ago",
      "Number of days from now",
    ];
    const newFilterData = [...filterData];
    if (dataType === "picklist" || dataType === "multipicklist") {
      const values = [...newFilterData[index].metric_value];
      if (values.includes(value)) {
        newFilterData[index].metric_value = values.filter(
          (data) => data !== value
        );
      } else {
        values.push(value);
        newFilterData[index].metric_value = values;
      }
    } else if (handleDateType === "datetime") {
      newFilterData[index].metric_value = !values.includes(value)
        ? new Date(value).toISOString()
        : value;
      if (!values.includes(value)) {
        newFilterData[index].filterDateValue = !values.includes(value)
          ? new Date(value).toISOString().slice(0, 10) +
            " " +
            new Date(value).toLocaleTimeString()
          : value;
      } else {
        newFilterData[index].filterDateValue = undefined;
      }
      value === "Number of days ago" || value === "Number of days from now"
        ? (newFilterData[index].days = day)
        : (newFilterData[index].days = undefined);
    } else if (handleDateType === "time") {
      newFilterData[index].metric_value = !values.includes(value)
        ? value.toLocaleTimeString()
        : value;

      if (!values.includes(value)) {
        newFilterData[index].filterDateValue = !values.includes(value)
          ? value.toISOString().split("T")[1]
          : value;
      } else {
        newFilterData[index].filterDateValue = undefined;
      }
      value === "Number of days ago" || value === "Number of days from now"
        ? (newFilterData[index].days = day)
        : (newFilterData[index].days = undefined);
    } else if (handleDateType === "date") {
      newFilterData[index].metric_value = value;
      value === "Number of days ago" || value === "Number of days from now"
        ? (newFilterData[index].days = day)
        : (newFilterData[index].days = undefined);
    } else if (handleDateType === "reference") {
      const values = { ...newFilterData[index].metric_value };
      let newValues = { ...values, [value]: Name };

      newFilterData[index].metric_value = newValues;
    } else {
      newFilterData[index].metric_value = value;
    }
    setFilterData(newFilterData);
    setAvoidFirstRecordCall(true);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleFilterView = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleFilterViewClose = () => {
    setAnchorEl(null);
  };
  const filterViewOpen = Boolean(anchorEl);
  const id = filterViewOpen ? "simple-popover" : undefined;

  const onChangeHandler = (id, name) => {
    setMultiTagFromFilter([
      ...multiTagFromFilter,
      { id: id, metric_name: name },
    ]);
  };

  const handleFilterValueRemove = (
    value,
    dataType,
    metric_name,
    handleDateType,
    day,
    index,
    Name
  ) => {
    const newFilterData = [...filterData];
    delete newFilterData[index].metric_value[value];
    if (!Object.keys(newFilterData[index].metric_value).length) {
      newFilterData[index].metric_value = "";
    }
    //delete values[value];
    setFilterData(newFilterData);
    setAvoidFirstRecordCall(true);
  };

  return (
    <OpportunityFilter>
      <DefaultValue
        role="button"
        tabIndex={0}
        onClick={handleFilterView}
        sx={{
          "&:hover": {
            color: "#00A1E0",
            svg: {
              path: {
                stroke: "#00A1E0",
              },
            },
          },
        }}
      >
        <FilterSVG />
        <p style={{ paddingLeft: "0.4rem" }}>Filters</p>
      </DefaultValue>
      <FilterBox
        id={id}
        open={filterViewOpen}
        anchorEl={anchorEl}
        onClose={handleFilterViewClose}
        role="button"
        tabIndex={0}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box zIndex={11} marginBottom={"36px"} width={"fit-content"}>
          <SimpleFilterDropDown
            value={localStorage.getItem("checkOpportunity") || DropDownVal}
            handleData={handleChange}
            header={[
              {
                editable: false,
                isVisible: true,
                metric_datatype: "string",
                metric_display_name: `All ${activeTable}`,
                metric_name: `All${activeTable}`,
              },
              {
                editable: false,
                isVisible: true,
                metric_datatype: "string",
                metric_display_name: `My ${activeTable}`,
                metric_name: `My${activeTable}`,
              },
              {
                editable: false,
                isVisible: true,
                metric_datatype: "string",
                metric_display_name: `My team's ${activeTable}`,
                metric_name: `MyTeam's${activeTable}`,
              },
            ]}
            searching={false}
            widthAdjustment={"main"}
          />
        </Box>
        <Box sx={{ paddingLeft: "24px" }}>
          {filterData?.map((data, index) => {
            if (index > 0)
              return (
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  width={"100%"}
                  marginBottom={"12px"}
                  // zIndex={filterData.length - index}
                  key={index}
                >
                  <Box
                    display={"flex"}
                    width={"100%"}
                    gap={"12px"}
                    alignItems={"center"}
                  >
                    <Box
                      fontFamily={"Inter"}
                      fontSize={"14px"}
                      fontWeight={"500"}
                      color={"#9DA5AD"}
                      padding={"0.75rem 0rem"}
                      sx={{
                        zoom: 0.8,
                      }}
                    >
                      AND
                    </Box>
                    <Box
                      key={`component_dropdowns_${index}`}
                      style={{
                        zIndex: filterData.length - index,
                      }}
                    >
                      <SimpleFilterDropDown
                        header={header}
                        handleData={(
                          value,
                          dataType,
                          metric_name,
                          metric_datatype
                        ) => {
                          handleFilterNameChange(
                            value,
                            dataType,
                            metric_name,
                            metric_datatype,
                            "",
                            index
                          );
                        }}
                        value={data.filterName}
                        searching={true}
                        widthAdjustment={"filterName"}
                      />
                    </Box>
                    <Box
                      key={`component_dropdown_${index}`}
                      style={{
                        zIndex: filterData.length - index,
                      }}
                    >
                      <SimpleFilterDropDown
                        header={customHeader(
                          data.metric_datatype === "reference"
                            ? "reference"
                            : data.filter_datatype
                        )}
                        handleData={(
                          value,
                          dataType,
                          metric_name,
                          metric_datatype
                        ) =>
                          handleFilterTypeChange(
                            value,
                            dataType,
                            metric_name,
                            metric_datatype,
                            "",
                            index
                          )
                        }
                        value={data.filterType}
                        searching={false}
                        widthAdjustment={"filterType"}
                        widthOfFilterType={customWidthForFilterType(
                          data.filter_datatype
                        )}
                      />
                    </Box>
                    <Box
                      width={"auto"}
                      key={`component_${index}`}
                      sx={{
                        position:
                          data.metric_datatype === "reference" && "relative",
                        ".MuiInputBase-input": {
                          zIndex: 1,
                          backgroundColor: "#f6f6f6",
                          padding: "6.5px 14px",
                          "&:focus": {
                            backgroundColor: "#EDF2FA",
                          },
                          borderRadius: "4px",
                        },
                      }}
                    >
                      <FilterDropDownComponents
                        dataType={data.filter_datatype}
                        value={
                          data.filterDateValue
                            ? data.filterDateValue
                            : data.metric_value
                        }
                        days={data?.days ? data.days : ""}
                        dataFilterType={data.filterType}
                        handleFilterValueChange={(
                          value,
                          dataType,
                          metric_name,
                          handleDateType,
                          day,
                          name
                        ) => {
                          handleFilterValueChange(
                            value,
                            dataType,
                            metric_name,
                            handleDateType,
                            day,
                            index,
                            name
                          );
                        }}
                        metricName={data.metric_name}
                        handleDateType={data.metric_datatype}
                        dropDownClose={true}
                        dropDownValue={data.values}
                        widthAdjustment={"filterValue"}
                        key={`component_${index}`}
                        tableName={tableName}
                        header={header.filter(
                          (values) => values.metric_name === data.metric_name
                        )}
                        tableRecordsData={tableRecordsData}
                        handleFilterValueRemove={(
                          value,
                          dataType,
                          metric_name,
                          handleDateType,
                          day,
                          name
                        ) => {
                          handleFilterValueRemove(
                            value,
                            dataType,
                            metric_name,
                            handleDateType,
                            day,
                            index,
                            name
                          );
                        }}
                        index={index}
                      />
                    </Box>
                    <Box
                      sx={{ display: "flex", paddingBottom: "4px" }}
                      id={`${index}`}
                      onClick={(e) => {
                        const tempFilterData = [...filterData];
                        tempFilterData.splice(index, 1);
                        tempFilterData.length === 1
                          ? setLastFilterCall(tempFilterData.length)
                          : setAvoidFirstRecordCall(true);
                        setFilterData(tempFilterData);
                      }}
                    >
                      <DeleteSVG
                        style={{
                          cursor: "pointer",
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              );
          })}
          <Box marginTop={"16px"}>
            <Button
              onClick={() => {
                const tempFilterData = [...filterData];
                tempFilterData.push(InitialFilterData);
                // const defaultOpportunity =
                if (filterData.length === 0) {
                  tempFilterData.unshift({
                    all_user: `All ${activeTable}`,
                  });
                } else {
                  localStorage.getItem("checkOpportunity") ===
                  `All ${activeTable}`
                    ? tempFilterData.splice(0, 1, {
                        all_user: `All ${activeTable}`,
                      })
                    : localStorage.getItem("checkOpportunity") ===
                      `My ${activeTable}`
                    ? tempFilterData.splice(0, 1, {
                        only_user: `My ${activeTable}`,
                      })
                    : tempFilterData.splice(0, 1, {
                        my_team_user: `My team's ${activeTable}`,
                      });
                }
                // tempFilterData.unshift(defaultOpportunity);
                setAvoidFirstRecordCall(true);
                setFilterData(tempFilterData);
              }}
              sx={{ border: "1px solid #e7e7e7" }}
            >
              <Add fontSize={"16px"} />
              <Typography
                fontSize={"14px"}
                textTransform={"capitalize"}
                letterSpacing={"0.8px"}
                marginRight={"0.5rem"}
                fontFamily={"Inter"}
              >
                Add Filter
              </Typography>
            </Button>
          </Box>
        </Box>
      </FilterBox>
    </OpportunityFilter>
  );
}

export default FilterOpportunity;
