import React, { useEffect, useState } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticTimePicker } from "@mui/x-date-pickers/StaticTimePicker";
import { TimePicker } from "@mui/lab";
import { Box, Button, Popover, Stack, TextField } from "@mui/material";
import styled from "@emotion/styled";

const CustomTimePicker = styled(Box)({
  ".MuiPickerStaticWrapper-root": {
    borderRadius: "15px",
    boxShadow: "-6px 7px 34px rgba(0, 0, 0, 0.16)",
    height: "400px",
  },
  ".PrivatePickersToolbar-root": {
    height: "75px",
    zoom: 0.7,
  },
  ".MuiClockPicker-root": {},
  ".MuiSvgIcon-root": {
    display: "none",
  },
});
export default function TimePickerComponent({
  value,
  handleChange,
  setEditMode,
}) {
  const [time, setTime] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    setAnchorEl(document.getElementById("time"));
  }, []);

  const open = Boolean(anchorEl);
  return (
    <>
      <Stack aria-describedby={"simple-popover"} id="time"></Stack>
      <Popover
        anchorEl={anchorEl}
        open={open}
        id={"simple - popover"}
        style={{ height: "400px" }}
        onClose={() => {
          setEditMode(false);
        }}
        sx={{
          zoom: "0.85",
          ".MuiPaper-root": {
            borderRadius: "15px",
            boxShadow: "-6px 7px 34px rgba(0, 0, 0, 0.16)",
            overflow: "hidden",
          },
        }}
      >
        <CustomTimePicker>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <StaticTimePicker
              displayStaticWrapperAs="mobile"
              value={value}
              onChange={(newValue) => {
                setTime(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
            />

            <Box
              top="88%"
              width="100%"
              display="flex"
              justifyContent="space-around"
              position="absolute"
            >
              <Button
              sx={{
                backgroundColor: "#00A1E0",
              }}
                variant="contained"
                size="small"
                onClick={() => setEditMode(false)}
              >
                Cancel
              </Button>
              <Button
              sx={{
                backgroundColor: "#00A1E0",
              }}
                variant="contained"
                size="small"
                onClick={() => {
                  // handleChange(time.toTimeString().trim().split(" ")[0]);

                  handleChange(time);
                  setEditMode(false);
                }}
              >
                Done
              </Button>
            </Box>
          </LocalizationProvider>
        </CustomTimePicker>
      </Popover>
    </>
  );
}
