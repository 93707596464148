import {
  ClickAwayListener,
  Divider,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import {
  CompletedBox,
  HeaderBox,
  MainTasksBox,
  ScrollableContainer,
  TaskBoxHeader,
  TasksDataBox,
} from "../../Pages/Task";
import { ReactComponent as IncompleteTaskSVG } from "../../Assets/SVG/IncompleteTask.svg";
import { ReactComponent as CompleteTaskSVG } from "../../Assets/SVG/CompleteTask.svg";
import { CloseRounded } from "@mui/icons-material";
import { ReactComponent as DeleteSVG } from "../../Assets/SVG/Delete.svg";
import { ReactComponent as FieldSVG } from "../../Assets/SVG/Field.svg";
import { ReactComponent as ArrowForwardSVG } from "../../Assets/SVG/ArrowForward.svg";
import { ReactComponent as EditIconSVG } from "../../Assets/SVG/EditIcon.svg";
import useDebounce from "../../Hooks/useDebounce";
import { Box } from "@mui/system";
import FilterColumn from "../Table/FilterColumn";
import RenderComponents from "../RenderComponents";
import {
  getKeyData,
  getTableHeadersApi,
  handleAddFieldInTable,
} from "../../Pages/Services/tableApi";
import {
  FieldBox,
  HeaderTaskTypoGraphy,
  RelatedAndDueDateBox,
  RelatedOrDueDateBox,
  SubFieldBox,
  TaskFieldSvg,
  TaskTextInput,
  TextArea,
  TextBox,
  CommentsTextArea,
} from "../../Assets/StyledComponents/TaskViewStyledComponents.js";
import {
  ScrollableBox,
  TypoGraphy,
} from "../../Assets/StyledComponents/ExpandViewStyledComponents";
import { DatePicker } from "@mui/x-date-pickers";
import DatePickers from "../Date";
import OpportunityView from "../ExpandView/ExpandOpportunityView";
import { ExpandViewPopover } from "../../Pages/Pipeline";
import { MainLeadBoxCovertTypoGraphy } from "../ExpandView/LeadView";
import ReferenceSearchDropDown from "../ReferenceSearchDropDown";
import { useDispatch } from "react-redux";
import Actions from "../../redux/actions";
import styled from "@emotion/styled";
import { ButtonBox, StyledButton } from "../SearchView/SearchDrawer";


const CreateTaskBox=styled.div({

  padding:"1rem", display:"flex", alignItems:"center", justifyContent:"flex-end", gap: "0.6rem", padding: "1rem 1rem 1rem 0rem",
  boxShadow: "0px -6px 16px rgba(0, 0, 0, 0.04)",
  width: "97.5",
  marginRight: "1rem",
background: "#ffffff",


})




const DueDateContainer = styled.div({
  padding: "8px",

  ":hover": { color: "#00A1E0" },
});

function EditTask({
  setNewTask,
  taskData,
  taskRelatedData,
  taskDataHeader,
  taskActiveDataHeader,
  syncTask,
  deleteTask,
  onUpdateSuccessOfRow,
  handleAddOrRemoveTaskField,
  taskCompletion,
  taskActiveCompleteId,
  filterColumns,
  updateRecordsOnly,
  handleSyncTask,
  taskActive,
  taskEditLoader,
  tableName,
  createTask,
  SearchTableData,
  name,
  error,
  handleError,
  editTask,
  expandHeaders,
  titleRowData,
  tilesData,
  completedTaskList,
  handleCompletedTaskList,
  searchTasksStyle,
  setSearchEditTask,
  referenceName,
  taskViewOnly = false,
}) {
  const [taskName, setTaskName] = useState(taskRelatedData?.Subject);
  const [hover, setHover] = useState(false);
  const [fieldDropDown, setFieldDropDown] = useState(false);
  const [fieldAddDropDown, setFieldAddDropDown] = useState(false);
  const [onHoverHeader, setOnHoverHeader] = useState(null);
  const [datePickers, setDatePickers] = useState(false);
  const [relatedTo, setRelatedTo] = useState(false);
  const [expandViewTableName, setExpandViewTableName] = useState("");
  const fields = taskData.Id ? ["Fields", "Delete Task"] : ["Fields"];
  // const [expandHeaders, setExpandHeaders] = useState(null);
  const [relatedFields, setRelatedFields] = useState(null);
  const [rowId, setRowId] = useState(null);
  const [outTaskExpandView, setOutTaskExpandView] = useState(false);
  const [comments, setComments] = useState(
    taskRelatedData?.Description
      ? taskRelatedData.Description
      : tableName === "Task"
      ? taskData?.Task?.Description
      : ""
  );
  const [editMode, setEditMode] = useState(false);
  let tableArray = ["Opportunity", "Account", "Contact", "Lead"];
  let whatTableName = taskRelatedData?.What?.attributes?.url?.split("/")[5];
  let whoTableName = taskRelatedData?.Who?.attributes?.url?.split("/")[5];
  const dispatch = useDispatch();

  useEffect(() => {
    setTaskName(taskData.Subject ?? taskData?.Task?.Subject);
    // whatTableName = whatTableName[whatTableName.length - 2];
    // whoTableName = whoTableName[whoTableName.length - 2];
    setComments(
      taskRelatedData?.Description
        ? taskRelatedData.Description
        : tableName === "Task" || tableName === "Tasks"
        ? taskData?.Task?.Description
          ? taskData?.Task?.Description
          : ""
        : editTask
        ? taskData?.Description
          ? taskData.Description
          : ""
        : ""
    );
    localStorage.setItem(
      "manageHeader",
      localStorage.getItem("manageHeader") ?? "Overview"
    );

    // const checkHeaderExist = await getKeyData(
    //   JSON.stringify({
    //     key: `Expand${localStorage.getItem("manageHeader")}${
    //       whoTableName ?? whatTableName
    //     }Headers`,
    //   })
    // );

    // localStorage.getItem(
    //   `Expand${localStorage.getItem("manageHeader")}${
    //     whoTableName ?? whatTableName
    //   }Headers`
    // );
    // if (!taskActive) {
    //   setExpandHeaders(null);
    // } else if (!checkHeaderExist?.data?.value?.length) {
    //   getTableHeader(whoTableName ?? whatTableName);
    // } else {
    //   tableArray.includes(tableName) &&
    //     setExpandHeaders(
    //       JSON.parse(
    //         localStorage.getItem(
    //           `Expand${localStorage.getItem("manageHeader")}${
    //             whoTableName ?? whatTableName
    //           }Headers`
    //         )
    //       )
    //     );
    //   setRelatedFields(
    //     JSON.parse(
    //       localStorage.getItem(`Expand${whoTableName ?? whatTableName}Headers`)
    //     )
    //   );
    // }
    setRowId(taskRelatedData?.Who?.Id ?? taskRelatedData?.What?.Id);
    setExpandViewTableName(whoTableName ?? whatTableName);
  }, [JSON.stringify(taskData)]);

  useEffect(() => {
    localStorage.setItem(
      "ExpandGridTaskHeaders",
      JSON.stringify(taskDataHeader)
    );
    localStorage.setItem(
      "ExpandActiveGridTaskHeaders",
      JSON.stringify(taskDataHeader.filter((data) => data.selected))
    );
  }, []);

  // useEffect(() => {
  //
  //   if (expandHeader) {
  //     setExpandHeaders(expandHeader.data.headers);
  //     setRelatedFields(expandHeader.data.related_sobjects);
  //   }
  // }, [expandHeader]);

  // const getTableHeader = async (tableName) => {
  //
  //   const dataParams = {
  //     table_name: tableName,
  //   };
  //   const JSONdataParams = JSON.stringify(dataParams);
  //   const res = await getTableHeadersApi(JSONdataParams);
  //   if (res && res.data.status === 200) {
  //     tableArray.includes(tableName) && setExpandHeaders(res.data.headers);
  //     setRelatedFields(res.data.related_sobjects);
  //     localStorage.setItem(
  //       `Expand${localStorage.getItem("manageHeader")}${tableName}Headers`,
  //       JSON.stringify(res.data.headers)
  //     );
  //     localStorage.setItem(
  //       `ExpandActive${localStorage.getItem(
  //         "manageHeader"
  //       )}${tableName}Headers`,
  //       JSON.stringify(res.data.headers.filter((data) => data.selected))
  //     );
  //   }
  // };

  const handleRelatedToExpandViewClick = (index) => {
    // const tableName = !index
    //   ? taskRelatedData?.Who?.attributes?.url?.split("/")[5]
    //   : taskRelatedData?.What?.attributes?.url?.split("/")[5];
    // const Id = !index ? taskRelatedData?.Who?.Id : taskRelatedData?.What?.Id;
    // if (
    //   !localStorage.getItem(
    //     `Expand${localStorage.getItem("manageHeader")}${tableName}Headers`
    //   )
    // ) {
    //   getTableHeader(tableName);
    // } else {
    //   tableArray.includes(tableName) &&
    //     setExpandHeaders(
    //       JSON.parse(
    //         localStorage.getItem(
    //           `Expand${localStorage.getItem("manageHeader")}${tableName}Headers`
    //         )
    //       )
    //     );
    //   setRelatedFields(
    //     JSON.parse(localStorage.getItem(`Expand${tableName}Headers`))
    //   );
    // }
    // setRowId(Id);
    // setExpandViewTableName(tableName);
  };

  const [expandAnchorEl, setExpandAnchorEl] = React.useState(null);

  const handleExpandView = () => {
    setExpandAnchorEl(document.getElementById("root"));
  };
  const handleExpandViewClose = () => {
    localStorage.setItem(`${expandViewTableName}TilesApiData`, null);

    localStorage.setItem("manageHeader", "Overview");
    setExpandAnchorEl(null);
    setOutTaskExpandView(false);
  };

  const expandViewOpen = Boolean(expandAnchorEl);
  const id = expandViewOpen ? "simple-popover" : undefined;

  const handleHeaderOrActiveHeader = async (
    headerOrActiveHeader,
    taskOrExpand
  ) => {
    //  expandHeaders?.filter((data) => data.isVisible || data.isSelected);
    const res = await getKeyData(
      JSON.stringify({
        key: taskOrExpand
          ? `ExpandActive${localStorage.getItem("manageHeader")}${
              whoTableName ?? whatTableName
            }Headers`
          : "ExpandGridTaskHeaders",
      })
    );
    return res?.data?.value?.length
      ? !headerOrActiveHeader
        ? res.data.value
        : res.data.value.filter(({ isVisible, selected }) => {
            return isVisible || selected;
          })
      : !headerOrActiveHeader
      ? taskDataHeader
      : (taskOrExpand ? expandHeaders : taskDataHeader).filter(
          ({ isVisible, selected }) => {
            return isVisible || selected;
          }
        );
  };

  const relatedFieldsHeader = async () => {
    const res = await getKeyData(
      JSON.stringify({
        key: `Expand${whoTableName ?? whatTableName}Headers`,
      })
    );

    return res?.data?.value;
  };

  return (
    <ScrollableContainer
      style={{
        overflowY:
          !searchTasksStyle && tableName != "Task" ? "scroll" : "hidden",
        scrollBehavior:
          !searchTasksStyle && tableName != "Task" ? "smooth" : "none",
      }}
    >
      <MainTasksBox
        height={
          expandHeaders &&
          (taskRelatedData?.Who?.Id ?? taskRelatedData?.What?.Id)
            ? "max-content"
            : tableName === "Task"
            ? "auto"
            : "100%"
        }
        width={searchTasksStyle ? "100%" : "500px"}
        sx={{
          pointerEvents: taskEditLoader ? "none" : "pointer",
        }}
      >
        <HeaderBox>
          <TaskBoxHeader justifyContent={"normal"} gap={"12px"}>
            {tableName !== "Task" && (
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                title={completedTaskList?.includes(taskData.Id)?"Mark task open":"Mark task complete"}
                onMouseEnter={() => taskData.Id && setHover(true)}
                onMouseLeave={() => taskData.Id && setHover(false)}
                onClick={() => {
                  // !taskActiveCompleteId
                  //   ? taskCompletion(taskData)
                  //   : taskCompletion(null);
                  taskCompletion(taskData);

                  if (!completedTaskList?.includes(taskData.Id)) {
                    handleCompletedTaskList([
                      ...completedTaskList,
                      taskData.Id,
                    ]);
                  } else {
                    const __taskListIds = completedTaskList?.filter(
                      (id) => id !== taskData.Id
                    );
                    handleCompletedTaskList(__taskListIds);
                  }
                }}
              >
                {completedTaskList?.includes(taskData.Id) || hover ? (
                  <CompleteTaskSVG
                    color={"#1DC4B4"}
                    height={"18px"}
                    cursor={"pointer"}
                  />
                ) : (
                  <IncompleteTaskSVG height={"30%"} cursor={"pointer"} />
                )}
              </Tooltip>
            )}
            <TaskTextInput
              placeholder={"Write a task name"}
              value={taskName}
              onChange={(e) => {
                if (taskName !== e.target.value) setTaskName(e.target.value);
              }}
              onBlur={(e) => {
                // if (taskName !== e.target.value) setTaskName(e.target.value);
                tableName !== "Task"
                  ? syncTask(taskName, taskData.Id ? false : true, taskData)
                  : onUpdateSuccessOfRow(e.target.value, "Subject", true);
                searchTasksStyle &&
                  dispatch(Actions.getAsyncTask.asyncTask(true));
              }}
            />

            <>
              <ClickAwayListener
                onClickAway={() => {
                  setFieldDropDown(false);
                  setFieldAddDropDown(false);
                }}
              >
                <Box>
                  <Tooltip
                    // PopperProps={{
                    //   disablePortal: true,
                    // }}

                    PopperProps={{
                      sx: {
                        "& .MuiTooltip-tooltip": {
                          width: "auto",
                          height: "16px",
                          background: "rgba(3, 46, 97, 0.72)",
                          borderRadius: "4px",
                          fontFamily: "Inter",
                          textAlign: "center",
                          fontSize: "12px",
                        },
                      },
                    }}
                    title={"Manage Fields"}
                    onClick={() => {
                      if (
                        (!editTask && !fieldAddDropDown) ||
                        fields?.length < 2
                      ) {
                        setFieldAddDropDown(!fieldAddDropDown);
                      } else if (!fieldDropDown && !fieldAddDropDown) {
                        setFieldDropDown(true);
                        // setFieldAddDropDown(true);
                      } else if (
                        (fieldDropDown && !fieldAddDropDown) ||
                        (!fieldDropDown && fieldAddDropDown)
                      ) {
                        setFieldDropDown(false);
                        setFieldAddDropDown(false);
                      } else {
                        setFieldDropDown((prev) => !prev);
                        setFieldAddDropDown((prev) => !prev);
                      }
                    }}
                  >
                    <TaskFieldSvg cursor={"pointer"} />
                  </Tooltip>
                  {fieldDropDown && (
                    <FieldBox>
                      {fields.map((value) => (
                        <SubFieldBox
                          onClick={(e) => {
                            if (value === "Fields") {
                              setFieldAddDropDown(!fieldAddDropDown);
                            }
                            if (value === "Delete Task") {
                              setFieldDropDown(false);
                              deleteTask(taskData);
                            }
                          }}
                        >
                          {value === "Fields" ? (
                            <FieldSVG width={"15px"} />
                          ) : (
                            <DeleteSVG width={"15px"} />
                          )}
                          {value}
                          {value === "Fields" && (
                            <ArrowForwardSVG style={{ marginLeft: "auto" }} />
                          )}
                        </SubFieldBox>
                      ))}
                    </FieldBox>
                  )}
                  {fieldAddDropDown && (
                    <FilterColumn
                      header={
                        localStorage.getItem("ExpandGridTaskHeaders")
                          ? JSON.parse(
                              localStorage.getItem("ExpandGridTaskHeaders")
                            )
                          : taskDataHeader ??
                            handleHeaderOrActiveHeader(false, false)
                      }
                      activeHeader={
                        localStorage.getItem("ExpandActiveGridTaskHeaders")
                          ? JSON.parse(
                              localStorage.getItem(
                                "ExpandActiveGridTaskHeaders"
                              )
                            )
                          : taskDataHeader.filter(
                              (data) => data.isVisible || data.selected
                            ) ?? handleHeaderOrActiveHeader(true, false)
                      }
                      fields={fields}
                      tableName={"Task"}
                      filterColumns={(header) => filterColumns(header)}
                      addOrRemoveFieldInColumn={(field, headerFlag) => {
                        handleAddOrRemoveTaskField(field, headerFlag, "Task");
                      }}
                    />
                  )}
                </Box>
              </ClickAwayListener>

              {tableName !== "Task" && (
                <CloseRounded
                  onClick={() => {
                    setNewTask();
                    dispatch(Actions.getAsyncTask.asyncTask(true));
                    searchTasksStyle && setSearchEditTask();
                  }}
                  style={{
                    cursor: "pointer",
                    width: "20px",
                  }}
                />
              )}
            </>
          </TaskBoxHeader>
          {completedTaskList?.includes(taskData.Id) && (
            <CompletedBox>
              <HeaderTaskTypoGraphy>Task Completed</HeaderTaskTypoGraphy>
              <Box>
                <HeaderTaskTypoGraphy
                  borderBottom={"1px dotted black"}
                  onClick={() => {
                    setNewTask(true);
                    dispatch(Actions.getAsyncTask.asyncTask(true));
                  }}
                >
                  Create next task
                </HeaderTaskTypoGraphy>
              </Box>
            </CompletedBox>
          )}
        </HeaderBox>
        <CommentsTextArea
          placeholder={"Comments"}
          minRows={4}
          value={comments}
          onChange={(e) => setComments(e.target.value)}
          onBlur={(e) => {
            tableName !== "Task"
              ? comments !== taskRelatedData?.Description &&
                onUpdateSuccessOfRow(comments, "Description", true, "Update")
              : onUpdateSuccessOfRow(e.target.value, "Description", true);
          }}
          style={{
            width: tableName === "Task" ? "90%" : "80%",
            marginTop: "1rem",
          }}
        />
        <Divider />
        {tableName === "Task" ? (
          <ScrollableBox
            style={{ padding: "0rem", height: "544px", overflowY: "hidden" }}
          >
            <TasksDataBox style={{ overflow: "auto" }}>
              <Box
                padding={"1rem"}
                paddingLeft={"50px"}
                //marginBottom={tableName == "Task" && "2rem"}
              >
                <RenderComponents
                  row={taskData}
                  activeColumnHeader={
                    taskActiveDataHeader
                      ? taskActiveDataHeader
                      : taskDataHeader.filter(
                          (data) => data.selected ?? data.isVisible
                        )
                  }
                  rowId={createTask ? "" : taskData.Id}
                  tableName={"Task"}
                  onUpdateSuccessOfRow={onUpdateSuccessOfRow}
                  rowData={taskData}
                  setRowData={null}
                  searchView={createTask ? true : "TaskView"}
                  SearchTableData={createTask ? SearchTableData : null}
                  name={createTask ? name : null}
                  error={createTask ? error : null}
                  handleError={createTask ? handleError : null}
                  onHoverHeader={onHoverHeader}
                  setOnHoverHeader={setOnHoverHeader}
                  editTask={editTask}
                  taskRelatedData={taskRelatedData}
                  taskViewOnly={taskViewOnly}
                />
              </Box>
            </TasksDataBox>
          </ScrollableBox>
        ) : (
          <TasksDataBox style={{ overflow: "auto" }}>
            <Box
              padding={"1rem"}
              paddingLeft={"50px"}
              marginBottom={tableName == "Task" && "2rem"}
            >
              <RenderComponents
                row={taskData}
                activeColumnHeader={
                  taskActiveDataHeader
                    ? taskActiveDataHeader
                    : taskDataHeader?.filter(
                        (data) => data.selected ?? data.isVisible
                      )
                }
                rowId={createTask ? "" : taskData.Id}
                tableName={"Task"}
                onUpdateSuccessOfRow={onUpdateSuccessOfRow}
                rowData={taskData}
                setRowData={null}
                searchView={createTask ? true : "TaskView"}
                SearchTableData={createTask ? SearchTableData : null}
                name={createTask ? name : null}
                error={createTask ? error : null}
                handleError={createTask ? handleError : null}
                onHoverHeader={onHoverHeader}
                setOnHoverHeader={setOnHoverHeader}
                editTask={editTask}
                taskRelatedData={taskRelatedData}
                MainTaskRelatedNameData={referenceName}
                taskViewOnly={taskViewOnly}
              />
            </Box>
          </TasksDataBox>
        )}
        <Divider />
        <RelatedAndDueDateBox>
          <ClickAwayListener onClickAway={() => setRelatedTo(false)}>
            <RelatedOrDueDateBox>
              {!relatedTo ? (
                <>
                  <TypoGraphy style={{ fontSize: "14px", display: "block" }}>
                    {taskRelatedData?.What?.Name ||
                    taskRelatedData?.Who?.Name ||
                    taskData?.Who?.Name ||
                    taskData?.What?.Name ? (
                      <>
                        {[
                          taskRelatedData?.Who?.Name || taskData?.Who?.Name,
                          taskRelatedData?.What?.Name || taskData?.What?.Name,
                          // taskData?.What?.Name
                        ].map((taskConnectionData, index) => {
                          return (
                            taskConnectionData && (
                              <Box
                                onClick={() =>
                                  handleRelatedToExpandViewClick(index)
                                }
                                // sx={{
                                //   "&:hover": {
                                //     color: "#0065FF",
                                //   },
                                // }}
                              >
                                {taskConnectionData}
                              </Box>
                            )
                          );
                        })}
                      </>
                    ) : (
                      "Related to"
                    )}
                  </TypoGraphy>
                  <EditIconSVG
                    onClick={() => {
                      setRelatedTo(true);
                      setEditMode(true);
                    }}
                    visibility={"hidden"}
                    height={"16px"}
                  />
                </>
              ) : (
                <TextBox
                  padding={
                    taskRelatedData?.What || taskRelatedData?.Who
                      ? "8px"
                      : "0px"
                  }
                >
                  <ReferenceSearchDropDown
                    setEditMode={setEditMode}
                    editMode={editMode}
                    rawData={{
                      sobject: "Task",
                      metric_display_name: "",
                      metric_name: "",
                    }}
                    onChangeHandler={(
                      value,
                      field,
                      required,
                      whoOrWhatName
                    ) => {
                      setRelatedTo(false);
                      setEditMode(false);
                      onUpdateSuccessOfRow(
                        value,
                        field,
                        required,
                        whoOrWhatName
                      );
                    }}
                    searchBox={false}
                    taskRelatedTo={true}
                    overflow={
                      expandHeaders &&
                      (taskRelatedData?.Who?.Id ?? taskRelatedData?.What?.Id)
                        ? false
                        : true
                    }
                  />
                  {[
                    taskRelatedData?.What?.Name,
                    taskRelatedData?.Who?.Name,
                  ].map((value, index) => {
                    return (
                      value && (
                        <MainLeadBoxCovertTypoGraphy width={"max-content"}>
                          {value}
                          <CloseRounded
                            color="none"
                            fontSize="16px"
                            sx={{
                              cursor: "pointer",
                            }}
                            id={`${index}`}
                            onClick={(e) => {
                              index
                                ? onUpdateSuccessOfRow("", "WhoId")
                                : onUpdateSuccessOfRow("", "WhatId");
                              e.stopPropagation();
                            }}
                          />
                        </MainLeadBoxCovertTypoGraphy>
                      )
                    );
                  })}
                </TextBox>
              )}
            </RelatedOrDueDateBox>
          </ClickAwayListener>
          <RelatedOrDueDateBox
            border={"1px solid transparent"}
            // borderBottom={"4px"}
            sx={{
              "&:hover": {
                border: "1px solid #E6E9EB",
              },
            }}
          >
            {taskData?.ActivityDate || taskData?.Task?.ActivityDate ? (
              <CloseRounded
                visibility={"hidden"}
                height={"16px"}
                onClick={(e) => {
                  e.stopPropagation();
                  onUpdateSuccessOfRow(null, "ActivityDate", true);
                }}
              />
            ) : (
              <></>
            )}
            {taskData?.ActivityDate || taskData?.Task?.ActivityDate ? (
              <span
                style={{ padding: "8px 8px 8px 0px" }}
                onClick={(e) => {
                  setDatePickers(!datePickers);
                }}
              >
                {taskData.ActivityDate || taskData?.Task?.ActivityDate}
              </span>
            ) : (
              <DueDateContainer
                onClick={(e) => {
                  setDatePickers(!datePickers);
                }}
              >
                {"Due date"}
              </DueDateContainer>
            )}
            {datePickers && (
              <DatePickers
                value={
                  tableName !== "Task"
                    ? taskData.ActivityDate
                    : taskData?.Task?.ActivityDate
                }
                handleChange={(x) => {
                  onUpdateSuccessOfRow(x, "ActivityDate", true);
                  setDatePickers(false);
                }}
                setEditMode={setDatePickers}
                hideStack={false}
                expandViewCell={true}
                editTask={true}
              />
            )}
          </RelatedOrDueDateBox>
        </RelatedAndDueDateBox>


        {/* ========================== */}
        {taskData.Id?.length===0 &&<CreateTaskBox>



<StyledButton
  variant="outlined"
  size="small"
  onClick={() => {
    setNewTask();
    dispatch(Actions.getAsyncTask.asyncTask(true));
    searchTasksStyle && setSearchEditTask();
  }}
  style={{
    width: "100px",
  }}
>
  Cancel
</StyledButton>


<StyledButton
  variant="contained"
  size="small"
  onClick={() => {
  
                tableName !== "Task"
                  ? syncTask(taskName, taskData.Id ? false : true, taskData)
                  : onUpdateSuccessOfRow(taskName, "Subject", true);
                searchTasksStyle &&
                  dispatch(Actions.getAsyncTask.asyncTask(true));

                  tableName !== "Task"
                  ? comments !== taskRelatedData?.Description &&
                    onUpdateSuccessOfRow(comments, "Description", true, "Update")
                  : onUpdateSuccessOfRow(comments, "Description", true);
              
  }}
  sx={{
    background: "#00A1E0",
  }}
>
  Create Task
</StyledButton>




</CreateTaskBox>}
     
       
        {/* ========================== */}
      </MainTasksBox>
      {expandHeaders &&
        !searchTasksStyle &&
        (taskRelatedData?.Who?.Id ?? taskRelatedData?.What?.Id) && (
          <MainTasksBox
            width={"502px"}
            sx={{
              pointerEvents: taskEditLoader ? "none" : "pointer",
            }}
          >
            {outTaskExpandView && (
              <ExpandViewPopover
                id={id}
                open={expandViewOpen}
                anchorEl={expandAnchorEl}
                onClose={handleExpandViewClose}
                anchorOrigin={{
                  vertical: "center",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "center",
                  horizontal: "center",
                }}
              >
                <OpportunityView
                  tableName={whoTableName ?? whatTableName}
                  header={expandHeaders}
                  activeHeader={expandHeaders?.filter(
                    (data) => data.isVisible || data.selected
                  )}
                  leadView={true}
                  expandView={() => handleExpandViewClose()}
                  relatedFields={relatedFields}
                  rowId={rowId}
                  onUpdateSuccessOfRow={() => {
                    updateRecordsOnly(rowId);
                  }}
                />
              </ExpandViewPopover>
            )}
            <OpportunityView
              tableName={whoTableName ?? whatTableName}
              header={expandHeaders}
              activeHeader={
                expandHeaders.filter((data) => data.selected) ||
                handleHeaderOrActiveHeader(true, true).then((data) => data)
              }
              taskView={true}
              expandView={() => {
                setOutTaskExpandView(true);
                handleExpandView();
              }}
              onUpdateSuccessOfRow={() => {
                updateRecordsOnly(rowId);
              }}
              relatedFields={relatedFieldsHeader()}
              rowId={rowId}
              titleRowData={titleRowData}
              navTileData={tilesData}
            />
          </MainTasksBox>
        )}
    </ScrollableContainer>
  );
}

export default EditTask;
