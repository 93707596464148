import styled from "@emotion/styled";
import { Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { ButtonBox, StyledButton } from "../SearchView/SearchDrawer";
import {
  FooterBox,
  HeaderTypoGraphy,
  LeadViewHeader,
  MainLeadBoxTypoGraphy,
  MainLeadExistingBox,
  MainLeadTypoGraphy,
  MainScrollBarBox,
} from "./LeadView";
import { ReactComponent as SuccessLeadSvg } from "../../Assets/SVG/SuccessLead.svg";
import { ReactComponent as AccountLeadSvg } from "../../Assets/SVG/AccountLead.svg";
import { ReactComponent as ContactLeadSvg } from "../../Assets/SVG/ContactLead.svg";
import { ReactComponent as OpportunityLeadSvg } from "../../Assets/SVG/OpportunityLead.svg";
import { ExpandViewPopover } from "../../Pages/Pipeline";
import OpportunityView from "./ExpandOpportunityView";
import {
  createOrUpdateKey,
  getKeyData,
  getTableHeadersApi,
  handleAddFieldInTable,
} from "../../Pages/Services/tableApi";

const LeadCompleteBox = styled(Box)({
  color: "#0C4E48",
  background: "#E8F9F7",
  display: "flex",
  alignItems: "center",
  gap: "10px",
  borderRadius: "8px",
  padding: "12px",
});

const ConversionCompleteTypoGraphy = styled(Box)({
  color: "#0C4E48",
  fontFamily: "Inter",
  fontSize: "14px",
});

const SuccessSvg = styled(SuccessLeadSvg)({
  fontSize: "14px",
  height: "21px",
  width: "25px",
});

const MainBox = styled(Box)({
  padding: "12px",
  border: "1px solid #E6E9EB",
  borderRadius: "8px",
  display: "flex",
  gap: "10px",
  cursor: "pointer",
  "&:hover": {
    border: "1px solid blue",
  },
});

const FlexBox = styled(Box)({
  display: "flex",
  width: "100%",
  justifyContent: "space-between",
});

const MainDataBox = styled(Box)({
  flexDirection: "column",
  paddingRight: "1rem",
});

const MainDataBoxHeader = styled(Box)({
  fontWeight: 500,
  fontSize: "8px",
  lineHeight: "15px",
  letterSpacing: "1px",
  color: "#848F99",
  textTransform: "uppercase",
});

const MainDataBoxValues = styled(Box)({
  fontSize: "14px",
});

function ConvertLeadView({
  data,
  setConvertLead,
  goToLeadTable,
  onUpdateSuccess,
}) {
  const [convertLeadData, setConvertLeadData] = useState(null);
  const [tableName, setTableName] = useState(null);
  const [rowId, setRowId] = useState(null);
  const [header, setHeader] = useState(null);
  const [activeHeaders, setActiveHeaders] = useState(null);

  useEffect(() => {
    if (data) {
      setConvertLeadData(data);
    }
  }, [data]);

  //----ExpandView Popover ---//
  const [expandAnchorEl, setExpandAnchorEl] = useState(null);

  const handleExpandView = (event) => {
    setExpandAnchorEl(document.getElementById("root"));
  };
  const handleExpandViewClose = () => {
    setExpandAnchorEl(null);
  };

  const expandViewOpen = Boolean(expandAnchorEl);
  const id = expandViewOpen ? "simple-popover" : undefined;

  //-----//

  const getTableFieldHeaders = async (tableName) => {
    const dataParams = {
      table_name: tableName,
    };
    // const checkDataExist = localStorage.getItem(`Expand${tableName}Headers`);
    const checkDataExist = await getKeyData(
      JSON.stringify({
        key: `Expand${tableName}Headers`,
      })
    )
   
    if (checkDataExist) {
      // const headers = JSON.parse(
      //   localStorage.getItem(`Expand${tableName}Headers`)
      // );
      const activeHeader = checkDataExist?.data?.value.filter((data) => data.isVisible === true);
      setHeader(checkDataExist?.data?.value);
      setActiveHeaders(activeHeader);
      handleExpandView();
    } else {
      const JSONdataParams = JSON.stringify(dataParams);
      const res = await getTableHeadersApi(JSONdataParams);
      if (res.data.status === 200) {
        const activeHeader = res.data.headers.filter(
          (data) => data.selected === true
        );
        setHeader(res.data.headers);
        setActiveHeaders(activeHeader);
        handleExpandView();
        // localStorage.setItem(
        //   `Expand${tableName}Headers`,
        //   JSON.stringify(res.data.headers)
        // );
        const JSONdataParams = JSON.stringify({
          key: `Expand${tableName}Headers`,
          value: res.data.headers,
        });
          await createOrUpdateKey(JSONdataParams);
        // localStorage.setItem(
        //   `ExpandActive${tableName}Headers`,
        //   JSON.stringify(activeHeader)
        // );
      }
    }
  };

  return (
    <Box display={"flex"} flexDirection={"column"} height={"100%"}>
      <LeadViewHeader>
        <HeaderTypoGraphy> {"Lead conversion complete"}</HeaderTypoGraphy>
        <Divider />
      </LeadViewHeader>
      <MainScrollBarBox height={"78%"}>
        <LeadCompleteBox>
          <SuccessSvg />
          <ConversionCompleteTypoGraphy>
            {convertLeadData && convertLeadData.message}
          </ConversionCompleteTypoGraphy>
        </LeadCompleteBox>
        {convertLeadData &&
          Object.values(convertLeadData.table_data).map((data) => {
            return (
              <Box height={"max-content"}>
                <MainLeadTypoGraphy
                  sx={{
                    marginBottom: "0.5rem",
                    fontSize: "16px",
                  }}
                >
                  {data.attributes.type}
                </MainLeadTypoGraphy>
                <MainBox
                  onClick={() => {
                    setTableName(data.attributes.type);
                    setRowId(data.Id);
                    getTableFieldHeaders(data.attributes.type);
                  }}
                >
                  {data.attributes.type === "Account" ? (
                    <AccountLeadSvg
                      style={{
                        height: "14px",
                        width: "15px",
                        paddingTop: "4px",
                      }}
                    />
                  ) : data.attributes.type === "Contact" ? (
                    <ContactLeadSvg
                      style={{
                        height: "14px",
                        width: "15px",
                        paddingTop: "4px",
                      }}
                    />
                  ) : (
                    <OpportunityLeadSvg
                      style={{
                        height: "14px",
                        width: "15px",
                        paddingTop: "4px",
                      }}
                    />
                  )}

                  <MainLeadExistingBox width={"100%"}>
                    <MainLeadBoxTypoGraphy> {data.Name}</MainLeadBoxTypoGraphy>
                    {data.attributes.type === "Account" ? (
                      <FlexBox flexDirection={"column"}>
                        <MainDataBoxHeader>{"Account Owner"}</MainDataBoxHeader>
                        <MainLeadBoxTypoGraphy>
                          {`${data.Owner.FirstName} ${data.Owner.LastName}`}
                        </MainLeadBoxTypoGraphy>
                      </FlexBox>
                    ) : data.attributes.type === "Contact" ? (
                      <FlexBox flexDirection={"row"}>
                        <MainDataBox>
                          <MainDataBoxHeader>{"first name"}</MainDataBoxHeader>
                          <MainDataBoxValues>
                            {data.FirstName}
                          </MainDataBoxValues>
                        </MainDataBox>
                        <MainDataBox>
                          <MainDataBoxHeader>{"last name"}</MainDataBoxHeader>

                          <MainDataBoxValues>{data.LastName}</MainDataBoxValues>
                        </MainDataBox>
                        <MainDataBox>
                          <MainDataBoxHeader>
                            {"Account name"}
                          </MainDataBoxHeader>
                          <MainDataBoxValues>
                            {data.Account.Name}
                          </MainDataBoxValues>
                        </MainDataBox>
                      </FlexBox>
                    ) : data.attributes.type === "Opportunity" ? (
                      <FlexBox flexDirection={"row"}>
                        <MainDataBox>
                          <MainDataBoxHeader> {"owner id"}</MainDataBoxHeader>
                          <MainDataBoxValues>
                            {`${data.Owner.FirstName} ${data.Owner.LastName}`}
                          </MainDataBoxValues>
                        </MainDataBox>
                        <MainDataBox>
                          <MainDataBoxHeader>{"close date"}</MainDataBoxHeader>
                          <MainDataBoxValues>
                            {data.CloseDate}
                          </MainDataBoxValues>
                        </MainDataBox>
                        <MainDataBox>
                          <MainDataBoxHeader> {"stage"}</MainDataBoxHeader>
                          <MainDataBoxValues>
                            {data.StageName}
                          </MainDataBoxValues>
                        </MainDataBox>
                      </FlexBox>
                    ) : (
                      <></>
                    )}
                  </MainLeadExistingBox>
                </MainBox>
              </Box>
            );
          })}
      </MainScrollBarBox>
      <FooterBox height={"80px"}>
        <Divider />
        <ButtonBox
          sx={{
            borderRadius: "12px",
            width: "97%",
            padding: "1rem 0rem",
            justifyContent: "right",
          }}
        >
          <StyledButton
            variant="contained"
            size="small"
            onClick={() => {
              goToLeadTable();
            }}
          >
            Go to Leads
          </StyledButton>
          <StyledButton
            variant="outlined"
            size="small"
            onClick={() => {
              setConvertLead();
            }}
            style={{
              width: "100px",
            }}
          >
            Close
          </StyledButton>
        </ButtonBox>
      </FooterBox>
      <ExpandViewPopover
        id={id}
        open={expandViewOpen}
        anchorEl={expandAnchorEl}
        onClose={handleExpandViewClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
      >
        <OpportunityView
          expandView={() => handleExpandViewClose()}
          header={header}
          activeHeader={activeHeaders}
          tableName={tableName}
          rowId={rowId}
          onUpdateSuccess={() => onUpdateSuccess()}
          leadView={true}
        />
      </ExpandViewPopover>
    </Box>
  );
}

export default ConvertLeadView;
