/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Checkbox,
  Input,
  styled,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { updateTableCellApi } from "../../Pages/Services/tableApi";
import DatePickers from "../Date";
import CustomDateTimePicker from "../DateTime";
import SimpleFilterDropDown from "../FilterOpportunity/SimpleFilterDropDown";
import Loader from "../Loader";
import MultiSelectDropDown from "../MultiSelectDropDown";
import ReferenceSearchDropDown from "../ReferenceSearchDropDown";
import SimpleDropDown from "../SimpleDropDown";
import Toaster from "../Toaster";
import ViewCell from "./ViewCell";
import { toast } from "react-toastify";

export const MetricDataType = {
  Picklist: "picklist",
  Date: "date",
  Int: "int",
  Id: "id",
  Percentage: "percent",
  Boolean: "boolean",
  Currency: "currency",
  String: "string",
  Double: "double",
  Email: "email",
  Textarea: "textarea",
  Reference: "reference",
  Url: "url",
  DateTime: "datetime",
  Multipicklist: "multipicklist",
  Phone: "phone",
  // Address: "address"
};

const TextArea = styled(TextareaAutosize)((theme) => ({
  resize: "none",
}));

const ErrorStyledTypoGraphy = styled(Typography)({
  color: "#ff0000",
  fontSize: "12px",
  fontFamily: "Inter",
});

function EditCell({
  rawData,
  cellData,
  edit = false,
  onUpdateSuccess,
  expandViewCell,
  expandViewOpen,
  searchView,
  error,
  handleError,
  tableName,
  expandViewFirst,
  pipeline,
  taskView,
  editTask,
  searchViews,
  taskViewOnly,
}) {
  const dataType = rawData?.metric_datatype;
  // const metricNameType = rawData?.metric_name
  const [editMode, setEditMode] = useState(false);
  const [updateLoader, setUpdateLoader] = useState(false);
  const [cellsData, setCellsData] = useState(cellData);
  const options =
    rawData.metric_datatype === MetricDataType.CategoricalDependent
      ? JSON.parse(rawData.values[1])[cellsData.row[rawData.values[0]]]
      : rawData.values;
  const [toaster, setToaster] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  // const metricNameTypes={
  //   city:[],
  //   state:[],
  //   phone:[],
  //   country:[],
  //   description:[],
  //   name:[],
  //   url:[]
  // }

  const initialValue = () => {
    // if (dataType === MetricDataType.Currency) {
    //   return cellData.value ? cellData.value.value.replaceAll(",", "") : "";
    // }
    // if (dataType === MetricDataType.Date) {
    //   return cellData.value.val;
    // }
    // if (dataType === MetricDataType.Reference) {
    //   return cellData.value.val;
    // }
    // if (dataType === MetricDataType.Number) {
    //   return cellData.value.val;
    // }
    // if (dataType === MetricDataType.Id) {
    //   return cellData.value.val;
    // }
    // if (dataType === MetricDataType.Url) {
    //   return cellData.value.val;
    // }
    // if (dataType === MetricDataType.Percentage) {
    //   return cellData.value.val;
    // }
    // if (dataType === MetricDataType.Textarea) {
    //   return cellData.value.val;
    // }
    // if (dataType === MetricDataType.Multipicklist) {
    //   return cellData.value.val;
    // }
    if (dataType === MetricDataType.Risk) {
      return cellsData.value.val;
    }

    // if (cellData.value?.val) {
    //   return cellData.value.val;
    // }
    return typeof cellsData.value === "object" ? "" : cellsData.value;
  };
  const [value, setValue] = useState(initialValue());
  const [referenceValue, setReferenceValue] = useState("");

  const [fromSimpleDropDown, setFromSimpleDropDown] = useState(false);
  useEffect(() => {
    setValue(initialValue());
  }, [cellsData]);

  useEffect(() => {
    if (expandViewOpen) setCellsData(cellData);
  }, [cellData]);

  useEffect(() => {
    setEditMode(edit);
  }, [edit]);

  useEffect(() => {}, [editMode]);

  const handleMsgChange = (e) => {
    setTimeout(() => {
      if (
        (dataType === MetricDataType.Textarea ||
          dataType === MetricDataType.Url) &&
        e.target.value.length > 255
      ) {
        setValue(e.target.value.slice(cellData.formattedValue, 255));
        toast.error("Maximum Number of Characters Reached");
      } else if (
        (dataType === MetricDataType.String ||
          dataType === MetricDataType.Phone) &&
        e.target.value.length > 40
      ) {
        setValue(e.target.value.slice(cellData.formattedValue, 40));
        toast.error("Maximum Number of Characters Reached");
      } else if (dataType === MetricDataType.Id && e.target.value.length > 18) {
        setValue(e.target.value.slice(cellData.formattedValue, 18));
        toast.error("Maximum Number of Characters Reached");
      } else if (
        dataType === MetricDataType.Email &&
        e.target.value.length > 80
      ) {
        setValue(e.target.value.slice(cellData.formattedValue, 80));
        toast.error("Maximum Number of Characters Reached");
      }
    }, 0);
    // if (dataType === MetricDataType.Textarea && e.target.value.length > 255) {
    //   toast.error("Maximum Number of Characters Reached");
    // } else if (
    //   dataType === MetricDataType.String &&
    //   e.target.value.length > 40
    // ) {
    //   toast.error("Maximum Number of Characters Reached");
    // }
    const newCellData = { ...cellsData };
    newCellData.value = e.target.value;
    setCellsData(newCellData);
    setValue(e.target.value);
  };

  const onChangeHandler = (x, name, dropdown) => {
    const val = name || x;
    const newCellData = { ...cellsData };
    newCellData.value = val;

    setCellsData(newCellData);
    setValue(val);
    setReferenceValue(val);
    if (!dropdown) {
      handleUpdate(x, name);
      setEditMode(false);
    }
  };
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const getInputType = () => {
    if (dataType === MetricDataType.Date) {
      return "date";
    }
    if (dataType === MetricDataType.Reference) {
      return "reference";
    }
    if (dataType === MetricDataType.Id) {
      return "id";
    }
    if (dataType === MetricDataType.Url) {
      return "url";
    }
    if (dataType === MetricDataType.DateTime) {
      return "datetime";
    }
    if (dataType === MetricDataType.Percentage) {
      return "percent";
    }
    if (dataType === MetricDataType.Boolean) {
      return "boolean";
    }
    if (
      dataType === MetricDataType.Currency ||
      dataType === MetricDataType.Int
    ) {
      return "number";
    }
    if (dataType === MetricDataType.String) {
      return "string";
    }
    if (dataType === MetricDataType.Textarea) {
      return "textarea";
    }
    if (dataType === MetricDataType.Double) {
      return "double";
    }
    // if (dataType === MetricDataType.Address){
    //   return "address"
    // }
    return dataType;
  };

  const handleUpdate = async (value, name) => {
    if (searchView) {
      onUpdateSuccess(value, cellData.field, cellData.required, name);
      setEditMode(false);
    } else if (cellData.value !== value) {
      setUpdateLoader(true);
      setEditMode(false);

      const field = cellsData.field.includes(":")
        ? cellsData.field.split(":")[1]
        : cellsData.field;

      const id = rawData?.child_field
        ? cellsData["row"][rawData.child_field]
        : cellsData.id;

      const dataParams = {
        table_name: rawData.sobject,
        id: id,
        field: {
          [field]: value,
        },
      };
      const JSONdataParams = JSON.stringify(dataParams);
      let res;
      if (expandViewCell) {
        if (!cellsData.required && value && value.length !== 0) {
          res = await updateTableCellApi(JSONdataParams);
        } else if (cellsData.required) {
          res = await updateTableCellApi(JSONdataParams);
        }
      } else if (
        (value && value.length !== 0) ||
        Boolean(cellData.value) !== Boolean(value)
      ) {
        res = await updateTableCellApi(JSONdataParams);
      }

      if (res && res.data.status === 200) {
        onUpdateSuccess(value);
        // return setTimeout(() => {
        //   setEditMode(false);
        // }, 300);
      }
      if (res?.data?.status === 400) {
        // setErrorMsg(res?.data?.error || res?.data?.message);
        // setToaster(true);
        toast.error(res?.data?.detail || res?.data?.error);
      }
      setUpdateLoader(false);
    } else {
      setEditMode(false);
    }
  };

  const renderEditColumn = (expandViewCell, searchView) => {
    const searchingEnable =
      typeof options === "string" &&
      options !== "" &&
      JSON.parse(options)?.length > 6
        ? true
        : options?.length > 6;
    if (dataType === MetricDataType.Picklist) {
      return (
        <>
          <Box>
            {/* <SimpleDropDown
              value={value}
              options={options || []}
              onChange={(x) => {
                onChangeHandler(x);
              }}
              closeDropdown={() => {
                setEditMode(false);
                handleUpdate(value);
              }}
              expandViewCell={expandViewCell}
              searchView={searchView}
              editTask={editTask}
            /> */}
            <SimpleFilterDropDown
              value={value}
              onChange={onChangeHandler}
              header={
                typeof options === "string" && options !== ""
                  ? JSON.parse(options)
                  : options
              }
              //value={data.filterName}
              searching={searchingEnable}
              fromSimpleDropDown={true}
              //setFromSimpleDropDown={setFromSimpleDropDown}
              //avoidViewBox={true}
              setEditMode={setEditMode}
            />
          </Box>
        </>
      );
    }

    if (dataType === MetricDataType.Date) {
      return (
        <DatePickers
          value={value}
          handleChange={(x) => {
            onChangeHandler(x, false);
          }}
          handleUpdate={handleUpdate}
          setEditMode={setEditMode}
          hideStack={expandViewCell ? true : false}
          expandViewCell={expandViewCell}
          searchView={searchView}
        />
      );
    }

    if (dataType === MetricDataType.DateTime) {
      return (
        <CustomDateTimePicker
          value={value}
          handleChange={(x) => {
            onChangeHandler(x, false);
          }}
          handleUpdate={handleUpdate}
          setEditMode={setEditMode}
          hideStack={expandViewCell ? true : false}
          expandViewCell={expandViewCell}
          searchView={searchView}
        />
      );
    }

    if (dataType === MetricDataType.Multipicklist) {
      return (
        <MultiSelectDropDown
          value={value}
          options={options || []}
          onChange={(x, dropdown) => {
            onChangeHandler(x, dropdown);
          }}
          handleUpdate={handleUpdate}
          setEditMode={setEditMode}
          expandViewCell={expandViewCell}
          searchView={searchView}
        />
      );
    }

    if (dataType === MetricDataType.Boolean) {
      return expandViewCell ? (
        <Checkbox
          checked={value === "true" ? true : false}
          onChange={(event) => {
            onChangeHandler(event.target.checked.toString());
          }}
          sx={{ padding: 0 }}
        />
      ) : (
        <SimpleDropDown
          value={value}
          options={["true", "false"]}
          onChange={(x) => {
            onChangeHandler(x);
          }}
          searchView={searchView}
        />
      );
    }

    if (dataType === MetricDataType.CategoricalDependent) {
      return (
        <SimpleDropDown
          value={value}
          options={options || []}
          onChange={(x) => {
            onChangeHandler(x);
          }}
        />
      );
    }

    if (dataType === MetricDataType.Textarea) {
      const textEle = document.createElement("div");
      textEle.value = value;
      textEle.innerHTML = value;
      textEle.style.width = "280px";
      textEle.style.fontSize = "14px";
      textEle.style.visibility = "hidden";
      document.body.append(textEle);

      const textAreaEle = (
        <TextArea
          onChange={handleMsgChange}
          value={value}
          type={getInputType()}
          autoFocus={true}
          style={{
            overflow: expandViewCell ? "visible" : "auto",
            outline: expandViewCell ? "none" : "2px solid #1976d2",
            border: "none",
            fontFamily: "Inter",
            background: "white",
            minHeight: "100%",
            // height: textEle.getBoundingClientRect().height,
            height: "50px",
            maxHeight: "200px",
            width: expandViewCell ? "100%" : "280px",
            padding: expandViewCell ? "0px" : "8px",
            transform: searchView !== "TaskView" && "translateX(-8px)",
            paddingLeft: "px",
          }}
        />
      );
      textEle.remove();
      return textAreaEle;
    }

    if (dataType === MetricDataType.Reference) {
      return (
        <ReferenceSearchDropDown
          setEditMode={setEditMode}
          editMode={editMode}
          rawData={rawData}
          onChangeHandler={onChangeHandler}
          searchView={searchView}
          tableName={tableName}
          taskViewOnly={taskViewOnly}
        />
      );
    }

    return (
      <Input
        onChange={handleMsgChange}
        value={
          cellData.field === "Amount"
            ? Number(value) === 0
              ? "-"
              : Number(value)
            : value
        }
        type={getInputType()}
        autoFocus={true}
        disableUnderline
        sx={{
          fontSize: 14,
          width: expandViewCell ? "100%" : 280,
          height: expandViewCell ? 0 : 52,
          fontFamily: "Inter",
        }}
      />
    );
  };

  const setLoaderPosition = (test) => {
    return test?.length > 15
      ? (test.length * 7).toString()
      : (test.length * 6).toString();
  };

  return (
    <Box
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          editMode ? handleUpdate(value) : setEditMode(!editMode);
        }
        e.stopPropagation();
      }}
      onBlur={(e) => {
        e.stopPropagation();
        if (
          dataType !== MetricDataType.Date &&
          dataType !== MetricDataType.DateTime &&
          dataType !== MetricDataType.Multipicklist &&
          dataType !== MetricDataType.Reference
        )
          editMode ? handleUpdate(value) : setEditMode(!editMode);
      }}
      height="100%"
      display="flex"
      alignItems="center"
      sx={{
        width: pipeline && "100%",
      }}
    >
      {editMode ? (
        <Box
          position={"relative"}
          height={
            searchView === "TaskView" || tableName === "Task" ? "50px" : "100%"
          }
          minHeight={"24px"}
          width={
            searchView === "TaskView" || tableName === "Task"
              ? "96%"
              : dataType === "reference"
              ? "97%"
              : "95.1%"
          }
          whiteSpace={"nowrap"}
          overflow={
            dataType === "textarea" ||
            searchView === "TaskView" ||
            tableName === "Task" ||
            dataType === "reference" ||
            dataType === "picklist"
              ? "visible"
              : "hidden"
          }
          textOverflow={"ellipsis"}
          margin={(searchView === "TaskView" || tableName === "Task") && "0px"}
          sx={
            expandViewCell && {
              border: dataType === "boolean" ? "0px" : "1px solid #c1c1c1",
              borderRadius: "0.3rem",
              padding:
                dataType === "boolean"
                  ? "4px"
                  : searchView !== "TaskView" &&
                    dataType !== "reference" &&
                    dataType !== "boolean"
                  ? "8px"
                  : "4px",
              fontSize: "14px",
            }
          }
        >
          {renderEditColumn(expandViewCell, searchView)}
        </Box>
      ) : !updateLoader ? (
        <Box width={tableName === "Task" ? "261px" : "97%"} marginRight={"4px"}>
          <Box
            onClick={() => {
              setEditMode(true);
            }}
            sx={{
              overflow: "hidden",
              whiteSpace: "break-spaces",
              wordBreak: "break-word",
              textOverflow: "ellipsis",
              width: searchView
                ? searchView === "TaskView" || tableName === "Task"
                  ? dataType === "reference"
                    ? "97.5%"
                    : "95%"
                  : "98%"
                : dataType === "reference"
                ? "100%"
                : "98%",
              border:
                error &&
                cellsData &&
                cellsData.row &&
                cellsData.tableName &&
                cellsData.row[cellsData.tableName][cellsData.field] === ""
                  ? "1px solid red"
                  : !searchView && expandViewCell
                  ? dataType === "boolean"
                    ? "0px"
                    : !cellsData.required && value?.length === 0
                    ? "1px solid red"
                    : "1px solid #c1c1c1"
                  : searchView
                  ? dataType === "boolean"
                    ? "0px"
                    : searchView === "TaskView" || tableName === "Task"
                    ? "1px solid transparent"
                    : "1px solid #c1c1c1"
                  : "none",
              borderRadius: expandViewCell ? "0.3rem" : "0px",
              padding: expandViewCell
                ? dataType === "boolean"
                  ? "0px"
                  : dataType === "reference" &&
                    (searchView === "TaskView" || tableName === "Task")
                  ? "4.5px"
                  : dataType === "reference"
                  ? "4px"
                  : "8px"
                : "0px",
              fontSize: expandViewCell ? "14px" : "",
              "&:hover": {
                border:
                  (searchView === "TaskView" || tableName === "Task") &&
                  "1px solid #c1c1c1",
                cursor: "pointer",
              },
              minHeight: "20px",
            }}
          >
            <ViewCell
              value={
                searchView === true
                  ? cellData.value
                  : cellData.fieldType === "reference" ||
                    (rawData.metric_datatype === "reference" &&
                      cellData.row[cellData.field] != null)
                  ? cellData?.row[
                      rawData.call_parent
                        ? `${rawData.call_parent}:${rawData.metric_name}`
                        : rawData.parentSobject
                    ]?.Name || cellData.value
                  : cellData.value
              }
              nullable={rawData.nullable}
              cellData={cellsData}
              taskView={searchView}
              dataType={rawData.metric_datatype}
              setEditMode={setEditMode}
              expandViewCell={expandViewCell}
              onChangeHandler={(value) => {
                onChangeHandler(value);
              }}
              taskViewOnly={taskViewOnly}
              pipeline={pipeline}
            />
          </Box>
          {error ? (
            cellsData &&
            cellsData.row &&
            cellsData.tableName &&
            cellsData.row[cellsData.tableName][cellsData.field] === "" ? (
              <ErrorStyledTypoGraphy>{`Required fields missing : ${cellsData.display}`}</ErrorStyledTypoGraphy>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}

          {!searchView && expandViewCell ? (
            !cellsData.required && value === "" ? (
              <ErrorStyledTypoGraphy>{`Required fields missing : ${cellsData.display}`}</ErrorStyledTypoGraphy>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
        </Box>
      ) : !expandViewCell ? (
        <Loader />
      ) : (
        <Box
          position={"relative"}
          height={"100%"}
          minHeight={"24px"}
          width={"95.1%"}
          whiteSpace={"nowrap"}
          overflow={"visible"}
          textOverflow={"ellipsis"}
          sx={
            expandViewCell && {
              border: dataType === "boolean" ? "0px" : "1px solid #c1c1c1",
              borderRadius: "0.3rem",
              padding: dataType === "boolean" ? "0px" : "8px",
              fontSize: "14px",
            }
          }
        >
          <Box
            position={"absolute"}
            top={"-26px"}
            left={
              setLoaderPosition(
                `${cellsData?.child_field}${cellsData.display}`
              ) + "px"
            }
            marginLeft={cellsData.required ? "1.6rem" : "2rem"}
          >
            <Loader handleWidthOrHeight={true} />
          </Box>
          <ViewCell
            value={
              cellData.fieldType === "reference" ||
              (rawData.metric_datatype === "reference" &&
                cellData.row[cellData.field] !== null)
                ? cellData?.row[
                    rawData.call_parent
                      ? `${rawData.call_parent}:${rawData.metric_name}`
                      : rawData.parentSobject
                  ]?.Name || cellData.value
                : cellData.value
            }
            nullable={rawData.nullable}
            cellData={cellsData}
            taskView={searchView}
            dataType={rawData.metric_datatype}
            setEditMode={setEditMode}
            expandViewCell={expandViewCell}
            onChangeHandler={(value) => {
              onChangeHandler(value);
            }}
            taskViewOnly={taskViewOnly}
            pipeline={pipeline}
          />
        </Box>
      )}
      <Toaster
        open={toaster}
        close={() => setToaster(false)}
        error={errorMsg}
      />
    </Box>
  );
}

export default EditCell;
