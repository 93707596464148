import styled from "@emotion/styled";
import { Button, Divider } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { SideBarSubContentBox, SideBarSubContentHeader } from "../SettingView";

function Integrations() {
  const [state, setState] = useState(false);
  return (
    <SideBarSubContentBox>
      <SideBarSubContentHeader>Integrations</SideBarSubContentHeader>
      <p style={{ color: "#6A727A", margin: "2px 0px" }}>
        Connect apps and services to your TrackTable account.
      </p>

      <Divider
        sx={{
          marginTop: "1rem",
        }}
      ></Divider>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          position: "relative",
          width: "100%",
          height: "40px",
          justifyContent: "space-between",
          marginTop: "1rem",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              width: "max-content",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "16px",
              lineHeight: "19px",
              color: "#444647",
            }}
          >
            Salesforce
            <Box
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "17px",
                color: " #798EA3",
              }}
            ></Box>
          </Box>
        </Box>
        <Box>
          {/* check once for styling */}
          <Button
            // disabled
            sx={{
              cursor: "not-allowed",
              backgroundColor: !state ? "white" : "#00A1E0",
              // color: !state ? "black" : "white",
            }}
            variant={state ? "contained" : "outlined"}
            // onClick={() => setState(!state)}
          >
            {state ? "Add" : "Integrated"}
          </Button>
        </Box>
      </Box>
      <Divider
        sx={{
          marginTop: "1rem",
        }}
      />
    </SideBarSubContentBox>
  );
}

export default Integrations;
