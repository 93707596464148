import React, { useRef, useState, useEffect } from "react";

import { AiOutlinePlus } from "react-icons/ai";
import { HiOutlineTrash } from "react-icons/hi";
import { FaSortAmountDownAlt } from "react-icons/fa";
import { GiClockwiseRotation } from "react-icons/gi";
import { useNotes } from "../../Context/NotesContext";

import styled from "@emotion/styled/macro";
import NotesNames from "./NotesNames";
import DeleteModal from "./DeleteModal";

import { ClickAwayListener, Tooltip } from "@mui/material";

import {
  restoreNote,
  reorderNotes,
  postNote,
} from "../../Pages/Services/notesApi";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const SidebarMain = styled.div({
  backgroundColor: "#FAFAFA",
  width: "20rem",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
});

const SidebarContainer = styled.div({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  minWidth:"15rem"
});

const MyNotes = styled.div({
  color: "#798EA3",
  wight: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",

  " & h3": {
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "19px",
    color: "#444647",
  },
});

const NoteHeaderIcons = styled.div({
  display: "flex",
  position: "relative",
});

const MyNoteAddIcon = styled.div({
  padding: "4px",
  borderRadius: "8px",
  cursor: "pointer",

  position: "relative",

  "& .addNoteHeader": {
    zIndex: "2",
    display: "none",
    position: "absolute",
    width: "max-content",
    backgroundColor: "rgba(3, 46, 97, 0.72)",
    color: "#FFFFFF",
    top: "2rem",
    right: "-2rem",
    textAlign: "center",
    borderRadius: "8px",
    fontFamily: "Inter",
    fontSize: "14px",
    padding: "4px",
  },

  ":hover": {
    color: "#00A1E0",

    "& .addNoteHeader": {
      display: "block",
    },
  },
});

const NoteNameContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  position: "relative",
});
const NoteDisplay = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
  width: "90%",
  overflowY: "auto",
  // scrollbarGutter: "stable",
  overflowX: "hidden",
  height: "30rem",

  "& .drag": {
    top: "auto !important",
    left: "auto !important",
  },
});

const DisplayNotes = styled.div({
  cursor: "pointer",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  width: "100%",
  borderRadius:"8px",

  ":hover": {
    backgroundColor: "#F2F7FC",
    color: "#00A1E0",
  },

  "& div": {
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: "8rem",
    height: "1.2em",
    whiteSpace: "nowrap",
    margin: "4px",
  },
});

const Trash = styled.div({
  // color: "#798EA3",
  width: "100%",
  textAlign: "left",
  padding: "0rem 0.5rem 12px .5rem",

  cursor: "pointer",
  fontWeight: "600",

  display: "flex",
  alignItems: "center",
  gap: "5px",

  "& .trashIcon": {
    paddingLeft: "1rem",
    fontSize: "110%",
    display: "flex",
    alignItems: "center",
  },

  ":hover": { color: "#00A1E0" },
});

const AddNoteContainer = styled.div({
  // color: "#798EA3",
  borderTop: "3px solid #F2F2F2",
  padding: "12px 0",
  height: "100%",
  width: "100%",
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  ":hover": { color: "#00A1E0" },
  cursor: "pointer",
});
const AddNote = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
});

const SortConatiner = styled.div({
  zIndex: "3",
  position: "absolute",
  top: "25px",
  right: "-120px",

  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flexStart",
  padding: "12px",
  width: "12rem",
  borderRadius: "8px",

  background: "#FFFFFF",

  border: "1px solid #EFF5F8",
  boxShadow: "8px 18px 12px rgba(0, 0, 0, 0.05)",

  "& p": {
    cursor: "pointer",
    padding: "4px",
    margin: "0",
    fontSize: "14px",
    color: "#1A1E1F",
    borderRadius: "8px",

    ":hover": {
      backgroundColor: "#F2F7FC",

      color: "#00A1E0",
    },
  },

  "& span": {
    color: "#848F99",
  },
});

const TrashContainer = styled.div({
  zIndex: "4",
  backgroundColor: "white",
  width: "12rem",
  padding: ".5rem",
  position: "absolute",
  bottom: "2rem",
  right: "-13rem",
  borderRadius: "8px",
  height: "10rem",
  overflow: "auto",

  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
});

const TrashContent = styled.div({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderRadius: "8px",



  "& .trashIcons": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingRight: "4px ",
    cursor: "pointer",
    ":hover": {
      backgroundColor: "#F2F7FC",
      color: "#00A1E0",
    },
  },
});

const SidebarFooter = styled.div({
  position: "relative",
  width: "100%",
  height: "10rem",
  display: "flex",
  flexDirection: "column",
});

function Sidebar() {
  const { stateNotes, dispatchNotes } = useNotes();

  const { allNotes, trashNotes } = stateNotes;

  const [trashOpen, setTrashOpen] = useState(false);

  const showNotesFunction = (noteToBeDisplayed) => {
    const setPayload = noteToBeDisplayed;

    dispatchNotes({ type: "LOAD_NOTE", payload: setPayload });
  };

  const addNoteHandler = () => {
    const NewNote = {
      parent: null,
      title: "",
      content: {
        blocks: [],
        time: Date.now(),
        version: "2.25.0",
      },
    };
    postNote(dispatchNotes, NewNote);
  };

  const [showSort, setShowSort] = useState(false);

  const [sortNotes, setSortNotes] = useState("Custom");

  let showNotes = allNotes;

  if (sortNotes === "Title A-Z")
    showNotes = allNotes.sort((a, b) => {
      var textA = a.title.toUpperCase();
      var textB = b.title.toUpperCase();
      textB = textB.length ? textB : "Untitled";
      textA = textA.length ? textA : "Untitled";
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
  else if (sortNotes === "Title Z-A")
    showNotes = allNotes.sort((a, b) => {
      var textA = a.title.toUpperCase();
      var textB = b.title.toUpperCase();
      textB = textB.length ? textB : "Untitled";
      textA = textA.length ? textA : "Untitled";
      return textA < textB ? 1 : textA > textB ? -1 : 0;
    });
  else if (sortNotes === "Created Newest to oldest")
    showNotes = allNotes.sort(
      (a, b) => Date.parse(b.created_at) - Date.parse(a.created_at)
    );
  else if (sortNotes === "Created Oldest to newest")
    showNotes = allNotes.sort(
      (a, b) => Date.parse(a.created_at) - Date.parse(b.created_at)
    );
  else if (sortNotes === "Updated Newest to Oldest")
    showNotes = allNotes.sort(
      (a, b) => Date.parse(b.updated_at) - Date.parse(a.updated_at)
    );
  else if (sortNotes === "Updated Oldest to newest")
    showNotes = allNotes.sort(
      (a, b) => Date.parse(a.updated_at) - Date.parse(b.updated_at)
    );

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",

    borderRadius: "8px",
    background: isDragging && "#4188ff",

    ...draggableStyle,
  });

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [remove] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, remove);

    return result;
  };

  const onEnd = (result) => {
    setSortNotes("Custom");

    const test = reorder(
      showNotes,
      result?.source?.index,
      result?.destination?.index
    );
    showNotes = test;

    // send to backend

    reorderNotes(dispatchNotes, showNotes);
  };

  const [showModal, setShowModal] = useState(false);

  const [tempItem, setTempItem] = useState("");

  return (
    <SidebarMain>
      <SidebarContainer>
        <MyNotes>
          <h3>My Notes</h3>

          <NoteHeaderIcons>
            {/* sort */}

            <MyNoteAddIcon
              onClick={() => {
                setShowSort((prev) => !prev);
              }}
            >
              <FaSortAmountDownAlt />

              <div
                className="addNoteHeader"
                style={{
                  display: showSort && "none",
                }}
              >
                Sort : {sortNotes}
              </div>
            </MyNoteAddIcon>
            {showSort && (
              <ClickAwayListener
                onClickAway={() => {
                  if (showSort) {
                    setShowSort(false);
                  }
                }}
              >
                <SortConatiner>
                  {/* <p
                    onClick={() => {
                      setSortNotes("Custom");
                      setShowSort(false);
                    }}
                  >
                    Custom
                  </p> */}

                  <p
                    onClick={() => {
                      setSortNotes("Title A-Z");
                      setShowSort(false);
                    }}
                  >
                    Title <span className="sortText"> A-Z</span>
                  </p>
                  <p
                    onClick={() => {
                      setSortNotes("Title Z-A");
                      setShowSort(false);
                    }}
                  >
                    Title <span className="sortText">Z-A</span>
                  </p>
                  <p
                    onClick={() => {
                      setSortNotes("Created Newest to oldest");
                      setShowSort(false);
                    }}
                  >
                    Created <span className="sortText">Newest to oldest</span>
                  </p>
                  <p
                    onClick={() => {
                      setSortNotes("Created Oldest to newest");
                      setShowSort(false);
                    }}
                  >
                    Created <span className="sortText">Oldest to newest</span>
                  </p>
                  <p
                    onClick={() => {
                      setSortNotes("Updated Newest to oldest");
                      setShowSort(false);
                    }}
                  >
                    Updated <span className="sortText">Newest to oldest</span>
                  </p>
                  <p
                    onClick={() => {
                      setSortNotes("Updated Oldest to newest");
                      setShowSort(false);
                    }}
                  >
                    Updated <span className="sortText">Oldest to newest</span>
                  </p>
                </SortConatiner>
              </ClickAwayListener>
            )}
            {/* add */}
            <MyNoteAddIcon onClick={addNoteHandler}>
              <AiOutlinePlus />

              <div className="addNoteHeader">Add Note</div>
            </MyNoteAddIcon>
          </NoteHeaderIcons>
        </MyNotes>

        <NoteNameContainer>
          <NoteDisplay>
            <DragDropContext onDragEnd={onEnd}>
              <Droppable droppableId="123">
                {(provided, snapshot) => (
                  <div style={{ width: "100%" }} ref={provided?.innerRef}>
                    {showNotes?.map((item, index) => (
                      <Draggable
                        draggableId={item.id}
                        key={item.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            className="drag"
                            ref={provided?.innerRef}
                            {...provided?.draggableProps}
                            {...provided?.dragHandleProps}
                            style={getItemStyle(
                              snapshot?.isDragging,
                              provided?.draggableProps.style
                            )}
                          >
                            <NotesNames key={item.id} item={item} />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided?.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </NoteDisplay>
        </NoteNameContainer>
      </SidebarContainer>

      <SidebarFooter>
        <Trash onClick={() => setTrashOpen((prev) => !prev)}>
          <HiOutlineTrash className="trashIcon" />
          <div>Trash</div>
        </Trash>
        {trashOpen && (
          <ClickAwayListener
            onClickAway={() => {
              if (trashOpen) {
                setTrashOpen(false);
              }
            }}
          >
            <TrashContainer
              style={{
                scrollbarGutter: trashNotes.length > 6 && "stable",
              }}
            >
              {trashNotes.length !== 0 ? (
                <div className="trashNotes">
                  {trashNotes.map((item) => (
                    <TrashContent key={item.id}>
                      <Tooltip
                        className="iconHover"
                        PopperProps={{
                          disablePortal: true,
                          sx: {
                            "& .MuiTooltip-tooltip": {
                              width: "auto",
                              height: "16px",
                              background: "rgba(3, 46, 97, 0.72)",
                              borderRadius: "4px",
                              fontFamily: "Inter",
                              textAlign: "center",
                            },
                          },
                        }}
                        placement="bottom"
                        title= {item.title ? item.title : "Untitled"}
                      >
                       <DisplayNotes>
                        <div onClick={() => showNotesFunction(item)}>
                          {item.title ? item.title : "Untitled"}
                        </div>
                      </DisplayNotes>
                      </Tooltip>
                      <Tooltip
                        className="iconHover"
                        PopperProps={{
                          disablePortal: true,
                          sx: {
                            "& .MuiTooltip-tooltip": {
                              width: "110px",
                              height: "16px",
                              background: "rgba(3, 46, 97, 0.72)",
                              borderRadius: "4px",
                              fontFamily: "Inter",
                              textAlign: "center",
                            },
                          },
                        }}
                        placement="bottom"
                        title="Restore from Trash"
                      >
                        <div className="trashIcons">
                          <GiClockwiseRotation
                            onClick={() => restoreNote(dispatchNotes, item.id)}
                          />
                        </div>
                      </Tooltip>

                      <Tooltip
                        className="iconHover"
                        PopperProps={{
                          disablePortal: true,
                          sx: {
                            "& .MuiTooltip-tooltip": {
                              width: "110px",
                              height: "16px",
                              background: "rgba(3, 46, 97, 0.72)",
                              borderRadius: "4px",
                              fontFamily: "Inter",
                              textAlign: "center",
                            },
                          },
                        }}
                        placement="bottom"
                        title="Delete Permanently"
                      >
                        <div className="trashIcons">
                          <HiOutlineTrash
                            onClick={() => {
                              setShowModal(true);
                              setTempItem(item);
                            }}
                          />
                        </div>
                      </Tooltip>
                    </TrashContent>
                  ))}
                </div>
              ) : (
                <div style={{ textAlign: "center" }}>Trash is Empty</div>
              )}
            </TrashContainer>
          </ClickAwayListener>
        )}

        <DeleteModal
          showModal={showModal}
          onClose={() => setShowModal(false)}
          item={tempItem}
        />
        <AddNoteContainer onClick={addNoteHandler}>
          <AddNote>
            <AiOutlinePlus
              style={{ paddingLeft: "16px", paddingRight: "4px" }}
            />
            New Note
          </AddNote>
        </AddNoteContainer>
      </SidebarFooter>
    </SidebarMain>
  );
}

export default Sidebar;
