import axios from "axios";
import { toast } from "react-toastify";
import { getImageData, getNewAccessToken } from "./tableApi";

const secureAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_TRACK_TABLE_BACKEND_REDIRECT_AWS_URL,
});

// Add a request interceptor
secureAxiosInstance.interceptors.request.use(
  (reqConfig) => {
    // Do something before request is sent
    const d = new Date();
    const month = d.getMonth() < 9 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
    const day = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate();
    const date = `${d.getFullYear()}-${month}-${day}`;

    const allGetUrls = [
      "/add_all_fields_in_db/",
      "/add_lead_convertedstatus/",
      "/recent_searches/",
      "/recent_searches/",
      "/add_table_keyprefix_records/",
      "/add_overview/",
      "/get_iamage_link/",
      `/get_all_task/?date=${date}`,
      "/add_foreign_table_fields/",
      "/sync_data_for_tile/",
      "/logout/",
    ];

    if (!reqConfig.url.includes("/api/v1/")) {
      reqConfig.method = allGetUrls.includes(reqConfig.url) ? "get" : "post";
    }

    reqConfig.headers = {
      "Content-Type": "application/json",
      "instance-url": localStorage.getItem("instance_url"),
      "access-token": localStorage.getItem("access_token"),
      user: localStorage.getItem("user_id"),
    };

    reqConfig.headers =
      reqConfig.url === "/logout/"
        ? {
            ...reqConfig.headers,
            "refresh-token": localStorage.getItem("refresh_token"),
          }
        : reqConfig.url === "/add_all_fields_in_db/"
        ? {
            ...reqConfig.headers,
            "user-url": localStorage.getItem("id"),
          }
        : { ...reqConfig.headers };

    return reqConfig;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
secureAxiosInstance.interceptors.response.use(
  async (response) => {
    // console.log("response", response);
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (response.config.url === "/login/" && response.data.status === 401) {
      console.log(response?.data?.message);
    } else if (
      response.config.url !== "/refresh_token/" &&
      response.data.status === 401
    ) {
      const res = await getNewAccessToken();
      if (res.data.status === 200) {
        const credentials = res.data.credentials;
        Object.keys(credentials).forEach((credential) => {
          localStorage.setItem(credential, credentials[credential]);
        });
        response = await secureAxiosInstance(response.config);
        document.cookie
          .split(";")
          .forEach(
            (cookie) =>
              (document.cookie = cookie
                .replace(/^ +/, "")
                .replace(
                  /=.*/,
                  `=;expires=${new Date(0).toUTCString()};path=/`
                ))
          );

        const imageResponse = await getImageData();
        if (imageResponse.data.status === 200) {
          const {
            image_link,
            location,
            photographer_name,
            photographer_user_link,
          } = imageResponse.data.data;
          const data = {
            url: `url("${image_link}&w=auto")`,
            location: location,
            pgName: photographer_name,
            pgProfileUrl: photographer_user_link,
          };
          document.cookie = `name=${JSON.stringify(data)};expires=${new Date(
            Date.now() + 86400e3
          ).toUTCString()};`;
        }
      }
    } else if (
      response.config.url === "/logout/" ||
      response.data.status === 401
    ) {
      window.location = "/login";
      localStorage.clear();

      if (response.config.url !== "/logout/")
        localStorage.setItem("key", "true");
    }
    // if (response.config.url === "/refresh_token/") {
    //   const credentials = response.data.credentials;
    //   Object.keys(credentials).forEach((credential) => {
    //     localStorage.setItem(credential, credentials[credential]);
    //   });
    //   document.cookie
    //     .split(";")
    //     .forEach(
    //       (cookie) =>
    //         (document.cookie = cookie
    //           .replace(/^ +/, "")
    //           .replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`))
    //     );

    //   const imageResponse = await getImageData();
    //   if (imageResponse.data.status === 200) {
    //     const {
    //       image_link,
    //       location,
    //       photographer_name,
    //       photographer_user_link,
    //     } = imageResponse.data.data;
    //     const data = {
    //       url: `url("${image_link}&w=auto")`,
    //       location: location,
    //       pgName: photographer_name,
    //       pgProfileUrl: photographer_user_link,
    //     };
    //     document.cookie = `name=${JSON.stringify(data)};expires=${new Date(
    //       Date.now() + 86400e3
    //     ).toUTCString()};`;
    //   }
    // }

    return response;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default secureAxiosInstance;
