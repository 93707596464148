const wishModalOn = (payload) => {
  return {
    type: "ON",
    payload: payload,
  };
};

const wishModalOff = (payload) => {
  return {
    type: "OFF",
    payload: payload,
  };
};

const toggleWishModal = {
  wishModalOn,
  wishModalOff,
};

export default toggleWishModal;
