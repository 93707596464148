import styled from "@emotion/styled";
import { CloseRounded } from "@mui/icons-material";
import { Checkbox, Chip, Paper, Stack, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect } from "react";
import { AddBox, MainLeadBoxCovertTypoGraphy } from "../ExpandView/LeadView";
import { MetricDataType } from "./EditCell";

export const Item = styled(Box)({
  backgroundColor: "#F5F5F5",
  padding: 2,
  textAlign: "center",
  color: "#444647",
  border: "1px solid #F5F5F5",
  borderRadius: "8px",
  margin: "-4px",
  display: "flex",
  alignItems: "center",
  gap: "4px",
  cursor: "pointer",
});

function ViewCell({
  value,
  cellData,
  dataType,
  expandViewCell,
  onChangeHandler,
  key,
  taskView,
  nullable,
  setEditMode,
  taskViewOnly,
  pipeline,
}) {
  // {
  //   console.log(dataType, ReferenceFieldWidthForPipeline);
  // }
  const {
    field,
    row: { Opportunity_Id, Account_Id },
  } = cellData;

  if (field === "Opportunity_Name") {
    return (
      <p
        style={{
          width: "100%",
          wordWrap: "break-word",
          whiteSpace: "normal",
        }}
        key={key}
      >
        {value}
      </p>
    );
  }
  if (dataType === MetricDataType.JSON) {
    return value
      ? Object.keys(value)
          .map((x) => value[x])
          .filter((x) => x !== null)
          .join(", ")
      : "-";
  }
  if (dataType === MetricDataType.MetricChange) {
    return value ? `${value.metric} ${value.change}` : "-";
  }
  if (dataType === MetricDataType.Risk) {
    return value ? value : "-";
  }
  if (dataType === MetricDataType.Notes) {
    if (value) return value.msg;
    return value;
  }
  if (dataType === MetricDataType.Date) {
    return value ? (
      <Stack width={expandViewCell && "max-content"} key={key}>
        {expandViewCell ? (
          <Item padding={taskView === "TaskView" && "4px"}>{value}</Item>
        ) : (
          <Chip label={value} />
        )}
      </Stack>
    ) : (
      "-"
    );
  }
  if (dataType === MetricDataType.Reference) {
    return value ? (
      <MainLeadBoxCovertTypoGraphy width={"max-content"}>
        {/* <Tooltip title={value}> */}
        <Box
          sx={
            taskView === "TaskView"
              ? {
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  width: "200px",
                }
              : pipeline
              ? {
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  maxWidth: cellData.field.includes(":") ? "220px" : "165px",
                }
              : {}
          }
        >
          {value}
        </Box>
        {/* </Tooltip> */}
        {taskViewOnly ? (
          cellData.field === "AccountId" ? (
            <></>
          ) : (
            <CloseRounded
              color="none"
              fontSize="16px"
              sx={{
                cursor: "pointer",
              }}
              id={`${"Task"}`}
              onClick={(e) => {
                //debugger;
                nullable && onChangeHandler("", field);
                if (!nullable && setEditMode) setEditMode(true);
                e.stopPropagation();
              }}
            />
          )
        ) : (
          <CloseRounded
            color="none"
            fontSize="16px"
            sx={{
              cursor: "pointer",
            }}
            id={`${"Task"}`}
            onClick={(e) => {
              //debugger;
              nullable && onChangeHandler("", field);
              if (!nullable && setEditMode) setEditMode(true);
              e.stopPropagation();
            }}
          />
        )}
      </MainLeadBoxCovertTypoGraphy>
    ) : (
      <AddBox />
    );
  }
  if (dataType === MetricDataType.Int) {
    return value ? value : "-";
  }
  if (dataType === MetricDataType.Id) {
    return value ? value : "-";
  }
  if (dataType === MetricDataType.Url) {
    return value ? (
      <Tooltip title={value.length > 15 ? value : ""}>
        <Box
          overflow={"hidden"}
          whiteSpace={"nowrap"}
          textOverflow={"ellipsis"}
        >
          {value}
        </Box>
      </Tooltip>
    ) : (
      "-"
    );

    // return value ? value : "-"
  }
  if (dataType === MetricDataType.DateTime) {
    // return value ? value : "-";
    const val = new Date(value);
    const date = val.toDateString();
    const time = val.toLocaleTimeString(navigator.language, {
      hour: "2-digit",
      minute: "2-digit",
    });

    return value ? (
      <Stack width={expandViewCell ? "max-content" : "100%"} key={key}>
        {expandViewCell ? (
          <Item padding={taskView === "TaskView" && "4px"}>
            {`${date} ${time}`}
            <CloseRounded
              onClick={(event) => {
                onChangeHandler("");
                event.stopPropagation();
              }}
              sx={{ fontSize: "14px", cursor: "pointer" }}
            />
          </Item>
        ) : (
          <Chip label={`${date} ${time}`} />
        )}
      </Stack>
    ) : (
      "-"
    );
  }
  if (dataType === MetricDataType.Percentage) {
    return value ? `${value} %` : "-";
  }
  if (dataType === MetricDataType.Boolean) {
    return expandViewCell ? (
      <Checkbox
        sx={{ padding: 0, scale: taskView === "TaskView" && 0.9 }}
        checked={
          (taskView === "TaskView" ? value === true : value === "true")
            ? true
            : false
        }
        onChange={(event) => {
          if (cellData.isEditable)
            taskView === "TaskView"
              ? onChangeHandler(event.target.checked, field)
              : onChangeHandler(event.target.checked.toString());
        }}
        key={key}
      />
    ) : value ? (
      `${value}`
    ) : (
      "-"
    );
  }
  if (dataType === MetricDataType.Currency) {
    return value ? `$ ${value}` : "-";
  }
  if (dataType === MetricDataType.Textarea) {
    // const textEle = document.createElement("div");
    // textEle.value = value;
    // textEle.innerHTML = value;
    // textEle.style.width = "280px";
    // textEle.style.fontSize = "14px";
    // textEle.style.visibility = "hidden";
    // document.body.append(textEle);

    if (value === undefined) {
      return "-";
    }
    return (
      <Tooltip
        title={value?.length > 15 ? value : ""}
        placement={"bottom"}
        PopperProps={{
          disablePortal: true,
        }}
      >
        <Box
          sx={{
            display: "-webkit-box",
            "-webkit-line-clamp": "2",
            "-webkit-box-orient": "vertical",
          }}
          className="longText"
        >
          {value}
        </Box>
      </Tooltip>
    );

    // debugger;
    // if (textEle.getBoundingClientRect().height > 16) {
    //   const rowDensity =
    //     localStorage.getItem(`${activeTab}RowSizeIndicator`) ?? "Medium";
    //   let _rowSize = 60;
    //   if (rowDensity === "Short") {
    //     _rowSize = 40;
    //   } else if (rowDensity === "Medium") {
    //     _rowSize = 60;
    //   } else if (rowDensity === "Tall") {
    //     _rowSize = 100;
    //   } else if (rowDensity === "Extra Tall") {
    //     _rowSize = 140;
    //   }
    //   console.log("_rowSize", _rowSize);
    //   console.log("rowDensity", rowDensity);
    //   const ellipsisValue =
    //     value?.length > 75 && _rowSize === 40
    //       ? value.trim().substring(0, 25) + "..."
    //       : value?.length > 75
    //       ? value.trim().substring(0, 75) + "..."
    //       : value;
    //   textEle.remove();
    //   return (
    //     <Tooltip
    //       title={ellipsisValue}
    //       PopperProps={{
    //         disablePortal: true,
    //       }}
    //     >
    //       <p
    //         style={{
    //           width: expandViewCell ? "100%" : "280px",
    //           whiteSpace: "normal",
    //           margin: expandViewCell ? "0px" : "1px",
    //         }}
    //       >
    //         {ellipsisValue}
    //       </p>
    //     </Tooltip>
    //   );
    // } else {
    //   // console.log(
    //   //   "textEle.getBoundingClientRect().height2",
    //   //   textEle.getBoundingClientRect().height
    //   // );
    //   // console.log("textEle", textEle);
    //   textEle.remove();
    //   return value && value.length > 190 ? (
    //     <Tooltip
    //       title={value.trim().substring(0, 195) + "..."}
    //       PopperProps={{
    //         disablePortal: true,
    //       }}
    //     >
    //       <p
    //         style={{
    //           width: expandViewCell ? "100%" : "280px",
    //           whiteSpace: "normal",
    //           margin: expandViewCell ? "0px" : "2px",
    //         }}
    //       >
    //         {value.trim().substring(0, 195) + "..."}
    //       </p>
    //     </Tooltip>
    //   ) : value && value.length < 190 ? (
    //     <p
    //       style={{
    //         width: expandViewCell ? "100%" : "280px",
    //         whiteSpace: "normal",
    //         margin: expandViewCell ? "0px" : "3px",
    //         // overflow: "hidden",
    //         // textOverflow: "ellipsis",
    //       }}
    //     >
    //       {value}
    //     </p>
    //   ) : (
    //     "-"
    //   );
    // }
  }
  if (dataType === MetricDataType.Double) {
    return value ? value : "-";
  }
  if (dataType === MetricDataType.Multipicklist) {
    if (expandViewCell) {
      // value.split(";").map((data) => `${data},`)
      return typeof value === "string" && value ? (
        <Box
          display={"flex"}
          gap={"10px"}
          className={`${cellData.field}`}
          key={key}
        >
          {value.split(";").map((data) => {
            return (
              <Stack width={expandViewCell ? "max-content" : "100%"}>
                <Item padding={taskView === "TaskView" && "4px"}>
                  {data}
                  <CloseRounded
                    id={`${data}`}
                    sx={{ fontSize: "14px", cursor: "pointer" }}
                    onClick={(event) => {
                      const data = event.currentTarget.id || event.target.id;
                      const handleValue = value.includes(data)
                        ? value
                            .split(";")
                            .filter((val) => val !== data)
                            .join(";")
                        : value.split(";").length > 0
                        ? value.concat(`;${data}`)
                        : data;
                      onChangeHandler(handleValue);
                      event.stopPropagation();
                    }}
                  />
                </Item>
              </Stack>
            );
          })}
        </Box>
      ) : (
        "-"
      );
    } else {
      return value ? value.split(";").map((data) => `${data},`) : "-";
    }
  }

  return value ? (
    <Tooltip
      title={value.length > 15 ? value : ""}
      placement={"bottom"}
      PopperProps={{
        disablePortal: true,
      }}
    >
      <Box
        sx={{
          display: "-webkit-box",
          "-webkit-line-clamp": "2",
          "-webkit-box-orient": "vertical",
        }}
        className="longText"
      >
        {typeof value === "object" ? "-" : value}
      </Box>
    </Tooltip>
  ) : (
    "-"
  );
}

export default ViewCell;
