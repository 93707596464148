import styled from "@emotion/styled";
import { Box, InputBase } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import useDebounce from "../../Hooks/useDebounce";
import Loader from "../Loader";

const SearchBox = styled(Box)({
  border: "1px solid #E6E9EB",
  borderRadius: "8px",
  width: "95%",
  marginLeft: "0px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingRight: "0.4rem",
});

const Search = styled(InputBase)({
  ".MuiInputBase-input": {
    fontSize: "14px",
    fontFamily: "Inter",
    width: "unset",
  },
});

function SimpleSearchDropDown({
  name,
  handleChange,
  key,
  rawData,
  searchLoader,
  taskRelatedTo = false,
  setEditMode,
  handleCss = false,
}) {
  const [search, setSearch] = useState("");
  const [tableName, setTableName] = useState("");

  const searchDebounceText = useDebounce(search, 500);

  useEffect(() => {
    setTableName(name);
  }, [name]);

  useEffect(() => {
    if (searchDebounceText !== "") {
      handleChange(searchDebounceText.trim(), tableName);
    }
  }, [searchDebounceText]);

  const SearchComponentBoxStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginRight: "12px",
  };

  return (
    <Box sx={{ padding: !taskRelatedTo && "12px" }} key={key}>
      {taskRelatedTo ? (
        <Box sx={SearchComponentBoxStyle}>
          <Search
            sx={{ ml: 1, flex: 1 }}
            value={search}
            placeholder={
              taskRelatedTo ? `Search Salesforce` : `Search ${tableName} Name`
            }
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            key={key}
            // inputProps={{ "aria-label": "search google maps" }}

            autoFocus
          />
          <Box>{searchLoader ? <Loader leadView={true} /> : <></>}</Box>
        </Box>
      ) : (
        <SearchBox>
          <Box sx={SearchComponentBoxStyle}>
            <Search
              sx={{ ml: 1, flex: 1 }}
              value={search}
              placeholder={
                taskRelatedTo
                  ? `Search Salesforce`
                  : handleCss
                  ? `Search ${
                      rawData.metric_datatype === "reference"
                        ? `${rawData.metric_display_name.split("ID")[0]}`
                        : rawData.metric_display_name
                    }`
                  : `Search ${tableName} Name`
              }
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              key={key}
              // inputProps={{ "aria-label": "search google maps" }}
              autoFocus
            />
            <Box>{searchLoader ? <Loader leadView={true} /> : <></>}</Box>
          </Box>
        </SearchBox>
      )}
    </Box>
  );
}

export default SimpleSearchDropDown;
