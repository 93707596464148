const setHasAccess = (payload) => {
  return {
    type: "TRUE",
    payload: payload,
  };
};

const setHasNoAccess = (payload) => {
  return {
    type: "FALSE",
    payload: payload,
  };
};

const getHasAccess = {
  setHasAccess,
  setHasNoAccess,
};

export default getHasAccess;
