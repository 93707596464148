import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Chip,
  Popover,
  Stack,
  styled,
  TextField,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { Item } from "./Table/ViewCell";

export const InputContainer = styled(TextField)({
  visibility: "hidden",
  width: 0,
  height: 0,
});

export const CustomDatePicker = styled(Box)({
  // ".MuiPickerStaticWrapper-root": {
  //   borderRadius: "15px",
  // },
  ".MuiPickersDay-root:focus": {
    backgroundColor: "rgb(0, 161, 224)",
    color: "white",
  },
  // ".MuiTypography-caption": {
  //   color: "blue !important",
  // },

  ".MuiDialogActions-root": {
    display: "none",
  },
  ".MuiCalendarPicker-root": {
    "& > :first-child": {
      "& > :first-child": {
        display: "-webkit-inline-box",
        marginLeft: "80px",
        color: "black",
        fontWeight: 400,
        ".MuiIconButton-sizeSmall": {
          display: "none",
        },
      },
      "& > :last-child": {
        ".MuiIconButton-edgeEnd": {
          display: "-webkit-inline-flex",
          right: "233px",
          color: "blue",
          paddingTop: "4px",
        },
        ".MuiIconButton-edgeStart": {
          color: "blue",
          paddingTop: "4px",
        },
        div: {
          display: "none",
        },
      },
    },
  },
});

export const PopOverBox = styled(Popover)({
  top: "50px",
  left: "80px",
  zoom: "0.85",
  "& .MuiPaper-root": {
    top: "50px",
    left: "80px",
  },
  ".MuiPaper-root": {
    borderRadius: "15px",
    boxShadow: "-6px 7px 34px rgba(0, 0, 0, 0.16)",
    height: "400px",
    width: "320px",
    overflow: "hidden",
  },
  ".PrivatePickersFadeTransitionGroup-root": {
    height: "280px",
    paddingTop: "4px",
  },
  ".MuiYearPicker-root": {
    height: "268px",
    margin: "0px",
  },
});

export const BoxButton = styled(Box)({
  width: "100%",
  position: "relative",
  top: "-8px",
  display: "flex",
  justifyContent: "space-around",
  height: "28px",
  background: "white",
  paddingTop: "0.3rem",
  paddingBottom: "0.6rem",
  
});

const DatePickerComponent = styled(StaticDatePicker)({
  margin: "4px",
  fontWeight: 400,
  fontFamily: "Inter",
  ".PrivatePickersToolbar-root": {
    display: "none",
  },
});

function DatePickers({
  value,
  handleChange,
  handleUpdate,
  setEditMode,
  hideStack = true,
  expandViewCell,
  searchView,
  taskView,
  taskFilter,
  editTask,
}) {
  const [newDate, setNewDate] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    setAnchorEl(document.getElementById("date-tag"));
  }, []);

  const open = Boolean(anchorEl);

  return (
    <>
      {hideStack ? (
        <Stack
          aria-describedby={"simple-popover"}
          width="max-content"
          id="date-tag"
        >
          <Item>{value ? value : "-"}</Item>
        </Stack>
      ) : !expandViewCell ? (
        <Stack
          aria-describedby={"simple-popover"}
          width="100%"
          id="date-tag"
          marginTop={"12px"}
        >
          <Chip label={value} />
        </Stack>
      ) : (
        <Stack
          aria-describedby={"simple-popover"}
          id="date-tag"
          // position={editTask && "absolute"}
        ></Stack>
      )}
      <PopOverBox
        anchorEl={anchorEl}
        open={open}
        id={"simple - popover"}
        onClose={() => {
          setEditMode(false);
        }}
        sx={{
          ".MuiPaper-root": {
            zoom: taskView && "0.9",
            boxShadow: taskView && "0 2px 8px rgb(0 0 0 / 10%)",
            borderRadius: (taskView || editTask) && "8px",
          },
          top:
            taskView && taskFilter === "Completed"
              ? "105px"
              : taskFilter === "Today and Overdue"
              ? "125px"
              : taskFilter === "Next 7 days"
              ? "125px"
              : taskFilter === "All"
              ? "125px"
              : new Date(taskFilter).getDate() && "105px",
          //   left:
          //     taskView && taskFilter === "Completed"
          //       ? "-85px"
          //       : taskFilter === "Today and Overdue"
          //       ? "-100px"
          //       : taskFilter === "Next 7 days"
          //       ? "-90px"
          //       : taskFilter === "All"
          //       ? "-75px"
          //       : "",
        }}
      >
        <CustomDatePicker
          sx={{
            ".MuiPickersDay-root": {
              borderRadius: taskView && "15%",
              height: taskView && "35px",
            },
            ".PrivatePickersSlideTransition-root": {
              minHeight: taskView && "230px",
            },
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePickerComponent
              value={value}
              onChange={(newValue) => {
                setNewDate(newValue);
                taskView &&
                  handleChange(new Date(newValue).toISOString().split("T")[0]);
                editTask &&
                  handleChange(new Date(newValue).toISOString().split("T")[0]);
              }}
              renderInput={(params) => (
                <InputContainer {...params} value={value} />
              )}
              showToolbar={false}
            />
            <BoxButton
              style={{
                top: taskView && "-12px",
                borderTop: (taskView || editTask) && "1px solid #E6E9EB",
                padding: taskView ? "12px 0rem" : editTask && "0.5rem 0rem",
              }}
            >
              {!taskView && !editTask && (
                <Button
                  sx={{
                    backgroundColor: "#00A1E0",
                  }}
                  variant="contained"
                  size="small"
                  onClick={() => setEditMode(false)}
                >
                  Cancel
                </Button>
              )}
              {!hideStack && (
                <Button
                  sx={{
                    backgroundColor: "#00A1E0",
                  }}
                  variant="contained"
                  size="small"
                  onClick={() => {
                    handleChange(new Date().toISOString().slice(0, 10));
                  }}
                >
                  Today
                </Button>
              )}
              {!taskView && !editTask && (
                <Button
                  sx={{
                    backgroundColor: "#00A1E0",
                  }}
                  variant="contained"
                  size="small"
                  type="button"
                  onClick={() => {
                    if (newDate) {
                      let LocalDate = new Date(newDate);
                      LocalDate =
                        LocalDate.getFullYear() +
                        "-" +
                        ("0" + (LocalDate.getMonth() + 1)).slice(-2) +
                        "-" +
                        ("0" + LocalDate.getDate()).slice(-2);
                      handleChange(LocalDate);
                    } else {
                      setEditMode(false);
                    }
                  }}
                >
                  Done
                </Button>
              )}
            </BoxButton>
          </LocalizationProvider>
        </CustomDatePicker>
      </PopOverBox>
    </>
  );
}
export default DatePickers;
