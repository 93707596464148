const asyncTask = (payload) => {
    return {
      type: "makeTaskAsync",
      payload: payload,
    };
  };

  const getAsyncTask = {
    asyncTask,
  };
  
  export default getAsyncTask;
  