import styled from "@emotion/styled";
import { LoadingButton } from "@mui/lab";
import { Button, Snackbar, TextareaAutosize, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { inviteSend, wishEmail } from "../../Pages/Services/tableApi";
import { ReactComponent as TwitterSVG } from "../../Assets/SVG/Twitter.svg";
import Link from "@mui/material/Link";

const MainInviteBox = styled(Box)({
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  fontFamily: "Inter",
  gap: "15px",
});

const InviteHeadingTypoGraphy = styled(Typography)({
  width: "100%",
  fontWeight: 600,
  //fontFamily: "Inter",
  fontSize: "15px",
  fontWeight: "600",
  fontFamily: "Inter",
  color: "#2d2727",
});

const TextAreaBox = styled(TextareaAutosize)({
  fontFamily: "Inter",
  width: "430px",
  minHeight: "36px",
  borderColor: "#E6E9EB",
  lineHeight: "2rem",
  paddingLeft: "8px",
  resize: "none",
  "&:focus": {
    outline: "none",
    borderColor: "#0065ff",
  },
});

const SendButton = styled(Button)({
  fontFamily: "Inter",
  color: "#fff",
  borderColor: "#0065ff",
  background: "#0065ff",
  letterSpacing: "1px",
  textTransform: "none",
  "&:hover": {
    outlineColor: "#0051cc",
    borderColor: "#0051cc",
    background: "#0051cc",
  },
});

const TwitterBox = styled(Box)({
  fontFamily: "Inter",
  color: "#000",
  borderColor: "#e6e9eb",
  textTransform: "none",
  "&:focus": {
    outline: "none",
    borderColor: "#0065ff",
  },
  "&:hover": {},
});

const CopyLinkBox = styled(Box)({
  fontFamily: "Inter",
  //background: "#0065ff",
  border: "1px solid #e6e9eb",
  width: "450px",
  height: "21px",
  "&:focus": {
    outline: "none",
    borderColor: "#0065ff",
    cursor: "pointer",
  },
  padding: "6px 12px",
  fontSize: "14px",
  lineHeight: "1.428571429",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderRadius: "8px",
});

function InviteView() {
  const [textAreaInput, setTextAreaInput] = useState("");
  const [invitedEmail, setInvitedEmail] = useState("");
  const [messageForwarding, setMessageForwarding] = useState(false);
  const [copyLink, setCopyLink] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [responseInvite, setResponseInvite] = useState("");

  const sendInviteEmail = async (email) => {
    const res = await inviteSend({ to_email_address: email.trim() });
    if (res.data.status === 200) {
      setSuccessMessage(true);
      setMessageForwarding(false);
      setInvitedEmail(email.trim());
      setTextAreaInput("");
      //setTextAreaInput("");
    } else {
      setResponseInvite(res.data.messages[0]);
    }
  };
  useEffect(() => {
    if (copyLink) {
      setTimeout(() => {
        setCopyLink(false);
      }, 3000);
    }
  }, [copyLink]);

  const sendInviteOnEnter = (e) => {
    if (e.key === "Enter") {
      textAreaInput.length && setMessageForwarding(true);
      textAreaInput.length && sendInviteEmail(textAreaInput);
      e.preventDefault();
    }
  };

  return (
    <MainInviteBox>
      <Box
        display={"flex"}
        flexDirection={"column"}
        bgcolor={"#f6f8f9"}
        padding={"20px"}
        gap={"5px"}
      >
        <InviteHeadingTypoGraphy
          color={"#1a1d1f"}
          //mb={"1rem"}
          fontSize={"15px"}
          style={{
            fontWeight: 500,
          }}
        >
          Invite friends to use TrackTable
        </InviteHeadingTypoGraphy>
        <InviteHeadingTypoGraphy
          color={"#848f99"}
          fontSize={"14px"}
          style={{
            color: "#97a9b2",
            fontWeight: 350,
            fontSize: "14px",
          }}
        >
          Help us grow by sharing TrackTable with your colleagues and friends in
          sales. Thanks!
        </InviteHeadingTypoGraphy>
      </Box>
      <Box
        padding={"20px"}
        flex={"1 1 auto"}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        <Box display={"flex"} gap="16px" justifyContent={"space-between"}>
          <TextAreaBox
            aria-label="empty textarea"
            value={textAreaInput}
            // maxRows={1}
            placeholder={!textAreaInput.length && "Enter email address..."}
            onChange={(e) => setTextAreaInput(e.target.value)}
            onKeyDown={(e) => sendInviteOnEnter(e)}
            style={{
              width: "492px",
              borderRadius: "8px",
            }}
          />
          <SendButton
            variant="outlined"
            size="small"
            onClick={() => {
              if (textAreaInput.length) {
                setMessageForwarding(true);
                sendInviteEmail(textAreaInput);
              }
            }}
            sx={{
              cursor: !messageForwarding ? "pointer" : "none",
              pointerEvents: !messageForwarding ? "pointer" : "none",
            }}
          >
            Send Invite
          </SendButton>
        </Box>
        <Box>
          <Typography sx={{color:"red"}}>
            {responseInvite.length ? responseInvite : ""}
          </Typography>
        </Box>
        {successMessage && (
          <p
            style={{
              color: "#848f99",
              marginTop: "4px",
              marginBottom: "0px",
              fontSize: "smaller",
              fontWeight: "400",
            }}
          >
            {" "}
            You have invited {invitedEmail.trim()} to TrackTable.{" "}
          </p>
        )}
        <hr
          style={{
            margin: "0.8rem 0rem",
            borderTop: "1px solid #eee",
          }}
        />
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "6px",
          }}
        >
          <InviteHeadingTypoGraphy
            fontSize={"14px"}
            mb={"4px"}
            style={{
              fontWeight: 500,
            }}
          >
            Share your invite link
          </InviteHeadingTypoGraphy>
          <Box display={"flex"} gap={"16px"} justifyContent={"space-between"}>
            <CopyLinkBox
              style={{
                height: "25px",
                width: "479px",
              }}
            >
              {/* <p>https://app.tracktable.io</p> */}
              <p
                style={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  width: "24rem",
                  height: "1.2em",
                  whiteSpace: "nowrap",
                  margin: "4px",
                }}
              >
                https://rebrand.ly/Tracktable-Invite
              </p>

              <Link
                underline="hover"
                style={{
                  fontWeight: 400,
                  color: "#0065ff",
                  borderRadius: 0,
                  cursor: "pointer",
                  "&:hover": {
                    cursor: "pointer",
                  },
                }}
                onClick={() => {
                  setCopyLink(true);
                  // navigator.clipboard.writeText("https://app.tracktable.io");
                  navigator.clipboard.writeText(
                    "https://rebrand.ly/Tracktable-Invite"
                  );
                }}
              >
                {!copyLink ? "Copy Link" : "Copied!"}
              </Link>
            </CopyLinkBox>
            {/* <TwitterBox
              onClick={() => window.open("https://app.tracktable.io", "_blank")}
            > */}
            <LoadingButton
              onClick={() =>
                window.open(
                  "https://twitter.com/intent/tweet?text=Streamline your Salesforce workflows with TrackTable! Manage all your sales tasks from your browser tab - update pipeline fields, take notes, and stay on top of your to-dos with ease. Sign up with my referral link and skip the waitlist: https://rebrand.ly/Tracktable-Invite",
                  "_blank"
                )
              }
              style={{
                fontSize: "12px",
                padding: "0px 8px",
              }}
              //color="secondary"
              //onClick={handleClick}
              //loading={loading}
              //loadingPosition="end"
              startIcon={<TwitterSVG />}
              variant="outlined"
            >
              <span>Twitter</span>
            </LoadingButton>
            {/* </TwitterBox> */}
          </Box>
        </Box>
      </Box>
    </MainInviteBox>
  );
}

export default InviteView;
