import {
  ClickAwayListener,
  Divider,
  Popover,
  Tooltip,
  Typography,
} from "@mui/material";
import { ReactComponent as EditIconSVG } from "../../Assets/SVG/EditIcon.svg";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Add, CloseRounded } from "@mui/icons-material";
import { ReactComponent as SalesForceLinkSVG } from "../../Assets/SVG/SalesForceLink.svg";
import { ReactComponent as GridSVG } from "../../Assets/SVG/Grid.svg";
import { ReactComponent as ArrowDownwardSVG } from "../../Assets/SVG/ArrowDownward.svg";
import { ReactComponent as TilesCreationSVG } from "../../Assets/SVG/TilesCreation.svg";
import { ReactComponent as SearchSettingSVG } from "../../Assets/SVG/SearchViewSetting.svg";
import { ReactComponent as SearchUserSVG } from "../../Assets/SVG/SearchViewUser.svg";
import { ReactComponent as ArrowForwardSVG } from "../../Assets/SVG/ArrowForward.svg";
import { ReactComponent as ExpandSVG } from "../../Assets/SVG/Expand.svg";
import { ReactComponent as OverView } from "../../Assets/SVG/OverView.svg";
import { ReactComponent as GridOverView } from "../../Assets/SVG/GridOverView.svg";
import { ReactComponent as ExpandViewTableFieldSVG } from "../../Assets/SVG/ExpandViewTableField.svg";
import FilterColumn from "../Table/FilterColumn";
import SimpleFilterDropDown from "../FilterOpportunity/SimpleFilterDropDown";
import styled from "@emotion/styled";
import {
  getKeyData,
  getIdRecord,
  lead_data,
  createTile,
  updateTile,
  deleteTile,
  getAllTiles,
  duplicateTile,
  relatedListData,
  relatedListTableHeaderApi,
  getRelatedRecord,
  getTileRecords,
  handleAddFieldInTable,
  deleteKey,
} from "../../Pages/Services/tableApi";
import { createOrUpdateKey } from "../../Pages/Services/tableApi";
import ViewCell from "../Table/ViewCell";
import LeadView from "./LeadView";
import Loader from "../Loader";
import ConvertLeadView from "./ConvertLeadView";
import { NameTextField } from "../SearchView/SearchDrawer";
import RenderComponents from "../RenderComponents";
import {
  AllTilesBox,
  ConvertLeadButton,
  ConvertLeadViewPopOver,
  CustomBox,
  ExpandViewSideMenu,
  LeadViewPopOver,
  LoaderBox,
  MainBox,
  MainBoxHeader,
  MainDataBox,
  OpportunityName,
  OpportunityType,
  SalesForceButton,
  SalesforceTypoGraphy,
  ScrollableBox,
  TilesBox,
  TypoGraphy,
  ViewCellBox,
} from "../../Assets/StyledComponents/ExpandViewStyledComponents";
import { useParams } from "react-router-dom";
import { handleRowData } from "../../Pages/Pipeline";
import { ReactComponent as LoaderSVG } from "../../Assets/SVG/Loader.svg";
import { gridDefaultFields } from "../../Pages/Pipeline";
import { toast } from "react-toastify";

export const renderCell = (cellData, data) => {
  return (
    <ViewCellBox
    // id={cellData.hasFocus ? "focused-none-editable-cell" : ""}
    >
      <ViewCell
        value={
          cellData.fieldType === "reference" ||
          (data.metric_datatype === "reference" &&
            cellData.row[cellData.field] !== null)
            ? cellData?.row[data.parentSobject]?.Name || cellData.value
            : cellData.value
        }
        cellData={cellData}
        dataType={data.metric_datatype}
        // expandViewCell={true}
      />
    </ViewCellBox>
  );
};

function OpportunityView({
  expandView,
  header,
  activeHeader,
  tableName,
  rowId,
  onUpdateSuccess,
  addOrRemoveFieldInColumn,
  relatedFields,
  leadView,
  expandViewFirst,
  taskView,
  setShowNotesExtended,
  titleRowData,
  navTileData,
  notesExpandedView,
  gridSubFields,
  expandGridRecord,
  setExpandGridRecord,
}) {
  const [rowData, setRowData] = useState({});

  const [previousId, setPreviousId] = useState(rowId);

  const [tilesData, setTilesData] = useState(null);
  const [subFields, setSubFields] = useState({});
  const [expandHeader, setExpandHeader] = useState(header);
  const [activeColumnHeader, setActiveColumnHeader] = useState(activeHeader);
  const [manageHeader, setManageHeader] = useState("Overview");
  const [convertLeadData, setConvertLeadData] = useState(null);
  const [convertLead, setConvertLead] = useState(false);
  const [successLeadData, setSuccessLeadData] = useState(null);
  const [handleTextBox, setHandleTextBox] = useState(false);
  const [onHoverHeader, setOnHoverHeader] = useState(null);
  const [relatedTableLoader, setRelatedTableLoader] = useState(false);
  const [allTiles, setAllTiles] = useState(
    leadView || taskView
      ? [
          {
            labelText: "All",
            svg: <ArrowDownwardSVG />,
            selected: true,
          },
        ]
      : expandViewFirst && tableName === "Lead"
      ? [
          {
            labelText: "All",
            svg: <ArrowDownwardSVG />,
            selected: false,
          },
          // {
          //   labelText: "Grid",
          //   svg: <></>,
          //   selected: true,
          // },
        ]
      : []
  );
  // const [simpleDropDown, setSimpleDropDown] = useState({
  //   top: 0,
  //   left: 0,
  // });
  const [allTilesDropDown, setAllTilesDropDown] = useState(false);
  const [newOrRenameTile, setNewOrRenameTile] = useState(false); // for handling TextBox on rename,duplicate and tile creation
  const [newTile, setNewTile] = useState(null); // for handling -> render components or new tile creation ui
  const [tileNameChange, setTileNameChange] = useState("New tile");
  const fields = [
    "Fields & Related List",
    "divider",
    "Rename",
    "Duplicate",
    "Delete",
  ];
  const [fieldDropDown, setFieldDropDown] = useState(false);
  const [fieldAddDropDown, setFieldAddDropDown] = useState(false);
  const [allDropDownHeader, setAllDropDownHeader] = useState(null);
  const [tileChangeLoader, setTileChangeLoader] = useState(false);
  const [tileLoader, setTileLoader] = useState(false);
  const [expandViewTableFieldHeader, setExpandViewTableFieldHeader] =
    useState(false);
  const [currentTileHeader, setCurrentTileHeaders] = useState(null);
  const [convertLeadStatus, setConvertLeadStatus] = useState(null);
  const [tooltip, setToolTip] = useState(0);
  const [currentActiveTileHeader, setCurrentActiveTileHeader] = useState(null);

  useEffect(() => {
    !taskView && getRowData(rowId);
    if (previousId !== rowId) {
      setPreviousId(rowId);
    }
    // async function call() {
    //   const dataParams = JSON.stringify({
    //     key: `Expand${manageHeader}${tableName}Headers`,
    //     value: header,
    //   });

    //   await createOrUpdateKey(dataParams);
    // }
    // call();
  }, [rowId]);

  useEffect(() => {
    if (localStorage.getItem("manageHeader") === "Grid") {
      setSubFields(gridSubFields);
    }
  }, [gridSubFields]);

  useEffect(
    () => {
      // !titleRowData?.length && setRowData({});
      (taskView || leadView) && getRowData(rowId);
    },
    taskView || leadView ? [rowId] : []
  );

  useEffect(() => {
    const getNewRecord = async () => {
      const response = await getKeyData(
        JSON.stringify({
          key: `ExpandGrid${tableName}Headers`,
        })
      );
      const dataParams = {
        table_name: tableName,
        record_id: rowId,
        fields: response.data.value,
      };

      const JSONdataParams = JSON.stringify(dataParams);
      const res = await getIdRecord(JSONdataParams);
      if (res.data.status === 200) {
        setRowData(res.data.rows[0]);
        localStorage.setItem(`tableData`, JSON.stringify(res.data.rows[0]));
      }
      setExpandGridRecord(false);
    };

    if (expandGridRecord) getNewRecord();
  }, [expandGridRecord]);

  const onUpdateSuccessOfRow = async () => {
    if (!taskView) {
      onUpdateSuccess();
      getRowData(rowId);
    }
  };

  const handleSetSubFields = (tileData, tileRecords) => {
    const tile = localStorage.getItem("manageHeader");

    const currentTileData = tileData?.filter(
      (data) => Object.keys(data)[0] === tile
    );

    const headers = currentTileData?.[0]?.[tile]?.tile_fields;

    const getSubFieldsFromResponse = headers?.filter(
      ({ parent_table_fields }) => {
        if (parent_table_fields) {
          return parent_table_fields.filter((data) => data.selected);
        }
      }
    );

    let _subFields = { ...subFields };
    // const getSelectedSubFieldData = JSON.parse(
    //   localStorage.getItem("SubFieldData")
    // );
    getSubFieldsFromResponse?.map(({ metric_name, parent_table_fields }) => {
      // if (
      //   getSelectedSubFieldData &&
      //   metric_name === getSelectedSubFieldData.metric_name
      // ) {
      //   localStorage.setItem(
      //     `${getSelectedSubFieldData?.parentSobject}SubFields`,
      //     JSON.stringify(parent_table_fields)
      //   );
      // }
      return (_subFields = {
        ..._subFields,
        [metric_name]: parent_table_fields,
      });
    });
    setRowData(tileRecords);
    setSubFields(_subFields);
  };

  const getRowData = async (rowId, activeOverviewHeader, tempAllTiles) => {
    if (titleRowData) {
      setRowData(titleRowData);
    }

    activeOverviewHeader && setTileChangeLoader(true);
    if (activeOverviewHeader) {
      setRowData(
        localStorage.getItem(`tableData`)
          ? JSON.parse(localStorage.getItem(`tableData`))
          : []
      );
      setSubFields(subFields);
      activeOverviewHeader && setTileChangeLoader(false);
    } else {
      (taskView || leadView) && setTileChangeLoader(true);
      let dataParams = {
        table_name: tableName,
        id: rowId,
      };

      let tilesResponse;
      if (!titleRowData || taskView || leadView) {
        if (previousId !== rowId) {
          setRowData();
        }

        const JSONdataParams = JSON.stringify(dataParams);
        tilesResponse = await getAllTiles(JSONdataParams);
        if (tilesResponse?.data?.status === 200) {
          // localStorage.setItem(
          //   `${tableName}TilesApiData`,
          //   JSON.stringify(tilesResponse.data.tile_data)
          // );
          setTilesData(tilesResponse?.data?.tile_data);

          localStorage.setItem(
            `tableData`,
            JSON.stringify(tilesResponse?.data?.tile_records)
          );

          let _manageHeader = localStorage.getItem("manageHeader");
          const getRelatedList = tilesResponse?.data?.tile_data?.filter(
            (data) => data.hasOwnProperty(_manageHeader)
          );

          // localStorage.setItem(
          //   `Expand${manageHeader}${tableName}Headers`,
          //   JSON.stringify(getRelatedList?.[0]?.[_manageHeader]?.tile_fields)
          // );

          let _subFields = {};
          getRelatedList?.[0]?.[_manageHeader]?.tile_fields.forEach(
            ({ parent_table_fields, metric_name }) => {
              if (parent_table_fields) {
                _subFields = {
                  ..._subFields,
                  [metric_name]: parent_table_fields,
                };
              }
            }
          );

          let subFieldsColumns = [];
          if (Object.keys(_subFields)?.length) {
            subFieldsColumns = Object.keys(_subFields)
              .map((field) =>
                _subFields[field]?.filter(
                  ({ isVisible, selected }) => isVisible || selected
                )
              )
              .flat();
          }

          // checking for key exist of user or not
          const getKeyDataOfTile = await getKeyData(
            JSON.stringify({
              key: `Expand${manageHeader}${tableName}Headers`,
            })
          );

          let currentTileHeader = [];
          if (getKeyDataOfTile.data.status === 404) {
            currentTileHeader = getRelatedList?.[0]?.[
              _manageHeader
            ]?.tile_fields.sort((a, b) => {
              if (a.selected === b.selected) return 0;
              if (a.selected > b.selected) return -1;
              return 1;
            });

            let sortedFields = gridDefaultFields[tableName]
              .map((name) =>
                currentTileHeader?.find(
                  (data) =>
                    (data?.call_parent
                      ? `${data?.call_parent}.${data?.metric_name}`
                      : data?.metric_name) === name
                )
              )
              .filter((d) => d);

            if (sortedFields?.length) {
              currentTileHeader.splice(0, sortedFields.length, sortedFields);
              currentTileHeader = currentTileHeader.flat();
            }

            currentTileHeader = [...currentTileHeader, ...subFieldsColumns];
            currentTileHeader = currentTileHeader.filter((d) =>
              d.call_parent ? d.selected : d
            );
          } else {
            currentTileHeader = subFieldsColumns?.length
              ? [
                  ...getRelatedList?.[0]?.[_manageHeader]?.tile_fields,
                  ...subFieldsColumns,
                ]
              : getRelatedList?.[0]?.[_manageHeader]?.tile_fields;

            currentTileHeader?.sort((a, b) => {
              if (a.selected === b.selected) return 0;
              if (a.selected > b.selected) return -1;
              return 1;
            });

            currentTileHeader = currentTileHeader?.map((data) => {
              const flag = getKeyDataOfTile?.data?.value?.includes(
                data?.call_parent
                  ? `${data?.call_parent}.${data?.metric_name}`
                  : data?.metric_name
              );
              return {
                ...data,
                isVisible: flag || data.selected,
                selected: flag || data.selected,
              };
            });

            // sub field manage here -> remains
            let sortedFields = getKeyDataOfTile?.data?.value?.length
              ? getKeyDataOfTile.data.value.map((name) =>
                  currentTileHeader?.find(
                    (data) =>
                      (data?.call_parent
                        ? `${data?.call_parent}.${data?.metric_name}`
                        : data?.metric_name) === name
                  )
                )
              : [];

            if (sortedFields?.length) {
              currentTileHeader?.splice(0, sortedFields.length, sortedFields);
              currentTileHeader = currentTileHeader
                ?.flat()
                .filter((data) => data);
            }
          }
          let activeCurrentTileHeader = currentTileHeader?.length
            ? currentTileHeader?.filter(
                ({ selected, isVisible }) => selected ?? isVisible
              )
            : [];

          activeCurrentTileHeader = currentTileHeader?.filter(
            ({ selected, isVisible }) => selected ?? isVisible
          );
          activeCurrentTileHeader = [
            ...new Set(
              activeCurrentTileHeader
                ?.filter((d) => d)
                .map((o) => JSON.stringify(o))
            ),
          ].map((s) => JSON.parse(s));

          // here, i can also able to set the sorting of header before setting current tile header
          // if (getKeyDataOfTile.data.status === 404) {
          const dataParams = JSON.stringify({
            key: `Expand${manageHeader}${tableName}Headers`,
            value: activeCurrentTileHeader?.map(
              ({ call_parent, metric_name }) =>
                `${
                  call_parent ? `${call_parent}.${metric_name}` : metric_name
                }`.trim()
            ),
          });

          getRelatedList?.length && (await createOrUpdateKey(dataParams));
          // }
          // localStorage.setItem(
          //   `ExpandActive${manageHeader}${tableName}Headers`,
          //   JSON.stringify(
          //     getRelatedList?.[0]?.[_manageHeader]?.tile_fields.filter(
          //       (data) => data.isVisible || data.selected
          //     )
          //   )
          // );
          setCurrentTileHeaders(currentTileHeader);
          setCurrentActiveTileHeader(activeCurrentTileHeader);
          (taskView || leadView) && setTileChangeLoader(false);

          getRelatedList?.length &&
            localStorage.setItem(
              `relatedTablesHeaders`,
              JSON.stringify(
                getRelatedList?.[0]?.[_manageHeader]?.tile_related_table_fields
              )
            );

          getRelatedList?.length &&
            localStorage.setItem(
              `Expand${tableName}RelatedList`,
              JSON.stringify(
                getRelatedList?.[0]?.[_manageHeader]?.tile_related_table
              )
            );

          localStorage.setItem(
            `ExpandActive${tableName}RelatedList`,
            JSON.stringify(
              getRelatedList?.[0]?.[_manageHeader]?.tile_related_table.filter(
                (data) => data.selected
              )
            )
          );

          activeOverviewHeader && setTileChangeLoader(false);
          handleSetSubFields(
            tilesResponse.data.tile_data,
            tilesResponse.data.tile_records
          );

          localStorage.setItem(
            `ExpandActive${tableName}RelatedList`,
            JSON.stringify(
              getRelatedList?.[0]?.[_manageHeader]?.tile_related_table.filter(
                (data) => data.selected
              )
            )
          );
        }
      }
      let tempTiles = activeOverviewHeader ? [...tempAllTiles] : [...allTiles];
      let eachTile = (
        titleRowData ? navTileData : tilesResponse?.data?.tile_data
      )
        ?.map((data) => Object.keys(data))
        .flat();

      // if expand isn't working then remove this
      if (!eachTile?.length) {
        eachTile = navTileData ?? tilesResponse?.data?.tile_data;
      }

      let userTiles = eachTile
        ?.map((tile) => {
          return (
            titleRowData ? navTileData : tilesResponse?.data?.tile_data
          ).map((data) => {
            if (data[tile]) {
              return {
                labelText: tile,
                selected: data[tile].selected,
                header: data[tile].tile_fields,
              };
            }
          });
        })
        .flat()
        .filter((data) => data);

      // userTiles.map((tile) =>
      //   tile.labelText === "Overview"
      //     ? tempTiles.unshift(tile)
      //     : tempTiles.splice(tempTiles.length - 1, 0, tile)
      // );

      userTiles?.map((tile) => {
        if (
          !tempTiles.filter((data) => eachTile.includes(data?.labelText))
            ?.length
        ) {
          tile?.labelText === "Overview"
            ? tempTiles.unshift(tile)
            : tempTiles.splice(tempTiles.length - 1, 0, tile);
        } else return tile;
      });

      tempTiles?.splice(leadView || taskView ? 1 : 2, 0, userTiles);
      const sortBySubstring = (tileData, match) => {
        return tileData?.sort((a, b) => {
          return b?.labelText.indexOf(match) - a?.labelText.indexOf(match);
        });
      };
      tempTiles = sortBySubstring(tempTiles.flat(), "Overview");

      let uniqueTiles = [
        ...new Map(
          tempTiles?.map((tile) => [tile?.["labelText"], tile])
        )?.values(),
      ];

      uniqueTiles = [...uniqueTiles].sort((a, b) => {
        if (a?.labelText === "Overview") {
          return -1; // "overview" comes first
        } else if (b?.labelText === "Overview") {
          return 1;
        } else {
          return 0; // no change in order
        }
      });

      userTiles = [...userTiles]?.sort((a, b) => {
        if (a?.labelText === "Overview") {
          return -1; // "overview" comes first
        } else if (b?.labelText === "Overview") {
          return 1;
        } else {
          return 0; // no change in order
        }
      });

      const tempAllDropDownHeader =
        taskView || leadView || notesExpandedView
          ? [...userTiles]
          : [...uniqueTiles];

      const dropDownHeader =
        taskView || leadView
          ? tempAllDropDownHeader
              ?.map((data) => {
                if (data?.labelText !== "All")
                  return {
                    metric_datatype: "string",
                    metric_display_name: data?.labelText,
                    metric_name: data?.labelText,
                    selected: data.selected,
                  };
              })
              .filter((d) => d != undefined)
          : tempAllDropDownHeader?.map((data) => {
              if (data?.labelText !== "All")
                return {
                  metric_datatype: "string",
                  metric_display_name: data?.labelText,
                  metric_name: data?.labelText,
                  selected: data.selected,
                };
            });

      // dropDownHeader?.splice(dropDownHeader.length - 1, 1, {
      //   metric_datatype: "string",
      //   metric_display_name: "New tile",
      //   metric_name: "New tile",
      // });

      dropDownHeader?.splice(dropDownHeader.length, 0, {
        metric_datatype: "string",
        metric_display_name: "New tile",
        metric_name: "New tile",
      });
      setAllDropDownHeader(dropDownHeader);
      // debugger;
      setAllTiles(
        taskView || leadView || notesExpandedView ? userTiles : uniqueTiles
      );
    }
  };

  const filterColumns = async (
    Headers,
    data,
    dragHandleIndividualTableHeader,
    currentActiveRelatedTable,
    subField,
    bothHeaders
  ) => {
    if (subField) {
      const selectedSubFieldData = JSON.parse(
        localStorage.getItem("SubFieldData")
      );

      let _subFields = { ...subFields };
      _subFields = {
        ...subFields,
        [selectedSubFieldData.metric_name]: Headers,
      };

      setSubFields(_subFields);
    }
    if (data && data !== "RelatedList") {
      // localStorage.setItem(
      //   `Expand${localStorage.getItem("manageHeader")}${tableName}Headers`,
      //   JSON.stringify(Headers)
      // );
      // await createOrUpdateKey(
      //   JSON.stringify({
      //     key: `Expand${localStorage.getItem(
      //       "manageHeader"
      //     )}${tableName}Headers`,
      //     value: Headers,
      //   })
      // );
      setCurrentTileHeaders(Headers);
      // localStorage.setItem(
      //   `ExpandActive${localStorage.getItem(
      //     "manageHeader"
      //   )}${tableName}Headers`,
      //   JSON.stringify(Headers.filter((data) => data.isVisible))
      // );

      const dataParams = {
        table_name: tableName,
        record_id: rowId,
        fields: Headers.filter((data) => data.isVisible).map((data) => {
          return `${data.call_parent ? `${data.call_parent}.` : ""}${
            data.metric_name
          }`;
        }),
      };

      const JSONdataParams = JSON.stringify(dataParams);
      const res = await getIdRecord(JSONdataParams);
      if (res.data.status === 200) {
        setRowData(res.data.rows[0]);
        localStorage.setItem(`tableData`, JSON.stringify(res.data.rows[0]));
      }
    }

    if (dragHandleIndividualTableHeader) {
      const newTableData = (
        localStorage.getItem(`${tableName}TilesApiData`) &&
        localStorage.getItem(`${tableName}TilesApiData`) !== "null" &&
        localStorage.getItem(`${tableName}TilesApiData`) !== "undefined"
          ? JSON.parse(localStorage.getItem(`${tableName}TilesApiData`))
          : tilesData
      ).map((relatedTableData) => {
        if (relatedTableData.hasOwnProperty(manageHeader)) {
          relatedTableData[manageHeader].tile_related_table_fields = {
            ...relatedTableData[manageHeader].tile_related_table_fields,
            [currentActiveRelatedTable]: Headers,
          };
          localStorage.setItem(
            `relatedTablesHeaders`,
            JSON.stringify(
              relatedTableData[manageHeader].tile_related_table_fields
            )
          );
          return relatedTableData;
        } else {
          return relatedTableData;
        }
      });
      // localStorage.setItem(
      //   `${tableName}TilesApiData`,
      //   JSON.stringify(newTableData)
      // );

      let tableData = JSON.parse(localStorage.getItem(`tableData`));
      setRowData(tableData);
      setTilesData(newTableData);
    }

    setExpandHeader(Headers);

    setCurrentTileHeaders(
      data !== "RelatedList"
        ? !bothHeaders
          ? Headers
          : bothHeaders
        : currentTileHeader
    );
    setActiveColumnHeader(
      data !== "RelatedList"
        ? bothHeaders
          ? bothHeaders.filter((data) => data.isVisible || data.selected)
          : Headers.filter((data) => data.isVisible || data.selected)
        : activeColumnHeader
    );
    setCurrentActiveTileHeader(
      data !== "RelatedList"
        ? bothHeaders
          ? bothHeaders.filter((data) => data.isVisible || data.selected)
          : Headers.filter((data) => data.isVisible || data.selected)
        : currentActiveTileHeader
    );
  };

  const handleAddOrRemoveExpandColumnField = async (
    fields,
    headerFlag,
    tableName,
    relatedTableHeader,
    data,
    searchViewFieldOrRelated,
    parentFieldName,
    child_field
  ) => {
    const manageTile = localStorage.getItem("manageHeader");

    if (relatedTableHeader) {
      const flag = headerFlag === "True" ? true : false;

      const dataParams = {
        id: rowId,
        table_name: tableName,
        tile_name: manageTile,
        updatevalue: flag,
        operation: "tile_Related_table_field_update",
        objectapiname: data.sobject,
        metric_name: data.metric_name,
      };

      const JSONdataParams = JSON.stringify(dataParams);
      const res = await updateTile(JSONdataParams);
      if (res.data.status === 200) {
        const getPreviousTilesData = tilesData
          ? tilesData
          : JSON.parse(localStorage.getItem(`${tableName}TilesApiData`));

        const newTilesData = getPreviousTilesData?.map((tileData) =>
          tileData.hasOwnProperty(manageTile) ? res.data.tile_data[0] : tileData
        );
        // localStorage.setItem(
        //   `${tableName}TilesApiData`,
        //   JSON.stringify(newTilesData)
        // );
        localStorage.setItem(`tableData`, JSON.stringify(res.data.tile_record));
        localStorage.setItem(
          `relatedTablesHeaders`,
          JSON.stringify(
            res.data.tile_data[0][manageTile].tile_related_table_fields
          )
        );
        setTilesData(newTilesData);
        setRowData(res.data.tile_record);
        handleSetSubFields(
          res.data.tile_data,
          res.data.tile_record ?? res.data.tile_records
        );
      }
    } else if (manageTile === "Grid") {
      // const response = await getKeyData(
      //   JSON.stringify({
      //     key: `Expand${localStorage.getItem(
      //       "manageHeader"
      //     )}${tableName}Headers`,
      //   })
      // );
      // const dataParams = {
      //   table_name: tableName,
      //   record_id: rowId,
      //   fields: [
      //     ...response.data.value,
      //     child_field ? `${child_field}.${fields}` : fields,
      //   ],
      // };

      // const JSONdataParams = JSON.stringify(dataParams);
      // const res = await getIdRecord(JSONdataParams);
      // if (res.data.status === 200) {
      //   setRowData(res.data.rows[0]);
      //   localStorage.setItem(`tableData`, JSON.stringify(res.data.rows[0]));

      {
        manageHeader === "Grid" &&
          addOrRemoveFieldInColumn(fields, headerFlag, parentFieldName);
      }
      // }
    } else if (searchViewFieldOrRelated) {
      const flag = headerFlag === "True" ? true : false;
      const dataParams = {
        id: rowId,
        table_name: tableName,
        tile_name: manageTile,
        updatevalue: flag,
        operation:
          parentFieldName && child_field
            ? "tile_parent_field_update"
            : "tile_field_update",
        metric_name: fields,
        child_field: child_field ?? undefined,
        parent_sobject: parentFieldName ?? undefined,
      };

      const JSONdataParams = JSON.stringify(dataParams);
      const res = await updateTile(JSONdataParams);
      if (res.data.status === 200) {
        setRowData(res.data.tile_record);
        // setRelatedTableLoader(false);
        localStorage.setItem(`tableData`, JSON.stringify(res.data.tile_record));
        const getPreviousTilesData = tilesData
          ? tilesData
          : JSON.parse(localStorage.getItem(`${tableName}TilesApiData`));

        const newTilesData = getPreviousTilesData?.map((tileData) =>
          tileData.hasOwnProperty(manageTile) ? res.data.tile_data[0] : tileData
        );
        // localStorage.setItem(
        //   `${tableName}TilesApiData`,
        //   JSON.stringify(newTilesData)
        // );
        handleSetSubFields(
          res.data.tile_data,
          res.data.tile_record ?? res.data.tile_records
        );
        setTilesData(newTilesData);
      }
    } else if (headerFlag === "handleRelated") {
      const tileRecords = JSON.parse(localStorage.getItem(`tableData`));
      setRowData(tileRecords);
      setRelatedTableLoader(false);
    } else {
      // const setKeyValue = JSON.parse(
      //   localStorage.getItem(`Expand${manageTile}${tableName}Headers`)
      // );
      const setKeyValue = await getKeyData(
        JSON.stringify({
          key: `Expand${manageTile}${tableName}Headers`,
        })
      );
      const dataParams = JSON.stringify({
        key: `Expand${manageTile}${tableName}Headers`,
        value: setKeyValue?.data?.value,
      });
      const res = await createOrUpdateKey(dataParams);
      if (res.data.status === 200) {
        // localStorage.setItem(
        //   `Expand${manageTile}${tableName}Headers`,
        //   JSON.stringify(setKeyValue)
        // );
        // const dataParams = JSON.stringify({
        //   key: `Expand${manageTile}${tableName}Headers`,
        //   value: setKeyValue?.data?.value,
        // });
        // await createOrUpdateKey(dataParams);
        const JSONdataParams = JSON.stringify({
          key: `Expand${localStorage.getItem(
            "manageHeader"
          )}${tableName}Headers`,
        });
        const response = await getKeyData(JSONdataParams);
        if (response.data.status === 200) {
          const dataParams = {
            [manageTile === "Overview" ? "sobject" : "table_name"]: tableName,
            [manageTile === "Overview" ? "id" : "record_id"]: rowId,
            fields: response.data.value
              .filter((data) => data.isVisible || data.selected)
              .map(
                (data) =>
                  `${data.call_parent ? `${data.call_parent}.` : ""}${
                    data.metric_name
                  }`
              ),
          };

          if (manageTile === "Overview") {
            dataParams = {
              ...dataParams,
              related_sobjects:
                manageTile === "Overview"
                  ? localStorage.getItem(`Expand${tableName}RelatedList`)
                    ? JSON.parse(
                        localStorage.getItem(`Expand${tableName}RelatedList`)
                      )
                        ?.filter(
                          ({ isVisible, selected }) => isVisible || selected
                        )
                        .map((data) => {
                          return {
                            relatedListId: data.relatedListId,
                            fieldApiName: data.fieldApiName,
                            objectApiName: data.objectApiName,
                          };
                        })
                    : []
                  : undefined,
            };
          }

          const JSONdataParams = JSON.stringify(dataParams);
          const res =
            manageTile === "Overview"
              ? await getRelatedRecord(JSONdataParams)
              : await getIdRecord(JSONdataParams);
          if (res.data.status === 200) {
            setRowData(res.data?.rows ? res.data.rows[0] : res.data.data);
            localStorage.setItem(
              `tableData`,
              JSON.stringify(res.data?.rows ? res.data.rows[0] : res.data.data)
            );
            {
              manageHeader === "Grid" &&
                addOrRemoveFieldInColumn(fields, headerFlag, parentFieldName);
            }
          }
          // localStorage.setItem(
          //   `Expand${manageTile}${tableName}Headers`,
          //   JSON.stringify(response.data.value)
          // );
          await createOrUpdateKey(
            JSON.stringify({
              key: `Expand${manageTile}${tableName}Headers`,
              value: response.data.value,
            })
          );
          setCurrentTileHeaders(response.data.value);
        }
      }
    }
  };

  //--- Lead view Popover ---//
  const [leadAnchorEl, setLeadAnchorEl] = React.useState(null);

  const handleLeadView = (event) => {
    setLeadAnchorEl(document.getElementById("root"));
  };
  const handleLeadViewClose = () => {
    setLeadAnchorEl(null);
    setManageHeader("Overview");
    localStorage.setItem("manageHeader", "Overview");
  };

  const leadViewOpen = Boolean(leadAnchorEl);
  const id = leadViewOpen ? "simple-popover" : undefined;

  const handleLeadViewPopOver = async () => {
    const dataParams = {
      table_name: "Lead",
      record_id: rowId,
      fields: ["Id", "Company", "OwnerId", "Owner.UserName"],
    };
    const JSONdataParams = JSON.stringify(dataParams);
    const res = await lead_data(JSONdataParams);
    if (res.data.status === 200) {
      const LeadStatus = await getKeyData(
        JSON.stringify({
          key: `convertedstatus`,
        })
      );
      setConvertLeadData(res.data.rows[0]);
      setConvertLeadStatus(LeadStatus.data.value);
    }
  };
  // --- //

  //---  converted Lead view ---//
  const [convertLeadViewAnchorEl, setConvertLeadViewAnchorEl] = useState(null);
  const handleConvertLeadViewClick = () => {
    setConvertLeadViewAnchorEl(document.getElementById("root"));
  };
  const handleConvertLeadViewClose = () => {
    setConvertLeadViewAnchorEl(null);
  };
  const convertLeadViewOpen = Boolean(convertLeadViewAnchorEl);
  const convertLeadViewId = convertLeadViewOpen ? "simple-popover" : undefined;
  //--- ---//

  const handleChange = (value) => {
    setAllTilesDropDown(false);
    if (value !== "All") {
      setNewTile(value);
      setManageHeader(value);
      setNewOrRenameTile(true);
      setHandleTextBox(false);
      localStorage.setItem("manageHeader", value);
      localStorage.setItem("ApiCall", "Create");
    }

    const tempTiles = [...allTiles];
    tempTiles.splice(tempTiles.length - 1, 0, {
      labelText: value,
      selected: true,
      header: [],
    });

    tempTiles.sort((a, b) => {
      if (a?.labelText === "Overview") {
        return -1; // "overview" comes first
      } else if (b?.labelText === "Overview") {
        return 1;
      } else {
        return 0; // no change in order
      }
    });
    setAllTiles(tempTiles);

    if (value === "New tile") {
      setTileNameChange(tileNameChange.trim());
      setManageHeader(tileNameChange.trim());
      setHandleTextBox(true);
      handleApiCall(tileNameChange.trim());
    }
  };

  const handleTilesChangeAsync = async (value) => {
    let currentTileHeader;
    let currentActiveTileHeader;
    const tile = localStorage.getItem("manageHeader");
    if (value === "Grid") {
      // let gridResponse = await getKeyData(
      //   JSON.stringify({
      //     key: `ExpandGrid${tableName}Headers`,
      //   })
      // );

      // const activeSubFields = Object.keys(gridSubFields)?.length
      //   ? Object.keys(gridSubFields)
      //       .map((field) =>
      //         gridSubFields[field]?.filter(
      //           ({ isVisible, selected }) => isVisible || selected
      //         )
      //       )
      //       .flat()
      //   : [];

      currentTileHeader = [...header];

      // currentTileHeader = currentTileHeader.map((data) => {
      //   const flag = gridResponse?.data?.value?.includes(
      //     data?.call_parent
      //       ? `${data?.call_parent}.${data?.metric_name}`
      //       : data?.metric_name
      //   );
      //   return {
      //     ...data,
      //     isVisible: flag ?? data.selected,
      //     selected: flag ?? data.selected,
      //   };
      // });

      // let response = await getKeyData(
      //   JSON.stringify({
      //     key: `Expand${tableName}Headers`,
      //   })
      // );

      // let sortedFields = gridResponse?.data?.value?.length
      //   ? gridResponse?.data?.value.map((name) =>
      //       currentTileHeader.find(
      //         (data) =>
      //           (data?.call_parent
      //             ? `${data?.call_parent}.${data?.metric_name}`
      //             : data?.metric_name) === name
      //       )
      //     )
      //   : [];

      // if (sortedFields?.length) {
      //   currentTileHeader.sort((a, b) => {
      //     if (a.selected === b.selected) return 0;
      //     if (a.selected > b.selected) return -1;
      //     return 1;
      //   });

      //   currentTileHeader.splice(0, sortedFields.length, sortedFields);
      //   currentTileHeader = currentTileHeader.flat();

      currentActiveTileHeader = currentTileHeader?.filter(
        ({ selected, isVisible }) => selected ?? isVisible
      );
      // }
      // currentTileHeader = gridResponse?.data?.value;
      // const activeSubFields = Object.keys(gridSubFields)?.length
      //   ? Object.keys(gridSubFields)
      //       .map((field) =>
      //         gridSubFields[field]?.filter(
      //           ({ isVisible, selected }) => isVisible || selected
      //         )
      //       )
      //       .flat()
      //   : [];
      // setCurrentTileHeaders([...currentTileHeader, ...activeSubFields]);
      setCurrentTileHeaders(currentTileHeader);
      setCurrentActiveTileHeader(currentActiveTileHeader);

      setSubFields(gridSubFields);

      // currentActiveTileHeader = [
      //   ...currentTileHeader,
      //   ...activeSubFields,
      // ].filter(({ isVisible, selected }) => isVisible || selected);
    } else if (value !== "Grid") {
      // const checkTileHeaderExist = localStorage.getItem(
      //   `Expand${tile}${tableName}Headers`
      // );

      const checkTileHeaderExist = await getKeyData(
        JSON.stringify({
          key: `Expand${tile}${tableName}Headers`,
        })
      );

      const getAllTilesData =
        tilesData ??
        JSON.parse(localStorage.getItem(`${tableName}TilesApiData`));

      const getCurrentTileData = getAllTilesData.filter((tileData) => {
        if (tileData.hasOwnProperty(value)) {
          return tileData;
        }
      });
      let _subFields = {};
      currentTileHeader = getCurrentTileData[0][value].tile_fields;
      const getSubFields = currentTileHeader.filter((data) => {
        if (data.parent_table_fields) {
          return data.parent_table_fields.filter((data) => data.selected);
        }
      });

      getSubFields.map(
        ({ metric_name, parent_table_fields }) =>
          (_subFields = { ..._subFields, [metric_name]: parent_table_fields })
      );

      let columns;
      if (Object.keys(_subFields)?.length) {
        columns = Object.keys(_subFields)

          .map((field) =>
            _subFields[field]?.filter(
              ({ isVisible, selected }) => isVisible || selected
            )
          )
          .flat();

        currentTileHeader = [...currentTileHeader, ...columns];
      }
      if (checkTileHeaderExist?.data?.value?.length) {
        currentTileHeader = currentTileHeader.map((data) => {
          const flag = checkTileHeaderExist?.data?.value?.includes(
            data?.call_parent
              ? `${data?.call_parent}.${data?.metric_name}`
              : data?.metric_name
          );
          return {
            ...data,
            isVisible: flag || data.selected,
            selected: flag || data.selected,
          };
        });

        currentTileHeader = currentTileHeader.filter((d) =>
          d.call_parent ? d.selected : d
        );

        // let response = await getKeyData(
        //   JSON.stringify({
        //     key: `Expand${tableName}Headers`,
        //   })
        // );

        let sortedFields = checkTileHeaderExist?.data?.value?.length
          ? checkTileHeaderExist?.data?.value.map((name) =>
              currentTileHeader.find(
                (data) =>
                  (data?.call_parent
                    ? `${data?.call_parent}.${data?.metric_name}`
                    : data?.metric_name) === name
              )
            )
          : [];

        if (sortedFields?.length) {
          currentTileHeader.sort((a, b) => {
            if (a.selected === b.selected) return 0;
            if (a.selected > b.selected) return -1;
            return 1;
          });

          currentTileHeader.splice(0, sortedFields.length, sortedFields);
          currentTileHeader = currentTileHeader.flat();

          currentActiveTileHeader = currentTileHeader
            ?.filter((data) => data !== undefined)
            .filter(({ selected, isVisible }) => selected ?? isVisible);
        }

        setCurrentTileHeaders(currentTileHeader);
        setCurrentActiveTileHeader(currentActiveTileHeader);

        // handleSetSubFields(getAllTilesData, tilesResponse.data.tile_records);
      } else {
        currentTileHeader.sort((a, b) => {
          if (a.selected === b.selected) return 0;
          if (a.selected > b.selected) return -1;
          return 1;
        });
        setCurrentTileHeaders(currentTileHeader);
        currentActiveTileHeader = currentTileHeader.filter(
          (data) => data.selected
        );
      }

      localStorage.setItem(
        `Expand${tableName}RelatedList`,
        JSON.stringify(getCurrentTileData[0][value].tile_related_table)
      );
      localStorage.setItem(
        `ExpandActive${tableName}RelatedList`,
        JSON.stringify(
          getCurrentTileData[0][value].tile_related_table.filter(
            (data) => data.selected
          )
        )
      );
      localStorage.setItem(
        `relatedTablesHeaders`,
        JSON.stringify(getCurrentTileData[0][value].tile_related_table_fields)
      );
    } else {
      currentTileHeader = await getKeyData(
        JSON.stringify({
          key: `Expand${tableName}Headers`,
        })
      );
      setCurrentTileHeaders(currentTileHeader?.data?.value);
      // JSON.parse(localStorage.getItem(`Expand${tableName}Headers`));
      currentActiveTileHeader =
        currentTileHeader?.data?.value.filter(
          ({ isVisible, selected }) => isVisible || selected
        ) ?? [];
    }
    setCurrentActiveTileHeader(currentActiveTileHeader);
    // localStorage.setItem(
    //   `Expand${tile}${tableName}Headers`,
    //   JSON.stringify(currentTileHeader)
    // );

    currentTileHeader = [
      ...new Set(
        currentTileHeader?.filter((d) => d).map((o) => JSON.stringify(o))
      ),
    ].map((s) => JSON.parse(s));

    const dataParams = JSON.stringify({
      key: `Expand${tile}${tableName}Headers`,
      value: currentTileHeader
        .filter(({ isVisible, selected }) => selected || isVisible)
        ?.map(({ call_parent, metric_name }) =>
          `${
            call_parent ? `${call_parent}.${metric_name}` : metric_name
          }`.trim()
        ),
    });

    await createOrUpdateKey(dataParams);
    // localStorage.setItem(
    //   `ExpandActive${tile}${tableName}Headers`,
    //   JSON.stringify(currentActiveTileHeader)
    // );
    if (currentActiveTileHeader?.length) {
      let dataParams = {
        table_name: tableName,
        [!(tile === "Grid") ? "id" : "record_id"]: rowId,
        tile_name: !(tile === "Grid") ? value : undefined,
        fields:
          tile === "Grid"
            ? currentActiveTileHeader.map(
                (data) =>
                  `${data.call_parent ? `${data.call_parent}.` : ""}${
                    data.metric_name
                  }`
              )
            : undefined,
      };

      const JSONdataParams = JSON.stringify(dataParams);
      const res = !(value === "Grid")
        ? await getTileRecords(JSONdataParams)
        : await getIdRecord(JSONdataParams);
      if (res?.data?.status === 200) {
        setRowData(res.data?.rows ? res.data.rows[0] : res.data.tile_records);

        localStorage.setItem(
          `tableData`,
          JSON.stringify(
            res.data?.rows ? res.data.rows[0] : res.data.tile_records
          )
        );

        value !== "Grid" &&
          handleSetSubFields(
            tilesData ??
              JSON.parse(localStorage.getItem(`${tableName}TilesApiData`)),
            res.data?.rows ? res.data.rows[0] : res.data.tile_records
          );
        setTileChangeLoader(false);
      }
    }
    setTileChangeLoader(false);
    setExpandHeader(currentTileHeader);
    setActiveColumnHeader(currentActiveTileHeader);
  };

  const handleTilesChange = async (value) => {
    setTileChangeLoader(true);
    // setRowData({});
    handleTilesChangeAsync(value);
  };

  const handlePinnedData = async (pinnedOrNot, tileName) => {
    const selected = pinnedOrNot ? false : true;
    const dataParams = {
      id: rowId,
      table_name: tableName,
      tile_name: tileName,
      updatevalue: selected,
      operation: "tile_select_unselect",
    };
    let tempTiles = [...allTiles];
    tempTiles = tempTiles.map((data) =>
      data?.labelText === tileName ? { ...data, selected: selected } : data
    );
    tempTiles = tempTiles.flat();
    tempTiles.sort((a, b) => {
      if (a?.labelText === "Overview") {
        return -1; // "overview" comes first
      } else if (b?.labelText === "Overview") {
        return 1;
      } else {
        return 0; // no change in order
      }
    });

    const tempAllDropDownHeader = [...tempTiles];
    const dropDownHeader = tempAllDropDownHeader.map((data) => {
      if (data?.labelText !== "All")
        return {
          metric_datatype: "string",
          metric_display_name: data?.labelText,
          metric_name: data?.labelText,
          selected: data.selected,
        };
    });
    dropDownHeader.splice(dropDownHeader.length, 0, {
      metric_datatype: "string",
      metric_display_name: "New tile",
      metric_name: "New tile",
    });
    setAllDropDownHeader(dropDownHeader);
    setAllTiles(tempTiles);
    if (tileName === manageHeader) {
      let activeOverviewHeader = await getKeyData(
        JSON.stringify({
          key: `ExpandOverview${tableName}Headers`,
        })
      );

      const overViewTileData = tilesData.filter((data) =>
        Object.keys(data).includes("Overview")
      )[0]["Overview"];

      let _subFields = {};

      let currentTileHeaders = overViewTileData.tile_fields;

      const getSubFields = currentTileHeaders.filter((data) => {
        if (data.parent_table_fields) {
          return data.parent_table_fields.filter((data) => data.selected);
        }
      });

      getSubFields.map(
        ({ metric_name, parent_table_fields }) =>
          (_subFields = { ..._subFields, [metric_name]: parent_table_fields })
      );

      let columns;
      if (Object.keys(_subFields)?.length) {
        columns = Object.keys(_subFields)

          .map((field) =>
            _subFields[field]?.filter(
              ({ isVisible, selected }) => isVisible || selected
            )
          )
          .flat();

        currentTileHeaders = [...currentTileHeaders, ...columns];
      }

      const checkTileHeaderExist = await getKeyData(
        JSON.stringify({
          key: `ExpandOverview${tableName}Headers`,
        })
      );
      currentTileHeaders = currentTileHeaders.map((data) => {
        const flag = checkTileHeaderExist?.data?.value?.includes(
          data?.call_parent
            ? `${data?.call_parent}.${data?.metric_name}`
            : data?.metric_name
        );
        return {
          ...data,
          isVisible: flag ?? data.selected,
          selected: flag ?? data.selected,
        };
      });

      currentTileHeaders = currentTileHeaders.filter((d) =>
        d.call_parent ? d.selected : d
      );

      // let response = await getKeyData(
      //   JSON.stringify({
      //     key: `Expand${tableName}Headers`,
      //   })
      // );

      let sortedFields = checkTileHeaderExist?.data?.value?.length
        ? checkTileHeaderExist?.data?.value.map((name) =>
            currentTileHeaders.find(
              (data) =>
                (data?.call_parent
                  ? `${data?.call_parent}.${data?.metric_name}`
                  : data?.metric_name) === name
            )
          )
        : [];

      if (sortedFields?.length) {
        currentTileHeaders.sort((a, b) => {
          if (a.selected === b.selected) return 0;
          if (a.selected > b.selected) return -1;
          return 1;
        });

        currentTileHeaders.splice(0, sortedFields.length, sortedFields);
        currentTileHeaders = currentTileHeaders.flat();

        // currentActiveTileHeader = currentTileHeaders?.filter(
        //   ({ selected, isVisible }) => selected ?? isVisible
        // );
      }

      activeOverviewHeader = currentTileHeaders.filter(
        ({ isVisible, selected }) => isVisible || selected
      );
      setNewOrRenameTile(false);
      setCurrentTileHeaders(currentTileHeaders);

      setCurrentActiveTileHeader(
        currentTileHeaders?.filter(
          ({ selected, isVisible }) => selected ?? isVisible
        )
      );
    }

    handleApiCallHeader(
      dataParams,
      "Pin",
      tileName === manageHeader ? "Overview" : manageHeader
    );
    setManageHeader(tileName === manageHeader ? "Overview" : manageHeader);
    localStorage.setItem(
      "manageHeader",
      tileName === manageHeader ? "Overview" : manageHeader
    );
  };

  const handleDuplicateTiles = async () => {
    setTileNameChange(tileNameChange.trim());
    setManageHeader(tileNameChange.trim());
    setHandleTextBox(true);
    handleApiCall(tileNameChange.trim());
    setManageHeader(`${manageHeader} Copy`);
    setTileNameChange(`${manageHeader} Copy`);
    localStorage.setItem("ApiCall", "Duplicate");
  };

  const handleApiCall = async (value) => {
    setTileLoader(true);
    setNewTile(null);
    const Api = localStorage.getItem("ApiCall");
    let dataParams = {
      table_name: tableName,
      id: rowId,
    };
    if (Api === "Create") {
      dataParams = { ...dataParams, tile_name: "New tile" };
      handleApiCallHeader(dataParams, Api, value);
    }
    if (Api === "Rename") {
      let header = await getKeyData(
        JSON.stringify({
          key: `Expand${localStorage.getItem(
            "manageHeader"
          )}${tableName}Headers`,
        })
      );
      header = header?.data?.value ?? [];

      // JSON.parse(
      //   localStorage.getItem(
      //     `Expand${localStorage.getItem("manageHeader")}${tableName}Headers`
      //   )
      // );
      // const activeHeader = header.filter(
      //   ({ isVisible, selected }) => isVisible || selected
      // );
      // localStorage.setItem(
      //   `Expand${value}${tableName}Headers`,
      //   JSON.stringify(header)
      // );
      deleteKey(
        JSON.stringify({
          key: `Expand${localStorage.getItem(
            "manageHeader"
          )}${tableName}Headers`,
        })
      );
      const dParams = JSON.stringify({
        key: `Expand${value}${tableName}Headers`,
        value: header,
      });
      await createOrUpdateKey(dParams);
      // localStorage.setItem(
      //   `ExpandActive${value}${tableName}Headers`,
      //   JSON.stringify(activeHeader)
      // );
      // localStorage.removeItem(
      //   `Expand${localStorage.getItem("manageHeader")}${tableName}Headers`
      // );
      // localStorage.removeItem(
      //   `ExpandActive${localStorage.getItem("manageHeader")}${tableName}Headers`
      // );
      dataParams = {
        ...dataParams,
        tile_name: localStorage.getItem("manageHeader"),
        tile_rename: value,
        operation: "tile_rename",
      };

      handleApiCallHeader(dataParams, Api, value);
    }
    if (Api === "Duplicate") {
      const duplicateName = `${localStorage.getItem("manageHeader")} Copy`;
      dataParams = {
        ...dataParams,
        tile_name: localStorage.getItem("manageHeader"),
      };
      handleApiCallHeader(dataParams, Api, duplicateName);
    }
    if (Api === "Delete") {
      localStorage.removeItem(`Expand${manageHeader}${tableName}Headers`);
      localStorage.removeItem(`ExpandActive${manageHeader}${tableName}Headers`);
      dataParams = { ...dataParams, tile_name: manageHeader, id: undefined };
      let tempTiles = [...allTiles];
      tempTiles = tempTiles.filter(
        (data) =>
          !(
            data?.labelText === localStorage.getItem("manageHeader") &&
            data.selected
          )
      );

      tempTiles = tempTiles.flat();
      tempTiles.sort((a, b) => {
        if (a?.labelText === "Overview") {
          return -1; // "overview" comes first
        } else if (b?.labelText === "Overview") {
          return 1;
        } else {
          return 0; // no change in order
        }
      });

      setAllTiles(tempTiles);
      setTileChangeLoader(true);
      setManageHeader("Overview");
      setNewOrRenameTile(false);
      localStorage.setItem("manageHeader", "Overview");
      handleApiCallHeader(dataParams, Api, tempTiles);
    }
  };

  const handleApiCallHeader = async (dataParams, Api, tile) => {
    // tile value -> tile name for create and rename
    // tile value -> all tiles data which is used on delete call
    const JSONdataParams = JSON.stringify(dataParams);
    let response;
    switch (Api) {
      case "Create":
        response = await createTile(JSONdataParams);
        break;
      case "Rename":
        response = await updateTile(JSONdataParams);
        break;
      case "Duplicate":
        response = await duplicateTile(JSONdataParams);
        break;
      case "Delete":
        response = await deleteTile(JSONdataParams);
        break;
      case "Pin":
        response = await updateTile(JSONdataParams);
        break;
      default:
        break;
    }
    if (Api === "Delete") {
      deleteKey(
        JSON.stringify({
          key: `Expand${manageHeader}${tableName}Headers`,
        })
      );
    }

    if (response?.data?.status === 200) {
      let tiles =
        tilesData ??
        JSON.parse(localStorage.getItem(`${tableName}TilesApiData`));
      let responseTile = tile;
      responseTile =
        Api === "Duplicate" || Api === "Create"
          ? Object.keys(response.data.tile_data[0])[0]
          : responseTile;
      let newRenamedTiles;
      if (Api !== "Delete" && Api !== "Pin") {
        // this block works only when tiles action is not -> delete or pin/unpin
        Api === "Create" &&
          tiles.splice(tiles.length - 1, 0, response.data.tile_data[0]);
        if (Api === "Rename") {
          tiles = tiles?.map((tileData) =>
            tileData.hasOwnProperty(localStorage.getItem("manageHeader"))
              ? response.data.tile_data[0]
              : tileData
          );
          newRenamedTiles = allTiles.map((data) =>
            data?.labelText === localStorage.getItem("manageHeader")
              ? { ...data, labelText: tile }
              : data
          );
        }
        Api === "Duplicate" && tiles.push(response.data.tile_data[0]);
        // localStorage.setItem(`${tableName}TilesApiData`, JSON.stringify(tiles));
        localStorage.setItem("manageHeader", responseTile);
        setManageHeader(responseTile);
        setTilesData(tiles);

        if (Api !== "Rename") {
          const getCurrentTileData = response.data.tile_data.filter(
            (tileData) => tileData.hasOwnProperty(responseTile)
          );

          let currentTileHeader =
            getCurrentTileData[0][responseTile].tile_fields;

          let _subFields = {};

          const getSubFields = currentTileHeader.filter((data) => {
            if (data.parent_table_fields) {
              return data.parent_table_fields.filter((data) => data.selected);
            }
          });

          getSubFields.map(
            ({ metric_name, parent_table_fields }) =>
              (_subFields = {
                ..._subFields,
                [metric_name]: parent_table_fields,
              })
          );

          let columns;
          if (Object.keys(_subFields)?.length) {
            columns = Object.keys(_subFields)

              .map((field) =>
                _subFields[field]?.filter(
                  ({ isVisible, selected }) => isVisible || selected
                )
              )
              .flat();

            currentTileHeader = [...currentTileHeader, ...columns];
          }

          const checkTileHeaderExist = await getKeyData(
            JSON.stringify({
              key: `Expand${responseTile}${tableName}Headers`,
            })
          );

          currentTileHeader = currentTileHeader.map((data) => {
            const flag = checkTileHeaderExist?.data?.value?.includes(
              data?.call_parent
                ? `${data?.call_parent}.${data?.metric_name}`
                : data?.metric_name
            );
            return {
              ...data,
              isVisible: flag ?? data.selected,
              selected: flag ?? data.selected,
            };
          });

          currentTileHeader = currentTileHeader.filter((d) =>
            d.call_parent ? d.selected : d
          );

          // let response = await getKeyData(
          //   JSON.stringify({
          //     key: `Expand${tableName}Headers`,
          //   })
          // );

          let sortedFields = (
            checkTileHeaderExist?.data?.value || gridDefaultFields[tableName]
          ).map((name) =>
            currentTileHeader.find(
              (data) =>
                (data?.call_parent
                  ? `${data?.call_parent}.${data?.metric_name}`
                  : data?.metric_name) === name
            )
          );

          if (sortedFields?.length) {
            currentTileHeader.sort((a, b) => {
              if (a.selected === b.selected) return 0;
              if (a.selected > b.selected) return -1;
              return 1;
            });

            currentTileHeader.splice(0, sortedFields.length, sortedFields);
            currentTileHeader = currentTileHeader.flat();

            // currentActiveTileHeader = currentTileHeader?.filter(
            //   ({ selected, isVisible }) => selected ?? isVisible
            // );
          }
          if (Api === "Create" || Api === "Duplicate") {
            setRowData(response.data.tile_records);

            localStorage.setItem(
              `tableData`,
              JSON.stringify(response.data.tile_records)
            );
            // localStorage.setItem(
            //   `Expand${responseTile}${tableName}Headers`,
            //   JSON.stringify(currentTileHeader)
            // );

            currentTileHeader = [
              ...new Set(
                currentTileHeader
                  ?.filter((d) => d)
                  .map((o) => JSON.stringify(o))
              ),
            ].map((s) => JSON.parse(s));

            setCurrentTileHeaders(currentTileHeader);
            setCurrentActiveTileHeader(
              currentTileHeader?.filter(
                ({ selected, isVisible }) => selected ?? isVisible
              )
            );

            const dataParams = JSON.stringify({
              key: `Expand${responseTile}${tableName}Headers`,
              value: currentTileHeader
                .filter(({ isVisible, selected }) => selected || isVisible)
                ?.map(({ call_parent, metric_name }) =>
                  `${
                    call_parent ? `${call_parent}.${metric_name}` : metric_name
                  }`.trim()
                ),
            });
            await createOrUpdateKey(dataParams);
            // setExpandHeader(currentTileHeader);
            // localStorage.setItem(
            //   `ExpandActive${responseTile}${tableName}Headers`,
            //   JSON.stringify(
            //     currentTileHeader?.filter((data) => data.selected !== false)
            //   )
            // );
          }

          const currentTileRelatedList =
            response.data.tile_data[0][responseTile].tile_related_table;
          const activeRelatedList = currentTileRelatedList?.filter(
            (data) => data.selected
          );

          localStorage.setItem(
            `Expand${tableName}RelatedList`,
            JSON.stringify(currentTileRelatedList)
          );
          localStorage.setItem(
            `ExpandActive${tableName}RelatedList`,
            JSON.stringify(activeRelatedList)
          );
        }
      } else {
        // this block works only when tiles action -> delete or pin/unpin
        tiles =
          Api === "Delete"
            ? tiles.filter((data) => !data.hasOwnProperty(manageHeader))
            : tiles;
        // localStorage.setItem(`${tableName}TilesApiData`, JSON.stringify(tiles));
        setTilesData(tiles);
        localStorage.setItem(
          "manageHeader",
          Api === "Delete" ? "Overview" : responseTile
        );

        const getOverviewTileData =
          tilesData ??
          JSON.parse(localStorage.getItem(`${tableName}TilesApiData`));

        const currentTileRelatedList = getOverviewTileData?.filter((data) =>
          data.hasOwnProperty(Api === "Delete" ? "Overview" : responseTile)
        )[0][Api === "Delete" ? "Overview" : responseTile].tile_related_table;

        const activeRelatedList = currentTileRelatedList?.filter(
          (data) => data.selected !== false
        );

        localStorage.setItem(
          `Expand${tableName}RelatedList`,
          JSON.stringify(currentTileRelatedList)
        );
        localStorage.setItem(
          `ExpandActive${tableName}RelatedList`,
          JSON.stringify(activeRelatedList)
        );
        localStorage.setItem(
          "relatedTablesHeaders",
          JSON.stringify(
            getOverviewTileData?.filter((data) =>
              data.hasOwnProperty(Api === "Delete" ? "Overview" : responseTile)
            )[0][Api === "Delete" ? "Overview" : responseTile]
              .tile_related_table_fields
          )
        );
        setManageHeader(Api === "Delete" ? "Overview" : responseTile);
        if (Api === "Pin") {
          return;
        }
      }

      let tempTiles =
        Api !== "Delete"
          ? Api === "Rename"
            ? [...newRenamedTiles]
            : [...allTiles]
          : [...tile];
      if (Api !== "Delete") {
        if (Api === "Duplicate") {
          tempTiles.splice(tempTiles.length - 1, 0, {
            labelText: responseTile,
            selected: response.data.tile_data[0][responseTile].selected,
            header: response.data.tile_data[0][responseTile].tile_fields,
          });
        } else {
          Api === "Create" &&
            tempTiles.push({
              labelText: responseTile,
              selected: true,
              header: [],
            });

          tempTiles = tempTiles.map((tileData) => {
            if (
              tileData?.labelText ===
              (Api === "Create" || Api === "Rename" ? responseTile : "New tile")
            ) {
              return response.data.tile_data.map((data) => {
                if (data[responseTile]) {
                  return {
                    labelText: responseTile,
                    selected: data[responseTile].selected,
                    header: data[responseTile].tile_fields,
                  };
                }
              });
            } else return tileData;
          });
        }
      }

      tempTiles = tempTiles.flat();
      tempTiles.sort((a, b) => {
        if (a?.labelText === "Overview") {
          return -1; // "overview" comes first
        } else if (b?.labelText === "Overview") {
          return 1;
        } else {
          return 0; // no change in order
        }
      });
      const tempAllDropDownHeader = [...tempTiles.flat()];
      const dropDownHeader = tempAllDropDownHeader.map((data) => {
        if (data?.labelText !== "All")
          return {
            metric_datatype: "string",
            metric_display_name: data?.labelText,
            metric_name: data?.labelText,
            selected: data.selected,
          };
      });
      dropDownHeader.splice(dropDownHeader.length, 0, {
        metric_datatype: "string",
        metric_display_name: "New tile",
        metric_name: "New tile",
      });
      if (Api === "Delete") {
        // let activeOverviewHeader = await getKeyData(
        //   JSON.stringify({
        //     key: `ExpandOverview${tableName}Headers`,
        //   })
        // );

        const overViewTileData = tilesData.filter((data) =>
          Object.keys(data).includes("Overview")
        )[0]["Overview"];

        let _subFields = {};

        let currentTileHeaders = overViewTileData.tile_fields;

        const getSubFields = currentTileHeaders.filter((data) => {
          if (data.parent_table_fields) {
            return data.parent_table_fields.filter((data) => data.selected);
          }
        });

        getSubFields.map(
          ({ metric_name, parent_table_fields }) =>
            (_subFields = { ..._subFields, [metric_name]: parent_table_fields })
        );

        let columns;
        if (Object.keys(_subFields)?.length) {
          columns = Object.keys(_subFields)

            .map((field) =>
              _subFields[field]?.filter(
                ({ isVisible, selected }) => isVisible || selected
              )
            )
            .flat();

          currentTileHeaders = [...currentTileHeaders, ...columns];
        }
        const checkTileHeaderExist = await getKeyData(
          JSON.stringify({
            key: `ExpandOverview${tableName}Headers`,
          })
        );
        currentTileHeaders = currentTileHeaders.map((data) => {
          const flag = checkTileHeaderExist?.data?.value?.includes(
            data?.call_parent
              ? `${data?.call_parent}.${data?.metric_name}`
              : data?.metric_name
          );
          return {
            ...data,
            isVisible: flag ?? data.selected,
            selected: flag ?? data.selected,
          };
        });

        currentTileHeaders = currentTileHeaders.filter((d) =>
          d.call_parent ? d.selected : d
        );

        // let response = await getKeyData(
        //   JSON.stringify({
        //     key: `Expand${tableName}Headers`,
        //   })
        // );

        let sortedFields = checkTileHeaderExist?.data?.value?.length
          ? checkTileHeaderExist?.data?.value.map((name) =>
              currentTileHeaders.find(
                (data) =>
                  (data?.call_parent
                    ? `${data?.call_parent}.${data?.metric_name}`
                    : data?.metric_name) === name
              )
            )
          : [];

        if (sortedFields?.length) {
          currentTileHeaders.sort((a, b) => {
            if (a.selected === b.selected) return 0;
            if (a.selected > b.selected) return -1;
            return 1;
          });

          currentTileHeaders.splice(0, sortedFields.length, sortedFields);
          currentTileHeaders = currentTileHeaders.flat();

          // currentActiveTileHeader = currentTileHeaders?.filter(
          //   ({ selected, isVisible }) => selected ?? isVisible
          // );
        }

        setCurrentActiveTileHeader(
          currentTileHeaders?.filter(
            ({ selected, isVisible }) => selected ?? isVisible
          )
        );
        const activeOverviewHeader = currentTileHeaders.filter(
          ({ isVisible, selected }) => isVisible || selected
        );
        setCurrentTileHeaders(currentTileHeaders);

        getRowData(rowId, activeOverviewHeader, tile);
      }
      setAllDropDownHeader(dropDownHeader);
      setAllTiles(tempTiles.flat());
      setTileLoader(false);
    } else if (response?.request?.status === 400) {
      setTileLoader(false);
      toast.error(
        response?.response?.data?.detail || response?.response?.data?.error
      );
    }
  };

  const handleTileChangeHeaderOrActiveHeader = (handleHeaderOrActiveHeader) => {
    const tile = localStorage.getItem("manageHeader");
    const getAllTilesData =
      tilesData ?? JSON.parse(localStorage.getItem(`${tableName}TilesApiData`));
    const getCurrentTileData = getAllTilesData.filter((tileData) =>
      tileData.hasOwnProperty(tile)
    );
    return handleHeaderOrActiveHeader
      ? getCurrentTileData[0][tile].tile_fields
      : getCurrentTileData[0][tile].tile_fields.filter(
          (data) => data.isVisible || data.selected
        );
  };

  const handleRelatedTableHeader = () => {
    const activeTile = localStorage.getItem("manageHeader");
    const getAllTilesData =
      tilesData ?? JSON.parse(localStorage.getItem(`${tableName}TilesApiData`));
    const getCurrentTileData = getAllTilesData?.filter((tileData) =>
      tileData.hasOwnProperty(activeTile)
    );
    return (
      getCurrentTileData && getCurrentTileData[0][activeTile].tile_related_table
    );
  };

  const headerOrActiveHeader = async (
    headerORactiveHeader,
    passToAnotherFunction
  ) => {
    const __tileHeader =
      !currentTileHeader?.length &&
      (await getKeyData(
        JSON.stringify({
          key: `Expand${localStorage.getItem(
            "manageHeader"
          )}${tableName}Headers`,
        })
      ));

    setCurrentTileHeaders(__tileHeader?.data?.value);

    return (
      !currentTileHeader ? __tileHeader?.data?.value?.length : currentTileHeader
    )
      ? !headerORactiveHeader
        ? !currentTileHeader
          ? __tileHeader?.data?.value
          : currentTileHeader
        : (!currentTileHeader
            ? __tileHeader?.data?.value
            : currentTileHeader
          )?.filter(({ isVisible, selected }) => isVisible || selected) ?? []
      : !headerORactiveHeader
      ? passToAnotherFunction
        ? null
        : header
      : passToAnotherFunction
      ? null
      : activeHeader;
  };

  return (
    <>
      {rowData && Object.keys(rowData)?.length ? (
        <MainBox>
          <MainBoxHeader
            style={{ height: "70px" }}
            sx={{
              svg: {
                // stroke: "#032E61",
              },
            }}
          >
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              flexDirection={"row"}
              width={"555px"}
              marginRight={taskView ? "10px" : "0px"}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                paddingRight={"12px"}
                width={taskView ? "200px" : "400px"}
              >
                {tooltip > 300 ? (
                  <Tooltip
                    title={
                      rowData?.Name
                        ? rowData.Name
                        : rowData?.FirstName
                        ? rowData.FirstName + " " + rowData.LastName
                        : rowData?.LastName
                        ? rowData.LastName
                        : rowData?.Subject
                    }
                    PopperProps={{
                      disablePortal: true,
                    }}
                  >
                    <OpportunityName
                      whiteSpace={"nowrap"}
                      textOverflow={"ellipsis"}
                      overflow={"hidden"}
                      onMouseEnter={(e) => setToolTip(e.target.clientWidth)}
                      onMouseLeave={() => setToolTip(0)}
                    >
                      {rowData?.Name
                        ? rowData.Name
                        : rowData?.FirstName
                        ? rowData.FirstName + " " + rowData.LastName
                        : rowData?.LastName
                        ? rowData.LastName
                        : rowData?.Subject}
                    </OpportunityName>
                  </Tooltip>
                ) : (
                  <OpportunityName
                    whiteSpace={"nowrap"}
                    textOverflow={"ellipsis"}
                    overflow={"hidden"}
                    onMouseEnter={(e) => setToolTip(e.target.clientWidth)}
                    onMouseLeave={() => setToolTip(0)}
                  >
                    {rowData?.Name
                      ? rowData.Name
                      : rowData?.FirstName
                      ? rowData.FirstName + " " + rowData.LastName
                      : rowData?.LastName
                      ? rowData.LastName
                      : rowData?.Subject}
                  </OpportunityName>
                )}

                <OpportunityType>{tableName}</OpportunityType>
              </Box>
            </Box>
            {taskView ? (
              <Box
                onClick={() => expandView()}
                sx={{
                  cursor: "pointer",
                  stroke: "#032E61",
                  ":hover": {
                    stroke: "#00A1E0",
                  },
                }}
              >
                <ExpandSVG />
              </Box>
            ) : (
              <CloseRounded
                onClick={() => {
                  expandView();
                  localStorage.setItem(`${tableName}TilesApiData`, null);
                  setTilesData(null);
                  localStorage.setItem("manageHeader", "Overview");
                  setExpandViewTableFieldHeader(null);
                  setOnHoverHeader(null);
                  notesExpandedView && setShowNotesExtended(false);
                }}
                sx={{
                  fontSize: "16px",
                  cursor: "pointer",
                  marginLeft: "1rem",
                  marginBottom: "2rem",
                  color: "#7A8391",
                }}
              />
            )}
          </MainBoxHeader>
          <MainDataBox
            style={{
              paddingBottom: taskView && "1rem",
              height: taskView ? "max-content" : "730px",
              flexDirection: "row",
            }}
          >
            {tileLoader && (
              <LoaderBox>
                <Loader></Loader>
              </LoaderBox>
            )}
            <ExpandViewSideMenu
              sx={{
                width: taskView ? "100px" : "131px",
              }}
            >
              <Box
                display={"flex"}
                gap={taskView ? "0px" : "8px"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <ClickAwayListener
                  onClickAway={() => setAllTilesDropDown(false)}
                >
                  <Box>
                    <AllTilesBox
                      onClick={() => {
                        setAllTilesDropDown(!allTilesDropDown);
                      }}
                      style={{
                        width: taskView && "100px",
                      }}
                    >
                      <Typography
                        fontFamily={"Inter"}
                        fontSize={"16px"}
                        width={"4rem"}
                        textAlign={"center"}
                      >
                        Views
                      </Typography>
                      <ArrowDownwardSVG />
                    </AllTilesBox>

                    {allTilesDropDown && (
                      <SimpleFilterDropDown
                        value={"All"}
                        handleData={handleChange}
                        handlePinnedData={handlePinnedData}
                        header={allDropDownHeader}
                        widthAdjustment={"handleTiles"}
                        allTilesDropDown={true}
                        name={"sortPinnedTiles"}
                        searching={true}
                      />
                    )}
                  </Box>
                </ClickAwayListener>
              </Box>

              <CustomBox
                sx={{
                  alignItems: taskView && "baseline",
                }}
              >
                <Box
                  sx={{
                    overflow: "scroll",
                    scrollBehavior: "smooth",
                    height: taskView ? "auto" : "483px",
                    "&::-webkit-scrollbar": {
                      width: "0.5em",
                      marginRight: "4px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "rgba(181,188,194,0.7)",
                      borderRadius: "10px",
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                      backgroundColor: "rgba(181,188,194,0.9)",
                    },
                  }}
                >
                  {allTiles.map((data) => {
                    // if (tableName === "Lead" && data.labelText === "All") {
                    //   return (
                    //     <>
                    //       <TilesBox
                    //         onClick={(event) => {
                    //           const value = event.target.innerText;
                    //           localStorage.setItem(
                    //             "manageHeader",
                    //             value === "Grid View" ? "Grid" : value
                    //           );
                    //           setNewTile(null);
                    //           setManageHeader(
                    //             value === "Grid View" ? "Grid" : value
                    //           );
                    //           handleLeadView(event);
                    //           handleLeadViewPopOver(event);
                    //         }}
                    //         sx={
                    //           "Convert Lead" === manageHeader && {
                    //             width: taskView ? "100px" : "131px",
                    //             background: "#d7d4d4",
                    //             ".MuiBox-root": {
                    //               flexGrow: 0,
                    //             },
                    //           }
                    //         }
                    //       >
                    //         <Add
                    //           sx={{
                    //             fontSize: "14px",
                    //             ".MuiSvgIcon-root": {
                    //               fontSize: "14px",
                    //             },
                    //           }}
                    //         />
                    //         {"Convert Lead "}
                    //       </TilesBox>

                    //       <LeadViewPopOver
                    //         id={id}
                    //         open={leadViewOpen}
                    //         anchorEl={leadAnchorEl}
                    //         onClose={handleLeadViewClose}
                    //         anchorOrigin={{
                    //           vertical: "center",
                    //           horizontal: "center",
                    //         }}
                    //         transformOrigin={{
                    //           vertical: "center",
                    //           horizontal: "center",
                    //         }}
                    //       >
                    //         <LeadView
                    //           handleLeadViewClose={() => {
                    //             handleLeadViewClose();
                    //           }}
                    //           handleConvertLeadClick={(data) => {
                    //             setSuccessLeadData(data);
                    //             setConvertLead(true);
                    //             handleConvertLeadViewClick();
                    //           }}
                    //           data={convertLeadData}
                    //           leadId={rowId}
                    //           leadViewStatus={convertLeadStatus}
                    //         />
                    //       </LeadViewPopOver>
                    //       {convertLead && (
                    //         <ConvertLeadViewPopOver
                    //           open={convertLeadViewOpen}
                    //           id={convertLeadViewId}
                    //           anchorEl={convertLeadViewAnchorEl}
                    //           onClose={() => handleConvertLeadViewClose()}
                    //           anchorOrigin={{
                    //             vertical: "center",
                    //             horizontal: "center",
                    //           }}
                    //           transformOrigin={{
                    //             vertical: "center",
                    //             horizontal: "center",
                    //           }}
                    //         >
                    //           <ConvertLeadView
                    //             data={successLeadData}
                    //             setConvertLead={() => {
                    //               setConvertLead(false);
                    //               handleConvertLeadViewClose();
                    //             }}
                    //             goToLeadTable={() => {
                    //               setConvertLead(false);
                    //               handleConvertLeadViewClose();
                    //               handleLeadViewClose();
                    //               setTilesData(null);
                    //               expandView();
                    //               localStorage.setItem(
                    //                 `${tableName}TilesApiData`,
                    //                 null
                    //               );
                    //             }}
                    //             onUpdateSuccess={onUpdateSuccess}
                    //           />
                    //         </ConvertLeadViewPopOver>
                    //       )}
                    //     </>
                    //   );
                    // } else
                    if (data.selected)
                      return (
                        <Tooltip
                          title={
                            data?.labelText.length > (taskView ? 11 : 14)
                              ? data?.labelText
                              : ""
                          }
                        >
                          <TilesBox
                            onClick={(event) => {
                              const value =
                                event.target.innerText ||
                                event.currentTarget.innerText;
                              if (
                                (value === "Grid View" ? "Grid" : value) ===
                                manageHeader
                              )
                                return;
                              if (value !== "All" && value !== "Convert Lead") {
                                localStorage.setItem(
                                  "manageHeader",
                                  value === "Grid View" ? "Grid" : value
                                );
                                setNewTile(null);
                                setManageHeader(
                                  value === "Grid View" ? "Grid" : value
                                );
                                if (
                                  value === "Overview" ||
                                  value === "Grid View"
                                ) {
                                  setNewOrRenameTile(false);
                                } else {
                                  setNewOrRenameTile(true);
                                  setHandleTextBox(true);
                                }
                                handleTilesChange(
                                  value === "Grid View" ? "Grid" : value
                                );
                              }
                              if (newTile) {
                                setAllTilesDropDown(false);
                              } else if (value === "All") {
                                setAllTilesDropDown(true);
                              }
                            }}
                            sx={{
                              width: taskView ? "100px" : "131px",
                              background:
                                data?.labelText === manageHeader && "#00A1E0",
                              // ".MuiBox-root": {
                              //   flexGrow: data?.labelText === manageHeader && 1,
                              // },
                              svg: {
                                path: {
                                  stroke:
                                    data?.labelText === manageHeader
                                      ? "#ffffff"
                                      : "#444647",
                                },
                              },
                            }}
                          >
                            {data?.labelText === "Overview" && <OverView />}
                            {data?.labelText === "Grid" && <GridOverView />}
                            <Box
                              color={
                                data?.labelText === manageHeader
                                  ? "#ffffff"
                                  : "#444647"
                              }
                              sx={{
                                padding:
                                  data?.labelText !== "Overview" &&
                                  data?.labelText !== "Grid"
                                    ? "0rem 0.75rem"
                                    : "",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                textAlign: "center",
                              }}
                            >
                              {data?.labelText === "Grid"
                                ? "Grid View"
                                : data?.labelText}
                            </Box>
                          </TilesBox>
                        </Tooltip>
                      );
                  })}
                </Box>
              </CustomBox>
            </ExpandViewSideMenu>

            {!newOrRenameTile ? (
              <>
                <ScrollableBox
                  style={{
                    height: taskView
                      ? "auto"
                      : notesExpandedView
                      ? "90%"
                      : "490px",
                    width: "100%",
                  }}
                >
                  {!tileChangeLoader ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                          paddingBottom: "1rem",
                        }}
                      >
                        <div style={{ fontWeight: "600", fontSize: "16px" }}>
                          {manageHeader}
                        </div>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row-reverse",
                            gap: "6px",
                          }}
                        >
                          {!newOrRenameTile && (
                            <Box
                              display={"flex"}
                              alignItems={"center"}
                              gap={"10px"}
                              justifyContent={"flex-end"}
                            >
                              <FilterColumn
                                header={
                                  taskView
                                    ? header
                                    : currentTileHeader
                                    ? currentTileHeader
                                    : header
                                }
                                activeHeader={
                                  taskView
                                    ? activeHeader
                                    : currentTileHeader
                                    ? currentTileHeader.filter(
                                        (data) =>
                                          data.isVisible || data.selected
                                      )
                                    : activeHeader
                                }
                                filterColumns={(
                                  header,
                                  data,
                                  dragHandleIndividualTableHeader,
                                  currentActiveRelatedTable,
                                  subFields,
                                  bothHeaders
                                ) => {
                                  filterColumns(
                                    header,
                                    data,
                                    dragHandleIndividualTableHeader,
                                    currentActiveRelatedTable,
                                    subFields,
                                    bothHeaders
                                  );
                                }}
                                tableName={tableName}
                                addOrRemoveFieldInColumn={(
                                  field,
                                  headerFlag,
                                  parentFieldName,
                                  child_field
                                ) => {
                                  handleAddOrRemoveExpandColumnField(
                                    field,
                                    headerFlag,
                                    tableName,
                                    null,
                                    null,
                                    headerFlag === "handleRelated"
                                      ? false
                                      : true,
                                    parentFieldName,
                                    child_field
                                  );
                                }}
                                rowId={rowId}
                                iconChange={false}
                                allTilesDropDown={false}
                                HandleExpandViewHeader={true}
                                expandViewRelated={true}
                                expandViewFirst={expandViewFirst}
                                handleExpandViewTileChange={false}
                                relatedFields={
                                  taskView
                                    ? relatedFields
                                    : localStorage.getItem(
                                        `Expand${tableName}RelatedList`
                                      )
                                    ? JSON.parse(
                                        localStorage.getItem(
                                          `Expand${tableName}RelatedList`
                                        )
                                      )
                                    : relatedFields
                                }
                                relatedTableLoader={() =>
                                  setRelatedTableLoader(!relatedTableLoader)
                                }
                                relatedFieldsLoader={relatedTableLoader}
                                taskView={taskView}
                                leadView={leadView}
                                subFields={subFields}
                                addOrRemoveSubField={true}
                                setTilesData={setTilesData}
                                tilesData={tilesData}
                              />
                            </Box>
                          )}
                          <SalesForceButton
                            sx={
                              {
                                // minWidth: "95px",
                              }
                            }
                            onClick={() => {
                              window.open(rowData.salseforce_ui_url, "_blank");
                            }}
                          >
                            <SalesForceLinkSVG color="#032E61" />
                            <SalesforceTypoGraphy>
                              View in SF
                            </SalesforceTypoGraphy>
                          </SalesForceButton>
                          {/*    */}

                          {allTiles.map((data) => {
                            if (
                              tableName === "Lead" &&
                              data?.labelText === "All"
                            ) {
                              return (
                                <>
                                  <ConvertLeadButton
                                    onClick={(event) => {
                                      const value = event.target.innerText;
                                      localStorage.setItem(
                                        "manageHeader",
                                        value === "Grid View" ? "Grid" : value
                                      );
                                      setNewTile(null);
                                      setManageHeader(
                                        value === "Grid View" ? "Grid" : value
                                      );
                                      handleLeadView(event);
                                      handleLeadViewPopOver(event);
                                    }}
                                    sx={
                                      "Convert Lead" === manageHeader && {
                                        width: taskView ? "100px" : "131px",
                                        background: "#d7d4d4",
                                        ".MuiBox-root": {
                                          flexGrow: 0,
                                        },
                                      }
                                    }
                                  >
                                    <Add
                                      sx={{
                                        fontSize: "14px",
                                        ".MuiSvgIcon-root": {
                                          fontSize: "14px",
                                        },
                                      }}
                                    />
                                    {"Convert Lead "}
                                  </ConvertLeadButton>

                                  <LeadViewPopOver
                                    id={id}
                                    open={leadViewOpen}
                                    anchorEl={leadAnchorEl}
                                    onClose={handleLeadViewClose}
                                    anchorOrigin={{
                                      vertical: "center",
                                      horizontal: "center",
                                    }}
                                    transformOrigin={{
                                      vertical: "center",
                                      horizontal: "center",
                                    }}
                                  >
                                    <LeadView
                                      handleLeadViewClose={() => {
                                        handleLeadViewClose();
                                      }}
                                      handleConvertLeadClick={(data) => {
                                        setSuccessLeadData(data);
                                        setConvertLead(true);
                                        handleConvertLeadViewClick();
                                      }}
                                      data={convertLeadData}
                                      leadId={rowId}
                                      leadViewStatus={convertLeadStatus}
                                    />
                                  </LeadViewPopOver>
                                  {convertLead && (
                                    <ConvertLeadViewPopOver
                                      open={convertLeadViewOpen}
                                      id={convertLeadViewId}
                                      anchorEl={convertLeadViewAnchorEl}
                                      onClose={() =>
                                        handleConvertLeadViewClose()
                                      }
                                      anchorOrigin={{
                                        vertical: "center",
                                        horizontal: "center",
                                      }}
                                      transformOrigin={{
                                        vertical: "center",
                                        horizontal: "center",
                                      }}
                                    >
                                      <ConvertLeadView
                                        data={successLeadData}
                                        setConvertLead={() => {
                                          setConvertLead(false);
                                          handleConvertLeadViewClose();
                                        }}
                                        goToLeadTable={() => {
                                          setConvertLead(false);
                                          handleConvertLeadViewClose();
                                          handleLeadViewClose();
                                          setTilesData(null);
                                          expandView();
                                          localStorage.setItem(
                                            `${tableName}TilesApiData`,
                                            null
                                          );
                                        }}
                                        onUpdateSuccess={onUpdateSuccess}
                                      />
                                    </ConvertLeadViewPopOver>
                                  )}
                                </>
                              );
                            }
                          })}

                          {/*  */}
                        </Box>
                      </div>
                      <RenderComponents
                        row={handleRowData([rowData])?.[0] ?? []}
                        activeColumnHeader={
                          currentTileHeader
                            ? currentActiveTileHeader ??
                              currentTileHeader.filter(
                                (data) => data.isVisible || data.selected
                              )
                            : headerOrActiveHeader(true)
                        }
                        rowId={rowId}
                        tableName={tableName}
                        onUpdateSuccessOfRow={onUpdateSuccessOfRow}
                        rowData={handleRowData([rowData])?.[0] ?? []}
                        setRowData={setRowData}
                        searchView={"Tiles"}
                        handleError={null}
                        error={null}
                        name={null}
                        SearchTableData={null}
                        onHoverHeader={onHoverHeader}
                        setOnHoverHeader={setOnHoverHeader}
                        handleAddOrRemoveExpandColumnField={
                          handleAddOrRemoveExpandColumnField
                        }
                        expandViewTableFieldHeader={expandViewTableFieldHeader}
                        setExpandViewTableFieldHeader={
                          setExpandViewTableFieldHeader
                        }
                        relatedTableLoader={relatedTableLoader}
                        setRelatedTableLoader={setRelatedTableLoader}
                        filterColumns={filterColumns}
                        expandViewFirst={expandViewFirst}
                        taskView={taskView}
                        leadView={leadView}
                        subFields={subFields}
                        tilesData={tilesData}
                      />
                    </div>
                  ) : (
                    !tileLoader && <Loader />
                  )}
                </ScrollableBox>
              </>
            ) : (
              <MainDataBox
                padding={taskView ? "0rem" : "1.5rem"}
                overflow={"auto"}
                height={
                  taskView ? "100%" : notesExpandedView ? "650px" : "527px"
                }
                width={"100%"}
                flexDirection={"column"}
              >
                <Box
                  paddingBottom={taskView ? "0rem" : "1rem"}
                  padding={taskView && "1rem"}
                  display={"flex"}
                  alignItems={"center"}
                  width={taskView ? "90%" : "100%"}
                  justifyContent={"space-between"}
                >
                  <Box>
                    {handleTextBox ? (
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        position={"relative"}
                        gap={"14px"}
                      >
                        <Tooltip
                          className="iconHover"
                          PopperProps={{
                            sx: {
                              "& .MuiTooltip-tooltip": {
                                width: "auto",
                                height: "auto",
                                background: "rgba(3, 46, 97, 0.72)",
                                borderRadius: "4px",
                                fontFamily: "Inter",
                                textAlign: "center",
                                fontSize: "12px",
                              },
                            },
                          }}
                          placement="bottom"
                          title={manageHeader}
                        >
                          <Typography
                            fontFamily={"Inter"}
                            textOverflow={"ellipsis"}
                            overflow={"hidden"}
                            maxWidth={"10rem"}
                            height={"1.2em"}
                            whiteSpace={"nowrap"}
                          >
                            {manageHeader}
                          </Typography>
                        </Tooltip>
                        <Box>
                          {" "}
                          <EditIconSVG
                            onClick={() => {
                              setNewOrRenameTile(true);
                              setFieldDropDown(false);
                              setHandleTextBox(false);
                              localStorage.setItem("ApiCall", "Rename");
                            }}
                            height={"12px"}
                            cursor={"pointer"}
                            color={"#798EA3"}
                          />
                        </Box>
                      </Box>
                    ) : (
                      <NameTextField
                        sx={{
                          ".MuiOutlinedInput-root": {
                            width: taskView
                              ? "100%"
                              : expandViewFirst
                              ? notesExpandedView
                                ? "190px"
                                : "310px"
                              : leadView
                              ? "100%"
                              : "485px",
                            marginRight: "2.3rem",
                          },
                        }}
                        value={manageHeader}
                        onChange={(e) => {
                          setTileNameChange(e.target.value);
                          setManageHeader(e.target.value);
                        }}
                        onBlur={() => {
                          setTileNameChange(tileNameChange.trim());
                          setManageHeader(tileNameChange.trim());
                          setHandleTextBox(true);
                          handleApiCall(tileNameChange.trim());
                        }}
                      />
                    )}
                  </Box>
                  <ClickAwayListener
                    onClickAway={() => {
                      setFieldDropDown(false);
                      setFieldAddDropDown(false);
                    }}
                  >
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      position={"relative"}
                      // width={ notesExpandedView?"9rem":   '18rem'}
                      // marginRight={"1.2rem"}
                    >
                      <Box
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        position={"relative"}
                        gap={"6px"}
                      >
                        {/*  */}

                        {allTiles.map((data) => {
                          if (
                            tableName === "Lead" &&
                            data?.labelText === "All"
                          ) {
                            return (
                              <>
                                <ConvertLeadButton
                                  onClick={(event) => {
                                    const value = event.target.innerText;
                                    localStorage.setItem(
                                      "manageHeader",
                                      value === "Grid View" ? "Grid" : value
                                    );
                                    setNewTile(null);
                                    setManageHeader(
                                      value === "Grid View" ? "Grid" : value
                                    );
                                    handleLeadView(event);
                                    handleLeadViewPopOver(event);
                                  }}
                                  sx={
                                    "Convert Lead" === manageHeader && {
                                      width: taskView ? "100px" : "131px",
                                      background: "#d7d4d4",
                                      ".MuiBox-root": {
                                        flexGrow: 0,
                                      },
                                    }
                                  }
                                >
                                  <Add
                                    sx={{
                                      fontSize: "14px",
                                      ".MuiSvgIcon-root": {
                                        fontSize: "14px",
                                      },
                                    }}
                                  />
                                  {"Convert Lead "}
                                </ConvertLeadButton>

                                <LeadViewPopOver
                                  id={id}
                                  open={leadViewOpen}
                                  anchorEl={leadAnchorEl}
                                  onClose={handleLeadViewClose}
                                  anchorOrigin={{
                                    vertical: "center",
                                    horizontal: "center",
                                  }}
                                  transformOrigin={{
                                    vertical: "center",
                                    horizontal: "center",
                                  }}
                                >
                                  <LeadView
                                    handleLeadViewClose={() => {
                                      handleLeadViewClose();
                                    }}
                                    handleConvertLeadClick={(data) => {
                                      setSuccessLeadData(data);
                                      setConvertLead(true);
                                      handleConvertLeadViewClick();
                                    }}
                                    data={convertLeadData}
                                    leadId={rowId}
                                    leadViewStatus={convertLeadStatus}
                                  />
                                </LeadViewPopOver>
                                {convertLead && (
                                  <ConvertLeadViewPopOver
                                    open={convertLeadViewOpen}
                                    id={convertLeadViewId}
                                    anchorEl={convertLeadViewAnchorEl}
                                    onClose={() => handleConvertLeadViewClose()}
                                    anchorOrigin={{
                                      vertical: "center",
                                      horizontal: "center",
                                    }}
                                    transformOrigin={{
                                      vertical: "center",
                                      horizontal: "center",
                                    }}
                                  >
                                    <ConvertLeadView
                                      data={successLeadData}
                                      setConvertLead={() => {
                                        setConvertLead(false);
                                        handleConvertLeadViewClose();
                                      }}
                                      goToLeadTable={() => {
                                        setConvertLead(false);
                                        handleConvertLeadViewClose();
                                        handleLeadViewClose();
                                        setTilesData(null);
                                        expandView();
                                        localStorage.setItem(
                                          `${tableName}TilesApiData`,
                                          null
                                        );
                                      }}
                                      onUpdateSuccess={onUpdateSuccess}
                                    />
                                  </ConvertLeadViewPopOver>
                                )}
                              </>
                            );
                          }
                        })}

                        {/*  */}
                        <SalesForceButton
                          // sx={{
                          //   minWidth: notesExpandedView
                          //     ? "max-content"
                          //     : "95px",
                          // }}
                          onClick={() => {
                            window.open(rowData.salseforce_ui_url, "_blank");
                          }}
                        >
                          <SalesForceLinkSVG color="#032E61" />

                          <SalesforceTypoGraphy>
                            View in SF
                          </SalesforceTypoGraphy>
                        </SalesForceButton>
                        <Tooltip
                          className="iconHover"
                          PopperProps={{
                            sx: {
                              "& .MuiTooltip-tooltip": {
                                width: "auto",
                                height: "16px",
                                background: "rgba(3, 46, 97, 0.72)",
                                borderRadius: "4px",
                                fontFamily: "Inter",
                                textAlign: "center",
                                fontSize: "12px",
                              },
                            },
                          }}
                          placement="bottom"
                          title={"Manage View and Fields"}
                        >
                          <Box
                            onClick={() => {
                              if (!fieldDropDown && !fieldAddDropDown) {
                                setFieldDropDown(true);
                              } else {
                                setFieldDropDown(false);
                              }
                              setFieldAddDropDown(false);
                            }}
                            // paddingRight={"0.8rem"}
                          >
                            <SearchSettingSVG
                              cursor={"pointer"}
                              height={"14px"}
                              width={"14px"}
                            />
                          </Box>
                        </Tooltip>
                      </Box>

                      {/* <Box>
                        <SearchUserSVG cursor={"pointer"} />
                      </Box> */}
                      {fieldDropDown ? (
                        <Box
                          width={"max-content"}
                          display={"flex"}
                          flexDirection={"column"}
                          position={"absolute"}
                          top={"37px"}
                          left={"-70px"}
                          border={"1px solid #E6E6E6"}
                          boxShadow={
                            "12px 8px 24px rgba(0, 0, 0, 0.06), inset -2px -2px 4px rgba(0, 0, 0, 0.06)"
                          }
                          borderRadius={"8px"}
                          sx={{
                            background: "#ffffff",
                          }}
                          zIndex={1}
                        >
                          {fields.map((value) => {
                            if (value === "divider") return <Divider />;
                            else
                              return (
                                <Box
                                  height={"33px"}
                                  padding={"0rem 1.5rem"}
                                  display={"flex"}
                                  alignItems={"center"}
                                  gap={"10px"}
                                  sx={{
                                    "&:hover": {
                                      // background: "#F2F7FC",
                                      backgroundColor: "#F2F7FC",

                                      color: "#00A1E0",
                                      cursor: "pointer",
                                    },
                                  }}
                                  onClick={(e) => {
                                    if (value === "Rename") {
                                      setNewOrRenameTile(true);
                                      setFieldDropDown(false);
                                      setHandleTextBox(false);
                                      localStorage.setItem("ApiCall", value);
                                    }
                                    if (value === "Delete") {
                                      localStorage.setItem("ApiCall", value);
                                      handleApiCall();
                                      setFieldDropDown(false);
                                    }
                                    if (value === "Duplicate") {
                                      setNewOrRenameTile(true);
                                      setFieldDropDown(false);
                                      setHandleTextBox(false);
                                      localStorage.setItem("ApiCall", value);
                                      handleDuplicateTiles();
                                    }
                                    if (value === "Fields & Related List") {
                                      setFieldDropDown(false);
                                      setFieldAddDropDown(true);
                                    }
                                  }}
                                >
                                  {value}
                                  {value === "Fields & Related List" && (
                                    <ArrowForwardSVG />
                                  )}
                                </Box>
                              );
                          })}
                        </Box>
                      ) : fieldAddDropDown ? (
                        <FilterColumn
                          header={
                            (currentTileHeader
                              ? currentTileHeader
                              : headerOrActiveHeader(false, true)) ??
                            handleTileChangeHeaderOrActiveHeader(true)
                          }
                          activeHeader={
                            (currentTileHeader
                              ? currentTileHeader.filter(
                                  (data) => data.isVisible || data.selected
                                )
                              : headerOrActiveHeader(true, true)) ??
                            handleTileChangeHeaderOrActiveHeader(false)
                          }
                          // filterColumns={(header, data) => {
                          //   filterColumns(header, data);
                          // }}
                          filterColumns={(
                            header,
                            data,
                            dragHandleIndividualTableHeader,
                            currentActiveRelatedTable,
                            subFields,
                            bothHeaders
                          ) => {
                            filterColumns(
                              header,
                              data,
                              dragHandleIndividualTableHeader,
                              currentActiveRelatedTable,
                              subFields,
                              bothHeaders
                            );
                          }}
                          tableName={tableName}
                          // addOrRemoveFieldInColumn={(field, headerFlag) => {
                          //   handleAddOrRemoveExpandColumnField(
                          //     field,
                          //     headerFlag,
                          //     tableName
                          //   );
                          // }}
                          addOrRemoveFieldInColumn={(
                            field,
                            headerFlag,
                            parentFieldName,
                            child_field
                          ) => {
                            handleAddOrRemoveExpandColumnField(
                              field,
                              headerFlag,
                              tableName,
                              null,
                              null,
                              headerFlag === "handleRelated" ? false : true,
                              parentFieldName,
                              child_field
                            );
                          }}
                          rowId={rowId}
                          iconChange={false}
                          allTilesDropDown={true}
                          // HandleExpandViewHeader={true}
                          expandViewRelated={true}
                          handleExpandViewTileChange={true}
                          relatedFields={handleRelatedTableHeader()}
                          relatedTableLoader={() =>
                            setRelatedTableLoader(!relatedTableLoader)
                          }
                          subFields={subFields}
                          setTilesData={setTilesData}
                          tilesData={tilesData}
                        />
                      ) : (
                        <></>
                      )}
                    </Box>
                  </ClickAwayListener>
                </Box>

                {!currentTileHeader?.length ? (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexDirection={"column"}
                    height={taskView && "300px"}
                    width={"100%"}
                    marginTop={taskView ? "0rem" : "10rem"}
                    gap={"10px"}
                  >
                    <TilesCreationSVG />
                    <TypoGraphy marginBottom={"1rem"}>
                      You just created a new personal tile.
                    </TypoGraphy>
                    <TypoGraphy textAlign={"center"}>
                      A tile is an efficient way to quickly access certain
                      fields that you utilize Click on the gear icon to get
                      started
                    </TypoGraphy>
                  </Box>
                ) : (
                  <ScrollableBox
                    style={
                      !taskView
                        ? {
                            // width: "500px",
                            padding: "0rem 0.75rem 1rem 0rem",
                            height: notesExpandedView ? "480px" : "440px",
                          }
                        : {
                            padding: taskView
                              ? "2rem"
                              : "0rem 0.75rem 0.75rem 0rem",
                            height: "620px",
                          }
                    }
                  >
                    {!tileChangeLoader ? (
                      <RenderComponents
                        row={handleRowData([rowData])?.[0] ?? []}
                        activeColumnHeader={
                          currentTileHeader
                            ? currentActiveTileHeader ??
                              currentTileHeader.filter(
                                (data) => data.isVisible ?? data.selected
                              )
                            : headerOrActiveHeader(true)
                        }
                        rowId={rowId}
                        tableName={tableName}
                        onUpdateSuccessOfRow={onUpdateSuccessOfRow}
                        rowData={handleRowData([rowData])?.[0] ?? []}
                        setRowData={setRowData}
                        searchView={"Tiles"}
                        handleError={null}
                        error={null}
                        name={null}
                        SearchTableData={null}
                        onHoverHeader={onHoverHeader}
                        setOnHoverHeader={setOnHoverHeader}
                        handleAddOrRemoveExpandColumnField={
                          handleAddOrRemoveExpandColumnField
                        }
                        expandViewTableFieldHeader={expandViewTableFieldHeader}
                        setExpandViewTableFieldHeader={
                          setExpandViewTableFieldHeader
                        }
                        relatedTableLoader={relatedTableLoader}
                        setRelatedTableLoader={setRelatedTableLoader}
                        filterColumns={() => {}}
                        taskView={taskView}
                        leadView={leadView}
                        expandViewFirst={expandViewFirst}
                        tilesData={tilesData}
                      />
                    ) : (
                      !tileLoader && <Loader />
                    )}
                  </ScrollableBox>
                )}
              </MainDataBox>
            )}
          </MainDataBox>
        </MainBox>
      ) : (
        <Loader />
      )}
    </>
  );
}

export default OpportunityView;
