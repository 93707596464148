const asyncTable = (payload) => {
    return {
      type: "makeTableAsync",
      payload: payload,
    };
  };

  const getAsyncTable = {
    asyncTable,
  };
  
  export default getAsyncTable;
  