export const noteReducer = (stateNote, actionNote) => {
  switch (actionNote.type) {
    case "LOAD_ALL_NOTES":
      return {
        ...stateNote,
        allNotes: actionNote.payload,
      };

    case "LOAD_NOTE":
      return { ...stateNote, singleNote: actionNote.payload };

    case "REARRANBE_NOTES":
      return { ...stateNote, allNotes: actionNote.payload };

    case "LOAD_TRASH_NOTES":
      return { ...stateNote, trashNotes: actionNote.payload };

    case "SET_NOTE":
      const newAllNote = stateNote.allNotes.map((item) =>
        item.id === actionNote.payload.root_note.id
          ? actionNote.payload.root_note
          : item
      );

      let newTemplates;
      if (actionNote.payload.template) {
        newTemplates = actionNote.payload.template;
      } else {
        newTemplates = stateNote.templates;
      }

      return {
        ...stateNote,
        singleNote: actionNote.payload.updated_note,
        allNotes: newAllNote,
        templates: newTemplates,
      };

    case "LOAD_NOTE_WITH_NEW_NOTE":
      return {
        ...stateNote,
        singleNote: actionNote.payload.created_note,
        allNotes: actionNote.payload.root_note,
      };

    case "TRASH_NOTE":
      let tempBool;
      if (actionNote.payload.root_note)
        tempBool = stateNote.allNotes.find((item) => {
          return item?.id === actionNote.payload?.trashed_note?.id;
        });

      // check if parent is deleted wihtout child
      const tempAllNotes = actionNote.payload.root_note
        ? // chekc if parent is deleted with child
          tempBool
          ? // if parent is deleted make its child as the parent notes
            // stateNote.allNotes.map((item) =>
            //   item.id === tempBool.id ? actionNote.payload.root_note : item
            // )
            stateNote.allNotes.filter((item) => item.id !== tempBool.id)
          : // if child is deleted update the parent with new parent without child
            stateNote.allNotes.map((item) =>
              item?.id === actionNote.payload?.root_note?.id
                ? actionNote.payload.root_note
                : item
            )
        : // if parent is deleted without child remove parent
          stateNote.allNotes.filter(
            (item) => item.id !== actionNote.payload.trashed_note.id
          );

      let NewtempAllNotes;
      if (tempBool) {
        NewtempAllNotes = [...tempAllNotes, ...actionNote.payload.root_note];
      } else {
        NewtempAllNotes = tempAllNotes;
      }

      return {
        ...stateNote,
        allNotes: NewtempAllNotes,
        singleNote: actionNote.payload.trashed_note,
        trashNotes: [...stateNote.trashNotes, actionNote.payload.trashed_note],
      };

    case "RESTORE_NOTE":
      const restoredAllNotes =
        actionNote.payload.root_note !== null
          ? stateNote.allNotes.map((item) =>
              item.id === actionNote.payload?.root_note?.id
                ? actionNote.payload.root_note
                : item
            )
          : [...stateNote.allNotes, actionNote.payload.restored_note];

      const newTrash = stateNote.trashNotes.filter(
        (item) => item.id !== actionNote.payload.restored_note.id
      );

      return {
        ...stateNote,
        allNotes: restoredAllNotes,
        singleNote: actionNote.payload.restored_note,
        trashNotes: newTrash,
      };

    case "DELETE_NOTE":
      return {
        ...stateNote,
        singleNote: stateNote.allNotes[0],
        trashNotes: actionNote.payload,
      };

    case "ADD_NOTE":
      const allNotes = [...stateNote.allNotes, actionNote.payload.root_note];

      return {
        ...stateNote,
        singleNote: actionNote.payload.created_note,
        allNotes,
      };

    case "ADD_SHARE_NOTE":
      const allShareNotes = [...stateNote.allNotes, actionNote.payload];

      return {
        ...stateNote,
        singleNote: actionNote.payload,
        allNotes: allShareNotes,
      };

    case "ADD_CHILD_NOTE":
      const newAllNotesWithChild = stateNote.allNotes.map((item) =>
        item.id === actionNote.payload.root_note.id
          ? actionNote.payload.root_note
          : item
      );

      return {
        ...stateNote,
        allNotes: newAllNotesWithChild,
        singleNote: actionNote.payload.created_note,
      };

    case "LOAD_TEMPLATES":
      return { ...stateNote, templates: actionNote.payload };

    case "LOAD_ALL_NOTES_WITH_SINGLE_NOTE":
      return {
        ...stateNote,
        allNotes: actionNote.payload,
        singleNote: actionNote.payload[0],
      };

    case "SET_SALESFORCE_OBJECT":
      return {
        ...stateNote,
        salesforceObject: {
          salesforceId: actionNote.payload.salesforceId,
          type: actionNote.payload.type,
        },
      };

    default:
      return stateNote;
  }
};
