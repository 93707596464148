import { Divider } from "@mui/material";
import React from "react";
import { SideBarSubContentBox, SideBarSubContentHeader } from "../SettingView";

function NotificationsMe() {
  return (
    <SideBarSubContentBox>
      <SideBarSubContentHeader>Notifications</SideBarSubContentHeader>
      <p style={{ color: "#6A727A", margin: "2px 0px" }}>
        Manage your preferences
      </p>

      <Divider
        sx={{
          marginTop: "1rem",
        }}
      ></Divider>
    </SideBarSubContentBox>
  );
}

export default NotificationsMe;
