import React, { useEffect, useState } from "react";

import styled from "@emotion/styled";
import SearchResult from "./SearchResult";
import { editNote, searchSalesForce } from "../../Pages/Services/notesApi";
import { useNotes } from "../../Context/NotesContext";

const NotesInputContainer = styled.div({
  position: "relative",
});

const NotesInputMain = styled.div({
  display: "flex",
  alignItems: "center",

  flexWrap: "wrap",
  minHeight: "40px",
  width: "21rem",
  padding: "0 8px",
  border: "1px solid rgb(214, 216, 218)",
  backgroundColor: "#FAFAFA",
  borderRadius: "8px",
  "&:focus-within": {
    border: "1px solid #0052cc",
  },
  "& input": {
    flex: "1",
    border: "none",
    height: "36px",
    width: "15rem",
    fontSize: "14px",
    padding: " 4px 0 0 0",
    "&:focus": {
      outline: "transparent",
    },
    backgroundColor: "#FAFAFA",
  },
});

const ListContainer = styled.ul({
  display: "flex",
  flexWrap: "wrap",
  padding: "0",
  margin: "8px 0 0 0",
});

const ListItem = styled.li({
  width: "auto",
  height: "32px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#696A6B",
  padding: "0 8px",
  fontSize: "14px",
  listStyle: "none",
  borderRadius: "8px",
  margin: " 0 8px 8px 0",
  background: "white",
  "& .tag-title": {
    marginTop: "3px",
  },
  "& .tag-close-icon": {
    display: "block",
    width: "16px",
    height: "16px",
    lineHeight: "16px",
    textAlign: "center",
    fontSize: "14px",
    marginLeft: " 8px",
    color: "#D2D2D2",
    borderRadius: "50%",
    background: "#fff",
    cursor: "pointer",
  },
});

function NotesInput({
  selectedTags,
  setSelectedTage,
  setShowSearchInput,
  setShowNotesExtended,
  syncNoteHandler,
  unsyncNoteHandler,
}) {
  const { stateNotes, dispatchNotes } = useNotes();

  const { singleNote } = stateNotes;

  const removeTags = (indexToRemove) => {
    setSelectedTage([
      ...selectedTags.filter((_, index) => index !== indexToRemove),
    ]);
  };
  const addTags = (event) => {
    setSelectedTage([...selectedTags, event]);
  };

  const [salesforceData, setSalesforceData] = useState({});

  const [searchText, setSearchText] = useState("");

  const NotesSearchHandler = (searchText) => {
    if (searchText.length >= 2) {
      searchSalesForce(setSalesforceData, searchText);
    }
    setSearchText(searchText);
    setSalesforceData({});
  };

  useEffect(() => {
    const newSingleNote = {
      ...singleNote,
      salesforce_array: selectedTags,
      // salesforce_array: [newValue],
    };
    editNote(dispatchNotes, newSingleNote);
  }, [selectedTags]);

  return (
    <NotesInputContainer>
      <NotesInputMain>
        <ListContainer>
          {selectedTags.map((tags, index) => (
            <ListItem key={index} className="tag">
              <span className="tag-title">{tags.name}</span>
              <span
                className="tag-close-icon"
                onClick={() => {
                  removeTags(index);
                  unsyncNoteHandler();
                }}
              >
                x
              </span>
            </ListItem>
          ))}
        </ListContainer>
        {selectedTags?.length < 1 && (
          <input
            type="text"
            value={searchText}
            onChange={(e) => NotesSearchHandler(e.target.value)}
            placeholder="Search Salesforce"
            autoFocus
          />
        )}
      </NotesInputMain>

      {selectedTags?.length < 1 && (
        <SearchResult
          addTags={addTags}
          setShowSearchInput={setShowSearchInput}
          setShowNotesExtended={setShowNotesExtended}
          salesforceData={salesforceData}
          setSearchText={setSearchText}
          searchText={searchText}
          syncNoteHandler={syncNoteHandler}
        />
      )}
    </NotesInputContainer>
  );
}

export default NotesInput;
