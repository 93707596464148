import { configureStore } from "@reduxjs/toolkit";
// import { setupListeners } from "@reduxjs/toolkit/query";
// import { getData } from "./Pages/Services/tableApi";
import rootReducer from "./redux/reducers/reducers";

// export const store = configureStore({
//   reducer: {
//     [getData.reducerPath]: getData.reducer,
//   },
//   middleware: (getDefaultMiddiware) =>
//     getDefaultMiddiware().concat(getData.middleware),
// });

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ immutableCheck: false, serializableCheck: false }),
});

// setupListeners(store.dispatch);
