import {
  Box,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  FormGroup,
  Stack,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { SearchText } from "./FilterOpportunity/SimpleFilterDropDown";
import { DropDown, DropDownOption } from "./SimpleDropDown";
import { ReactComponent as SearchSVG } from "../Assets/SVG/Search.svg";
import { CloseRounded } from "@mui/icons-material";
import { Item } from "./Table/ViewCell";

function MultiSelectDropDown({
  value,
  options,
  onChange,
  handleUpdate,
  setEditMode,
  expandViewCell,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [dropDownOption, setDropDownOption] = useState([]);
  const [selValue, setSelValue] = useState("");
  const [search, setSearch] = useState("");

  const handleMultipleSelect = (e, option) => {
    if (value && value.length) {
      !value.includes(option)
        ? onChange(value.concat(`;${option}`), true)
        : onChange(
            value
              .split(";")
              .filter((val) => val !== option)
              .join(";"),
            true
          );
      setSelValue(
        !value.includes(option)
          ? value.concat(`;${option}`)
          : value
              .split(";")
              .filter((val) => val !== option)
              .join(";")
      );
    } else {
      onChange(option, true);
      expandViewCell && setSelValue(option);
    }
  };
  useEffect(() => {
    if (options) {
      setDropDownOption(options);
    }
  }, [options]);
  useEffect(() => {
    let dropDown = [...options];
    if (search?.length) {
      dropDown = dropDown.filter((data) => {
        if (data.toLowerCase().includes(search.toLowerCase())) return true;
      });
    }
    setDropDownOption(dropDown);
  }, [selValue, search]);

  useEffect(() => {
    setAnchorEl(document.getElementById("dropdown"));
  }, []);

  const open = Boolean(anchorEl);

  return (
    <Box>
      <Box
        direction="row"
        spacing={1}
        id="dropdown"
        aria-describedby={"simple-popover"}
        textOverflow={"ellipsis"}
        overflow={"hidden"}
        whiteSpace={"nowrap"}
        width={expandViewCell ? "max-content" : "100%"}
        marginTop={expandViewCell ? "0px" : "12px"}
      >
        {value ? (
          expandViewCell ? (
            <Box display={"flex"} gap={"10px"}>
              {value.split(";").map((data) => {
                return (
                  <Stack width={expandViewCell ? "max-content" : "100%"}>
                    <Item>
                      {data}
                      <CloseRounded
                        id={`${data}`}
                        sx={{ fontSize: "14px", cursor: "pointer" }}
                        onClick={(event) => {
                          const data =
                            event.currentTarget.id || event.target.id;
                          const handleValue = value.includes(data)
                            ? value
                                .split(";")
                                .filter((val) => val !== data)
                                .join(";")
                            : value.split(";").length > 0
                            ? value.concat(`;${data}`)
                            : data;
                          onChange(handleValue);
                          event.stopPropagation();
                        }}
                      />
                    </Item>
                  </Stack>
                );
              })}
            </Box>
          ) : (
            value.split(";").join(",")
          )
        ) : (
          "-"
        )}
      </Box>
      <DropDown
        open={open}
        id={"simple - popover"}
        anchorEl={anchorEl}
        onClose={() => {
          setEditMode(false);
          handleUpdate(value);
        }}
      >
        <>
          <Box display={"flex"} alignItems={"center"} padding={"0px 12px"}>
            <Box height={"17px"}>
              <SearchSVG />
            </Box>
            <SearchText
              size="normal"
              value={search}
              placeholder="Search"
              disableUnderline
              onChange={(e) => setSearch(e.target.value)}
              style={{
                background: "none",
              }}
              autoFocus
            />
            <Box height={"17px"}>
              {search && (
                <CloseRounded
                  color="none"
                  fontSize="16px"
                  onClick={() => {
                    search && setSearch("");
                  }}
                  sx={{
                    cursor: "pointer",
                  }}
                />
              )}
            </Box>
          </Box>
          <Box
            sx={{
              background: "#2356DF",
            }}
          >
            <Divider />
          </Box>
        </>

        {dropDownOption?.map((option, index) => (
          <DropDownOption
            onClick={(e) => {
              handleMultipleSelect(e, option);
            }}
            style={{
              padding: "12px 20px 8px 16px",
            }}
          >
            <Checkbox
              size="small"
              value={option}
              sx={{
                transform: "scale(0.8)",
                padding: 0,
              }}
              checked={value ? (value.includes(option) ? true : false) : false}
              onClick={(e) => handleMultipleSelect(e, option)}
            />
            {option}
          </DropDownOption>
        ))}
      </DropDown>
    </Box>
  );
}

export default MultiSelectDropDown;
