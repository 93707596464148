import React, { useContext, useEffect, useState } from "react";
import { BiCollapse, BiExpand } from "react-icons/bi";
import dummyData from "../../Assets/Dummy/response.json";
import { ReactComponent as GiftSVG } from "../../Assets/SVG/Gift.svg";
import LockIcon from "@mui/icons-material/Lock";
import { DataGridPro, GridOverlay } from "@mui/x-data-grid-pro";
import { Box, styled } from "@mui/system";
import moment from "moment";
import EditCell from "../../Components/Table/EditCell";
import EditPopup from "../../Components/Table/EditPopup";
import ViewCell from "../../Components/Table/ViewCell";
import Loader from "../../Components/Loader";
import { exitFullscreen, requestFullscreen } from "../../utils/index";
import { Add } from "@mui/icons-material";
import {
  Backdrop,
  Button,
  Checkbox,
  ClickAwayListener,
  FormControlLabel,
  FormGroup,
  Popover,
  Popper,
  Tooltip,
  Typography,
} from "@mui/material";
import FilterColumn from "../../Components/Table/FilterColumn";
import useComponentVisible from "../../Hooks/useComponentVisible";
import {
  getTableDataApi,
  getTableHeadersApi,
  handleAddFieldInTable,
  getKeyData,
  createOrUpdateKey,
} from "../Services/tableApi";
import { RowResizeHeader } from "../../Components/FilterOpportunity/FilterDropDownHeader";
import FilterOpportunity from "../../Components/Table/FilterOpportunity";
import { ReactComponent as ExpandSVG } from "../../Assets/SVG/Expand.svg";
import { ReactComponent as SalesForceLinkSVG } from "../../Assets/SVG/SalesForceLink.svg";
import { ReactComponent as OpportunitySVG } from "../../Assets/SVG/CreateOpportunity.svg";
import { ReactComponent as AccountSVG } from "../../Assets/SVG/CreateAccount.svg";
import { ReactComponent as ContactSVG } from "../../Assets/SVG/CreateContact.svg";
import { ReactComponent as TableLeadSVG } from "../../Assets/SVG/TableLead.svg";
import { ReactComponent as EventSVG } from "../../Assets/SVG/CreateEvent.svg";
import { ReactComponent as RefreshSVG } from "../../Assets/SVG/Refresh.svg";
//import { ReactComponent as RowSizeManipulateSVG } from "../../Assets/SVG/RowSizeManipulate.svg";
import { ReactComponent as CheckBoxSVG } from "../../Assets/SVG/CheckBox.svg";
import { ReactComponent as SelectedCheckBoxSVG } from "../../Assets/SVG/SelectedCheckbox.svg";
//import { ReactComponent as LoaderSVG } from "../../Assets/SVG/Loader.svg";
import OpportunityView from "../../Components/ExpandView/ExpandOpportunityView";

import { useSelector, useDispatch } from "react-redux";
import Actions from "../../redux/actions";
import SimpleFilterDropDown from "../../Components/FilterOpportunity/SimpleFilterDropDown";
import mainShortCutContext from "../../Context/Context";
import { WishBox } from "../../Components/Footer";
import WishView from "../../Components/FooterChildComponents/WishView";

const getLineClampForRowDensity = (rowSize) => {
  if (rowSize === 40) {
    return "2";
  } else if (rowSize === 60) {
    return "3";
  } else if (rowSize === 100) {
    return "4";
  } else if (rowSize === 140) {
    return "5";
  }
};

let value = "";
export const CustomDataGrid = styled(DataGridPro)({
  "&.MuiDataGrid-root": {
    scrollBehavior: "smooth",
    fontFamily: "Inter",
    "::-webkit-scrollbar": {
      width: 0,
      marginRight: "4px",
    },
    ".MuiDataGrid-row": {
      background: "none",
      fontWeight: 400,
    },
    ".MuiDataGrid-row:hover": {
      background: "none",
    },
    ".MuiDataGrid-withBorder": {
      wordBreak: "break-word",
      width: "100%",
      whiteSpace: "normal",
      ".MuiDataGrid-columnSeparator--sideRight": {
        "&:hover": {
          background: "blue",
          width: "4px",
        },
      },
    },
    ".MuiDataGrid-withBorder:focus": {
      outline: "2px solid #1976d2",
    },
    ".MuiDataGrid-columnSeparator--sideRight": {
      display: "none",
    },
    ".MuiDataGrid-cell": {
      wordBreak: "break-word",
      whiteSpace: "normal",
      overflow: "visible !important",
    },
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "#D7EBF7",
      fontWeight: 600,
      fontSize: "16px",
    },
  },
});

export const ExpandViewPopover = styled(Popover)({
  background: "rgba(0, 0, 0, 0.3)",
  ".MuiPaper-root": {
    borderRadius: "12px",
    boxShadow:
      "20px 28px 24px rgba(0, 0, 0, 0.1), inset -2px -2px 4px rgba(0, 0, 0, 0.06)",
    height: "604px",
    width: "683px",
    overflow: "hidden",
    // paddingBottom: "1rem",
  },
});

export const StyledActiveTableTab = styled(Button)({
  height: 42,
  fontSize: 14,
  color: "black",
  cursor: "pointer",
  padding: "12px 36px 12px 16px",
  marginRight: 0.5,
  borderRadius: "6px 6px 0px 0px",
  fontFamily: "Inter",
  position: "relative",
  overflow: "hidden",
  // boxShadow: "6px 0px 8px rgb(0 0 0 / 4%)",
  // gap: "8px",
  // background: "transparent",
  alignItems: "center",
  gap: "4px",

  "&::after": {
    content: `""`,
    position: "absolute",
    width: "100%",
    // background: "#ffffff",
    height: "calc(100% + 2px)",
    transform: "skew(10deg)",
    left: "-6px",
    borderRadius: "6px 6px 0 0",
    zIndex: -1,
    top: 0,
    boxShadow: "6px 0px 8px rgb(0 0 0 / 4%)",
  },
});

export const TabViewPopover = styled(Popover)({
  ".MuiPaper-root": {
    boxSizing: "border-box",
    boxShadow: "8px 18px 12px rgba(0, 0, 0, 0.05)",
    border: "1px solid #E9EEF0",
    borderRadius: "8px",
    minWidth: 158,
    position: "absolute",
    top: 25,
    zIndex: 1,
    marginLeft: "0.25rem",
    height: "173px",
  },
});

export const AddTabButton = styled(Button)({
  minWidth: 35,
  maxHeight: 34,
  // padding: 8,
  borderRadius: 8,
  backdropFilter: "blur(2px)",
  background: "#00A1E0",
  cursor: "pointer",
  "& .MuiSvgIcon-root": {
    color: "#fff",
    fontSize: "15px",
  },
  ":hover": {
    background: "#008ae0",
  },
});

const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  "& .ant-empty-img-1": {
    fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
  },
  "& .ant-empty-img-2": {
    fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
  },
  "& .ant-empty-img-3": {
    fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
  },
  "& .ant-empty-img-4": {
    fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
  },
  "& .ant-empty-img-5": {
    fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
    fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
  },
}));

const handleTableObj = {
  Opportunities: "Opportunity",
  Accounts: "Account",
  Contacts: "Contact",
  Leads: "Lead",
  Events: "Event",
};

export const handleTable = (activeTab) => {
  return handleTableObj[activeTab] || "Opportunity";
};

export const handleRowData = (rows) => {
  if (rows?.length) {
    let _rows = [...rows];

    _rows = rows.map((data) => {
      const arrayOfTableData = Object.keys(data).filter(
        (field) =>
          typeof data[field] === "object" &&
          data[field] &&
          field !== "attributes"
      );

      if (arrayOfTableData.length) {
        let customRowData = {};

        arrayOfTableData?.map((table, index) =>
          Object.keys(data[table]).map((field) => {
            const value = data[table];
            customRowData = {
              ...customRowData,
              [`${table}:${field}`]:
                field?.includes("Id") &&
                value[field.split("Id")[0]]?.hasOwnProperty("Name")
                  ? value[field.split("Id")[0]]["Name"]
                  : value[field],
            };
            return customRowData;
            // }
          })
        );
        return {
          ...data,
          ...customRowData,
        };
      } else {
        return data;
      }
    });
    return _rows;
  }
};

const EDIT_GRID_OPTIONS = ["Rename view", "Duplicate view", "Delete view"];

export const gridDefaultFields = {
  Opportunity: [
    "Id",
    "Name",
    "Description",
    "Amount",
    "CloseDate",
    "StageName",
    "NextStep",
    "LeadSource",
    "Type",
    "Probability",
    "ForecastCategory",
    "AccountId",
    "OwnerId",
  ],
  Lead: [
    "Id",
    "FirstName",
    "LastName",
    "Company",
    "Status",
    "Title",
    "Email",
    "Phone",
    "Website",
    "Industry",
    "LeadSource",
    "OwnerId",
  ],
  Contact: [
    "Id",
    "FirstName",
    "LastName",
    "AccountId",
    "Title",
    "Email",
    "MobilePhone",
    "LeadSource",
    "OwnerId",
  ],
  Event: [
    "Id",
    "Subject",
    "Description",
    "StartDateTime",
    "EndDateTime",
    "WhoId",
    "WhatId",
    "OwnerId",
  ],
  Account: [
    "Id",
    "Name",
    "Description",
    "Industry",
    "Website",
    "Phone",
    "OwnerId",
  ],
  Task: [
    "Id",
    "WhoId",
    "WhatId",
    "Subject",
    "Status",
    "ActivityDate",
    "OwnerId",
    "Description",
  ],
};

const ReachOutText = styled(Typography)({
  fontSize: "16px",
  fontWeight: 400,
  color: "#626161",
});

function Pipeline({ showOrHideFields }) {
  // const { rows, headers } = dummyData;
  const dispatch = useDispatch();
  const [tableHeaders, setTableHeaders] = useState([]);
  const [columns, setColumns] = useState([]);
  const [pageData, setPageData] = useState(null);
  const [activeTab, setActiveTab] = useState(
    localStorage.getItem("activeTab") || "Opportunities"
  ); // for setting the data as per tab click
  const [tab, setTab] = useState(
    JSON.parse(localStorage.getItem("getAllSelectedTab")) || ["Opportunities"]
  ); // Setting tab as per tabViewDropdown
  const [update, setUpdate] = useState(false);
  const [initialDataLoader, setInitialDataLoader] = useState(false);
  const [newDataLoader, setNewDataLoader] = useState(false);
  const [filterLoader, setFilterLoader] = useState(false);
  const [onHoverRowDataId, setOnHoverRowDataId] = useState("");
  const [relatedFields, setRelatedFields] = useState([]);
  const [cachingFilter, setCachingFilter] = useState(false);
  const [expandGridRecord, setExpandGridRecord] = useState(false);
  const [subFields, setSubFields] = useState({});
  const [previousScrollTop, setScrollTop] = useState(0);
  const [sort, setSort] = useState({ sort: "desc", field: "" });
  const [wishPopup, setWishPopup] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const { idFields } = useContext(mainShortCutContext);

  // this is used when record is created for table
  const createRecord = useSelector((state) => {
    return state.asyncTable.TableAsync;
  });

  const hasAccess = useSelector((state) => {
    return state.hasAccess;
  });

  const rowSizeObj = {
    Short: 40,
    Medium: 60,
    Tall: 100,
    "Extra Tall": 140,
  };

  const getRowSize = (key) => {
    return rowSizeObj[key] || 60;
  };

  const [rowSize, setRowSize] = useState(
    getRowSize(localStorage.getItem(`${activeTab}RowSizeIndicator`))
  );

  const [lockCSS, setLockCSS] = useState({
    left: 0,
    top: 0,
    showLock: false,
  });
  const [popCSS, setPopCSS] = useState({
    left: 0,
    top: 0,
  });
  const {
    isComponentVisible: tabViewDropdown,
    setIsComponentVisible: setTabViewDropdown,
    ref,
  } = useComponentVisible(false);

  const [expandAnchorEl, setExpandAnchorEl] = useState(null);
  const [sideBarState, setSideBarState] = useState(false);

  const handleExpandView = (event) => {
    setExpandAnchorEl(document.getElementById("root"));
  };
  const handleExpandViewClose = () => {
    localStorage.setItem(`${handleTable(activeTab)}TilesApiData`, null);
    localStorage.setItem("manageHeader", "Overview");
    setExpandAnchorEl(null);
  };

  const expandViewOpen = Boolean(expandAnchorEl);
  const id = expandViewOpen ? "simple-popover" : undefined;

  const allTab = ["Opportunities", "Accounts", "Contacts", "Leads", "Events"];

  const tableHeaderField = useSelector((state) => {
    if (handleTable(activeTab) === "Opportunity") {
      return state.tableField.opportunityFieldData;
    }
    if (handleTable(activeTab) === "Account") {
      return state.tableField.accountFieldData;
    }
    if (handleTable(activeTab) === "Lead") {
      return state.tableField.leadFieldData;
    }
    if (handleTable(activeTab) === "Contact") {
      return state.tableField.contactFieldData;
    }
    if (handleTable(activeTab) === "Event") {
      return state.tableField.eventFieldData;
    }
  });
  const tableRecordsData = useSelector((state) => {
    if (handleTable(activeTab) === "Opportunity") {
      return state.tableData.opportunityData;
    }
    if (handleTable(activeTab) === "Account") {
      return state.tableData.accountData;
    }
    if (handleTable(activeTab) === "Lead") {
      return state.tableData.leadData;
    }
    if (handleTable(activeTab) === "Contact") {
      return state.tableData.contactData;
    }
    if (handleTable(activeTab) === "Event") {
      return state.tableData.eventData;
    }
  });

  // Handling table data on field update
  useEffect(() => {
    if (update) {
      const tableName = handleTable(activeTab);
      const filterData = JSON.parse(localStorage.getItem("filterData"));
      getTableData(
        tableName,
        filterData?.length ? filterData : [],
        true,
        subFields
      );
    }
    // setTimeout(() => {
    //   [...document.getElementsByClassName("MuiDataGrid-cell")].forEach(
    //     (ele) => {
    //       ele.addEventListener("focus", focus, true);
    //     }
    //   );
    // }, 1000);
  }, [update]);

  //--- On Caching Getting Filter Of Table ---//
  const getInitialFilter = async (tableName) => {
    const JSONdataParams = JSON.stringify({
      key: `${tableName}Filter`,
    });
    const response = await getKeyData(JSONdataParams);
    if (response.data.status === 200) {
      const Opportunity = response.data.value && response.data.value[0];
      localStorage.setItem(
        `checkOpportunity`,
        Opportunity.all_user ||
          Opportunity.only_user ||
          Opportunity.my_team_user
      );
      localStorage.setItem("filterData", JSON.stringify(response.data.value));
    }
    // else if (response.data.status === 404) {
    //   localStorage.setItem(`checkOpportunity`, `All ${tableName}`);
    //   localStorage.setItem(
    //     "filterData",
    //     JSON.stringify([
    //       {
    //         all_user: `All ${tableName}`,
    //       },
    //     ])
    //   );
    //   setCachingFilter(!cachingFilter);
    // }
  };

  // Handling table data on tab change
  useEffect(() => {
    if (activeTab) {
      setInitialDataLoader(true);
      const tableName = handleTable(activeTab);
      if (Object.keys(tableHeaderField)?.length) {
        setInitialDataLoader(false);
        // getInitialFilter(tableName);
        setCachingFilter(!cachingFilter);
        // filterColumns(tableRecordsData?.headers);
      } else {
        getTableHeader(tableName);
      }
    } else {
      filterColumns([]);
      // setPageData([]);
    }
    // getData();
  }, [activeTab]);

  useEffect(
    () => {
      //this is for reload the page after creating from searchbar
      const callBackFunction = async () => {
        const dataParams = {
          table_name: createRecord && createRecord[1],
          limit:
            tableRecordsData?.rows?.length || 0 < 20
              ? 20
              : tableRecordsData?.rows?.length,
          offset: 0,
        };
        const sortParams = {
          sort: getSortField(sort.field),
          dir: sort.sort,
        };
        const JSONdataParams = JSON.stringify(dataParams, sortParams);
        const table = await getTableDataApi(JSONdataParams);
        if (table.data.status === 200) {
          switch (createRecord && createRecord[1]) {
            case "Opportunity":
              dispatch(Actions.getTableData.opportunityTableData(table.data));
              break;
            case "Account":
              dispatch(Actions.getTableData.accountTableData(table.data));
              break;
            case "Lead":
              dispatch(Actions.getTableData.leadTableData(table.data));
              break;
            case "Contact":
              dispatch(Actions.getTableData.contactTableData(table.data));
              break;
            case "Event":
              dispatch(Actions.getTableData.eventTableData(table.data));
              break;
            default:
              break;
          }
          dispatch(
            Actions.getHasAccess.setHasNoAccess({
              table: activeTab,
              access: false,
            })
          );
        } else if (table.data.status === 400) {
          dispatch(
            Actions.getHasAccess.setHasAccess({
              table: activeTab,
              access: true,
            })
          );
        }
        dispatch(Actions.getAsyncTable.asyncTable([false, ""]));
      };
      if (createRecord && createRecord[0]) {
        callBackFunction();
      }
    },
    createRecord && createRecord ? [createRecord[0]] : []
  );

  useEffect(() => {
    if (tableRecordsData?.rows?.length) {
      setInitialDataLoader(true);
      const tableName = handleTable(activeTab);
      const filterData = JSON.parse(localStorage.getItem("filterData"));
      getTableData(
        tableName,
        filterData?.length ? filterData : [],
        "",
        subFields
      );
    }
  }, [showOrHideFields]);

  useEffect(() => {
    localStorage.setItem("activeTab", activeTab);
    localStorage.setItem("checkOpportunity", `All ${activeTab}`);
    localStorage.setItem("getAllSelectedTab", JSON.stringify(tab));
    localStorage.setItem("manageHeader", "Overview");
    localStorage.setItem("ApiCall", null);
    localStorage.setItem("OpportunityTilesApiData", null);
    localStorage.setItem("AccountTilesApiData", null);
    localStorage.setItem("ContactTilesApiData", null);
    localStorage.setItem("LeadTilesApiData", null);
    localStorage.setItem("EventTilesApiData", null);
    localStorage.setItem("SubFieldData", null);
  }, []);

  // let scroll = false;
  const getNewRowsOnScroll = (e) => {
    const newScrollData = true;
    setInitialDataLoader(false);
    setNewDataLoader(true);
    const filterData = JSON.parse(localStorage.getItem("filterData"));
    getTableData(
      handleTable(activeTab),
      filterData?.length ? filterData : [],
      newScrollData,
      subFields
    );
    // const { scrollTop, clientHeight, scrollHeight } = e.target;
    // if (!scrollTop) return;
    // if (previousScrollTop === scrollTop) return;
    // setScrollTop(scrollTop);

    // if (
    //   ((Math.trunc(scrollTop) + Math.trunc(clientHeight)) / scrollHeight) *
    //     100 >
    //     96 &&
    //   !scroll
    // ) {
    //   scroll = true;
    //   const newScrollData = true;
    //   setNewDataLoader(true);
    //   const filterData = JSON.parse(localStorage.getItem("filterData"));
    //   getTableData(
    //     handleTable(activeTab),
    //     filterData?.length ? filterData : [],
    //     newScrollData,
    //     subFields
    //   );
    // }
  };

  // New Rows Loader
  const BackdropLoader = () => {
    return (
      <div style={{ opacity: 0.4 }}>
        <Backdrop
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 10,
            position: "absolute",
          }}
          open={newDataLoader}
        >
          <Loader />
        </Backdrop>
      </div>
    );
  };

  const CustomLoadingOverlay = () => {
    return (
      <GridOverlay
        sx={{
          "&.MuiDataGrid-overlay": {
            backgroundColor: "#000",
            opacity: "0.25",
          },
        }}
      />
    );
  };

  // No Rows
  const CustomNoRowsOverlay = () => {
    return (
      <StyledGridOverlay>
        <svg
          width="120"
          height="100"
          viewBox="0 0 184 152"
          aria-hidden
          focusable="false"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(24 31.67)">
              <ellipse
                className="ant-empty-img-5"
                cx="67.797"
                cy="106.89"
                rx="67.797"
                ry="12.668"
              />
              <path
                className="ant-empty-img-1"
                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
              />
              <path
                className="ant-empty-img-2"
                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
              />
              <path
                className="ant-empty-img-3"
                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
              />
            </g>
            <path
              className="ant-empty-img-3"
              d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
            />
            <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
              <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
              <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
            </g>
          </g>
        </svg>
        <Box sx={{ mt: 1 }}>No Rows</Box>
      </StyledGridOverlay>
    );
  };

  const CustomUnAuthorizedOverlay = () => {
    return (
      <StyledGridOverlay>
        <svg
          width="120"
          height="100"
          viewBox="0 0 184 152"
          aria-hidden
          focusable="false"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(24 31.67)">
              <ellipse
                className="ant-empty-img-5"
                cx="67.797"
                cy="106.89"
                rx="67.797"
                ry="12.668"
              />
              <path
                className="ant-empty-img-1"
                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
              />
              <path
                className="ant-empty-img-2"
                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
              />
              <path
                className="ant-empty-img-3"
                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
              />
            </g>
            <path
              className="ant-empty-img-3"
              d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
            />
            <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
              <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
              <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
            </g>
          </g>
        </svg>
        <Box sx={{ mt: 1 }}>
          <Typography>
            Sign in with different Salesforce user to access data
          </Typography>
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={2}
            justifyContent={"center"}
          >
            <ReachOutText>
              Reach out to us with questions or feedback
            </ReachOutText>
            <GiftSVG
              stroke="#00A1E0"
              onClick={() => {
                setWishPopup(true);
                dispatch(Actions.toggleWishModal.wishModalOn(true));
              }}
              style={{
                cursor: "pointer",
              }}
            />
          </Box>
        </Box>
      </StyledGridOverlay>
    );
  };

  const getTableHeader = async (tableName) => {
    // debugger;
    const dataParams = {
      table_name: tableName,
    };
    const JSONdataParams = JSON.stringify(dataParams);
    const res = await getTableHeadersApi(JSONdataParams);
    if (res && res.data.status === 200) {
      setRelatedFields(res.data.related_sobjects);
      res.data.related_sobjects.length &&
        localStorage.setItem(
          `Expand${handleTable(activeTab)}RelatedList`,
          JSON.stringify(res.data.related_sobjects)
        );
      // localStorage.setItem(
      //   `ExpandActive${handleTable(activeTab)}RelatedList`,
      //   JSON.stringify(
      //     res.data.related_sobjects.length &&
      //       res.data.related_sobjects.filter((list) => list.selected !== false)
      //   )
      // );
      // setTableHeaders(res.data.headers);

      let tableHeaders = res.data;

      const getSubFieldsFromResponse = tableHeaders.headers.filter((data) => {
        if (data.parent_table_fields) {
          return data.parent_table_fields.filter((data) => data.selected);
        }
      });

      let _subFields = { ...subFields };

      getSubFieldsFromResponse.map(
        ({ metric_name, parent_table_fields }) =>
          (_subFields = { ..._subFields, [metric_name]: parent_table_fields })
      );

      const getKeyDataOfTable = await getKeyData(
        JSON.stringify({
          key: `ExpandGrid${handleTable(activeTab)}Headers`,
        })
      );

      // await getKeyData(
      //   JSON.stringify({
      //     key: `ExpandGrid${handleTable(activeTab)}Headers`,
      //   })
      // );

      // u can add the sub field here to tableHeaders
      const columns = Object.keys(_subFields)
        ?.map((field) =>
          _subFields[field]?.filter(
            ({ isVisible, selected }) => isVisible || selected
          )
        )
        .flat();

      const tableHeadersData = await getKeyDataOfTable;

      if (tableHeadersData.data.status === 404) {
        tableHeaders = res.data.headers.sort((a, b) => {
          if (a.selected === b.selected) return 0;
          if (a.selected > b.selected) return -1;
          return 1;
        });

        let sortedFields = gridDefaultFields[handleTable(activeTab)]
          .map((name) =>
            tableHeaders.find(
              (data) =>
                (data?.call_parent
                  ? `${data?.call_parent}.${data?.metric_name}`
                  : data?.metric_name) === name
            )
          )
          .filter((d) => d);

        if (sortedFields?.length) {
          tableHeaders.splice(0, sortedFields.length, sortedFields);
          tableHeaders = tableHeaders.flat();
        }

        tableHeaders = [...tableHeaders, ...columns];
        tableHeaders = tableHeaders.filter((d) =>
          d.call_parent ? d.selected : d
        );

        const fields = tableHeaders
          .filter((data) => data && data.selected)
          ?.map(({ call_parent, metric_name }) =>
            `${
              call_parent ? `${call_parent}.${metric_name}` : metric_name
            }`.trim()
          );
        await createOrUpdateKey(
          JSON.stringify({
            key: `ExpandGrid${handleTable(activeTab)}Headers`,
            value: fields,
          })
        );

        await createOrUpdateKey(
          JSON.stringify({
            key: `${handleTable(activeTab)}Headers`,
            value: fields,
          })
        );

        await createOrUpdateKey(
          JSON.stringify({
            key: `initial${handleTable(activeTab)}Headers`,
            value: fields,
          })
        );
      } else {
        const userStoredHeader = tableHeadersData.data.value;

        tableHeaders = [...res.data.headers, ...columns];

        // check the user has externally added the field or not (through salesforce)

        if (tableHeaders.length) {
          tableHeaders
            .filter((data) => data && data.selected)
            ?.map(({ call_parent, metric_name }) => {
              const field = `${
                call_parent ? `${call_parent}.${metric_name}` : metric_name
              }`.trim();
              if (!userStoredHeader.includes(field)) {
                userStoredHeader.push(field);
              }
            });
        }

        // const value = tableHeaders
        //   .filter((data) => data && data.selected)
        //   ?.map(({ call_parent, metric_name }) =>
        //     `${
        //       call_parent ? `${call_parent}.${metric_name}` : metric_name
        //     }`.trim()
        //   );

        // await createOrUpdateKey(
        //   JSON.stringify({
        //     key: "ExpandGridOpportunityHeaders",
        //     value: value,
        //   })
        // );

        tableHeaders.sort((a, b) => {
          if (a.selected === b.selected) return 0;
          if (a.selected > b.selected) return -1;
          return 1;
        });

        tableHeaders = tableHeaders
          .map((data) => {
            const flag = userStoredHeader.includes(
              data?.call_parent
                ? `${data?.call_parent}.${data?.metric_name}`
                : data?.metric_name
            );
            return {
              ...data,
              isVisible: flag ?? data.selected,
              selected: flag ?? data.selected,
            };
          })
          .filter((d) => (d.call_parent ? d.selected : d));

        // sub field manage here -> remains
        let sortedFields = userStoredHeader?.length
          ? userStoredHeader
              .map((name) =>
                tableHeaders.find(
                  (data) =>
                    (data?.call_parent
                      ? `${data?.call_parent}.${data?.metric_name}`
                      : data?.metric_name) === name
                )
              )
              .filter((d) => d)
          : [];

        tableHeaders.sort((a, b) => {
          if (a.selected === b.selected) return 0;
          if (a.selected > b.selected) return -1;
          return 1;
        });

        if (sortedFields?.length) {
          tableHeaders.splice(0, sortedFields.length, sortedFields);
          tableHeaders = tableHeaders.flat().filter((d) => d);
        }
      }

      // i can dispatch the headers with sub field so our headers is being easily managable
      switch (tableName) {
        case "Opportunity":
          dispatch(Actions.getTableField.opportunityTableField(tableHeaders));
          break;
        case "Account":
          dispatch(Actions.getTableField.accountTableField(tableHeaders));
          break;
        case "Lead":
          dispatch(Actions.getTableField.leadTableField(tableHeaders));
          break;
        case "Contact":
          dispatch(Actions.getTableField.contactTableField(tableHeaders));
          break;
        case "Event":
          dispatch(Actions.getTableField.eventTableField(tableHeaders));
          break;
        default:
          break;
      }
      // const getSubFieldsFromResponse = tableHeaders.filter((data) => {
      //   if (data.parent_table_fields) {
      //     return data.parent_table_fields.filter((data) => data.selected);
      //   }
      // });

      // let _subFields = { ...subFields };

      // getSubFieldsFromResponse.map(
      //   ({ metric_name, parent_table_fields }) =>
      //     (_subFields = { ..._subFields, [metric_name]: parent_table_fields })
      // );

      setSubFields(_subFields);
      // localStorage.setItem("AllSubField", JSON.stringify(_subFields));

      // const JSONdataParams = JSON.stringify({
      //   key: `${tableName}Filter`,
      // });
      // const response = await getKeyData(JSONdataParams);
      // if (response.data.status === 200) {
      //   localStorage.setItem("filterData", JSON.stringify(response.data.value));
      //   getTableData(tableName, response.data.value, "", _subFields);
      // } else if (response.data.status === 404) {
      //   localStorage.setItem("filterData", JSON.stringify([]));
      // }
      const filterData =
        localStorage.getItem("filterData") &&
        JSON.parse(localStorage.getItem("filterData"));
      getTableData(
        tableName,
        filterData?.length ? filterData : [],
        "",
        _subFields
      );
    }
  };

  const getSortField = (field) => {
    let columnToSort = {};
    // metric_name can be same for two column so differentiate them by checking if a column headers contains call_parent
    if (field.includes(":")) {
      field = field.split(":").map(function (value) {
        return value.trim();
      });
      columnToSort = tableRecordsData?.headers?.filter(
        (header) =>
          header?.metric_name === field[1] && header?.call_parent === field[0]
      )[0];
      field = field[1];
    } else {
      columnToSort = tableRecordsData?.headers?.filter(
        (header) => header?.metric_name === field
      )[0];
    }
    let columnName = "";
    if (
      columnToSort?.hasOwnProperty("call_parent") &&
      columnToSort?.hasOwnProperty("parentSobject") &&
      columnToSort?.parentSobject !== ""
    ) {
      columnName = `${columnToSort.call_parent}_${field}_${columnToSort.parentSobject}`;
    } else if (columnToSort?.hasOwnProperty("call_parent")) {
      columnName = `${columnToSort.call_parent}_${field}_xxx`;
    } else if (
      columnToSort?.hasOwnProperty("parentSobject") &&
      columnToSort?.parentSobject !== ""
    ) {
      columnName = `xxx_${field}_${columnToSort.parentSobject}`;
    } else if (field !== undefined && field !== "") {
      columnName = `xxx_${field}_xxx`;
    } else {
      columnName = "xxx_xxx_xxx";
    }
    return columnName;
  };

  const getTableData = async (
    tableName,
    filterData,
    newScrollData,
    allSubField
  ) => {
    const activeTable = {
      Opportunity: "Opportunities",
      Account: "Accounts",
      Lead: "Leads",
      Event: "Events",
      Contact: "Contacts",
    };
    const setInitialFilterOnTableChange = [
      {
        all_user: `All ${activeTable[tableName]}`,
      },
    ];
    if (filterData.length) {
      const Opportunity = filterData && filterData[0];
      localStorage.setItem(
        `checkOpportunity`,
        Opportunity.all_user ||
          Opportunity.only_user ||
          Opportunity.my_team_user
      );
      localStorage.setItem("filterData", JSON.stringify(filterData));
    } else {
      localStorage.setItem(`checkOpportunity`, `All ${activeTable[tableName]}`);
      localStorage.setItem(
        "filterData",
        JSON.stringify(setInitialFilterOnTableChange)
      );
    }
    setFilterLoader(true);
    const dataParams = {
      //filter: filterData.length ? filterData : setInitialFilterOnTableChange,
      table_name: tableName,
      limit: newScrollData ? tableRecordsData?.rows?.length + 10 : 20,
      offset: 0,
    };
    const sortParams = {
      sort: getSortField(sort.field),
      dir: sort.sort,
    };
    // const dataParams = {
    //   filter: filterData.length ? filterData : [],
    //   table_name: tableName,
    //   limit: newScrollData
    //     ? tableRecordsData?.rows?.length + 10
    //     : tableRecordsData?.rows?.length || 20,
    //   offset: 0,
    // };
    // if (newScrollData) {
    //   setLimit(tableRecordsData.rows.length + 10);
    // }
    const JSONdataParams = JSON.stringify(dataParams);
    const res = await getTableDataApi(JSONdataParams, sortParams);
    if (res.data.status === 200) {
      let tableData = res.data;
      // let tableHeaders = [];
      // u can add the sub field here to tableHeaders

      // await createOrUpdateKey(
      //   JSON.stringify({
      //     key: `ExpandGrid${handleTable(activeTab)}Headers`,
      //     value: tableData.headers.map((data) =>
      //       data?.call_parent
      //         ? `${data?.call_parent}.${data?.metric_name}`
      //         : data?.metric_name
      //     ),
      //   })
      // );

      const getKeyTableData = await getKeyData(
        JSON.stringify({
          key: `ExpandGrid${handleTable(activeTab)}Headers`,
        })
      );

      if (getKeyTableData?.data?.status === 200) {
        // sub field manage here -> remains
        const userStoredHeader = [...getKeyTableData.data.value];

        // check the user has externally added the field or not

        if (tableData.headers.length) {
          tableData.headers?.map(({ call_parent, metric_name }) => {
            const field = `${
              call_parent ? `${call_parent}.${metric_name}` : metric_name
            }`.trim();
            if (!userStoredHeader.includes(field)) {
              userStoredHeader.push(field);
            }
          });
        }

        // if (getKeyTableData.data.value.length !== userStoredHeader.length) {
        //   await createOrUpdateKey(
        //     JSON.stringify({
        //       key: `ExpandGrid${handleTable(activeTab)}Headers`,
        //       value: userStoredHeader,
        //     })
        //   );
        // }

        const tableHeaders = tableData.headers
          .map((data) => {
            const flag = userStoredHeader.includes(
              data?.call_parent
                ? `${data?.call_parent}.${data?.metric_name}`
                : data?.metric_name
            );
            return {
              ...data,
              isVisible: flag ?? data.selected,
              selected: flag ?? data.selected,
            };
          })
          .filter((d) => d);
        let sortedFields = userStoredHeader?.length
          ? userStoredHeader
              .map((name) =>
                tableHeaders.find(
                  (data) =>
                    (data?.call_parent
                      ? `${data?.call_parent}.${data?.metric_name}`
                      : data?.metric_name) === name
                )
              )
              .filter((d) => d && d.selected)
          : [];

        if (sortedFields?.length) {
          tableData = { ...tableData, headers: sortedFields };
        }
      }
      switch (tableName) {
        case "Opportunity":
          dispatch(Actions.getTableData.opportunityTableData(tableData));
          break;
        case "Account":
          dispatch(Actions.getTableData.accountTableData(tableData));
          break;
        case "Lead":
          dispatch(Actions.getTableData.leadTableData(tableData));
          break;
        case "Contact":
          dispatch(Actions.getTableData.contactTableData(tableData));
          break;
        case "Event":
          dispatch(Actions.getTableData.eventTableData(tableData));
          break;
        default:
          break;
      }
      // const sequentialHeader = JSON.parse(
      //   localStorage.getItem(`${handleTable(activeTab)}Headers`)
      // );
      // let columns = sequentialHeader
      //   ? sequentialHeader.filter((data) => data.isVisible || data.selected)
      //   : [];

      // const activeSubFields =
      //   localStorage.getItem(`ActiveSubFields`) &&
      //   JSON.parse(localStorage.getItem(`ActiveSubFields`));

      // if (allSubField?.length) {
      //   columns = Object.keys(allSubField)
      //     .map((field) =>
      //       allSubField[field]?.filter(
      //         ({ isVisible, selected }) => isVisible || selected
      //       )
      //     )
      //     .flat();
      // }

      // --- checkout this if in future it will create issue maybe
      // if (columns.length) {
      //   filterColumns(columns);
      //   console.log(columns);
      // } else {
      // filterColumns(tableData.headers, true);
      // }
      // setPageData(res.data);
      // setFilterLoader(false);
      // getInitialFilter(tableName);
      setUpdate(false);
      setFilterLoader(false);
      setInitialDataLoader(false);
      setNewDataLoader(false);
      dispatch(
        Actions.getHasAccess.setHasNoAccess({
          table: activeTab,
          access: false,
        })
      );
    } else if (res.data.status === 400) {
      dispatch(
        Actions.getHasAccess.setHasAccess({
          table: activeTab,
          access: true,
        })
      );
    }
    setFilterLoader(false);
    setInitialDataLoader(false);
    setNewDataLoader(false);
    // scroll = false;
  };

  const handleSetSubFields = (headers, selectedSubFieldData) => {
    let _subFields = { ...subFields };
    const localSelectedSubFieldData =
      localStorage.getItem("SubFieldData") &&
      JSON.parse(localStorage.getItem("SubFieldData"));
    _subFields = {
      ...subFields,
      [selectedSubFieldData.metric_name ??
      localSelectedSubFieldData.metric_name]: headers,
    };
    // _subFields = {
    //   ...subFields,
    //   [selectedSubFieldData.metric_name]: headers,
    // };
    setSubFields(_subFields);
  };

  //--- Table Records Based On Filter ---//
  const handleFilterData = async (dataParams) => {
    // if (dataParams.value.length) {
    //   setFilterLoader(true);
    // }
    // const data = dataParams.value
    const JSONdataParams = JSON.stringify(dataParams);
    const res = await createOrUpdateKey(JSONdataParams); // create_key_Api
    if (res.data.status === 200) {
      // key and value creation success
      getTableData(handleTable(activeTab), dataParams.value, "", subFields);
    }
  };
  //--------//

  const addFieldInTable = async (fields, headerFlag, parent, tileGridView) => {
    setFilterLoader(true);
    const tableName = handleTable(activeTab);
    const dataParams = JSON.stringify({
      field: fields,
      sobject: tableName,
      value: headerFlag,
      parent_sobject: parent ?? undefined,
    });
    const res = await handleAddFieldInTable(dataParams);
    if (res.data.status === 200) {
      const filterData = JSON.parse(localStorage.getItem("filterData"));
      getTableData(
        tableName,
        filterData?.length ? filterData : [],
        "",
        subFields
      );

      if (tileGridView && parent) {
        const d = Object.keys(subFields).filter(
          (subFieldData) => subFieldData === `${parent}Id`
        );

        let subFieldHeader = d && subFields[d];

        subFieldHeader = subFieldHeader.map((data) => {
          if (data.metric_name === fields) {
            return {
              ...data,
              selected: headerFlag === "False" ? false : true,
            };
          } else return data;
        });

        if (subFieldHeader?.length) {
          setSubFields({ ...subFields, [`${parent}Id`]: subFieldHeader });
        }
      }

      setExpandGridRecord(true);
    } else {
      setFilterLoader(false);
    }
  };

  const filterColumns = async (header, saveAll) => {
    // const getKeyTableData = await getKeyData(
    //   JSON.stringify({
    //     key: `ExpandGrid${handleTable(activeTab)}Headers`,
    //   })
    // );
    // let sortedFields = [];
    // if (getKeyTableData?.data?.status === 200) {
    //   // sub field manage here -> remains
    //   sortedFields = getKeyTableData?.data?.value?.length
    //     ? getKeyTableData.data.value
    //         .map((name) =>
    //           header.find(
    //             (data) =>
    //               (data?.call_parent
    //                 ? `${data?.call_parent}.${data?.metric_name}`
    //                 : data?.metric_name) === name
    //           )
    //         )
    //         .filter((d) => d)
    //     : [];
    // }

    let tempHeaders = [...header];

    let a = [];
    tempHeaders = tempHeaders
      ?.map((data) => {
        let name = data?.child_field
          ? `${data.child_field}.${data.metric_name}`
          : data.metric_name;
        if (!a.includes(name)) {
          a.push(name);
          return data;
        }
      })
      .filter((d) => d);

    a = [];
    // unique column only

    // unique column only
    const uniqueHeaders = [
      ...new Set(tempHeaders.filter((d) => d).map((o) => JSON.stringify(o))),
    ].map((s) => JSON.parse(s));

    let temp = [];
    temp = uniqueHeaders
      .map((rawData, index) => {
        if (!idFields && rawData.metric_name === "Id") return;
        return {
          field: rawData?.call_parent
            ? `${rawData.call_parent}:${rawData.metric_name}`
            : rawData.metric_name,
          headerName: (
            <span
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around",
              }}
            >
              {!rawData.editable && (
                <LockIcon
                  sx={{
                    fontSize: "17px",
                    color: "#9E9E9E",
                    marginRight: "0.6rem",
                  }}
                />
              )}
              {rawData?.call_parent
                ? `${rawData.call_parent.split("ID")[0]}:${
                    rawData.metric_display_name?.split("ID")[0]
                  }`
                : rawData.metric_display_name?.split("ID")[0]}
            </span>
          ),
          // tooltip === description
          description: rawData.metric_display_name?.split("ID")[0],
          // minWidth: 180,
          width:
            rawData.metric_datatype === "textarea"
              ? 300
              : rawData?.call_parent
              ? `${rawData.call_parent}:${rawData.metric_display_name}`.length *
                  15 >
                250
                ? `${rawData.call_parent}:${rawData.metric_display_name}`
                    .length * 12
                : 250
              : rawData.metric_datatype === "boolean" ||
                rawData.metric_datatype === "currency" ||
                rawData.metric_datatype === "percent"
              ? 150
              : rawData.metric_datatype === "datetime"
              ? 210
              : rawData.metric_datatype === "id"
              ? 185
              : rawData.metric_datatype === "reference"
              ? 220
              : rawData.metric_display_name?.length > 15
              ? 250
              : 160,
          // flex: 1,
          sortable: true,
          renderCell: (cellData) => renderCell(cellData, rawData),
        };
      })
      .filter((d) => d);

    // switch (handleTable(activeTab)) {
    //   case "Opportunity":
    //     dispatch(Actions.getTableField.opportunityTableField(header));
    //     break;
    //   case "Account":
    //     dispatch(Actions.getTableField.accountTableField(header));
    //     break;
    //   case "Lead":
    //     dispatch(Actions.getTableField.leadTableField(header));
    //     break;
    //   case "Contact":
    //     dispatch(Actions.getTableField.contactTableField(header));
    //     break;
    //   case "Event":
    //     dispatch(Actions.getTableField.eventTableField(header));
    //     break;
    //   default:
    //     break;
    // }

    // temp = !saveAll
    //   ? temp?.filter(({ field }) =>
    //       tableRecordsData?.headers
    //         ?.map(({ metric_name, child_field, sobject, call_parent }) =>
    //           (call_parent
    //             ? `${call_parent}:${metric_name}`
    //             : metric_name
    //           ).trim()
    //         )
    //         .includes(field)
    //     )
    //   : temp;
    // console.log(tableRecordsData.headers, saveAll);
    // if (!saveAll) {
    //   const alreadyHasOrNot = temp?.find(
    //     ({ field }) => temp[temp.length - 1]?.field === field
    //   );
    //   !alreadyHasOrNot && temp.push(temp[temp?.length - 1]);
    // }

    // const _tempCopy = [...temp];

    // temp = !saveAll
    //   ? temp.filter(({ field }) =>
    //       tableRecordsData?.headers
    //         ?.map(({ metric_name, child_field, sobject, call_parent }) =>
    //           call_parent ? `${call_parent}.${metric_name}` : metric_name
    //         )
    //         .includes(field)
    //     )
    //   : temp;
    // if (!saveAll) {
    //   const alreadyHasOrNot = _tempCopy.find(
    //     ({ field }) => _tempCopy[_tempCopy.length - 1].field === field
    //   );
    //   !alreadyHasOrNot && temp.push(_tempCopy[_tempCopy.length - 1]);
    // }

    const indexColumn = {
      field: " ",
      headerName: "Serial No.",
      description: " ",
      // minWidth: 180,
      width: 100,
      renderCell: (cellData) => renderCell(cellData),
    };
    temp.unshift(indexColumn);
    temp = temp.filter((data) => data);

    setColumns(temp);
  };

  const renderCell = (cellData, rawData, event) => {
    if (rawData) {
      if (rawData.editable) {
        // if (cellData.hasFocus) {
        //   setLockCSS({ ...lockCSS, showLock: false });
        // }
        if (cellData.field === "notes") {
          const props = { value: "", metadata: "" };
          if (cellData.value) {
            props.value = cellData.value?.msg;
            props.metadata = `
      ${cellData.value.sender_first_name} ${cellData.value.sender_last_name} ${
              cellData.value.created_at
                ? `${moment(cellData.value.created_at).format("h:mm a")}
        ${moment(cellData.value.created_at).format("D MMM YYYY")}`
                : ""
            }`;
          } else {
            props.value = "No Comment";
          }
          return (
            <EditPopup
              metaKey={cellData.field}
              opportunity_id={cellData.row.Opportunity_Id}
              {...props}
              type={rawData.metric_datatype}
              options={rawData.values}
            />
          );
        }
        return (
          <EditCell
            expandViewOpen={expandViewOpen}
            onUpdateSuccess={() => {
              setUpdate(true);
            }}
            rawData={rawData}
            cellData={cellData}
            tableName={handleTable(activeTab)}
            pipeline={true}
          />
        );
      }
      // focus();
      // if (cellData.hasFocus) {
      //   setTimeout(() => {
      //     console.log(document.getElementById("focused-none-editable-cell"));
      //     // focus(document.getElementById("focused-none-editable-cell"));
      //   }, []);
      // }
      return typeof cellData.value === "object" ? (
        ""
      ) : (
        <Box
          position="relative"
          width="100%"
          height="100%"
          display="flex"
          alignItems={"center"}
          id={cellData.hasFocus ? "focused-none-editable-cell" : ""}
        >
          <ViewCell
            value={
              rawData.metric_datatype === "reference" &&
              cellData.row[cellData.field] != null
                ? cellData?.row[
                    rawData.call_parent
                      ? `${rawData.call_parent}:${rawData.metric_name}`
                      : rawData.parentSobject
                  ]?.Name || cellData.value
                : cellData.value
            }
            cellData={cellData}
            dataType={rawData.metric_datatype}
          />
        </Box>
      );
    } else {
      if (cellData.field === " ") {
        return (
          <>
            {cellData?.row?.Id &&
            cellData?.api?.getRowIndexRelativeToVisibleRows(
              cellData?.row?.Id
            ) !== undefined ? (
              <p
                style={{
                  margin: "0rem 1rem",
                  color: "#798ea3",
                }}
              >
                {cellData?.row?.Id !== null &&
                cellData?.row?.Id !== undefined &&
                cellData?.row?.Id !== "" &&
                cellData?.api?.getRowIndexRelativeToVisibleRows !== undefined
                  ? cellData?.api?.getRowIndexRelativeToVisibleRows(
                      cellData?.row?.Id ?? 0
                    ) + 1
                  : ""}
              </p>
            ) : (
              ""
            )}
            <Box
              width="100%"
              display={cellData.id === value ? "flex" : "none"}
              alignItems={"center"}
              gap={"15px"}
              position={"relative"}
              id={`${cellData.id}`}
            >
              {[
                {
                  title: "Expand View",
                  svg: (
                    <ExpandSVG
                      aria-describedby={id}
                      onClick={handleExpandView}
                      cursor={"pointer"}
                    />
                  ),
                },
                {
                  title: "View In Salesforce",
                  svg: (
                    <SalesForceLinkSVG
                      onClick={() => {
                        window.open(cellData.row.salseforce_ui_url, "_blank");
                      }}
                      cursor={"pointer"}
                    />
                  ),
                },
              ].map((data, index) => {
                return (
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                      sx: {
                        "& .MuiTooltip-tooltip": {
                          height: "16px",
                          borderRadius: "8px",
                          fontFamily: "Inter",
                          textAlign: "center",
                        },
                      },
                    }}
                    placement="bottom-start"
                    title={data.title}
                    key={index}
                  >
                    <Box
                      sx={{
                        stroke: "#AFCADF",
                        ":hover": {
                          stroke: "#00A1E0",
                        },
                      }}
                    >
                      {data.svg}
                    </Box>
                  </Tooltip>
                );
              })}
            </Box>
          </>
        );
      }
    }
  };

  const handleTabCheckbox = (value) => {
    if (tab[0] !== value && tab.length >= 1) {
      !tab.includes(value)
        ? setTab([...tab, value])
        : setTab(tab.filter((tab) => tab !== value));

      localStorage.setItem(
        "getAllSelectedTab",
        JSON.stringify(
          !tab.includes(value)
            ? [...tab, value]
            : tab.filter((tab) => tab !== value)
        )
      );
    } else if (tab.length > 1) {
      setTab(tab.filter((tab) => tab !== value));
      localStorage.setItem(
        "getAllSelectedTab",
        JSON.stringify(tab.filter((tab) => tab !== value))
      );
    }
    setTabViewDropdown(false);
  };

  const handleDropdownActiveTab = (value) => {
    activeTab !== value
      ? setActiveTab(tab.includes(value) ? activeTab : value)
      : setActiveTab(tab[0] === activeTab && tab.length > 1 ? tab[1] : tab[0]);
    localStorage.setItem(
      "activeTab",
      activeTab !== value
        ? tab.includes(value)
          ? activeTab
          : value
        : tab[0] === activeTab && tab.length > 1
        ? tab[1]
        : tab[0]
    );
    const tabHandle =
      activeTab !== value
        ? tab.includes(value)
          ? activeTab
          : value
        : tab[0] === activeTab && tab.length > 1
        ? tab[1]
        : tab[0];
    localStorage.setItem(
      `${tabHandle}RowSizeIndicator`,
      localStorage.getItem(`${tabHandle}RowSizeIndicator`)
        ? localStorage.getItem(`${tabHandle}RowSizeIndicator`)
        : "Medium"
    );
    setRowSize(
      getRowSize(
        localStorage.getItem(`${tabHandle}RowSizeIndicator`)
          ? localStorage.getItem(`${tabHandle}RowSizeIndicator`)
          : "Medium"
      ) ?? 60
    );
    localStorage.setItem(
      `checkOpportunity`,
      activeTab !== value
        ? tab.includes(value)
          ? activeTab
          : value
        : tab[0] === activeTab && tab.length > 1
        ? tab[1]
        : tab[0]
    );
  };

  const handleActiveTab = (e) => {
    const tab = activeTab !== e.target.value ? e.target.value : activeTab;
    setActiveTab(tab);
    localStorage.setItem(
      `${tab}RowSizeIndicator`,
      localStorage.getItem(`${tab}RowSizeIndicator`)
        ? localStorage.getItem(`${tab}RowSizeIndicator`)
        : "Medium"
    );
    setRowSize(
      getRowSize(
        localStorage.getItem(`${tab}RowSizeIndicator`)
          ? localStorage.getItem(`${tab}RowSizeIndicator`)
          : "Medium"
      ) ?? 60
    );
    localStorage.setItem("activeTab", tab);
    localStorage.setItem("checkOpportunity", tab);
  };

  //---Row Hover Expand view---//
  const popperRef = React.useRef();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handlePopperOpen = (event) => {
    const id = event.currentTarget.dataset.id;
    if (!newDataLoader) {
      value = id;
      setAnchorEl(event.currentTarget);
      setOnHoverRowDataId(id);
    }
  };
  const handlePopperClose = (event) => {
    if (
      anchorEl == null ||
      popperRef.current.contains(event.nativeEvent.relatedTarget)
    ) {
      return;
    }
    setAnchorEl(null);
  };
  //---------------//

  //--- column field header handling for expand view (basedOnTable) ---//
  const ExpandOverviewHeader = async (headerOrActiveHeader) => {
    const headers = await getKeyData(
      JSON.stringify({
        key: `ExpandOverview${handleTable(activeTab)}Headers`,
      })
    );
    return !headerOrActiveHeader
      ? headers?.data?.value ?? tableHeaderField
      : headers?.data?.value.filter(
          (data) => data.isVisible || data.selected
        ) ?? tableHeaderField.filter((data) => data.isVisible || data.selected);
  };

  //-------//

  //--- tab Dropdown popover ---//
  const [tabAnchorEl, setTabAnchorEl] = React.useState(null);
  const handleTabView = (event) => {
    setTabAnchorEl(event.currentTarget);
  };
  const handleTabViewClose = () => {
    setTabAnchorEl(null);
  };
  const tabViewOpen = Boolean(tabAnchorEl);
  const tabId = tabViewOpen ? "simple-popover" : undefined;
  // ----- //

  const getSvgOfTable = (table) => {
    let svg;
    switch (table) {
      case "Opportunities":
        svg = <OpportunitySVG />;
        break;
      case "Accounts":
        svg = <AccountSVG />;
        break;
      case "Contacts":
        svg = <ContactSVG />;
        break;
      case "Leads":
        svg = <TableLeadSVG />;
        break;
      case "Events":
        svg = <EventSVG />;
        break;

      default:
        break;
    }
    return svg;
  };

  // console.log("hasAccess", hasAccess);
  // console.log("activeTab", activeTab);

  return (
    <>
      <Box
        style={{
          display: "flex",
          gap: "4px",
          alignItems: "center",
          position: "relative",
          // "&:not(:first-child)": {
          //   marginLeft: "-13px",
          // },
          // "&:last-child": {
          //   margin: 0,
          // },
        }}
      >
        {tab &&
          tab.map((tabData, index) => (
            <StyledActiveTableTab
              key={index}
              value={tabData}
              sx={{
                background: "transparent",
                "&::after": {
                  background:
                    tab[tab.length - 1] === activeTab
                      ? tab.length - 1 === index
                        ? "#fff"
                        : "#F5F5F5"
                      : activeTab === tab[0]
                      ? 0 === index
                        ? "#fff"
                        : "#F5F5F5"
                      : activeTab === tabData
                      ? "#fff"
                      : "#F5F5F5",
                },
                zIndex:
                  tab[tab.length - 1] === activeTab
                    ? tab.length - 1 === index
                      ? "1"
                      : "0"
                    : activeTab === tab[0]
                    ? 0 === index
                      ? "1"
                      : "0"
                    : activeTab === tabData
                    ? "1"
                    : "0",
                // "&:hover":
                //   tab[tab.length - 1] === activeTab
                //     ? tab.length - 1 === index
                //       ? {
                //           background: "#ffffff",
                //         }
                //       : {
                //           background: "rgba(255,255,255,0.9)",
                //         }
                //     : activeTab === tab[0]
                //     ? 0 === index
                //       ? {
                //           background: "#ffffff",
                //         }
                //       : {
                //           background: "rgba(255,255,255,0.9)",
                //         }
                //     : activeTab === tabData
                //     ? { background: "#ffffff" }
                //     : {
                //         background: "rgba(255,255,255,0.9)",
                //       },
                marginLeft: index !== 0 && "-12px",
              }}
              onClick={(e) => handleActiveTab(e)}
            >
              <Box paddingTop={"4px"}>{getSvgOfTable(tabData)}</Box>
              {tabData}
            </StyledActiveTableTab>
          ))}
        <AddTabButton
          onClick={() => {
            const { left, top } = document
              .getElementById("tab-view-dropdown")
              .getBoundingClientRect();
            setPopCSS({
              top: top,
              left: left,
            });
          }}
          onMouseDown={handleTabView}
          id="tab-view-dropdown"
        >
          <Add />
        </AddTabButton>
        <TabViewPopover
          id={tabId}
          open={tabViewOpen}
          anchorEl={tabAnchorEl}
          onClose={handleTabViewClose}
          role={"button"}
          tabIndex={0}
          sx={{
            ".MuiPaper-root": {
              left: popCSS.left - 5,
            },
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          ref={ref}
        >
          {allTab.map((tab_label, index) => (
            <FormGroup
              key={index}
              value={tab_label}
              sx={{ padding: "0px 12px" }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    value={tab_label}
                    icon={<CheckBoxSVG />}
                    checkedIcon={<SelectedCheckBoxSVG />}
                    onChange={(e) => {
                      handleTabCheckbox(e.target.value);
                      handleDropdownActiveTab(e.target.value);
                    }}
                    checked={
                      tab.length
                        ? tab.includes(tab_label)
                          ? true
                          : false
                        : false
                    }
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: 14,
                      },
                    }}
                    key={index}
                  />
                }
                label={tab_label}
                sx={{
                  ".MuiTypography-root": {
                    fontFamily: "Inter",
                    fontSize: "14px",
                    lineHeight: "17px",
                    color: "#444647",
                  },
                }}
              />
            </FormGroup>
          ))}
        </TabViewPopover>
      </Box>
      <Box
        padding={"12px"}
        borderRadius={"0px 5px 5px"}
        backgroundColor="white"
        height={"calc(100vh - 211px)"}
        // height={"725px"}
        width={"95%"}
      >
        {tableRecordsData?.headers?.length ? (
          <Box
            visibility={!initialDataLoader ? "visible" : "hidden"}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            paddingRight={"0.5rem"}
          >
            <Box display={"flex"} alignItems={"center"} gap={"8px"}>
              {/* <button
                onClick={() => {
                  setSideBarState(!sideBarState);
                }}
              >
                Grids
              </button> */}
              <FilterColumn
                header={tableHeaderField}
                activeHeader={tableRecordsData.headers}
                filterColumns={(header, save) => filterColumns(header, save)}
                tableName={handleTable(activeTab)}
                addOrRemoveFieldInColumn={(field, headerFlag, parent) =>
                  addFieldInTable(field, headerFlag, parent)
                }
                subFields={subFields}
                setSubFields={(headers, selectedSubFieldData) =>
                  handleSetSubFields(headers, selectedSubFieldData)
                }
              />
              <FilterOpportunity
                header={tableHeaderField}
                tableName={handleTable(activeTab)}
                filterRowData={(data) => {
                  handleFilterData(data);
                }}
                filter={cachingFilter}
                initialFilter={localStorage.getItem("filterData")}
                // setCachingFilter={() => setCachingFilter(false)}
              />
              <SimpleFilterDropDown
                header={RowResizeHeader("RowResize")}
                widthAdjustment={"RowResize"}
                handleData={(value) => {
                  localStorage.setItem(`${activeTab}RowSizeIndicator`, value);
                  setRowSize(
                    value === "Short"
                      ? 40
                      : value === "Medium"
                      ? 60
                      : value === "Tall"
                      ? 100
                      : value === "Extra Tall"
                      ? 140
                      : 60
                  );
                }}
                tableName={activeTab}
              />
              {/* <AddAndEditGridDropdown
                options={EDIT_GRID_OPTIONS}
                buttonText="..."
              /> */}
              {filterLoader ? <Loader /> : <></>}
            </Box>
            <Box display={"flex"} gap={2}>
              <Box
                sx={{
                  fontFamily: "Inter",
                  color: "#798EA3",
                  display: "flex",
                  gap: "8px",
                  cursor: "pointer",
                  alignItems: "center",

                  ":hover": {
                    color: "#00A1E0",
                    stroke: "#00A1E0",
                  },
                }}
                onClick={() => {
                  setInitialDataLoader(true);
                  const tableName = handleTable(activeTab);
                  const filterData = JSON.parse(
                    localStorage.getItem("filterData")
                  );
                  getTableData(
                    tableName,
                    filterData?.length ? filterData : [],
                    "",
                    subFields
                  );
                }}
              >
                <RefreshSVG />
                Refresh
              </Box>
              <Box
                sx={{
                  fontFamily: "Inter",
                  color: "#798EA3",
                  display: "flex",
                  gap: "8px",
                  cursor: "pointer",
                  alignItems: "center",

                  ":hover": {
                    color: "#00A1E0",
                    stroke: "#00A1E0",
                  },
                }}
                onClick={() => {
                  if (isFullscreen) {
                    exitFullscreen();
                    setIsFullscreen(false);
                  } else {
                    setIsFullscreen(true);
                    const myElement = document.getElementsByTagName("body")[0];
                    requestFullscreen(myElement);
                  }
                }}
              >
                {!isFullscreen ? (
                  <BiExpand size={20} />
                ) : (
                  <BiCollapse size={20} />
                )}
              </Box>
            </Box>
          </Box>
        ) : (
          <></>
        )}
        <Box
          // height={"calc(100vh - 375px)"}
          height={"calc(100vh - 260px)"}
          position="relative"
          width={"100%"}
          // sx={{ display: "flex" }}
        >
          {/* {sideBarState && (
            <GridSidebar
              tableName={activeTab}
              gridDefaultFields={gridDefaultFields}
            />
          )} */}
          <Popper
            ref={popperRef}
            open={open}
            anchorEl={anchorEl}
            placement={"left"}
            transition
            onMouseLeave={() => setAnchorEl(null)}
          />
          {wishPopup && (
            <ClickAwayListener
              onClickAway={() => {
                if (wishPopup) {
                  setWishPopup(false);
                  dispatch(Actions.toggleWishModal.wishModalOff(false));
                }
              }}
            >
              <WishBox
                sx={{
                  position: "absolute",
                  bottom: "-10.95%",
                  left: "-2.95%",
                }}
              >
                <WishView
                  onClose={() => setWishPopup(false)}
                  handleSuccessWish={() => {
                    console.log("WishPopup Clicked!");
                    setWishPopup(false);
                  }}
                />
              </WishBox>
            </ClickAwayListener>
          )}
          {!initialDataLoader ? (
            <CustomDataGrid
              sx={{
                "&.MuiDataGrid-root": {
                  "& .MuiDataGrid-cell": {
                    ".longText": {
                      "-webkit-line-clamp": getLineClampForRowDensity(rowSize),
                    },
                  },
                },
              }}
              rows={
                tableRecordsData?.rows?.length
                  ? handleRowData(tableRecordsData?.rows)
                  : []
              }
              onRowsScrollEnd={() => {
                if (tableRecordsData?.rows?.length > 0) {
                  getNewRowsOnScroll();
                }
              }}
              columnThreshold={0}
              disableColumnMenu
              hideFooter
              columns={columns}
              getRowId={(e, index) => e.Id}
              // showColumnRightBorder={true}
              showCellRightBorder={true}
              loading={newDataLoader}
              getRowHeight={() => {
                return rowSize ?? 60;
              }}
              slots={{
                loadingOverlay: CustomLoadingOverlay,
                noRowsOverlay: hasAccess[activeTab]
                  ? CustomUnAuthorizedOverlay
                  : CustomNoRowsOverlay,
              }}
              onCellKeyDown={(params, e) => {
                if (e.key === " ") {
                  e.stopPropagation();
                }
                // const { left, top, height } =
                //   event.currentTarget.getBoundingClientRect();
                // if (!params.isEditable)
                //   setLockCSS({
                //     ...lockCSS,
                //     left: `calc(${left}px - 0.5rem)`,
                //     top: `calc(${top}px + ${height}px - 0.5rem)`,
                //     showLock: true,
                //   });
                // else
                //   setLockCSS({
                //     ...lockCSS,
                //     showLock: false,
                //   });
              }}
              // onCellClick={(e, ele, a) => {
              //   console.log(e.colDef.editable);
              //   if (e.colDef.editable) focus(ele);
              // }}
              localeText={{
                toolbarColumns: "Fields",
              }}
              componentsProps={{
                panel: {
                  sx: {
                    "& .MuiDataGrid-panelWrapper": {
                      background: "#ffffff",
                      border: "1px solid #ebebeb",
                      boxShadow:
                        "4px 20px 24px rgba(0, 0, 0, 0.08), inset -1px -2px 4px rgba(0, 0, 0, 0.14)",
                      borderRadius: "8px",
                    },
                    "& .MuiDataGrid-paper": {
                      borderRadius: "8px",
                    },
                    "& .MuiFormControlLabel-label, .MuiFormControl-root,  .MuiDataGrid-panelFooter, .MuiInputBase-input":
                      {
                        fontSize: "14px !important",
                        fontWeight: "400",
                      },
                    "& .MuiDataGrid-columnsPanel": {
                      height: "200px",
                    },
                    top: "-65px !important",
                    left: "10px !important",
                    whiteSpace: "normal",
                    wordBreak: "break-word",
                    // width: "100%",
                  },
                },
                row: {
                  onMouseEnter: handlePopperOpen,
                  onMouseLeave: handlePopperClose,
                },
              }}
              sortingMode="server"
              onSortModelChange={(model) => {
                if (model[0]) {
                  setSort(model[0]);
                  setUpdate(true);
                } else {
                  setSort({ sort: "", field: "" });
                  setUpdate(true);
                }
              }}
            />
          ) : (
            <Loader />
          )}
        </Box>
      </Box>

      {tableRecordsData?.headers?.length && expandAnchorEl ? (
        <ExpandViewPopover
          id={id}
          open={expandViewOpen}
          anchorEl={expandAnchorEl}
          onClose={handleExpandViewClose}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
        >
          <OpportunityView
            expandView={() => handleExpandViewClose()}
            header={tableHeaderField}
            activeHeader={tableRecordsData.headers}
            // activeHeader={tableRecordsData.headers}
            tableName={handleTable(activeTab)}
            rowId={onHoverRowDataId}
            onUpdateSuccess={() => {
              setUpdate(true);
            }}
            addOrRemoveFieldInColumn={(field, headerFlag, parent) =>
              addFieldInTable(field, headerFlag, parent, true)
            }
            relatedFields={relatedFields}
            expandViewFirst={true}
            gridSubFields={subFields}
            expandGridRecord={expandGridRecord}
            setExpandGridRecord={setExpandGridRecord}
          />
        </ExpandViewPopover>
      ) : (
        <></>
      )}
    </>
  );
}

export default Pipeline;
