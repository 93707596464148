import { Search, SearchIconWrapper } from "../../Components/Common";
import { ReactComponent as SalesforceSVG } from "../../Assets/SVG/Salesforce.svg";
import { CloseRounded } from "@mui/icons-material";

import { Box, Popover, styled, Typography } from "@mui/material";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as HomePageDropDownSVG } from "../../Assets/SVG/HomePageDropDown.svg";
import { ReactComponent as LogoTrackTable } from "../../Assets/SVG/logo_tracktable.svg";
import { ReactComponent as HomeSideBar } from "../../Assets/SVG/HomeSideBar.svg";
import { ReactComponent as Vector } from "../../Assets/SVG/Vector.svg";
import { HiOutlineLockClosed } from "react-icons/hi";
import GenerateGreetings from "../../Components/GenerateGreetings";
import { MainHeaderChildBox } from "../../Components/Navbar";
import { toast } from "react-toastify";

export const CTime = styled(Box)((theme) => ({
  fontVariantNumeric: "tabular-nums",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "700",
  color: "white",
  fontSize: "24px",
  lineHeight: "29px",
}));

const Input = styled("input")((theme) => ({
  background: "transparent",
  border: "none",
  fontSize: "24px",
  lineHeight: "29px",
  width: "150px",
  borderBottom: "1px solid",
  "&:focus": {
    outline: "none",
  },
  fontFamily: "Inter",
  // color: theme.fontColor,
  color: "white",
}));

const MainContainer = styled(Box)((theme) => ({
  fontSize: "24px",
  display: "flex",
  flexDirection: "column",
  marginInline: "200px",
  justifyContent: "space-between",
  alignItems: "center",
  color: "white",
  backgroundColor: "rgba(255, 255, 255, 0.24)",
  border: "1px solid rgba(235, 235, 235, 0.24)",
  backdropFilter: "blur(14px)",
  borderRadius: "16px",
  padding: "8px",

  height: "300px",
  width: "500px",
}));

function Login() {
  const rURI = process.env.REACT_APP_TRACK_TABLE_FRONTEND_REDIRECT_AWS_URL;

  const [close, setCloseState] = useState(true);
  const [textInput, setTextInput] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);
  const [time, setTime] = useState(moment());
  const refInput = useRef(null);
  const dropDownArr = ["Events", "Opportunity", "Task", "Lead", "Notes"];

  // video preview popup //
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  // ------- //

  useEffect(() => {
    if (refInput.current !== null) {
      refInput.current.focus();
    }
  }, [textInput]);

  useEffect(() => {
    setInterval(() => {
      setTime(moment());
    }, 1000);
  }, []);

  let temp = localStorage.getItem("key");
  // if (temp === "true") {
  //   localStorage.removeItem("key");
  // }

  useEffect(() => {
    if (temp === "true")
      toast.error("Session Expired!", {
        toastId: "id",
      });
  }, [temp]);

  const generateGreetings = () => {
    var currentHour = time.format("HH");

    if (currentHour >= 3 && currentHour < 12) {
      return "Good Morning";
    } else if (currentHour >= 12 && currentHour < 15) {
      return "Good Afternoon";
    } else if (currentHour >= 15 && currentHour < 20) {
      return "Good Evening";
    } else if (currentHour >= 20 && currentHour < 3) {
      return "Good Night";
    } else {
      return "Hello";
    }
  };

  return (
    <>
      <Box
        container
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display={"flex"} width={"100%"}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
            width="70%"
            flex="1 1 70%"
          >
            {/* time */}
            <Box
              color="#444647"
              display="flex"
              position="relative"
              width="100%"
              padding="12px 28px"
            >
              <MainHeaderChildBox
                color="#ffffff"
                paddingLeft="40px"
                textTransform="uppercase"
                fontSize="20px"
                fontWeight="600"
                flexDirection="row"
              >
                <LogoTrackTable
                  style={{
                    width: "125px",
                  }}
                />
              </MainHeaderChildBox>

              <Box
                color="#444647"
                // display="flex"
                display="none"
                flexDirection="column"
                width="100%"
                paddingRight="40px"
                paddingTop="16px"
              >
                <Box display="flex" flexDirection="column" alignItems="end">
                  <CTime>
                    {time.format("hh:mm")} {time.format("A")}
                  </CTime>
                  <Box
                    fontSize="20px"
                    lineHeight="29px"
                    fontWeight="400"
                    color="white"
                  >
                    {time.format("D MMMM")}, {time.format("dddd")}
                  </Box>
                </Box>
              </Box>
            </Box>

            {/* maing content */}
            <Box
              fontSize="24px"
              display="flex"
              flexDirection="column"
              height="70vh"
              alignItems="center"
              // marginInline="200px"
              justifyContent="flex-start"
              color="white"
            >
              {/* demo video */}
              <Box
                fontSize="24px"
                lineHeight="29px"
                fontWeight="600"
                color="white"
                paddingBottom="24px"
                // marginTop="300px"
              >
                <Box>
                  <Popover
                    id={id}
                    open={open}
                    anchorOrigin={{
                      vertical: "center",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "center",
                      horizontal: "center",
                    }}
                    sx={{
                      ".MuiPaper-root": {
                        width: "808px",
                        height: "454px",
                        display: "flex",
                        /* align-content: center; */
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "10px",
                      },
                      ".MuiBackdrop-root": {
                        backdropFilter: "blur(2px)",
                      },
                    }}
                    onClose={handleClose}
                  >
                    <iframe
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                      src="https://www.youtube.com/embed/vA-hyjvsE54"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen="true"
                    />
                  </Popover>
                </Box>
                <GenerateGreetings greeting={true} />!{" "}
                {/* <span
            onClick={() => {
              setTextInput(true);
            }}
            onBlur={() => setTextInput(false)}
          >
            {textInput ? (
              <Input
                ref={refInput}
                type="text"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
            ) : (
              userName
            )}
            .{" "}
          </span> */}
                Welcome to TrackTable!
              </Box>

              {/* signing */}
              <MainContainer>
                <Box
                  fontSize="24px"
                  fontWeight="600"
                  color="white"
                  paddingTop="16px"
                >
                  Sign In
                </Box>
                <Box
                  fontSize="16px"
                  fontWeight="400"
                  color="white"
                  paddingTop="8px"
                >
                  with your Salesforce Account to get started
                </Box>
                <Box>
                  <a
                    style={{
                      textDecoration: "none",
                    }}
                    href={`https://login.salesforce.com/services/oauth2/authorize?response_type=code&client_id=3MVG9p1Q1BCe9GmAEVcq99LCCil0CWE1Jrlz.5qhejxCuTVe8tdRqHLLxdiPyAVHpshfCp.P7DvgIHH9Ezlho&redirect_uri=${rURI}`}
                    target={"_parent"}
                  >
                    <Box
                      style={{
                        marginTop: "4px",
                        background: "white",
                        borderRadius: "8px",
                      }}
                    >
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        padding="16px 24px"
                      >
                        <SalesforceSVG
                          style={{
                            width: "44px",
                            height: "32px",
                          }}
                        />
                        <Box
                          style={{
                            marginLeft: "12px",
                            fontSize: "20px",
                            fontWeight: "400",
                            color: "black",
                          }}
                        >
                          Sign In with Salesforce
                        </Box>
                      </Box>
                    </Box>
                  </a>
                </Box>
                <Box
                  color="#fff"
                  fontSize="12px"
                  fontWeight="500"
                  lineHeight="17px"
                  margin="1rem 0rem 1rem"
                  padding="0px 3rem"
                >
                  *By clicking on signin you agree with the&nbsp;
                  <a
                    href="https://www.getclientell.com/terms-and-conditions"
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      color: "#fff",
                    }}
                  >
                    Privacy Policy
                  </a>{" "}
                  and&nbsp;
                  <a
                    href="https://www.getclientell.com/terms-and-conditions"
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      color: "#fff",
                    }}
                  >
                    Terms and Services
                  </a>{" "}
                  of Clientell
                </Box>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  fontSize="24px"
                  fontWeight="500"
                  marginTop="4px"
                >
                  <HiOutlineLockClosed
                    style={{ width: "24px", height: "28px" }}
                  />
                  <span style={{ marginLeft: "8px" }}> Secure</span>
                </Box>
                <Box color="#EFF3F5" fontSize="16px" fontWeight="400">
                  Your Data is not Stored.
                </Box>
                <Box
                  color="#EFF3F5"
                  fontSize="1rem"
                  fontWeight="400"
                  paddingBottom="32px"
                >
                  Secured Using Military grade encryption
                </Box>
              </MainContainer>
            </Box>
          </Box>
          {/* Side Greatinngs */}
          <Box
            style={{
              // position: "absolute",
              // top: "0px",
              // right: "0px",
              // transition: "ease 1s",
              // height: "100%",
              background: "#1B1E1F",
              zIndex: "0",
              width: close ? "30%" : "0%",
              height: "100vh",
            }}
          >
            <Box
              style={{
                display: "flex",
                padding: "10px",
                margin: "10px",
                position: "relative",
                height: "95%",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box>
                {/* <CloseRounded
              color="none"
              fontSize="25px"
              onClick={() => {
                setCloseState(false);
              }}
              sx={{
                cursor: "pointer",
                fontSize: "25px",
                fill: "white",
              }}
            /> */}
              </Box>
              <Box
                style={{
                  display: "flex",
                  /* align-content: center; */
                  flexDirection: "column",
                  gap: "40px",
                  justifyContent: "center",

                  position: "relative",
                  flexWrap: "nowrap",
                  width: "90%",
                }}
              >
                {/* for text */}
                <Box
                  style={{
                    fontSize: "1rem",
                    textAlign: "center",
                    color: "#FFFFFF",
                    // width: "350px",
                  }}
                >
                  Maximize your productivity with TrackTable - the all-in-one
                  tool for note taking, tracking to-do's, and pipeline
                  management. Simplify your workflow and take control of your
                  day with TrackTable.
                </Box>
                {/* for vidoes */}
                <Box
                  style={{
                    // width: "350px",
                    position: "relative",
                    left: "0",
                    cursor: "pointer",
                    textAlign: "center",
                  }}
                  onClick={handleClick}
                >
                  <HomeSideBar />
                  <Box
                    style={{
                      position: "absolute",
                      top: "45%",
                      left: "45%",
                    }}
                  >
                    <Vector />
                  </Box>
                </Box>
                {/* for footertext */}
                <Box
                  style={{
                    fontSize: "16px",
                    textAlign: "center",
                    color: "#FFFFFF",
                    // width: "350px",
                  }}
                >
                  Your personal Revenue Workspace.
                </Box>
              </Box>
              <Box
                sx={{
                  height: "127px",
                  maxWidth: "90%",
                  display: "flex",
                  gap: "13px",
                  background: "#32383A",
                  borderRadius: "12px",
                  padding: "0rem 1rem",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "end",
                  }}
                >
                  <img src="Layer.png" alt="image..." />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                  }}
                >
                  <Box
                    sx={{
                      fontSize: "12px",
                      letterSpacing: "-0.02em",
                      color: "#FFFFFF",
                      width: "245px",
                      padding: "0.5rem 0rem",
                    }}
                  >
                    TrackTable has been a lifesaver for our revenue team,
                    simplifying our daily workflow and allowing us to focus on
                    what matters most - selling. We couldn't be happier with our
                    decision to switch to TrackTable!
                  </Box>
                  <Box>
                    <Box
                      sx={{
                        fontSize: "12px",
                        color: "#FFFFFF",
                        opacity: "0.5",
                      }}
                    >
                      Hari Valiyath, CBO
                    </Box>
                    <Typography fontSize={"12px"} color={"#31A1FF"}>
                      Pixis.ai
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Login;
