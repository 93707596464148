import React from 'react'
import styled from "@emotion/styled/macro";
import Loader from '../Loader';


const Main = styled.div({
    position: "fixed",
    left: "0",
    top: "0",
    right: "0",
    bottom: "0",
    // backgroundColor: "rgba(0,0,0,0.25)",
  
    background: "rgba(0, 81, 112, 0.1)",
    backdropFilter: "blur(4px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "7",
  });
function FullScreenLoader() {
  return (
    <Main >
        <Loader />
    </Main>
  )
}

export default FullScreenLoader