import { Box, Divider, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { TaskRow, TaskRowTypoGraphy, TasksDataBox } from "../../Pages/Task";
import { ReactComponent as IncompleteTaskSVG } from "../../Assets/SVG/IncompleteTask.svg";
import { ReactComponent as CompleteTaskSVG } from "../../Assets/SVG/CompleteTask.svg";
import { ReactComponent as PriorityHighTaskSVG } from "../../Assets/SVG/PriorityHighTask.svg";
import styled from "@emotion/styled";
import Loader from "../Loader";

const FilterNameBox = styled(Box)({
  textTransform: "uppercase",
  letterSpacing: "1.25px",
  fontSize: "11px",
  fontWeight: "600",
  lineHeight: "20px",
  color: "#6A727A",
  padding: "8px 2rem",
});

const MainRowClickableBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  // "& :hover": {
  //   background: "#F6F8F9",
  //   cursor: "pointer",
  // },
});

const CustomDivider = styled(Divider)({
  display: "block",
  margin: "auto",
  width: "90%",
});

const FlexBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "12px",
});

const ConnectionBox = styled(Box)({
  width: "max-content",
  maxWidth: "105px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  textAlign: "right",
  color: "#848F99",
  fontFamily: "Inter",
  fontSize: "12px",
});

const NoTasksBox = styled(Box)({
  display: "flex",
  gap: "4px",
  alignItems: "center",
  width: "100%",
  height: "100%",
  justifyContent: "center",
});

function TaskData({
  taskData,
  taskFilter,
  taskDataFilter,
  taskActive,
  taskRowClick,
  taskCompletion,
  taskActiveCompleteId,
  setActiveEditTask,
  manageEllipsis,
  searchTasksStyle = false,
  completedTaskList,
  handleCompletedTaskList,
  setTaskState,
  setSearchEditTask,
  setSearchTikClose,
  setNewTaskAsync,
}) {
  const [onHoverId, setOnHoverId] = useState(null);

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const dayNames = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];

  // useEffect(() => {
  //   console.log(taskActive, completedTaskList);
  // }, [completedTaskList, taskActive]);

  const SortData = (data) => {
    let sortingData;
    if (taskFilter.sort) {
      if (taskFilter.sort === "Priority")
        sortingData = data.sort((a, b) => {
          if (a.IsHighPriority === b.IsHighPriority) return 0;
          if (a.IsHighPriority > b.IsHighPriority) return -1;
          return 1;
        });
      if (taskFilter.sort === "Due date desc")
        sortingData = data.sort((a, b) => {
          if (a.ActivityDate < b.ActivityDate) return 1;
          if (a.ActivityDate > b.ActivityDate) return -1;
          return 0;
        });
      if (taskFilter.sort === "Due date asc")
        sortingData = data.sort((a, b) => {
          if (a.ActivityDate < b.ActivityDate) return -1;
          if (a.ActivityDate > b.ActivityDate) return 1;
          return 0;
        });
    } else {
      sortingData = data;
    }
    return sortingData;
  };

  const ToolTipComponent = ({
    data,
    setSearchEditTask,
    searchTasksStyle,
    setSearchTikClose,
  }) => {
    return (
      <Tooltip
        PopperProps={{
          disablePortal: true,
          sx: {
            "& .MuiTooltip-tooltip": {
              height: "16px",
              borderRadius: "8px",
              fontFamily: "Inter",
              textAlign: "center",
            },
          },
        }}
        id={`${data.Id}`}
        key={`${data.Id}`}
        title={completedTaskList.includes(data.Id)?"Mark task open":"Mark task complete"}
        onMouseEnter={(event) => setOnHoverId(event.currentTarget.id)}
        onMouseLeave={() => setOnHoverId(null)}
        onClick={(e) => {
          e.stopPropagation();
          setActiveEditTask(data.Id);
          // !taskActiveCompleteId ||
          // (taskActiveCompleteId && taskActiveCompleteId !== data.Id)
          //   ? taskCompletion(data)
          //   : taskCompletion(null);
          taskCompletion(data);

          if (!completedTaskList.includes(data.Id)) {
            handleCompletedTaskList([...completedTaskList, data.Id]);
          } else {
            const __taskListIds = completedTaskList.filter(
              (id) => id !== data.Id
            );

            handleCompletedTaskList(__taskListIds);
          }
          searchTasksStyle ? setSearchTikClose(true) : setSearchTikClose(false);
        }}
      >
        {
          // (taskActive !== data.Subject && taskFilter.view === "Completed") ||
          // data.Id === taskActiveCompleteId ||
          completedTaskList.includes(data.Id) ? (
            <CompleteTaskSVG
              color={"#1DC4B4"}
              height={"18px"}
              cursor={"pointer"}
            />
          ) : onHoverId === data.Id ? (
            <Box id={`${data.Id}`}>
              <CompleteTaskSVG
                color={data.IsHighPriority ? "#F18895" : "#1DC4B4"}
                height={"18px"}
                cursor={"pointer"}
              />
            </Box>
          ) : (
            <Box id={`${data.Id}`}>
              <IncompleteTaskSVG
                height={"18px"}
                width={"18px"}
                cursor={"pointer"}
                color={data.IsHighPriority && "#F18895"}
              />
            </Box>
          )
        }
      </Tooltip>
    );
  };

  const allRowData = () => {
    let taskSortedData = taskData;
    if (taskFilter.view === "All") {
      taskSortedData = SortData(taskData);
    }

    return taskSortedData.length ? (
      taskSortedData.map((data) => {
        const currentDate = new Date().setHours(0, 0, 0, 0);
        const taskDate = new Date(data.ActivityDate).setHours(0, 0, 0, 0);
        return (
          <MainRowClickableBox
            onClick={(e) => {
              e.stopPropagation();
              taskRowClick(data);
              searchTasksStyle && setSearchEditTask(true);
              setNewTaskAsync && setNewTaskAsync(true);
            }}
            // bgcolor={
            //   taskActive === data.Subject
            //     ? "#F6F8F9"
            //     : completedTaskList.includes(data.Id) && "#E8F9F7"
            // }
            bgcolor={
              completedTaskList.includes(data.Id) &&
              taskFilter.view !== "Completed"
                ? "#E8F9F7"
                : !completedTaskList.includes(data.Id) &&
                  taskActive === data.Id &&
                  "#F6F8F9"
            }
            sx={{
              "& :hover": {
                background: completedTaskList.includes(data.Id)
                  ? "#E8F9F7"
                  : "#F6F8F9",
                cursor: "pointer",
              },
            }}
          >
            <TaskRow onMouseLeave={() => setOnHoverId(null)}>
              <FlexBox>
                <ToolTipComponent
                  data={data}
                  searchTasksStyle={searchTasksStyle}
                  setSearchEditTask={setSearchEditTask}
                  setSearchTikClose={setSearchTikClose}
                />
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  title={data.Subject.length > 28 ? data.Subject : ""}
                >
                  <TaskRowTypoGraphy
                    // color={
                    //   taskActive === data.Subject
                    //     ? ""
                    //     : taskFilter.view === "Completed" && "#1DC4B4"
                    // }
                    color={completedTaskList.includes(data.Id) && "#1DC4B4"}
                    sx={{
                      whiteSpace: "nowrap",
                      width: !searchTasksStyle
                        ? manageEllipsis
                          ? "350px"
                          : "215px"
                        : "auto",
                    }}
                  >
                    {data.Subject}
                  </TaskRowTypoGraphy>
                </Tooltip>
              </FlexBox>
              <FlexBox>
                {data.What && <ConnectionBox>{data.What.Name}</ConnectionBox>}
                <TaskRowTypoGraphy
                  color={
                    taskFilter.view !== "Completed" && taskDate === currentDate
                      ? "#E57B5B"
                      : taskFilter.view !== "Completed" &&
                        taskDate < currentDate
                      ? "#E8384F"
                      : "#848F99"
                  }
                  width={"50px"}
                  textAlign={"right"}
                  fontSize={"12px"}
                >
                  {data.ActivityDate &&
                    `${
                      monthNames[new Date(data.ActivityDate).getMonth()]
                    } ${new Date(data.ActivityDate).getDate()}`}
                </TaskRowTypoGraphy>
              </FlexBox>
            </TaskRow>
            <CustomDivider />
          </MainRowClickableBox>
        );
      })
    ) : (
      <NoTasksBox>
        <CompleteTaskSVG height={"18px"} cursor={"pointer"} />
        <Typography color={"#9DA5AD"} fontSize={"14px"} fontFamily={"Inter"}>
          No task
        </Typography>
      </NoTasksBox>
    );
  };

  if (
    taskFilter.view !== "Today and Overdue" &&
    taskFilter.view !== "Next 7 days"
  ) {
    return taskDataFilter ? (
      <Loader />
    ) : (
      <TasksDataBox>{allRowData()}</TasksDataBox>
    );
  }

  if (taskFilter.view === "Today and Overdue") {
    const todayData = taskData.length
      ? taskData.filter(
          (data) =>
            new Date(data.ActivityDate).toLocaleDateString() ===
              new Date().toLocaleDateString() || data.ActivityDate === null
        )
      : [];
    let overDueData = taskData.length
      ? taskData.filter(
          (data) =>
            new Date(data.ActivityDate).toLocaleDateString() !==
              new Date().toLocaleDateString() && data.ActivityDate !== null
        )
      : [];

    overDueData = SortData(overDueData);

    const allData = [todayData, overDueData];
    return !taskDataFilter ? (
      <TasksDataBox>
        {allData.flat().length ? (
          allData.map((everyData, index) => {
            return everyData.length ? (
              <>
                <FilterNameBox>{index ? "Overdue" : "Today"}</FilterNameBox>
                {everyData.map((data, index) => {
                  const currentDate = new Date().setHours(0, 0, 0, 0);
                  const taskDate = new Date(data.ActivityDate).setHours(
                    0,
                    0,
                    0,
                    0
                  );

                  return (
                    <MainRowClickableBox
                      key={`${data.Id}`}
                      // bgcolor={
                      //   taskActive === data.Subject
                      //     ? "#F6F8F9"
                      //     : data.Id === taskActiveCompleteId && "#E8F9F7"
                      // }
                      bgcolor={
                        completedTaskList.includes(data.Id)
                          ? "#E8F9F7"
                          : !completedTaskList.includes(data.Id) &&
                            taskActive === data.Id &&
                            "#F6F8F9"
                      }
                      sx={{
                        "& :hover": {
                          background: completedTaskList.includes(data.Id)
                            ? "#E8F9F7"
                            : "#F6F8F9",
                          cursor: "pointer",
                        },
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        taskRowClick(data);
                        setSearchTikClose(false);
                        searchTasksStyle && setSearchEditTask(true);
                        setNewTaskAsync && setNewTaskAsync(true);
                      }}
                    >
                      <CustomDivider />
                      <TaskRow onMouseLeave={() => setOnHoverId(null)}>
                        <FlexBox>
                          <ToolTipComponent
                            data={data}
                            searchTasksStyle={searchTasksStyle}
                            setSearchEditTask={setSearchEditTask}
                            setSearchTikClose={setSearchTikClose}
                          />
                          <Tooltip
                            PopperProps={{
                              disablePortal: true,
                            }}
                            title={data.Subject.length > 28 ? data.Subject : ""}
                          >
                            <TaskRowTypoGraphy
                              sx={{
                                whiteSpace: "nowrap",
                                width: !searchTasksStyle
                                  ? manageEllipsis
                                    ? "350px"
                                    : "215px"
                                  : "auto",
                              }}
                            >
                              {data.Subject}
                            </TaskRowTypoGraphy>
                          </Tooltip>
                        </FlexBox>
                        <FlexBox>
                          {data.What && (
                            <Tooltip
                              PopperProps={{
                                disablePortal: true,
                              }}
                              title={
                                data.What?.Name.length > 18
                                  ? data.What?.Name
                                  : ""
                              }
                            >
                              <ConnectionBox>{data.What.Name}</ConnectionBox>
                            </Tooltip>
                          )}
                          <TaskRowTypoGraphy
                            color={
                              taskDate === currentDate ? "#E57B5B" : "#E8384F"
                            }
                            width={"50px"}
                            textAlign={"right"}
                          >
                            {data.ActivityDate &&
                              `${
                                monthNames[
                                  new Date(data.ActivityDate).getMonth()
                                ]
                              } ${new Date(data.ActivityDate).getDate()}`}
                          </TaskRowTypoGraphy>
                        </FlexBox>
                      </TaskRow>
                      {everyData.length === index + 1 && <CustomDivider />}
                    </MainRowClickableBox>
                  );
                })}
              </>
            ) : (
              <></>
            );
          })
        ) : (
          <NoTasksBox>
            <CompleteTaskSVG height={"18px"} cursor={"pointer"} />
            <Typography
              color={"#9DA5AD"}
              fontSize={"14px"}
              fontFamily={"Inter"}
            >
              No task
            </Typography>
          </NoTasksBox>
        )}
      </TasksDataBox>
    ) : (
      <Loader />
    );
  }

  if (taskFilter.view === "Next 7 days") {
    const dates = [];
    const distinctData = taskData.filter((data) => {
      if (!dates.includes(data.ActivityDate)) {
        dates.push(data.ActivityDate);
        return data;
      } else {
        dates.push(data.ActivityDate);
      }
    });

    return !taskDataFilter ? (
      <TasksDataBox>
        {distinctData.length ? (
          distinctData.map((data) => {
            const currentDate = new Date().getDate();
            const taskDate = Number(new Date(data.ActivityDate).getDate());
            const day = new Date(data.ActivityDate).getDay();

            let dataAsPerDay = taskData.filter(
              (allData) => allData.ActivityDate === data.ActivityDate
            );

            dataAsPerDay = SortData(dataAsPerDay);
            return (
              <>
                <FilterNameBox>
                  {taskDate === currentDate + 1
                    ? "tomorrow"
                    : taskDate === currentDate
                    ? "today"
                    : dayNames[day]}
                </FilterNameBox>
                {dataAsPerDay.map((filterData, index) => {
                  return (
                    <MainRowClickableBox
                      bgcolor={
                        completedTaskList.includes(data.Id)
                          ? "#E8F9F7"
                          : !completedTaskList.includes(data.Id) &&
                            taskActive === filterData.Id &&
                            "#F6F8F9"
                      }
                      sx={{
                        "& :hover": {
                          background: completedTaskList.includes(data.Id)
                            ? "#E8F9F7"
                            : "#F6F8F9",
                          cursor: "pointer",
                        },
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        taskRowClick(filterData);
                        searchTasksStyle && setSearchEditTask(true);
                        setNewTaskAsync && setNewTaskAsync(true);
                      }}
                    >
                      <CustomDivider />
                      <TaskRow onMouseLeave={() => setOnHoverId(null)}>
                        <ToolTipComponent
                          data={filterData}
                          searchTasksStyle={searchTasksStyle}
                          setSearchEditTask={setSearchEditTask}
                          setSearchTikClose={setSearchTikClose}
                        />
                        <Tooltip
                          PopperProps={{
                            disablePortal: true,
                          }}
                          title={
                            filterData.Subject.length > 28
                              ? filterData.Subject
                              : ""
                          }
                        >
                          <TaskRowTypoGraphy
                            sx={{
                              whiteSpace: "nowrap",
                              width: !searchTasksStyle
                                ? manageEllipsis
                                  ? "350px"
                                  : "215px"
                                : "auto",
                            }}
                          >
                            {filterData.Subject}
                          </TaskRowTypoGraphy>
                        </Tooltip>
                        <TaskRowTypoGraphy
                          marginLeft={"auto"}
                          color={taskDate === currentDate && "#E57B5B"}
                        >
                          {data.ActivityDate &&
                            `${
                              monthNames[
                                new Date(filterData.ActivityDate).getMonth()
                              ]
                            } ${new Date(filterData.ActivityDate).getDate()}`}
                        </TaskRowTypoGraphy>
                      </TaskRow>
                      {dataAsPerDay.length === index + 1 && <CustomDivider />}
                    </MainRowClickableBox>
                  );
                })}
              </>
            );
          })
        ) : (
          <NoTasksBox>
            <CompleteTaskSVG height={"18px"} cursor={"pointer"} />
            <Typography
              color={"#9DA5AD"}
              fontSize={"14px"}
              fontFamily={"Inter"}
            >
              No task
            </Typography>
          </NoTasksBox>
        )}
      </TasksDataBox>
    ) : (
      <Loader />
    );
  }
}

export default TaskData;
