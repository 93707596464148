import styled from "@emotion/styled";
import { Add, CloseRounded } from "@mui/icons-material";
import {
  Checkbox,
  Divider,
  InputBase,
  Popover,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import Loader from "../Loader";
import { ButtonBox, StyledButton } from "../SearchView/SearchDrawer";
import SimpleDropDown, { DropDown, DropDownOption } from "../SimpleDropDown";
import SimpleSearchDropDown from "./SimpleSearchDropDown";
import { ReactComponent as ArrowDownwardSVG } from "../../Assets/SVG/ArrowDownward.svg";
import {
  search_records_convert_lead,
  convert_lead_api,
} from "../../Pages/Services/tableApi";
import useDebounce from "../../Hooks/useDebounce";
import { StyledAlert, StyledSnackBar } from "../Navbar";
import Actions from "../../redux/actions";
import { useDispatch } from "react-redux";

export const LeadViewHeader = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "15px",
});

export const MainLeadExistingBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  alignItems: "flex-start",
});

export const HeaderTypoGraphy = styled(Typography)({
  fontFamily: "Inter",
  fontSize: "18px",
  fontWeight: 700,
  padding: "1rem 0rem 0rem 1rem",
  ".MuiTypography-root": {
    fontFamily: "Inter",
  },
});

const MainLeadNewExistingBox = styled(Box)({
  display: "flex",
  borderRadius: "8px",
  outline: "1px solid #E6E9EB",
  width: "fit-content",
  background: "#F6F8F9",
  overflow: "hidden",
});

export const MainLeadTypoGraphy = styled(Typography)({
  fontFamily: "Inter",
  fontWeight: 500,
  width: "max-content",
  padding: "4px 8px",
  cursor: "pointer",
  position: "relative",
});

export const MainLeadBoxTypoGraphy = styled(Typography)({
  fontFamily: "Inter",
  fontSize: "14px",
  fontWeight: 400,
  cursor: "pointer",
  display: "flex",
  justifyContent: "space-between",
  paddingRight: "12px",
  width: "100%",
});

export const MainLeadBoxCovertTypoGraphy = styled(Typography)({
  fontFamily: "Inter",
  fontSize: "14px",
  fontWeight: 400,
  border: "1px solid #E6E9EB",
  borderRadius: "8px",
  color: "#6A727A",
  background: "#EDF1F2",
  display: "flex",
  alignItems: "center",
  gap: "5px",
  padding: "4px",
  cursor: "pointer",
});

export const MainLeadBox = styled(Box)({
  background: "#ffffff",
  borderRadius: "8px",
  padding: "20px 12px",
  display: "flex",
  flexDirection: "column",
  gap: "14px",
});

export const SearchBox = styled(Box)({
  border: "1px solid #E6E9EB",
  borderRadius: "8px",
  color: "#6A727A",
  minHeight: "36px",
  width: "98%",
  display: "flex",
  alignItems: "center",
  paddingLeft: "0.5rem",
});

const SearchPopOver = styled(Popover)({
  ".MuiPaper-root": {
    borderRadius: "5px",
    boxShadow: "0 3px 10px rgb(0 0 0 / 10%)",
    maxHeight: "max-content",
    minWidth: "250px",
    marginTop: "8px",
  },
});

export const SearchDataScrollBox = styled(Box)({
  overflowX: "hidden",
  overflowY: "scroll",
  scrollBehavior: "smooth",
  "&::-webkit-scrollbar": {
    width: "0.5em",
    marginRight: "4px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(181,188,194,0.7)",
    borderRadius: "10px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(181,188,194,0.7)",
    borderRadius: "10px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "rgba(181,188,194,0.9)",
  },
});

export const AddBox = styled(Add)({
  background: "#EDF1F2",
  borderRadius: "0.2rem",
  "&:hover": {
    cursor: "pointer",
    background: "#d1dee1",
    color: "#1565c0",
  },
});

export const MainScrollBarBox = styled(Box)({
  background: "#F6F8F9",
  overflowY: "scroll",
  height: "80%",
  padding: "1rem",
  scrollBehavior: "smooth",
  display: "flex",
  gap: "20px",
  flexDirection: "column",
  "&::-webkit-scrollbar": {
    width: "0.5em",
    marginRight: "4px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(181,188,194,0.7)",
    borderRadius: "10px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(181,188,194,0.7)",
    borderRadius: "10px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "rgba(181,188,194,0.9)",
  },
});

export const FooterBox = styled(Box)({
  height: "75px",
  display: "flex",
  gap: "20px",
  flexDirection: "column",
});

export const SearchDataBox = styled(Box)({
  height: "30px",
  width: "255px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  padding: "0 8px",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  "& hover": {
    background: "#F6F8F9",
  },
});

function LeadView({
  handleLeadViewClose,
  data,
  leadId,
  handleConvertLeadClick,
  leadViewStatus,
}) {
  const [status, setStatus] = useState(false);
  const [searchRecords, setSearchRecords] = useState({
    Account: [],
    Contact: [],
    Opportunity: [],
    Convert: [],
  });
  const [leadViewData, setLeadViewData] = useState(null);
  const [opportunityText, setOpportunityText] = useState("");
  const [convertLeadLoader, setConvertLeadLoader] = useState(false);
  const [searchLeadLoader, setSearchLeadLoader] = useState(false);
  const [apiToaster, setApiToaster] = useState(false);
  const [toasterState, setToasterState] = useState({
    open: false,
  });
  const [fields, setFields] = useState({});
  const [searchApiResponse, setSearchApiResponse] = useState(false);
  const [searchDataLimit, setSearchDataLimit] = useState(5);
  const debounceText = useDebounce(opportunityText, 1000);

  //--- account,contact and opportunity popover section ---//
  const [accountAnchorEl, setAccountAnchorEl] = useState(null);
  const [contactAnchorEl, setContactAnchorEl] = useState(null);
  const [opportunityAnchorEl, setOpportunityAnchorEl] = useState(null);

  const dispatch = useDispatch();

  const handleClick = (event, Name) => {
    switch (Name) {
      case "Account":
        setAccountAnchorEl(event.currentTarget);
        break;
      case "Contact":
        setContactAnchorEl(event.currentTarget);
        break;
      case "Opportunity":
        setOpportunityAnchorEl(event.currentTarget);
        break;
      default:
        setAccountAnchorEl(event.currentTarget);
        break;
    }
  };

  const handleClose = (tableName) => {
    const tempSearchRecords = { ...searchRecords };
    if (tempSearchRecords[tableName].length) {
      tempSearchRecords[tableName] = [];
      setSearchRecords(tempSearchRecords);
    }
    switch (tableName) {
      case "Account":
        setAccountAnchorEl(null);
        break;
      case "Contact":
        setContactAnchorEl(null);
        break;
      case "Opportunity":
        setOpportunityAnchorEl(null);
        break;
      default:
        setAccountAnchorEl(null);
        break;
    }
  };
  //--- ---//

  //--- convert section and converted Lead view ---//
  const [convertAnchorEl, setConvertAnchorEl] = useState(null);
  const handleConvertClick = (event) => {
    setConvertAnchorEl(event.currentTarget);
  };
  const handleConvertClose = (convertName) => {
    const tempSearchRecords = { ...searchRecords };
    if (tempSearchRecords[convertName]?.length) {
      tempSearchRecords[convertName] = [];
      setSearchRecords(tempSearchRecords);
    }
    setConvertAnchorEl(null);
  };
  const convertOpen = Boolean(convertAnchorEl);
  const convertId = convertOpen ? "simple-popover" : undefined;
  //--- ---//

  useEffect(() => {
    if (debounceText) {
      const checkDataExist = leadViewData.filter(
        (oppLead) => oppLead.Name === "Opportunity"
      )[0].data.OpportunityName;
      const tempLeadViewData = [...leadViewData];
      tempLeadViewData.map((tempLead) => {
        if (tempLead.Name === "Opportunity") {
          tempLead.data["OpportunityName"] = debounceText.trim();
          return tempLead;
        } else {
          return tempLead;
        }
      });
      if (checkDataExist !== debounceText.trim()) {
        setLeadViewData(tempLeadViewData);
      }
    }
  }, [debounceText]);

  useEffect(() => {
    if (data) {
      setLeadViewData([
        {
          Name: "Account",
          data: {
            Account: "New",
          },
        },
        {
          Name: "Contact",
          data: {
            Contact: "New",
            LeadSource: false,
          },
        },
        {
          Name: "Opportunity",
          data: {
            Opportunity: "New",
            OpportunityName: data?.Company,
          },
        },
        {
          Name: "Convert",
          data: {
            OwnerEmail: data?.Owner.Username,
            OwnerName: data?.Owner.Name,
            OwnerId: data?.OwnerId,
            LeadStatus: leadViewStatus[0],
          },
        },
      ]);
      setOpportunityText(data?.Company);
    }
  }, [data]);

  const handleNewOrExistingData = (value, name) => {
    const tempLeadViewData = [...leadViewData];
    tempLeadViewData.map((data) => {
      if (data.Name === name) {
        if (value === "None") {
          data.data = {
            ...data.data,
            doNotCreateOpportunity: true,
            Opportunity: value,
          };
        } else if (data.Name === "Opportunity" && value !== "None") {
          data.data = {
            ...data.data,
            Opportunity: value,
            doNotCreateOpportunity: undefined,
          };
        } else {
          data.data[name] = value;
          return data;
        }
      } else {
        return data;
      }
    });
    setLeadViewData(tempLeadViewData);
  };

  const handleSearchApi = async (value, name) => {
    setSearchLeadLoader(true);
    setSearchDataLimit(5);
    const tempSearchRecords = { ...searchRecords };
    tempSearchRecords[name] = [];
    setSearchRecords(tempSearchRecords);
    setSearchApiResponse(false);

    const selectedAccountValue = leadViewData
      ?.filter(
        (leadData) =>
          leadData?.value?.length && leadData.Name === "Account" && leadData
      )
      .map((selectedValue) => selectedValue.value);

    const dataParams = {
      sobject: name === "Convert" ? "User" : name,
      account_name:
        selectedAccountValue.length && name === "Contact"
          ? selectedAccountValue[0]
          : "",
      search_value: value,
      limit: "200",
      offset: 0,
    };
    const JSONdataParams = JSON.stringify(dataParams);
    const res = await search_records_convert_lead(JSONdataParams);
    if (res?.data?.status === 200) {
      const tempSearchRecords = { ...searchRecords };
      tempSearchRecords[name] = res.data.all_data;
      setSearchRecords(tempSearchRecords);
      !res.data.all_data.length && setSearchApiResponse(true);
      setSearchLeadLoader(false);
    }
    setSearchLeadLoader(false);
  };

  const handleConvertLeadApi = async () => {
    let dataParams = {
      leadId: leadId,
    };

    const handleConvertOpportunity = leadViewData.filter(
      (data) => data.Name === "Opportunity"
    )[0];

    const handleOwnerId = leadViewData
      .filter((data) => data.Name === "Opportunity" || data.Name === "Account")
      .map((data) => data.data[data.Name]);

    const ToasterField = leadViewData.filter((data) => {
      if (
        data.data[data.Name] === "Existing" &&
        (data.value === "" || data.value === undefined || data.value === null)
      ) {
        return data;
      }
    });
    if (ToasterField.length) {
      setApiToaster(true);
      setToasterState({ ...toasterState, open: true });
      setFields(ToasterField[0]);
    } else {
      setConvertLeadLoader(true);
      leadViewData.map((leadData) => {
        dataParams = { ...dataParams };
        if (leadData.data[leadData.Name] === "New") {
          if (leadData.Name === "Opportunity") {
            dataParams = {
              ...dataParams,
              opportunityName: leadData.data.OpportunityName,
            };
          }
        }
        if (leadData.data[leadData.Name] === "Existing") {
          if (leadData.Name === "Account") {
            dataParams = {
              ...dataParams,
              accountId: leadData.id ? leadData.id : undefined,
            };
          }
          if (leadData.Name === "Contact") {
            dataParams = {
              ...dataParams,
              contactId: leadData.id ? leadData.id : undefined,
              overwriteLeadSource: leadData.data.LeadSource,
            };
          }
          if (leadData.Name === "Opportunity") {
            dataParams = {
              ...dataParams,
              opportunityId: leadData.id ? leadData.id : undefined,
            };
          }
        }
        if (leadData.Name === "Convert") {
          dataParams = {
            ...dataParams,
            convertedStatus: leadData.data.LeadStatus,
            ownerId: handleOwnerId.includes("New")
              ? leadData.data.OwnerId
              : undefined,
            doNotCreateOpportunity:
              handleConvertOpportunity.data[handleConvertOpportunity.Name] ===
              "None"
                ? true
                : undefined,
          };
        }
      });
      const JSONdataParams = JSON.stringify(dataParams);
      const res = await convert_lead_api(JSONdataParams);
      if (res.data && res.data.status === 200) {
        dispatch(Actions.getAsyncTable.asyncTable([true, "Lead"]));
        handleConvertLeadClick(res.data);
        setConvertLeadLoader(false);
        handleLeadViewClose();
      } else {
        setConvertLeadLoader(false);
        setApiToaster(true);
        setToasterState({ ...toasterState, open: true });
        setFields(res.data.error);
      }
    }
  };

  const convertDataTitle = () => {
    const data = leadViewData.filter(
      (Data) => Data.data[Data.Name] === "New" && Data.Name !== "Contact"
    );
    if (data?.length <= 1) {
      return `${data[0].Name} Owner`;
    } else {
      return `${data[0].Name} and ${data[1].Name} Owner`;
    }
  };

  const handleConvertSectionName = () => {
    const checkForAccountAndOpportunityExistingOrNot =
      leadViewData &&
      leadViewData
        .filter(
          (data) =>
            data.data[data.Name] === "Existing" ||
            data.data[data.Name] === "None"
        )
        .map((data) => data.Name);
    const checkOpportunityExistingOrNot =
      checkForAccountAndOpportunityExistingOrNot.includes("Opportunity");
    const checkAccountExistingOrNot =
      checkForAccountAndOpportunityExistingOrNot.includes("Account");

    if (checkOpportunityExistingOrNot && checkAccountExistingOrNot) {
      return false;
    } else {
      return true;
    }
  };

  const handleSetSearchData = (leadData, value, id, handleDataFlag) => {
    const tempLeadViewData = [...leadViewData];
    if (handleDataFlag) {
      tempLeadViewData.map((data) => {
        if (data.Name === leadData.Name) {
          data.value = value;
          if (data.data[leadData.Name] === "Existing") {
            data.id = id;
          }
          return data;
        } else {
          return data;
        }
      });
      if (leadData.Name === "Account") {
        tempLeadViewData.map((tempData) => {
          if (tempData.Name === "Contact") {
            tempData.value = "";
            return tempData;
          } else {
            return tempData;
          }
        });
      }
    } else {
      tempLeadViewData.map((data) => {
        if (data.Name === leadData.Name) {
          data.data.OwnerName = value;
          data.data.OwnerId = id;
          return data;
        } else {
          return data;
        }
      });
    }
    setLeadViewData(tempLeadViewData);
  };

  const handleRemoveSelectedSearchValue = (leadData, value) => {
    const tempLeadViewData = [...leadViewData];
    if (value === "Account") {
      tempLeadViewData.map((lead) => {
        if (lead.Name !== "Contact" && lead.Name !== "Account") {
          return lead;
        } else {
          lead.value = "";
          return lead;
        }
      });
    } else {
      tempLeadViewData.map((lead) => {
        if (lead.Name === leadData.Name) {
          lead.value = "";
          return lead;
        } else {
          return lead;
        }
      });
    }
    setLeadViewData(tempLeadViewData);
  };

  const handleLeadSourceAndDropDown = (leadData, value) => {
    const tempLeadViewData = [...leadViewData];
    if (value) {
      tempLeadViewData.map((tempData) => {
        if (tempData.Name === leadData.Name) {
          tempData.data.LeadStatus = value;
          return tempData;
        } else {
          return tempData;
        }
      });
      setStatus(false);
    } else {
      tempLeadViewData.map((tempLeadView) => {
        if (tempLeadView.Name === leadData.Name) {
          tempLeadView.data["LeadSource"] = !tempLeadView.data["LeadSource"];
          return tempLeadView;
        } else {
          return tempLeadView;
        }
      });
    }
    setLeadViewData(tempLeadViewData);
  };

  return (
    <>
      <Box display={"flex"} flexDirection={"column"} height={"100%"}>
        <LeadViewHeader>
          <HeaderTypoGraphy> {"Convert Lead"}</HeaderTypoGraphy>
          <Divider />
        </LeadViewHeader>
        <MainScrollBarBox>
          {leadViewData ? (
            leadViewData.map((leadData, index) => {
              const LeadBoxHeader = ({ data, Name }) => {
                return (
                  <MainLeadTypoGraphy
                    id={Name}
                    sx={{
                      color:
                        leadData.data[leadData.Name] === data
                          ? "black"
                          : "#848F99",

                      background:
                        leadData.data[leadData.Name] === data && "white",
                      boxShadow:
                        leadData.data[leadData.Name] === data &&
                        "0 0 4px 0 rgb(0 0 0 / 10%)",
                      borderRadius:
                        leadData.data[leadData.Name] === data && "4px",
                      outline:
                        leadData.data[leadData.Name] === data &&
                        "1px solid #E6E9EB",
                      fontSize: "14px",
                    }}
                    onClick={(e) =>
                      handleNewOrExistingData(e.target.innerText, e.target.id)
                    }
                  >
                    {data}
                  </MainLeadTypoGraphy>
                );
              };
              return (
                <Box height={"max-content"} key={`${index}_key`}>
                  <MainLeadTypoGraphy
                    sx={{
                      marginBottom: "0.5rem",
                      fontSize: "16px",
                    }}
                  >
                    {leadData.Name}
                  </MainLeadTypoGraphy>
                  <MainLeadBox>
                    {leadData.Name !== "Convert" ? (
                      <>
                        <MainLeadNewExistingBox>
                          {["New", "Existing", "None"].map((data, index) => {
                            if (index < 2) {
                              return (
                                <LeadBoxHeader
                                  data={data}
                                  Name={leadData.Name}
                                />
                              );
                            } else if (leadData.Name === "Opportunity") {
                              return (
                                <LeadBoxHeader
                                  data={data}
                                  Name={leadData.Name}
                                />
                              );
                            }
                          })}
                        </MainLeadNewExistingBox>
                        {leadData.data[leadData.Name] === "New" &&
                          leadData.Name === "Opportunity" && (
                            <MainLeadExistingBox>
                              <SearchBox>
                                <InputBase
                                  sx={{
                                    width: "100%",
                                    fontFamily: "Inter",
                                    fontSize: "14px",
                                  }}
                                  onChange={(e) =>
                                    setOpportunityText(e.target.value)
                                  }
                                  value={
                                    opportunityText ? opportunityText : "-"
                                  }
                                />
                              </SearchBox>
                            </MainLeadExistingBox>
                          )}
                        {leadData.data[leadData.Name] === "Existing" && (
                          <MainLeadExistingBox>
                            <MainLeadBoxTypoGraphy>{`${leadData.Name} search`}</MainLeadBoxTypoGraphy>
                            <SearchBox>
                              {leadData.value ? (
                                <MainLeadBoxCovertTypoGraphy>
                                  {leadData.value}
                                  <CloseRounded
                                    color="none"
                                    fontSize="16px"
                                    sx={{
                                      cursor: "pointer",
                                    }}
                                    id={`${leadData.Name}`}
                                    onClick={(e) => {
                                      const value =
                                        e.target.id || e.currentTarget.id;
                                      handleRemoveSelectedSearchValue(
                                        leadData,
                                        value
                                      );
                                    }}
                                  />
                                </MainLeadBoxCovertTypoGraphy>
                              ) : (
                                <AddBox
                                  onClick={(event) =>
                                    handleClick(event, leadData.Name)
                                  }
                                />
                              )}
                            </SearchBox>
                            <SearchPopOver
                              open={
                                leadData.Name === "Account"
                                  ? Boolean(accountAnchorEl)
                                  : leadData.Name === "Contact"
                                  ? Boolean(contactAnchorEl)
                                  : Boolean(opportunityAnchorEl)
                              }
                              id={
                                Boolean(accountAnchorEl) ||
                                Boolean(contactAnchorEl) ||
                                Boolean(opportunityAnchorEl)
                                  ? "simple-popover"
                                  : undefined
                              }
                              anchorEl={
                                leadData.Name === "Account"
                                  ? accountAnchorEl
                                  : leadData.Name === "Contact"
                                  ? contactAnchorEl
                                  : opportunityAnchorEl
                              }
                              onClose={() => handleClose(leadData.Name)}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                              key={`${index}_key`}
                            >
                              <SimpleSearchDropDown
                                name={leadData.Name}
                                handleChange={handleSearchApi}
                                key={`${index}_key`}
                                searchLoader={searchLeadLoader}
                              />
                              <SearchDataScrollBox
                                sx={{
                                  height: searchRecords[leadData.Name]?.length
                                    ? "180px"
                                    : "0px",
                                  fontSize: "14px",
                                }}
                              >
                                {searchRecords[leadData.Name]?.length ? (
                                  searchRecords[leadData.Name].map(
                                    (searchData, index) => {
                                      if (index < searchDataLimit) {
                                        return (
                                          <SearchDataBox
                                            id={`${searchData.Id}`}
                                            sx={{
                                              background:
                                                index === 0 && "#F6F8F9",
                                            }}
                                            onClick={(e) => {
                                              handleClose(leadData.Name);
                                              const value = e.target.innerText;
                                              const id = e.target.id;
                                              handleSetSearchData(
                                                leadData,
                                                value,
                                                id,
                                                true
                                              );
                                            }}
                                          >
                                            {searchData.Name}
                                          </SearchDataBox>
                                        );
                                      } else if (
                                        searchDataLimit + 1 === index &&
                                        searchDataLimit + 1 !==
                                          searchRecords[leadData.Name]?.length
                                      ) {
                                        return (
                                          <SearchDataBox
                                            onClick={(e) => {
                                              setSearchDataLimit(
                                                searchDataLimit + 5
                                              );
                                            }}
                                            sx={{ fontSize: "12px" }}
                                          >
                                            {"Show More"}
                                          </SearchDataBox>
                                        );
                                      }
                                    }
                                  )
                                ) : (
                                  <>
                                    {searchApiResponse && (
                                      <SearchDataBox fontSize={"14px"}>
                                        {"No Records Found"}
                                      </SearchDataBox>
                                    )}
                                  </>
                                )}
                              </SearchDataScrollBox>
                            </SearchPopOver>
                            {leadData.Name === "Contact" && (
                              <MainLeadExistingBox>
                                <MainLeadBoxTypoGraphy>{`Update Lead Source`}</MainLeadBoxTypoGraphy>
                                <Checkbox
                                  size="small"
                                  value={leadData.data["LeadSource"]}
                                  sx={{
                                    padding: 0,
                                  }}
                                  checked={leadData.data["LeadSource"]}
                                  onClick={() => {
                                    handleLeadSourceAndDropDown(leadData, null);
                                  }}
                                />
                              </MainLeadExistingBox>
                            )}
                          </MainLeadExistingBox>
                        )}
                      </>
                    ) : (
                      <>
                        <MainLeadExistingBox>
                          {handleConvertSectionName() && (
                            <>
                              <MainLeadBoxTypoGraphy>
                                {convertDataTitle()}
                              </MainLeadBoxTypoGraphy>
                              <SearchBox>
                                <MainLeadBoxCovertTypoGraphy
                                  onClick={handleConvertClick}
                                >
                                  {leadData.data?.OwnerName}
                                  <CloseRounded
                                    color="none"
                                    fontSize="16px"
                                    sx={{
                                      cursor: "pointer",
                                    }}
                                  />
                                </MainLeadBoxCovertTypoGraphy>
                              </SearchBox>
                            </>
                          )}
                          <MainLeadBoxTypoGraphy>
                            {"Converted Status"}
                          </MainLeadBoxTypoGraphy>
                          <SearchBox
                            onClick={() => {
                              setStatus(!status);
                            }}
                            style={{
                              flexDirection: !status ? "row" : "row-reverse",
                            }}
                          >
                            <MainLeadBoxTypoGraphy>
                              {leadData.data?.LeadStatus}
                              <ArrowDownwardSVG
                                style={{
                                  transform: !status
                                    ? "rotate(0deg)"
                                    : "rotate(180deg)",
                                  height: "17px",
                                }}
                              />
                            </MainLeadBoxTypoGraphy>
                            {!status ? (
                              <></>
                            ) : (
                              <SimpleDropDown
                                value={leadData.data?.LeadStatus}
                                options={leadViewStatus}
                                onChange={(value) => {
                                  handleLeadSourceAndDropDown(leadData, value);
                                }}
                                closeDropdown={() => {
                                  setStatus(false);
                                }}
                                hideDefaultBox={false}
                                leadView={true}
                              />
                            )}
                          </SearchBox>
                          <SearchPopOver
                            id={convertId}
                            open={convertOpen}
                            anchorEl={convertAnchorEl}
                            onClose={() => handleConvertClose(leadData.Name)}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                          >
                            <SimpleSearchDropDown
                              name={leadData.Name}
                              handleChange={handleSearchApi}
                              key={`${index}_key`}
                              searchLoader={searchLeadLoader}
                            />
                            <SearchDataScrollBox
                              sx={{
                                height: searchRecords[leadData.Name]?.length
                                  ? "180px"
                                  : "0px",
                                fontSize: "14px",
                              }}
                            >
                              {searchRecords[leadData.Name]?.length ? (
                                searchRecords[leadData.Name].map(
                                  (searchData, index) => {
                                    if (index < searchDataLimit) {
                                      return (
                                        <SearchDataBox
                                          id={`${searchData.Id}`}
                                          sx={{
                                            background:
                                              index === 0 && "#F6F8F9",
                                          }}
                                          onClick={(e) => {
                                            handleConvertClose(leadData.Name);
                                            const value = e.target.innerText;
                                            const id = e.target.id;
                                            handleSetSearchData(
                                              leadData,
                                              value,
                                              id,
                                              false
                                            );
                                          }}
                                        >
                                          {searchData.Name}
                                        </SearchDataBox>
                                      );
                                    } else if (searchDataLimit + 1 === index) {
                                      return (
                                        <SearchDataBox
                                          onClick={() => {
                                            setSearchDataLimit(
                                              searchDataLimit + 5
                                            );
                                          }}
                                          sx={{ fontSize: "12px" }}
                                        >
                                          {"Show More"}
                                        </SearchDataBox>
                                      );
                                    }
                                  }
                                )
                              ) : (
                                <>
                                  {searchApiResponse && (
                                    <SearchDataBox fontSize={"14px"}>
                                      {"No Records Found"}
                                    </SearchDataBox>
                                  )}
                                </>
                              )}
                            </SearchDataScrollBox>
                          </SearchPopOver>
                        </MainLeadExistingBox>
                      </>
                    )}
                  </MainLeadBox>
                </Box>
              );
            })
          ) : (
            <Loader />
          )}
        </MainScrollBarBox>
        <FooterBox>
          <Divider />
          <ButtonBox
            sx={{
              borderRadius: "12px",
              width: "97%",
              padding: "1rem 0rem",
              justifyContent: "right",
            }}
          >
            {convertLeadLoader ? <Loader leadView={true} /> : <></>}
            <StyledButton
              variant="contained"
              size="small"
              onClick={() => {
                handleConvertLeadApi();
              }}
            >
              Convert Lead
            </StyledButton>
            <StyledButton
              variant="outlined"
              size="small"
              onClick={() => {
                handleLeadViewClose();
              }}
              style={{
                width: "100px",
              }}
            >
              Close
            </StyledButton>
          </ButtonBox>
        </FooterBox>
      </Box>
      {apiToaster ? (
        <StyledSnackBar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={3000}
          open={toasterState.open}
          onClose={() => {
            setApiToaster(false);
            setToasterState({ ...toasterState, open: false });
          }}
          sx={{
            ".MuiPaper-root": {
              background: "#ff0000",
              height: "max-content",
              width: "max-content",
            },
          }}
        >
          {fields && Object.keys(fields)?.length ? (
            <StyledAlert
              onClose={() => setToasterState({ ...toasterState, open: false })}
              severity={"error"}
            >
              {fields?.message
                ? fields.message
                : `Please Select an : ${fields?.Name}`}
            </StyledAlert>
          ) : (
            <></>
          )}
        </StyledSnackBar>
      ) : (
        <></>
      )}
    </>
  );
}

export default LeadView;
