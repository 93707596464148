import React, { useEffect, useRef, useState } from "react";

import {
  Alert,
  Box,
  ClickAwayListener,
  Divider,
  Drawer,
  Popover,
  Snackbar,
  Tooltip,
} from "@mui/material";
import { NavButton, Search, SearchIconWrapper } from "./Common";
import { Link, useNavigate, NavLink, useLocation } from "react-router-dom";
import { ReactComponent as HomeSVG } from "../Assets/SVG/Home.svg";
import { ReactComponent as TaskSVG } from "../Assets/SVG/Task.svg";
import { ReactComponent as NotesSVG } from "../Assets/SVG/Notes.svg";
import { ReactComponent as PipelineSVG } from "../Assets/SVG/Pipeline.svg";
import { ReactComponent as SaveSVG } from "../Assets/SVG/Save.svg";
import { ReactComponent as SearchSVG } from "../Assets/SVG/Search.svg";
import { ReactComponent as SettingSVG } from "../Assets/SVG/Footer.svg";
import { ReactComponent as GiftSVG } from "../Assets/SVG/Gift.svg";
import { ReactComponent as HorizontalLineSVG } from "../Assets/SVG/HorizontalLine.svg";
import { ReactComponent as MenuBarSVG } from "../Assets/SVG/MenuBar.svg";
import { ReactComponent as QuestionsSVG } from "../Assets/SVG/Questions.svg";
import { ReactComponent as LogoTrackTable } from "../Assets/SVG/logo_tracktable.svg";
import SearchView from "./SearchView/SearchView";
import styled from "@emotion/styled";
import { getTableHeadersApi, recentSearches } from "../Pages/Services/tableApi";
import SearchDrawer from "./SearchView/SearchDrawer";
import { createOrUpdateKey, getKeyData } from "../Pages/Services/tableApi";
import { ExpandViewPopover } from "../Pages/Pipeline";
import OpportunityView from "./ExpandView/ExpandOpportunityView";
import {
  FooterTypoGraphy,
  SettingPopover,
  WishBox,
  WishSnackBar,
} from "./Footer";
import SettingView from "./SettingView/SettingView";
import WishView from "./FooterChildComponents/WishView";
import { useNotes } from "../Context/NotesContext";
import { borderRadius, width } from "@mui/system";

import GenerateGreetings from "./GenerateGreetings";

import { hover } from "@testing-library/user-event/dist/hover";
import AccountContext, { ThingsProvider } from "../Context/new_Account_Context";
import { jsx } from "@emotion/react";
import { useSelector } from "react-redux";

const SearchViewPopover = styled(Popover)({
  background: "rgba(0, 0, 0, 0.3)",
  ".MuiPaper-root": {
    borderRadius: "6px",
    boxShadow: "0 4px 20px rgb(0 0 0 / 40%)",
    // boxShadow:
    //   "20px 28px 24px rgba(0, 0, 0, 0.1), inset -2px -2px 4px rgba(0, 0, 0, 0.06)",
    height: "529px",
    width: "660px",
  },
});

export const MainHeaderChildBox = styled(Box)({
  display: "flex",
  position: "relative",
  width: "100%",
  fontFamily: "Inter",
});

export const StyledSnackBar = styled(Snackbar)({
  ".MuiPaper-root": {
    color: "#ffffff",
    fontFamily: "Inter",
    wordSpacing: "1px",
  },
});

export const StyledAlert = styled(Alert)({
  width: "100%",
  ".MuiAlert-icon": {
    display: "none",
  },
  ".MuiAlert-action": {
    padding: "0px",
    paddingLeft: "1rem",
    margin: "auto",
  },
  ".MuiSvgIcon-root": {
    fontSize: "16px",
  },
});

const DisappearingSideNavigation = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  alignItems: "center",
  gap: "20px",

  marginTop: "6rem",
  position: "absolute",
  left: "0%",
  transform: "translate(0%, -50%)",
  zIndex: 111,

  width: "56px",
  height: "574px",

  backgroundColor: "#EBEBEB",

  // background: " rgba(255, 255, 255, 0.24)",
  // backdropFilter: "blur(124px)",
  border: "1px solid rgba(235, 235, 235, 0.24)",
  borderRadius: "0px 16px 16px 0px",
});

const SideNavigationBarBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  alignItems: "center",
  gap: "20px",

  position: "absolute",
  top: "50%",
  left: "0%",
  transform: "translate(0%, -50%)",
  zIndex: 111,

  width: "56px",
  height: "574px",

  backgroundColor: "#EBEBEB",

  // background: " rgba(255, 255, 255, 0.24)",
  // backdropFilter: "blur(124px)",
  border: "1px solid rgba(235, 235, 235, 0.24)",
  borderRadius: "0px 16px 16px 0px",
});

const SideNavBarMenusBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "20px",
  position: "relative",
  stroke: "#798EA3",
  strokeWidth: "1.4px",

  "& .iconHover": {
    "&:hover": {
      // strokeWidth: "1.4px",
      stroke: "#00A1E0",
    },
  },
});

export const defaultFields = {
  Opportunity: [
    "Name",
    "Description",
    "Amount",
    "CloseDate",
    "AccountId",
    "OwnerId",
    "StageName",
    "NextStep",
    "LeadSource",
    "Type",
    "Probability",
  ],
  Lead: [
    "FirstName",
    "LastName",
    "Company",
    "Status",
    "Title",
    "Email",
    "Phone",
    "Website",
    "Industry",
    "LeadSource",
    "OwnerId",
  ],
  Contact: [
    "FirstName",
    "LastName",
    "AccountId",
    "Title",
    "Email",
    "MobilePhone",
    "LeadSource",
    "OwnerId",
  ],
  Event: [
    "Subject",
    "Description",
    "StartDateTime",
    "EndDateTime",
    "WhoId",
    "WhatId",
    "OwnerId",
  ],
  Account: ["Name", "Description", "Industry", "Website", "Phone", "OwnerId"],
  Task: [
    "WhoId",
    "WhatId",
    "Subject",
    "Status",
    "ActivityDate",
    "OwnerId",
    "Description",
  ],
  Tasks: ["Subject"],
};

export const requiredFields = {
  Opportunity: ["AccountId", "Name", "StageName", "CloseDate"],
  Account: ["Name"],
  Contact: ["AccountId", "LastName"],
  Lead: ["LastName", "Company"],
  Event: ["Subject", "StartDateTime", "EndDateTime"],
  Task: ["Subject"],
  Tasks: ["Subject"],
};

function Navbar({
  isLoggedIn,
  showOrHide,
  showOrHideFields,
  setAppShortCut,
  appShortCut,
  setBookmark,
}) {
  let navigate = useNavigate();
  const [rowId, setRowId] = useState("");
  const [header, setHeader] = useState([]);
  const [activeHeader, setActiveHeader] = useState([]);
  const [searchAnchorEl, setSearchAnchorEl] = useState(null);
  const [recentSearch, setRecentSearch] = useState([]);
  const [state, setState] = useState({
    right: false,
  });
  const [tableName, setTableName] = useState(null);
  const [tableHeader, setTableHeader] = useState(null);
  const [data, setData] = useState(null);
  const [apiToaster, setApiToaster] = useState(false);
  const [toasterState, setToasterState] = useState({
    open: false,
  });
  const [fields, setFields] = useState({});
  const [successTableName, setSuccessTableName] = useState(null);

  const [SettingAnchorEl, setSettingAnchorEl] = useState(null);
  const [WishAnchorEl, setWishAnchorEl] = useState(null);
  const [messageSent, setMessageSent] = useState(false);
  const [openWishView, setOpenWishView] = useState(false);
  const [openSuccessToaster, setOpenSuccessToaster] = useState(true);
  const { stateNotes, dispatchNotes } = useNotes();
  // const [time, setTime] = useState(moment());
  const [preserveData, setPreserveData] = useState({
    Note: "",

    Task: "",

    Opportunity: "",

    Account: "",

    Contact: "",

    Lead: "",

    Event: "",

    Tasks: "",

    Help: "",
  });
  const [preserveDataKeys, setPreserveDataKeys] = useState({
    Note: "",

    Task: "",

    Opportunity: "",

    Account: "",

    Contact: "",

    Lead: "",

    Event: "",

    Tasks: "",

    Help: "",
  });
  const isWishModalActive = useSelector((state) => {
    return state?.toggleWish?.active;
  });

  const location = useLocation();

  //--- settingView Open ---//
  const handleSettingClick = () => {
    setSettingAnchorEl(document.getElementById("root"));
  };

  const handleSettingClose = () => {
    setSettingAnchorEl(null);
    if (!localStorage.getItem("access_token")) {
      window.location = "/login";
    }
  };
  const open = Boolean(SettingAnchorEl);
  const settingId = open ? "simple-popover" : null;
  // --- //

  const handleClose = () => {
    setToasterState({ ...toasterState, open: false });
  };
  useEffect(() => {
    if (localStorage.getItem("access_token")) {
      // localStorage.setItem("preserveKeyData", JSON.stringify(preserveDataKeys));
      const callBackFunction = async () => {
        let oldPreserveData = await getKeyData(
          JSON.stringify({
            key: `preserveData`,
          })
        );
        setPreserveDataKeys(
          oldPreserveData?.data?.value
            ? oldPreserveData?.data?.value
            : preserveDataKeys
        );
        // if (Object.keys(oldPreserveData.data.value).length) {
        // }
      };
      callBackFunction();
    }
  }, [localStorage.getItem("access_token")]);
  // //--- wish, invite and gift View Open ---//
  // const handleWishClick = (event) => {
  //   setWishAnchorEl(event.currentTarget);
  // };
  // const handleWishClose = () => {
  //   setWishAnchorEl(null);
  // };
  // const wishOpen = Boolean(WishAnchorEl);
  // const wishId = wishOpen ? "simple-popover" : null;

  // // --- //

  useEffect(() => {}, [apiToaster, toasterState]);

  // useEffect(() => {
  //   setInterval(() => {
  //     setTime(moment());
  //   }, 1000);
  // }, []);

  const getTableHeader = async (tableName) => {
    //debugger;
    const AllDropDownData = [
      {
        metric_datatype: "string",
        metric_display_name: `New ${tableName}`,
        metric_name: `New ${tableName}`,
      },
      {
        metric_datatype: "string",
        metric_display_name: "New tile",
        metric_name: "New tile",
      },
    ];
    // localStorage.removeItem("SearchViewData");
    const dataParams = {
      table_name: tableName,
    };
    const JSONdataParams = JSON.stringify(dataParams);
    const res = await getTableHeadersApi(JSONdataParams);

    if (res?.data?.status === 200) {
      const createAbleDataOnly = res.data.headers.filter(
        (Data) =>
          Data.creatable ||
          (tableName === "Task" ? Data.metric_name === "Id" : false)
      );

      // const defaultActiveField = createAbleDataOnly.map((value, index) => {
      //   if (index < 5) {
      //     return { ...value, isVisible: true };
      //   } else return { ...value, isVisible: false };
      // });

      let defaultActiveField = createAbleDataOnly.map((value, index) => {
        return {
          ...value,
          isVisible:
            tableName === "Task"
              ? value.selected
              : defaultFields[tableName].includes(value.metric_name),
          selected:
            tableName === "Task"
              ? value.selected
              : defaultFields[tableName].includes(value.metric_name),
          nullable: !requiredFields[tableName].includes(value.metric_name),
        };
      });

      defaultActiveField = defaultActiveField.sort((a, b) => {
        if (a.selected === b.selected) return 0;
        if (a.selected > b.selected) return -1;
        return 1;
      });

      let sortedFields = defaultFields[tableName]
        .map((name) =>
          defaultActiveField.find(
            (data) =>
              (data?.call_parent
                ? `${data?.call_parent}.${data?.metric_name}`
                : data?.metric_name) === name
          )
        )
        .filter((d) => d);

      if (sortedFields?.length) {
        defaultActiveField.splice(0, sortedFields.length, sortedFields);
        defaultActiveField = defaultActiveField.flat();
      }

      let data = {
        AllDropDownValue: AllDropDownData,
        data: { [`New ${tableName}`]: defaultActiveField },
        defaultData: createAbleDataOnly,
      };

      // const JSONdataParams = JSON.stringify({
      //   key: `${tableName}SearchViewData`,
      //   value: data,
      // });
      // const response = await createOrUpdateKey(JSONdataParams);
      // if (response.data.status) {
      let oldPreserveData = await getKeyData(
        JSON.stringify({
          key: `preserveData`,
        })
      );
      //debugger;
      let preserveDatas = {};
      let a = [];
      let changeData = {};
      if (
        oldPreserveData.data.status === 200 &&
        oldPreserveData.data.value[tableName] !== ""
      ) {
        Object.keys(oldPreserveData.data.value[tableName]).map((mData) => {
          // debugger;
          const AllDropDownDataCopy = {
            metric_datatype: "string",
            metric_display_name: mData,
            metric_name: mData,
          };
          a.push(AllDropDownDataCopy);

          const header = defaultActiveField.map((fieldData) => {
            const flag = oldPreserveData.data.value[tableName][mData].includes(
              fieldData.metric_name
            );

            fieldData.selected = flag;
            fieldData.isVisible = flag;

            return fieldData;
          });

          Object.assign(changeData, { [mData]: header });
          changeData = JSON.parse(JSON.stringify(changeData));
        });

        a.push({
          metric_datatype: "string",
          metric_display_name: "New tile",
          metric_name: "New tile",
        });

        preserveDatas.defaultData = createAbleDataOnly;
        preserveDatas.AllDropDownValue = a;
        preserveDatas.data = changeData;
      }
      if (Object.keys(preserveDatas)?.length) {
        data = preserveDatas;
      }

      setData(data);
      setPreserveData({
        ...preserveData,
        [tableName]: data,
      });
      localStorage.setItem(
        "SearchViewData",
        JSON.stringify(createAbleDataOnly)
      );
      setTableHeader(createAbleDataOnly);
      setState({ ...state, right: true });
      setTableName(tableName);
      // }
    }
  };

  useEffect(() => {
    let a = { ...preserveData[tableName]?.data };
    let simpleKey = Object.keys(a);
    simpleKey.map((data) => {
      a = {
        ...a,
        [data]: a[data]
          .map((data) => {
            if (data.selected) {
              return data.metric_name;
            }
          })
          .filter((d) => d),
      };
    });

    setPreserveDataKeys({
      ...preserveDataKeys,
      [tableName]: a,
    });
  }, [preserveData]);

  const toggleDrawer =
    (anchor, open, tableName, newAccFromOpp, backToTableName) =>
    async (event) => {
      // debugger;
      handleSearchViewClose();
      if (newAccFromOpp) {
        localStorage.setItem(
          "newAccFromOpp",
          JSON.stringify([true, backToTableName])
        );
      }
      //setTableName(tableName);
      if (
        event?.type === "keydown" &&
        (event?.key === "Tab" || event?.key === "Shift")
      ) {
        return;
      }

      const JSONdataParams = JSON.stringify({
        key: `${tableName}SearchViewData`,
      });
      //debugger;
      if (open) {
        let key = "";
        // const response = await getKeyData(JSONdataParams);
        // if (
        //   response?.data?.status === 200 &&
        //   Object.keys(response?.data?.value).length
        // ) {

        if (preserveData[tableName] !== "") {
          // debugger;

          //let keysOfPreservData = a.data.map((data,i)=>)
          key =
            preserveData[tableName] &&
            Object.keys(preserveData[tableName]?.data);
          if (
            preserveData[tableName]?.data?.hasOwnProperty(`New ${tableName}`) ||
            preserveData[tableName]?.AllDropDownValue?.some(
              (data) => data.metric_display_name == key[0]
            )
          ) {
            setData(preserveData[tableName]);
            setTableName(tableName);
            setState({ ...state, [anchor]: open });
          } else {
            getTableHeader(tableName);
          }
        } else {
          getTableHeader(tableName);
        }
      } else {
        localStorage.setItem("newAccFromOpp", JSON.stringify([false, ""]));
        setState({ ...state, [anchor]: open });
      }
    };

  const handleSearchView = async (event) => {
    localStorage.setItem("newAccFromOpp", JSON.stringify([false, ""]));
    setSearchAnchorEl(document.getElementById("root"));
    const response = await recentSearches();
    if (response?.data?.status === 200) {
      setRecentSearch(response.data.recent_search);
    }
  };

  const handleSearchViewClose = () => {
    setSearchAnchorEl(null);
  };

  const SearchViewOpen = Boolean(searchAnchorEl);
  const id = SearchViewOpen ? "simple-popover" : undefined;

  const Menus = [
    { url: "/", svg: <HomeSVG />, title: "Home" },
    { url: `/tasks`, svg: <TaskSVG />, title: "Tasks" },
    { url: "/notes/1", svg: <NotesSVG />, title: "Notes" },
    { url: "/pipeline", svg: <PipelineSVG />, title: "Pipeline" },
  ];
  const Menus2 = [
    {
      svg: <QuestionsSVG />,
      title: "Help",
    },
    { svg: <GiftSVG />, title: "Request a feature" },
  ];

  const onClickRow = async (_tableName, _id, data) => {
    if (_tableName === "Notes") {
      navigate(`/Notes/1`);
      dispatchNotes({ type: "LOAD_NOTE", payload: data });
      setSearchAnchorEl(null);
      return;
    }
    if (_tableName === "Task") {
      navigate(`/tasks/${_id}`);
      setSearchAnchorEl(null);
      return;
    }
    setRowId(_id);
    setTableName(_tableName);
    localStorage.setItem("manageHeader", "Overview");
    const response = await getTableHeadersApi(
      JSON.stringify({
        table_name: _tableName,
      })
    );
    let _header = response?.data?.headers;
    let _activeHeader = response?.data?.headers?.filter(
      (data) => data.selected
    );
    setHeader(_header);
    setActiveHeader(_activeHeader);

    // localStorage.setItem(
    //   `ExpandOverview${_tableName}Headers`,
    //   JSON.stringify(_header)
    // );
    const JSONdataParams = JSON.stringify({
      key: `ExpandOverview${_tableName}Headers`,
      value: _header,
    });
    await createOrUpdateKey(JSONdataParams);
    // localStorage.setItem(
    //   `ExpandActiveOverview${_tableName}Headers`,
    //   JSON.stringify(_activeHeader)
    // );

    setSearchAnchorEl(null);
    handleExpandView();
  };

  useEffect(() => {
    if (
      localStorage.getItem("access_token") &&
      preserveDataKeys &&
      Object.values(preserveDataKeys).some((d) => d !== "")
    ) {
      const callBackFunction = async () => {
        await createOrUpdateKey(
          JSON.stringify({
            key: `preserveData`,
            value: preserveDataKeys,
          })
        );
      };
      callBackFunction();
    }

    // localStorage.setItem("preserveKeyData", JSON.stringify(preserveDataKeys));
  }, [preserveDataKeys]);

  const [expandAnchorEl, setExpandAnchorEl] = useState(null);
  const [focusOn, setFocusOn] = useState(false);
  const [bottom, setBottom] = useState(false);
  const [navIndex, setIndex] = useState(null);

  const handleExpandView = () => {
    setExpandAnchorEl(document.getElementById("root"));
  };
  const handleExpandViewClose = () => {
    localStorage.setItem(`${tableName}TilesApiData`, null);
    localStorage.setItem("manageHeader", "Overview");
    setExpandAnchorEl(null);
  };

  const expandViewOpen = Boolean(expandAnchorEl);
  const searchId = expandViewOpen ? "simple-popover" : undefined;

  // useEffect(() => {}, [openWishView]);

  const getActiveStyle = ({ isActive }) => {
    if (!focusOn && !bottom) {
      return {
        backgroundColor: isActive && "#00A1E0",
        padding: isActive && "12px",
        stroke: isActive && "white",
        borderRadius: "29px",
        transitionDuration: ".5s",
      };
    }
  };

  const handleHelpClick = () => {
    window.open(
      "https://www.notion.so/aryan-product/TrackTable-Help-fb0297c0c5c64c96a1ea6a538b1e5ddb",
      "_blank"
    );
  };
  const backToLastPage = JSON.parse(localStorage.getItem("newAccFromOpp"));
  return (
    <Box
      container
      spacing={2}
      display="flex"
      justifyContent="center"
      padding="12px 28px"
    >
      {location.pathname !== "/" ? (
        <SideNavigationBarBox>
          <SideNavBarMenusBox>
            {Menus.map((menuItems, index) => {
              return (
                <NavLink
                  style={getActiveStyle}
                  to={menuItems.url}
                  onClick={(e) => {
                    if (!isLoggedIn) {
                      e.preventDefault();
                    } else {
                      setFocusOn(false);
                      setBottom(false);
                    }
                  }}
                  className="iconHover"
                  key={index}
                >
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                      sx: {
                        "& .MuiTooltip-tooltip": {
                          width: "50px",
                          height: "16px",
                          background: "rgba(3, 46, 97, 0.72)",
                          borderRadius: "4px",
                          fontFamily: "Inter",
                          textAlign: "center",
                        },
                      },
                    }}
                    placement="right"
                    title={menuItems.title}
                  >
                    <NavButton>{menuItems.svg}</NavButton>
                  </Tooltip>
                </NavLink>
              );
            })}
          </SideNavBarMenusBox>

          {/* -------------------------------------------------------------------------------- */}
          <SideNavBarMenusBox>
            <Box
              sx={{
                backgroundColor: focusOn && "#00A1E0",
                padding: focusOn ? "12px" : "24px 0",
                stroke: focusOn && "white",
                borderRadius: focusOn && "29px",
                transitionDuration: focusOn && ".5s",
                "&:hover": {
                  stroke: focusOn ? "white" : " #00A1E0",
                  strokeWidth: !focusOn && "1.4px",
                },
              }}
              borderTop="1px solid #939fa5"
              borderBottom="1px solid #939fa5"
              onClick={(e) => {
                if (!isLoggedIn) {
                  e.preventDefault();
                } else {
                  setFocusOn(true);
                  setIndex(null);
                  setBottom(false);
                  handleSettingClick();
                }
              }}
            >
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                  sx: {
                    "& .MuiTooltip-tooltip": {
                      width: "50px",
                      height: "16px",
                      background: "rgba(3, 46, 97, 0.72)",
                      borderRadius: "4px",
                      fontFamily: "Inter",
                      textAlign: "center",
                    },
                  },
                }}
                placement="right"
                title="Settings"
              >
                <NavButton>
                  <SettingSVG />
                </NavButton>
              </Tooltip>
            </Box>
          </SideNavBarMenusBox>

          {/* -------------------------------------------------------------------------------- */}

          <SideNavBarMenusBox>
            {Menus2.map((menuItems, index) => {
              return (
                <Box
                  sx={{
                    backgroundColor:
                      ((navIndex === index && bottom) ||
                        (isWishModalActive &&
                          menuItems.title === "Request a feature")) &&
                      "#00A1E0",
                    padding:
                      ((navIndex === index && bottom) ||
                        (isWishModalActive &&
                          menuItems.title === "Request a feature")) &&
                      "12px",
                    stroke:
                      ((navIndex === index && bottom) ||
                        (isWishModalActive &&
                          menuItems.title === "Request a feature")) &&
                      "white",
                    borderRadius:
                      ((navIndex === index && bottom) ||
                        (isWishModalActive &&
                          menuItems.title === "Request a feature")) &&
                      "29px",
                    transitionDuration:
                      ((navIndex === index && bottom) ||
                        (isWishModalActive &&
                          menuItems.title === "Request a feature")) &&
                      ".5s",
                    "&:hover": {
                      stroke: navIndex === index ? "white" : " #00A1E0",
                      strokeWidth: navIndex != index && "1.4px",
                    },
                  }}
                  onClick={(e) => {
                    if (!isLoggedIn) {
                      e.preventDefault();
                    } else {
                      setBottom(true);
                      setFocusOn(false);
                      setIndex(index);
                      menuItems.title === "Request a feature" &&
                        setOpenWishView(!openWishView);
                      menuItems.title === "Help" && handleHelpClick();
                    }
                  }}
                  key={index}
                >
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                      sx: {
                        "& .MuiTooltip-tooltip": {
                          width: "50px",
                          height:
                            menuItems.title === "Request a feature"
                              ? "24px"
                              : "16px",
                          // height: "16px",
                          background: "rgba(3, 46, 97, 0.72)",
                          borderRadius: "4px",
                          fontFamily: "Inter",
                          textAlign: "center",
                        },
                      },
                    }}
                    placement="right"
                    title={menuItems.title}
                  >
                    <NavButton>{menuItems.svg}</NavButton>
                  </Tooltip>
                </Box>
              );
            })}
          </SideNavBarMenusBox>
        </SideNavigationBarBox>
      ) : (
        <DisappearingSideNavigation className="animate__animated animate__slideOutLeft">
          <SideNavBarMenusBox>
            {Menus.map((menuItems, index) => {
              return (
                <NavLink
                  style={getActiveStyle}
                  to={menuItems.url}
                  onClick={(e) => {
                    if (!isLoggedIn) {
                      e.preventDefault();
                    } else {
                      setBottom(false);
                    }
                  }}
                  className="iconHover"
                  key={index}
                >
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                      sx: {
                        "& .MuiTooltip-tooltip": {
                          width: "50px",
                          height: "16px",
                          background: "rgba(3, 46, 97, 0.72)",
                          borderRadius: "4px",
                          fontFamily: "Inter",
                          textAlign: "center",
                        },
                      },
                    }}
                    placement="right"
                    title={menuItems.title}
                  >
                    <NavButton>{menuItems.svg}</NavButton>
                  </Tooltip>
                </NavLink>
              );
            })}
          </SideNavBarMenusBox>

          <SideNavBarMenusBox>
            <Box
              borderTop="1px solid #939fa5"
              borderBottom="1px solid #939fa5"
              padding="24px 0"
              onClick={(e) => {
                if (!isLoggedIn) {
                  e.preventDefault();
                } else {
                  handleSettingClick();
                }
              }}
            >
              <Tooltip
                className="iconHover"
                PopperProps={{
                  disablePortal: true,
                  sx: {
                    "& .MuiTooltip-tooltip": {
                      width: "50px",
                      height: "16px",
                      background: "rgba(3, 46, 97, 0.72)",
                      borderRadius: "4px",
                      fontFamily: "Inter",
                      textAlign: "center",
                    },
                  },
                }}
                placement="right"
                title="Setting"
              >
                <NavButton>
                  <SettingSVG />
                </NavButton>
              </Tooltip>
            </Box>
          </SideNavBarMenusBox>

          <SideNavBarMenusBox>
            {Menus2.map((menuItems, index) => {
              return (
                <Box
                  className="iconHover"
                  style={{
                    "& .iconHover": {
                      "&:hover": {
                        stroke: "white",
                      },
                    },
                  }}
                  onClick={(e) => {
                    if (!isLoggedIn) {
                      e.preventDefault();
                    } else {
                      menuItems.title === "Request a feature" &&
                        setOpenWishView(!openWishView);
                      menuItems.title === "Help" && handleHelpClick();
                    }
                  }}
                  key={index}
                >
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                      sx: {
                        "& .MuiTooltip-tooltip": {
                          width: "50px",
                          height: "16px",
                          background: "rgba(3, 46, 97, 0.72)",
                          borderRadius: "4px",
                          fontFamily: "Inter",
                          textAlign: "center",
                        },
                      },
                    }}
                    placement="right"
                    title={menuItems.title}
                  >
                    <NavButton>{menuItems.svg}</NavButton>
                  </Tooltip>
                </Box>
              );
            })}
          </SideNavBarMenusBox>
        </DisappearingSideNavigation>
      )}

      {!messageSent ? (
        <>
          {openWishView ? (
            <ClickAwayListener
              onClickAway={() => {
                if (openWishView) {
                  setOpenWishView(false);
                }
              }}
            >
              <Box>
                <WishBox
                  sx={{
                    bottom: "15px",
                    left: "65px",
                  }}
                >
                  <WishView
                    handleSuccessWish={() => {
                      setMessageSent(true);
                      setOpenSuccessToaster(true);
                    }}
                  />
                </WishBox>
              </Box>
            </ClickAwayListener>
          ) : (
            <></>
          )}
        </>
      ) : (
        <Box>
          <WishSnackBar
            open={true}
            autoHideDuration={2000}
            onClose={() => {
              setOpenWishView(false);
              setMessageSent(false);
              setOpenSuccessToaster(false);
            }}
            message="Thank you! We will be in touch."
            sx={{
              ".MuiPaper-root": {
                bottom: "180px",
                left: "42px",
              },
            }}
          />
        </Box>
      )}
      {apiToaster ? (
        <StyledSnackBar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={3000}
          open={toasterState.open}
          onClose={handleClose}
          sx={{
            ".MuiPaper-root": {
              background: Object.keys(fields).length
                ? "#ff0000"
                : successTableName === "AccountId"
                ? "#ff0000"
                : "#008000",
            },
          }}
        >
          <StyledAlert
            onClose={handleClose}
            severity={
              Object.keys(fields).length
                ? "error"
                : successTableName === "AccountId"
                ? "error"
                : "success"
            }
          >
            {Object.keys(fields).length &&
            !Object.keys(fields).includes("msg_status")
              ? `Some Required Fields Missing: ${Object.keys(fields)?.filter(
                  (value) => fields[value] === "" && `${value},`
                )}`
              : Object.keys(fields).includes("message")
              ? fields.message
              : successTableName === "AccountId"
              ? `${successTableName} is Wrong`
              : `${successTableName} Created Successfully`}
          </StyledAlert>
        </StyledSnackBar>
      ) : (
        <></>
      )}
      <Box display="flex" width="100%" alignItems="center">
        <MainHeaderChildBox
          color="#ffffff"
          paddingLeft="32px"
          textTransform="uppercase"
          fontSize="20px"
          fontWeight="600"
          flexDirection="row"
        >
          <LogoTrackTable
            style={{
              width: "150px",
            }}
          />
        </MainHeaderChildBox>
        <Tooltip
          PopperProps={{
            disablePortal: true,
            sx: {
              "& .MuiTooltip-tooltip": {
                height: "16px",
                borderRadius: "8px",
                fontFamily: "Inter",
                textAlign: "center",
              },
            },
          }}
          title={"Search or Create"}
        >
          <Search
            aria-describedby={id}
            onClick={handleSearchView}
            cursor={"pointer"}
            style={{
              width: "100%",
            }}
          >
            <SearchIconWrapper>
              <SearchSVG />
            </SearchIconWrapper>
            <Box style={{ paddingLeft: "40px", color: "#fff" }}>
              Search or Create...
            </Box>
          </Search>
        </Tooltip>
        <GenerateGreetings greeting={false} />
      </Box>
      <SearchViewPopover
        id={id}
        open={SearchViewOpen}
        anchorEl={searchAnchorEl}
        onClose={handleSearchViewClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
      >
        <SearchView
          onClickRow={onClickRow}
          toggleDrawer={toggleDrawer}
          searchView={() => handleSearchViewClose()}
          recentSearches={recentSearch}
          manageShortCut={showOrHide}
          showOrHideFields={showOrHideFields}
          appShortCut={appShortCut}
          setAppShortCut={setAppShortCut}
          setBookmark={setBookmark}
        />
      </SearchViewPopover>
      <Drawer
        anchor={"right"}
        open={state.right}
        onClose={toggleDrawer("right", false)}
        sx={{
          ".MuiPaper-root": {
            overflow: "visible",
          },
        }}
      >
        <ThingsProvider value={toggleDrawer}>
          <SearchDrawer
            tableName={tableName}
            tableHeader={tableHeader}
            searchTableData={data}
            closeDrawer={toggleDrawer("right", false)}
            setData={(data) => {
              setData(data);
            }}
            setApiToaster={(fields, tableName) => {
              setFields(fields);
              setApiToaster(true);
              setSuccessTableName(tableName);
              setToasterState({ open: true });
            }}
            setPreserveData={setPreserveData}
            preserveData={preserveData}
            toggleDrawer={toggleDrawer(
              "right",
              true,
              backToLastPage && backToLastPage[1]
            )}
            //setAccountData={() => {}}
          />
        </ThingsProvider>
      </Drawer>
      <ExpandViewPopover
        id={searchId}
        open={expandViewOpen}
        anchorEl={expandAnchorEl}
        onClose={handleExpandViewClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
      >
        <OpportunityView
          expandView={() => {
            setExpandAnchorEl(null);
          }}
          header={header}
          activeHeader={activeHeader}
          tableName={tableName}
          rowId={rowId}
          leadView={true}
          taskView={false}
        />
      </ExpandViewPopover>
      <SettingPopover
        id={settingId}
        open={open}
        anchorEl={SettingAnchorEl}
        onClose={handleSettingClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
      >
        <SettingView
          settingViewClose={() => handleSettingClose()}
          manageShortCut={showOrHide}
          showOrHideFields={showOrHideFields}
          appShortCut={appShortCut}
          setAppShortCut={setAppShortCut}
          setBookmark={setBookmark}
        />
      </SettingPopover>
    </Box>
  );
}

export default Navbar;
