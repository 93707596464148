import styled from "@emotion/styled";
import { TextareaAutosize, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ReactComponent as TaskFieldSVG } from "../../Assets/SVG/TaskField.svg";

export const TaskTextInput = styled("input")({
  display: "flex",
  flex: 1,
  fontFamily: "Inter",
  fontWeight: 600,
  borderRadius: "8px",
  padding: "8px 12px",
  fontSize: "18px",
  border: "1px solid transparent",
  "&:hover": {
    border: "1px solid #E6E9EB",
    cursor: "pointer",
  },
  "&:focus": {
    outline: "none",
    borderColor: "rgba(157,165,173,0.75)",
  },
});

export const TextBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  fontFamily: "Inter",
  fontWeight: 600,
  borderRadius: "8px",
  border: "1px solid #E6E9EB",
  gap: "4px",
  cursor: "pointer",
  "&:focus-visible": {
    outline: "none",
    outlineColor: "#E6E9EB",
  },
  position: "relative",
});

export const TextArea = styled(TextareaAutosize)({
  display: "flex",
  flex: 1,
  fontFamily: "Inter",
  borderRadius: "8px",
  padding: "8px 12px",
  fontSize: "16px",
  border: "none",
  cursor: "pointer",
  resize: "none",
  "&:focus-visible": {
    outline: "none",
  },
});

export const FieldBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  height: "max-content",
  boxShadow: "0 3px 10px rgb(0 0 0 / 10%)",
  background: "#ffffff",
  zIndex: 1,
  position: "absolute",
  border: "1px solid #E6E9EB",
  top: "80%",
  left: "50%",
  minWidth: "200px",
  borderRadius:"8px"
});

export const SubFieldBox = styled(Box)({
  height: "33px",
  padding: "0rem 1.5rem",
  display: "flex",
  alignItems: "center",
  gap: "10px",
  "&:hover": {
    backgroundColor: "#F2F7FC",
    stroke: "#00A1E0",
    color: "#00A1E0",
    cursor: "pointer",
  },
});

export const HeaderTaskTypoGraphy = styled(Typography)({
  fontSize: "14px",
  fontFamily: "Inter",
  cursor: "pointer",
});

export const TaskFieldSvg = styled(TaskFieldSVG)({
  height: "30%",
  width: "22px",
  marginTop: "4px",
  marginRight: "8px",

  ":hover": { color: "#00A1E0" },
});

export const RelatedAndDueDateBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "1rem",
});

export const RelatedOrDueDateBox = styled(Box)({
  display: "flex",
  gap: "12px",
  alignItems: "center",
  cursor: "pointer",
  "&:hover svg": {
    visibility: "visible",
    height: "16px",
    color: "#00A1E0",
  },
});

export const CommentsTextArea = styled(TextareaAutosize)({
  resize: "none",
  margin: "auto",
  fontSize: "14px",
  marginBottom: "1rem",
  fontFamily: "Inter",
  border: "1px solid transparent",
  borderRadius: "8px",
  padding: "0.25rem",
  "&:hover": {
    borderColor: "#E6E9EB",
  },
  "&:focus": {
    outline: "none",
    borderColor: "rgba(157,165,173,0.75)",
  },
});

// export const DueDateBox = styled(Box)({
//   display: "flex",
//   gap: "12px",
//   alignItems: "center",
//   cursor: "pointer",
// });
