import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { useNotes } from "../../Context/NotesContext";
import { editNote } from "../../Pages/Services/notesApi";
import Loader from "../Loader";

const SearchResultContainer = styled.div({
  width: "21rem",
  height: "300px",
  position: "absolute",
  bottom: "-315px",
  padding: "0 8px",
  zIndex: "3",

  borderRadius: "8px",
  background: "#FFFFFF",
  border: "1px solid #EFF5F8",
  boxShadow: "8px 18px 12px rgba(0, 0, 0, 0.05)",

  overflow: "auto",
  scrollbarGutter: "stable",
});

const SearchMain = styled.div({
  padding: "8px",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "space-between",
});

const SalesforceObjects = styled.div({
  width: "100%",
  borderBottom: "3px solid #FAFAFA",
});

const SalesforceHeader = styled.div({
  padding: "8px 0",
  fontSize: "85%",
  fontWeight: "400",
});

const ResultContainer = styled.div({
  position: "relative",
  padding: "4px 0",

  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",

  "& .resultTooltip": {
    display: "none",
    fontSize: "90%",
    position: "absolute",
    top: "30px",
    background: "rgba(3, 46, 97, 0.72)",
    zIndex: "5",
    padding: "4px",
    color: "white",
    borderRadius: "4px",
  },

  ":hover": {
    "& .resultTooltip": {
      display: "block",
    },
  },
});

const ResultContent = styled.div({
  cursor: "pointer",
  fontSize: "95%",
  color: "black",
  fontWeight: "500",

  textOverflow: "ellipsis",
  overflow: "hidden",
  maxWidth: "15rem",
  height: "1.2em",
  whiteSpace: "nowrap",
});

const ResultMail = styled.div({
  color: "grey",
  fontSize: "80%",
  paddingLeft: "8px",

  textOverflow: "ellipsis",
  overflow: "hidden",
  maxWidth: "7rem",
  height: "1.2em",
  whiteSpace: "nowrap",
});

const NoResultDiv = styled.div({
  paddingBottom: "8px",
  fontSize: "95%",
  color: "black",
  fontWeight: "500",
});

const LoadMoreDiv = styled.div({
  fontSize: "80%",
  paddingBottom: "8px",
  cursor: "pointer",
});

function SearchResult({
  addTags,
  setShowSearchInput,
  setShowNotesExtended,
  salesforceData,
  setSearchText,
  searchText,
  syncNoteHandler,
}) {
  const { stateNotes, dispatchNotes } = useNotes();
  const { singleNote } = stateNotes;

  const clickHandler = async (itemId, name, data, type) => {
    dispatchNotes({
      type: "SET_SALESFORCE_OBJECT",
      payload: { salesforceId: itemId, type: type },
    });

    let newValue = {
      id: itemId,
      name: name,
      data: data,
      type: type,
    };
    addTags(newValue);

    const newSingleNote = {
      ...singleNote,
      salesforce_array: [...singleNote.salesforce_array, newValue],
      // salesforce_array: [newValue],
    };
    editNote(dispatchNotes, newSingleNote);
    syncNoteHandler(newSingleNote);
    setShowSearchInput(false);
    setShowNotesExtended(true);
    setSearchText("");
  };

  const [accountArray, setAccountArray] = useState([]);
  const [contactArray, setContactArray] = useState([]);
  const [eventArray, setEventArray] = useState([]);
  const [leadArray, setLeadArray] = useState([]);
  const [opportunityArray, setOpportunityArray] = useState([]);

  const [showMoreAccount, setShowMoreAccount] = useState(1);
  const [showMoreContact, setShowMoreContact] = useState(1);
  const [showMoreEvent, setShowMoreEvent] = useState(1);
  const [showMoreLead, setShowMoreLead] = useState(1);
  const [showMoreOpportunity, setShowMoreOpportunity] = useState(1);

  useEffect(() => {
    setAccountArray(salesforceData?.Account);
    setContactArray(salesforceData?.Contact);
    setEventArray(salesforceData?.Event);
    setLeadArray(salesforceData?.Lead);
    setOpportunityArray(salesforceData?.Opportunity);
  }, [salesforceData]);

  return (
    <SearchResultContainer>
      {Object?.keys(salesforceData)?.length === 0 ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {searchText.length < 2 ? "Enter atleast 2 characters" : <Loader />}
        </div>
      ) : (
        <SearchMain>
          <SalesforceObjects>
            <SalesforceHeader>Opportunity</SalesforceHeader>
            {opportunityArray?.length ? (
              opportunityArray.slice(0, showMoreOpportunity * 5).map((item) => (
                <ResultContainer key={item.Id}>
                  <ResultContent
                    onClick={() =>
                      clickHandler(item.Id, item.Name, item, "Opportunity")
                    }
                  >
                    {item.Name}
                  </ResultContent>
                  <ResultMail>{item.StageName}</ResultMail>
                  <div className="resultTooltip">{item.Name}</div>
                </ResultContainer>
              ))
            ) : (
              <NoResultDiv>No Result</NoResultDiv>
            )}
            {showMoreOpportunity * 5 < opportunityArray?.length && (
              <LoadMoreDiv
                onClick={() => setShowMoreOpportunity((prev) => prev + 1)}
              >
                Load More
              </LoadMoreDiv>
            )}
          </SalesforceObjects>
          <SalesforceObjects>
            <SalesforceHeader>Lead</SalesforceHeader>
            {leadArray?.length ? (
              leadArray.slice(0, showMoreLead * 5).map((item) => (
                <ResultContainer key={item.Id}>
                  <ResultContent
                    onClick={() =>
                      clickHandler(item.Id, item.Name, item, "Lead")
                    }
                  >
                    {item.Name}
                  </ResultContent>
                  <ResultMail>{item.Email}</ResultMail>
                  <div className="resultTooltip">{item.Name}</div>
                </ResultContainer>
              ))
            ) : (
              <NoResultDiv>No Result</NoResultDiv>
            )}
            {showMoreLead * 5 < leadArray?.length && (
              <LoadMoreDiv onClick={() => setShowMoreLead((prev) => prev + 1)}>
                Load More
              </LoadMoreDiv>
            )}
          </SalesforceObjects>
          <SalesforceObjects>
            <SalesforceHeader>Account</SalesforceHeader>
            {accountArray?.length ? (
              accountArray.slice(0, showMoreAccount * 5).map((item) => (
                <ResultContainer key={item.Id}>
                  <ResultContent
                    onClick={() => {
                      clickHandler(item.Id, item.Name, item, "Account");
                    }}
                  >
                    {item.Name}
                  </ResultContent>
                  <ResultMail>{item.PersonEmail}</ResultMail>
                  <div className="resultTooltip">{item.Name}</div>
                </ResultContainer>
              ))
            ) : (
              <NoResultDiv>No Result</NoResultDiv>
            )}
            {showMoreAccount * 5 < accountArray?.length && (
              <LoadMoreDiv
                onClick={() => setShowMoreAccount((prev) => prev + 1)}
              >
                Load More
              </LoadMoreDiv>
            )}
          </SalesforceObjects>

          <SalesforceObjects>
            <SalesforceHeader>Contact</SalesforceHeader>
            {contactArray?.length ? (
              contactArray.slice(0, showMoreContact * 5).map((item) => (
                <ResultContainer key={item.Id}>
                  <ResultContent
                    onClick={() =>
                      clickHandler(item.Id, item.Name, item, "Contact")
                    }
                  >
                    {item.Name}
                  </ResultContent>
                  <ResultMail>{item.Email}</ResultMail>
                  <div className="resultTooltip">{item.Name}</div>
                </ResultContainer>
              ))
            ) : (
              <NoResultDiv>No Result</NoResultDiv>
            )}
            {showMoreContact * 5 < contactArray?.length && (
              <LoadMoreDiv
                onClick={() => setShowMoreContact((prev) => prev + 1)}
              >
                Load More
              </LoadMoreDiv>
            )}
          </SalesforceObjects>

          <SalesforceObjects>
            <SalesforceHeader>Event</SalesforceHeader>
            {eventArray?.length ? (
              eventArray.slice(0, showMoreEvent * 5).map((item) => (
                <ResultContainer key={item.Id}>
                  <ResultContent
                    onClick={() =>
                      clickHandler(item.Id, item.Subject, item, "Event")
                    }
                  >
                    {item.Subject}
                  </ResultContent>
                  <ResultMail>{item?.email}</ResultMail>
                  <div className="resultTooltip">{item.Subject}</div>
                </ResultContainer>
              ))
            ) : (
              <NoResultDiv>No Result</NoResultDiv>
            )}
            {showMoreEvent * 5 < eventArray?.length && (
              <LoadMoreDiv onClick={() => setShowMoreEvent((prev) => prev + 1)}>
                Load More
              </LoadMoreDiv>
            )}
          </SalesforceObjects>
        </SearchMain>
      )}
    </SearchResultContainer>
  );
}

export default SearchResult;
