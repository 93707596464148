const opportunityTableField = (payload) => {
  return {
    type: "opportunity_table_Field",
    payload: payload,
  };
};
const accountTableField = (payload) => {
  return {
    type: "account_table_Field",
    payload: payload,
  };
};
const eventTableField = (payload) => {
  return {
    type: "event_table_Field",
    payload: payload,
  };
};
const contactTableField = (payload) => {
  return {
    type: "contact_table_Field",
    payload: payload,
  };
};
const leadTableField = (payload) => {
  return {
    type: "lead_table_Field",
    payload: payload,
  };
};

const getTableField = {
  opportunityTableField,
  accountTableField,
  eventTableField,
  contactTableField,
  leadTableField,
};

export default getTableField;
