const opportunityTableData = (payload) => {
  return {
    type: "opportunity_table_data",
    payload: payload,
  };
};
const accountTableData = (payload) => {
  return {
    type: "account_table_data",
    payload: payload,
  };
};
const eventTableData = (payload) => {
  return {
    type: "event_table_data",
    payload: payload,
  };
};
const contactTableData = (payload) => {
  return {
    type: "contact_table_data",
    payload: payload,
  };
};
const leadTableData = (payload) => {
  return {
    type: "lead_table_data",
    payload: payload,
  };
};

const getTableData = {
  opportunityTableData,
  accountTableData,
  eventTableData,
  contactTableData,
  leadTableData,
};

export default getTableData;
