import { Box } from "@mui/system";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { CTime } from "../Pages/Login";
import { MainHeaderChildBox } from "./Navbar";



import styled from "@emotion/styled";


const TimeContainer = styled.p({
  fontVariantNumeric: "tabular-nums",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "700",
  color: "white",
  fontSize: "28px",
  lineHeight: "29px",
margin:"0",

  "& span":{
    display:"block"
  },

  "& span:last-of-type": {
    letterSpacing: "2px",
    fontSize: "20px",
    fontWeight: "400",
}

});

function GenerateGreetings({ greeting }) {
  let TimeFormat = localStorage.getItem("TimeFormat");
  const [time, setTime] = useState(moment());

  useEffect(() => {
    setInterval(() => {
      setTime(moment());
    }, 1000);
  }, []);

  const generateGreetings = () => {
    var currentHour = time.format("HH");

    if (currentHour >= 3 && currentHour < 12) {
      return "Good Morning";
    } else if (currentHour >= 12 && currentHour < 15) {
      return "Good Afternoon";
    } else if (currentHour >= 15 && currentHour < 20) {
      return "Good Evening";
    } else if (currentHour >= 20 && currentHour < 3) {
      return "Good Night";
    } else {
      return "Hello";
    }
  };

  return greeting ? (
    generateGreetings()
  ) : (
    <MainHeaderChildBox
      color="#444647"
      paddingRight="32px"
      flexDirection="column"
      paddingTop="16px"
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="end"
        textAlign="end"
        maxWidth="100%"
      >
        {/* <CTime sx={{}}>
         {time.format(TimeFormat === "24" ? "HH:mm" : "hh:mm")}{" "}
        {time.format("A")}
   
         
        </CTime>
        <Box
          sx={{
            fontVariantNumeric: "tabular-nums",
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "400",
            color: "white",
            fontSize: "24px",
            lineHeight: "29px",
            display:"flex",
            justifyContent:"space-between",
            alignItems:"center",
            width:"8rem",
          }}
        >
         {time.format("D MMM")}
        ,
         {time.format("ddd")}
           
        </Box> */}


        <TimeContainer>
<span>{time.format(TimeFormat === "24" ? "HH:mm" : "hh:mm")}{" "}
        {time.format("A")}</span>
<span> {time.format("D MMM")}
        ,
         {time.format("ddd")}</span>
        </TimeContainer>
      </Box>
    </MainHeaderChildBox>
  );
}

export default GenerateGreetings;
