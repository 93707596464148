import styled from "@emotion/styled";
import { TextField } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ReactComponent as CalenderSVG } from "../../Assets/SVG/Calender.svg";
import DatePickers from "../Date";
import CustomDateTimePicker from "../DateTime";
import ReferenceSearchDropDown from "../ReferenceSearchDropDown";
import TimePickerComponent from "../TimeFilter";
import { customDropDown } from "./FilterDropDownHeader";
import SimpleFilterDropDown from "./SimpleFilterDropDown";

const TextBox = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    height: "34px",
    fontSize: "14px",
    fontFamily: "Inter",
    width: "210px",
    "& :hover": {
      border: "none",
    },
    ".MuiOutlinedInput-notchedOutline": {
      outline: "none",
      border: "none",
      "& :hover": {
        border: "none",
      },
    },
    ".MuiOutlinedInput-notchedOutline :hover": {
      background: "blue",
      border: "none",
      outline: "none",
    },
    // "& > fieldset:hover": { border: "none", outline: "none" },
    // "fieldset:focus": { borderColor: "none", outlineColor: "none" },
  },
  //   ".Mui-focused": {
  //     border: "none",
  //     outline: "none",
  //     color: "blue",
  //   },
  //   "&:hover fieldset": {
  //     border: "1px solid",
  //     outline: "none",
  //   },
  //   "&:focus fieldset": {
  //     borderColor: "none",
  //     outline: "none",
  //   },
});

const RenderCustomComponent = ({
  dataType,
  value,
  dataFilterType,
  handleFilterValueChange,
  metricName,
  handleDateType,
  dropDownClose,
  dropDownValue,
  widthAdjustment,
  days,
  tableName,
  header,
  handleFilterValueRemove,
  index,
}) => {
  //   const {
  //     isComponentVisible: dropdown,
  //     setIsComponentVisible: setDropdown,
  //     ref,
  //   } = useComponentVisible(false);
  const [openDate, setOpenDate] = useState(false);
  const [textValue, setTextValue] = useState(value);
  const [editMode, setEditMode] = useState(false);
  const [showMode, setShowMode] = useState(true);

  const [numberValue, setNumberValue] = useState(value);
  const [day, setDay] = useState("");

  useEffect(() => {
    setTextValue(value);
    setNumberValue(value);
    setDay(days);
  }, [value]);

  if (
    handleDateType === "datetime" ||
    handleDateType === "date" ||
    handleDateType === "time"
  ) {
    if (dataFilterType !== "is empty" && dataFilterType !== "is not empty") {
      return (
        <Box display="flex" alignItems="center" gap="8px">
          <Box>
            <SimpleFilterDropDown
              header={customDropDown(
                "dateDropDown",
                "",
                "",
                dataType,
                handleDateType
              )}
              handleData={(value, dataType, metric_name, handleDateType) => {
                handleFilterValueChange(
                  value,
                  dataType,
                  metric_name,
                  handleDateType,
                  day
                );
              }}
              value={typeof value === "object" ? value.toString() : value}
              searching={false}
              widthAdjustment={widthAdjustment}
              widthOfDate={true}
            />
          </Box>
          <Box>
            {value === "Number of days ago" ||
            value === "Number of days from now" ? (
              <TextField
                size="small"
                value={day}
                onBlur={() => {
                  handleFilterValueChange(
                    value,
                    dataType,
                    metricName,
                    handleDateType,
                    day
                  );
                }}
                onChange={(e) => {
                  const onlyNums = e.target.value.replace(/[^0-9]/g, "");
                  if (onlyNums.length > 0) {
                    setDay(onlyNums);
                  } else {
                    setDay(onlyNums);
                  }
                }}
                sx={{
                  ".MuiOutlinedInput-input": {
                    fontFamily: "Inter",
                    height: "18px",
                  },
                  display:
                    value === "Number of days ago" ||
                    value === "Number of days from now"
                      ? "block"
                      : "none",

                  width:
                    value === "Number of days ago" ||
                    value === "Number of days from now"
                      ? "60px"
                      : "0px",
                }}
              />
            ) : (
              ""
            )}
          </Box>
          <Box
            sx={{
              cursor: "pointer",
            }}
            onClick={(e) => {
              setOpenDate(!openDate);
            }}
          >
            <CalenderSVG />
          </Box>
          {openDate ? (
            handleDateType === "datetime" ? (
              <CustomDateTimePicker
                value={value}
                handleChange={(x) => {
                  handleFilterValueChange(
                    x,
                    dataType,
                    metricName,
                    handleDateType,
                    ""
                  );
                  setOpenDate(false);
                }}
                setEditMode={setOpenDate}
                hideStack={false}
                expandViewCell={true}
              />
            ) : handleDateType === "date" ? (
              <DatePickers
                value={value}
                handleChange={(x) => {
                  handleFilterValueChange(
                    x,
                    dataType,
                    metricName,
                    handleDateType,
                    ""
                  );
                  setOpenDate(false);
                }}
                setEditMode={setOpenDate}
                hideStack={false}
                expandViewCell={true}
              />
            ) : handleDateType === "time" ? (
              <TimePickerComponent
                value={value}
                handleChange={(x) => {
                  handleFilterValueChange(
                    x,
                    dataType,
                    metricName,
                    handleDateType,
                    ""
                  );
                  setOpenDate(false);
                }}
                setEditMode={setOpenDate}
              />
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
        </Box>
      );
    }
  }

  if (dataType === "picklist" || dataType === "multipicklist") {
    if (dataFilterType !== "is empty" && dataFilterType !== "is not empty") {
      return (
        <SimpleFilterDropDown
          header={customDropDown(dataType, dropDownValue, value)}
          handleData={(value) => {
            handleFilterValueChange(
              value,
              dataType,
              metricName,
              handleDateType,
              ""
            );
          }}
          value={value}
          searching={true}
          checkable={true}
          dropDownClose={dropDownClose}
          widthAdjustment={widthAdjustment}
        />
      );
    }
  }

  if (dataType === "numeric") {
    return (
      <TextBox
        size="small"
        value={numberValue}
        onBlur={() => {
          handleFilterValueChange(numberValue);
        }}
        onChange={(e) => {
          const onlyNums = e.target.value.replace(/[^0-9]/g, "");
          if (onlyNums.length > 0) {
            Number(onlyNums) < 1000000000
              ? setNumberValue(onlyNums)
              : toast.error("You've reached the Maximum amount");
          } else {
            setNumberValue(onlyNums);
          }
        }}
        sx={{
          display:
            dataFilterType === "is empty" || dataFilterType === "is not empty"
              ? "none"
              : "block",
        }}
      />
    );
  }

  if (dataType === "checkbox") {
    if (dataFilterType !== "is empty" && dataFilterType !== "is not empty") {
      return (
        <SimpleFilterDropDown
          header={customDropDown("BooleanDropDown")}
          handleData={(value) => {
            handleFilterValueChange(
              value,
              dataType,
              metricName,
              handleDateType,
              ""
            );
          }}
          value={value}
          searching={false}
          widthAdjustment={widthAdjustment}
        />
      );
    }
  }
  if (handleDateType === "reference") {
    // debugger;
    if (showMode || (value && !Object.keys(value).length)) {
      return (
        <>
          <ReferenceSearchDropDown
            key={`components_${index}`}
            setEditMode={setEditMode}
            editMode={editMode}
            rawData={header[0]}
            onChangeHandler={(value, Name) => {
              handleFilterValueChange(
                value,
                dataType,
                metricName,
                handleDateType,
                "",
                Name
              );
            }}
            //searchView={searchView}
            tableName={tableName}
            pipelineReferenceFilter
            selectedReferenceValues={value}
            removeFilterValues={(id) => {
              handleFilterValueRemove(
                id,
                dataType,
                metricName,
                handleDateType,
                ""
              );
            }}
            setShowMode={setShowMode}
            //taskViewOnly={taskViewOnly}
          />
        </>
      );
    }
  }

  console.log("showMode", showMode);
  console.log("value", value);

  return (
    <TextBox
      size="small"
      placeholder="yu"
      value={textValue}
      onChange={(e) => {
        const inputValue = e.target.value;
        // Regular expression to match the characters #, \, &
        const forbiddenChars = /[#\\&]/;
        // Check if the input contains any forbidden characters
        if (!forbiddenChars.test(inputValue)) {
          setTextValue(inputValue);
        } else {
          console.warn("Input contains forbidden characters: #, \\, &");
        }
      }}
      onBlur={(e) => {
        handleFilterValueChange(textValue);
      }}
      InputLabelProps={{
        FormLabelClasses: {
          focused: "black",
        },
      }}
      sx={{
        display:
          dataFilterType === "is empty" || dataFilterType === "is not empty"
            ? "none"
            : "block",
        root: {
          "&$focused": {
            color: "black",
            border: "none",
            outline: "none",
          },
        },
      }}
    />
  );
};
export default RenderCustomComponent;
