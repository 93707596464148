import { CloseRounded, ExpandMoreRounded } from "@mui/icons-material";
import { Button, Divider, Stack, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ReactComponent as SearchSettingSVG } from "../../Assets/SVG/SearchViewSetting.svg";
import { ReactComponent as ArrowForwardSVG } from "../../Assets/SVG/ArrowForward.svg";
import { ReactComponent as OpportunitySVG } from "../../Assets/SVG/CreateOpportunity.svg";
import { ReactComponent as AccountSVG } from "../../Assets/SVG/CreateAccount.svg";
import { ReactComponent as ContactSVG } from "../../Assets/SVG/CreateContact.svg";
import { ReactComponent as LeadSVG } from "../../Assets/SVG/CreateLead.svg";
import { ReactComponent as EventSVG } from "../../Assets/SVG/CreateEvent.svg";
import { ReactComponent as BackArrowSVG } from "../../Assets/SVG/ArrowBackward.svg";
import { ReactComponent as FieldSVG } from "../../Assets/SVG/TaskField.svg";
import RenderComponents from "../RenderComponents";
import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import SimpleFilterDropDown from "../FilterOpportunity/SimpleFilterDropDown";
import FilterColumn from "../Table/FilterColumn";
import Loader from "../Loader";
import { create_record } from "../../Pages/Services/tableApi";
import useComponentVisible from "../../Hooks/useComponentVisible";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import EditTask from "../TaskView/EditTask";
import Task from "../../Pages/Task";
import { defaultFields, requiredFields } from "../Navbar";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Actions from "../../redux/actions";

const Drawer = styled(Box)({
  width: 500,
  boxShadow: "6px 11px 24px -3px rgba(0, 0, 0, 0.06)",
  height: "100%",
  background: "#ffffff",
});

const ScrollableBox = styled(Box)({
  height: "84%",
  padding: "4px 16px",
  overflowY: "scroll",
  overflowX: "hidden",
  scrollBehavior: "smooth",
  "&::-webkit-scrollbar": {
    width: "0.5em",
    marginRight: "4px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(181,188,194,0.7)",
    borderRadius: "10px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "rgba(181,188,194,0.9)",
  },
});

export const NameTextField = styled(TextField)({
  ".MuiOutlinedInput-root": {
    fontSize: "14px",
    fontFamily: "Inter",
  },
  "&.MuiTextField-root": {
    width: "100%",
  },
});

export const TileNameError = styled(Typography)({
  color: "red",
  fontSize: "12px",
});

const CustomNameBox = styled(Box)({
  background: "#F3F6F8",
  borderRadius: "8px",
  display: "flex",
  alignItems: "center",
  gap: "5px",
  padding: "0.5rem",
  fontSize: "14px",
  cursor: "pointer",
});

export const ButtonBox = styled(Box)({
  position: "absolute",
  bottom: 0,
  left: 0,
  width: "95%",
  display: "flex",
  alignItems: "center",
  gap: "0.6rem",
  background: "#ffffff",
});

export const StyledButton = styled(Button)({
  textTransform: "capitalize",
  width: "152px",
  height: "34px",
  borderRadius: "8px",
});

const SearchTableHeader = styled(Typography)({
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "18px",
  lineHeight: "22px",
  color: "#032E61",
});

const MainSearchHeaderBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  gap: "2px",
  height: "80px",

  // backgroundImage: `url("Mask group.png")`,
  // backgroundPosition: "right",
  // backgroundRepeat: "no-repeat",
});

const SearchDrawerHeaderFlexBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "4px",
});

const SearchDrawerHeaderRight = styled(SearchDrawerHeaderFlexBox)({
  marginLeft: "auto !important",
});

const SearchDrawer = ({
  tableName,
  tableHeader,
  searchTableData,
  closeDrawer,
  setApiToaster,
  setData,
  setPreserveData,
  preserveData,
  toggleDrawer,
}) => {
  const handleDefaultState = searchTableData?.AllDropDownValue?.map(
    (data) => data.metric_display_name
  );

  const dispatch = useDispatch();
  const [taskState, setTaskState] = useState(false);
  const [backArrow, setBackArrow] = useState(false);
  const [state, setState] = useState(
    handleDefaultState[0] || `New ${taskState ? "Task" : tableName}`
  );
  const [name, setName] = useState(state);
  const [fieldAddDropDown, setFieldAddDropDown] = useState(false);
  const [newTaskAsync, setNewTaskAsync] = useState(false);
  const [handleRenameAndNewTile, setHandleRenameAndNewTile] = useState(false);
  const [SearchTableData, setSearchTableData] = useState(searchTableData);

  const [createdData, setCreatedData] = useState({
    Opportunity: { AccountId: "", Name: "", StageName: "", CloseDate: "" },
    Account: { Name: "" },
    Contact: { AccountId: "", LastName: "" },
    Lead: { LastName: "", Company: "" },
    Event: { Subject: "", StartDateTime: "", EndDateTime: "" },
    Task: {
      Subject: "",
    },
    Tasks: {
      Subject: "",
    },
  });

  const [referenceData, setReferenceData] = useState({
    Opportunity: { AccountId: "" },
    Account: { Name: "" },
    Contact: { AccountId: "", LastName: "" },
    Lead: { LastName: "", Company: "" },
    Event: { Subject: "", StartDateTime: "", EndDateTime: "" },
    Task: {
      Subject: "",
    },
    Tasks: {
      Subject: "",
    },
  });

  const [relatedData, setRelatedData] = useState({});
  const [dataCreationLoader, setDataCreationLoader] = useState(false);
  const [rename, setRename] = useState(false);
  const [renameValue, setRenameValue] = useState("");
  const [error, setError] = useState(false);
  const [changeForDuplicate, setChangeForDuplicate] = useState(false);
  const [tileNameError, setTileNameError] = useState("");
  const disableDelete = [
    "New Opportunity",
    "New Account",
    "New Contact",
    "New Lead",
    "New Event",
  ];
  const fields = disableDelete.includes(name)
    ? ["Fields & Related List", "divider", "Duplicate"]
    : ["Fields & Related List", "divider", "Rename", "Duplicate", "Delete"];
  const [api, setApi] = useState(false);

  const tableRecordsData = useSelector((state) => {
    if (tableName === "Opportunity") {
      return state.tableData.opportunityData;
    }
    if (tableName === "Account") {
      return state.tableData.accountData;
    }
    if (tableName === "Lead") {
      return state.tableData.leadData;
    }
    if (tableName === "Contact") {
      return state.tableData.contactData;
    }
    if (tableName === "Event") {
      return state.tableData.eventData;
    }
  });

  useEffect(() => {}, [taskState]);
  const {
    isComponentVisible: dropdown,
    setIsComponentVisible: setDropdown,
    ref,
  } = useComponentVisible(false);
  const {
    isComponentVisible: fieldDropDown,
    setIsComponentVisible: setFieldDropDown,
    fieldRef,
  } = useComponentVisible(false);

  const handleChange = (value) => {
    setDropdown(false);
    if (value === "New tile") {
      setState("");
      setHandleRenameAndNewTile(true);
    } else if (value) {
      setHandleRenameAndNewTile(false);
      setState(value);
    }
  };

  const handleSearchTableData = (header) => {
    //
    const addValueInDropDown = {
      metric_datatype: "string",
      metric_display_name: name,
      metric_name: name,
    };
    const __searchTableData = { ...searchTableData };
    const allTiles = __searchTableData.AllDropDownValue?.map(
      (value) => value.metric_display_name
    );

    const checkFieldsExists = header?.filter((data) => data.isVisible) || [];

    if (checkFieldsExists.length) {
      if (!allTiles.includes(name)) {
        const index = __searchTableData?.AllDropDownValue?.length - 1;
        __searchTableData.AllDropDownValue.splice(index, 0, addValueInDropDown);
      }
      __searchTableData.data[name] = header;
      setSearchTableData(__searchTableData);
      setPreserveData({ ...preserveData, [tableName]: __searchTableData });
    }
  };

  const handleDefaultHeader = (state) => {
    // debugger;
    const addValueInDropDown = {
      metric_datatype: "string",
      metric_display_name: state,
      metric_name: state,
    };
    const __searchTableData = { ...searchTableData };
    const allTiles = __searchTableData.AllDropDownValue?.map(
      (value) => value.metric_display_name
    );

    // const defaultActiveField = tableHeader?.map((value, index) => {
    //   return {
    //     ...value,
    //     isVisible: !value.nullable,
    //     selected: !value.nullable,
    //   };
    // });
    // debugger;
    const defaultActiveField = __searchTableData.defaultData.map(
      (value, index) => {
        return {
          ...value,
          isVisible: defaultFields[tableName].includes(value.metric_name),
          selected: defaultFields[tableName].includes(value.metric_name),
          nullable: !requiredFields[tableName].includes(value.metric_name),
        };
      }
    );

    const checkFieldsExists =
      defaultActiveField?.filter((data) => data.isVisible) || [];

    if (checkFieldsExists.length) {
      if (!allTiles.includes(state)) {
        const index = __searchTableData?.AllDropDownValue?.length - 1;
        __searchTableData.AllDropDownValue.splice(index, 0, addValueInDropDown);
      }
      __searchTableData.data[state] = defaultActiveField;
      if (!rename) {
        setSearchTableData(__searchTableData);
      }
      setPreserveData({ ...preserveData, [tableName]: __searchTableData });
    }
  };

  const filterColumns = (header) => {
    const checkForSameData =
      JSON.stringify(header) !== JSON.stringify(searchTableData.data[name]);
    if (!searchTableData) {
      setSearchTableData(searchTableData);
    }
    if (checkForSameData) {
      handleSearchTableData(header);
    }
  };

  useEffect(() => {
    if (searchTableData && Object.keys(searchTableData.data).length)
      setSearchTableData(searchTableData);
  }, [searchTableData]);

  const updateSearchTableData = async () => {
    // const dataParams = JSON.stringify({
    //   key: `${tableName}SearchViewData`,
    //   value: Object.keys(SearchTableData?.data)?.length ? SearchTableData : {},
    // });
    if (!Object.keys(SearchTableData.data)?.length && !tableHeader?.length) {
      closeDrawer();
    }
    // await createOrUpdateKey(dataParams);
    setData(
      Object.keys(SearchTableData?.data)?.length ? SearchTableData : null
    );
  };

  useEffect(() => {
    updateSearchTableData();
  }, [SearchTableData]);

  useEffect(() => {
    // debugger;
    if (rename && !(renameValue === state)) {
      const __searchTableData = { ...SearchTableData };

      const index = __searchTableData.AllDropDownValue.map(
        (d) => d.metric_display_name
      ).findIndex((data) => data === renameValue);

      __searchTableData.AllDropDownValue[index] = {
        ...__searchTableData.AllDropDownValue[index],
        metric_display_name: state,
        metric_name: state,
      };

      Object.defineProperty(
        __searchTableData.data,
        state,
        Object.getOwnPropertyDescriptor(__searchTableData.data, renameValue)
      );
      delete __searchTableData.data[renameValue];
      setSearchTableData(__searchTableData);
      setRename(false);
    }
    setName(state.trim());
  }, [dropdown, fieldDropDown, handleRenameAndNewTile]);

  useEffect(() => {
    if (localStorage.getItem("newAccFromOpp")) {
      setState(handleDefaultState[0]);
      setName(handleDefaultState[0]);
      setSearchTableData(searchTableData);
    }
  }, [localStorage.getItem("newAccFromOpp"), tableName]);

  const handleDeleteTiles = () => {
    const filterDropDown = SearchTableData?.AllDropDownValue?.filter(
      (data) => data.metric_display_name !== name && data
    );
    const filterDropDownValue = filterDropDown?.map(
      (displayName) => displayName.metric_display_name
    );

    const __searchTableData = { ...SearchTableData };
    if (filterDropDownValue.includes(`New ${taskState ? "Task" : tableName}`)) {
      setState(`New ${taskState ? "Task" : tableName}`);
      __searchTableData.AllDropDownValue = filterDropDown;
      delete __searchTableData.data[name];
      setSearchTableData(__searchTableData);
      setPreserveData({ ...preserveData, [tableName]: __searchTableData });
    } else {
      if (SearchTableData?.AllDropDownValue?.length > 2) {
        setState(filterDropDownValue[0]);
        __searchTableData.AllDropDownValue = filterDropDown;
        delete __searchTableData.data[name];
        setSearchTableData(__searchTableData);
        setPreserveData({ ...preserveData, [tableName]: __searchTableData });
      } else {
        setState(`New ${taskState ? "Task" : tableName}`);
        delete __searchTableData.data[name];
        setSearchTableData(__searchTableData);
        setPreserveData({ ...preserveData, [tableName]: __searchTableData });
      }
    }
  };

  const handleDuplicateTiles = () => {
    const __searchTableData = { ...SearchTableData };
    const data = __searchTableData.data[name];
    const index = __searchTableData?.AllDropDownValue?.length - 1;
    __searchTableData.AllDropDownValue.splice(index, 0, {
      metric_datatype: "string",
      metric_display_name: `${name} copy`,
      metric_name: `${name} copy`,
    });
    __searchTableData.data = {
      ...__searchTableData.data,
      [`${name} copy`]: data,
    };
    setSearchTableData(__searchTableData);
    setPreserveData({ ...preserveData, [tableName]: __searchTableData });
    setHandleRenameAndNewTile(false);
    // setPreserveData({ ...preserveData, [tableName]: __searchTableData });
  };

  const onUpdateSuccessOfRow = (value, fieldName, required, whoOrWhatName) => {
    const require = !required;
    let data = { ...createdData };
    data[taskState ? "Task" : tableName] = {
      ...data[taskState ? "Task" : tableName],
      [fieldName]: value,
    };
    if (fieldName === "WhoId" || fieldName === "WhatId") {
      let tempRelatedData = Object.keys(relatedData)?.length ? relatedData : {};

      tempRelatedData = {
        ...tempRelatedData,
        [fieldName === "WhoId" ? "Who" : "What"]: {
          Name: whoOrWhatName,
          Id: value,
        },
      };

      setRelatedData(tempRelatedData);
    }
    if (fieldName.includes("Id")) {
      let data = { ...referenceData };
      data[tableName] = {
        ...data[tableName],
        [fieldName]: whoOrWhatName,
      };
      setReferenceData(data);
    }

    setCreatedData(data);
  };
  let newAccFromOpp = JSON.parse(localStorage.getItem("newAccFromOpp"));
  const DataCreation = async () => {
    if (manageError()) {
      setError(true);
      setDataCreationLoader(false);
      setApiToaster(
        createdData[taskState ? "Task" : tableName],
        taskState ? "Task" : tableName
      );
    } else {
      const dataParams = {
        table_name: taskState ? "Task" : tableName,
        fields: createdData[taskState ? "Task" : tableName],
      };
      const JSONdataParams = JSON.stringify(dataParams);
      const res = await create_record(JSONdataParams);
      if (res.data.status === 200) {
        dispatch(Actions.getAsyncTable.asyncTable([true, tableName]));
        let newObj = {};
        let newObjKeys = tableHeader
          .filter((data) => data.selected)
          .map((data) => data.metric_name);
        newObjKeys.forEach((data) => {
          newObj[data] = "";
        });

        let newObject = tableRecordsData?.rows?.length
          ? { ...tableRecordsData.rows[0] }
          : newObj;

        let key = Object.keys(newObject);
        key.forEach((data) => (newObject[data] = ""));
        let __copy = Object.keys(createdData[taskState ? "Task" : tableName]);
        __copy.forEach((data) => {
          if (key.indexOf(data) != -1) {
            newObject[data] = createdData[taskState ? "Task" : tableName][data];
          }
        });

        let tableData = tableRecordsData?.rows?.length
          ? {
              ...tableRecordsData,
              rows: [
                { ...newObject, Id: res.data.id },
                ...tableRecordsData.rows,
              ],
            }
          : {
              rows: [{ ...newObject, Id: res.data.id }],
              headers: [...tableHeader.filter((data) => data.selected)],
              message: "Success",
              status: 200,
            };

        // switch (tableName) {
        //   case "Opportunity":
        //     dispatch(Actions.getTableData.opportunityTableData(tableData));
        //     break;
        //   case "Account":
        //     dispatch(Actions.getTableData.accountTableData(tableData));
        //     break;
        //   case "Lead":
        //     dispatch(Actions.getTableData.leadTableData(tableData));
        //     break;
        //   case "Contact":
        //     dispatch(Actions.getTableData.contactTableData(tableData));
        //     break;
        //   case "Event":
        //     dispatch(Actions.getTableData.eventTableData(tableData));
        //     break;
        //   default:
        //     break;
        // }

        setDataCreationLoader(false);
        if (newAccFromOpp[0]) {
          let referenceData_copy = { ...referenceData };
          let createdData_copy = { ...createdData };
          referenceData_copy[newAccFromOpp[1]].AccountId =
            createdData.Account.Name;
          createdData_copy[newAccFromOpp[1]].AccountId = res.data.id;
          setReferenceData(referenceData_copy);
          setCreatedData(createdData_copy);
          setError(false);
          setApiToaster({}, taskState ? "Task" : tableName);
          dispatch(Actions.getAsyncTask.asyncTask(true));

          toggleDrawer();
          localStorage.setItem("newAccFromOpp", JSON.stringify([false, ""]));
        } else {
          closeDrawer();
          setError(false);
          setApiToaster({}, taskState ? "Task" : tableName);
          dispatch(Actions.getAsyncTask.asyncTask(true));
        }
      } else {
        setDataCreationLoader(false);
        setError(false);
        setApiToaster(
          {
            message: JSON.parse(res.data.message)[0].message,
            msg_status: res.data.status,
          },
          taskState ? "Task" : tableName
        );
      }
    }
  };

  const manageError = () => {
    let errorFlag;
    switch (taskState ? "Task" : tableName) {
      case "Opportunity":
        if (
          createdData[tableName].StageName !== "" &&
          createdData[tableName].Name !== "" &&
          createdData[tableName].CloseDate !== "" &&
          createdData[tableName].AccountId !== ""
        )
          errorFlag = false;
        else errorFlag = true;

        break;
      case "Account":
        if (createdData[tableName].Name !== "") errorFlag = false;
        else errorFlag = true;
        break;
      case "Contact":
        if (
          createdData[tableName].LastName !== "" &&
          createdData[tableName].AccountId !== ""
        )
          errorFlag = false;
        else errorFlag = true;

        break;
      case "Lead":
        if (createdData[tableName].LastName !== "") errorFlag = false;
        else errorFlag = true;
        break;
      case "Event":
        if (
          createdData[tableName].Subject !== "" &&
          createdData[tableName].StartDateTime !== "" &&
          createdData[tableName].EndDateTime !== ""
        )
          errorFlag = false;
        else errorFlag = true;
        break;
      case "Task":
        if (createdData[tableName].Subject !== "") errorFlag = false;
        else errorFlag = true;
        break;
      default:
        errorFlag = false;
        break;
    }
    return errorFlag;
  };

  const handleError = () => {
    setError(true);
  };

  const getSvgOfTable = (table) => {
    let svg;
    switch (table) {
      case "Opportunity":
        svg = <OpportunitySVG />;
        break;
      case "Account":
        svg = <AccountSVG />;
        break;
      case "Contact":
        svg = <ContactSVG />;
        break;
      case "Lead":
        svg = <LeadSVG />;
        break;
      case "Event":
        svg = <EventSVG />;
        break;

      default:
        break;
    }
    return svg;
  };

  // const SearchDrawerFieldsColumn = () => {
  //   return (
  //     <FilterColumn
  //       header={
  //         SearchTableData?.data[name]
  //           ? SearchTableData?.data[name]
  //           : SearchTableData?.defaultData?.map((data) => {
  //             data.isVisible = false;
  //             return data;
  //           })
  //       }
  //       activeHeader={
  //         SearchTableData?.data[name]
  //           ? SearchTableData?.data[name].filter(
  //             (data) => data.isVisible !== false
  //           )
  //           : undefined
  //       }
  //       filterColumns={(header) => filterColumns(header)}
  //       tableName={tableName}
  //       searchViewHeader={true}
  //       setFieldAddDropDown={() => {
  //         setFieldAddDropDown(false);
  //         tableName !== "Task" && setFieldDropDown(true);
  //       }}
  //     />
  //   );
  // };

  const handleCreateTile = () => {
    const tileNames = Object.keys(SearchTableData?.data);
    const tileAlreadyexist = [...tileNames, "New"].includes(state);
    if (state?.length === 0) {
      setTileNameError("Please enter tile name");
    } else if (tileAlreadyexist) {
      setTileNameError("Tile with the same name already exists");
    } else {
      setName(state);
      setHandleRenameAndNewTile(false);
      setChangeForDuplicate(false);
      api && handleDefaultHeader(state);
      setApi(false);
      setTileNameError("");
    }
  };

  return (
    <Drawer role="presentation" position={"relative"}>
      <MainSearchHeaderBox>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          width={"89%"}
          padding={"8px 16px"}
          paddingBottom={"0px"}
        >
          <SearchDrawerHeaderFlexBox
            style={{
              gap: newAccFromOpp && newAccFromOpp[0] && "10px",
            }}
          >
            {newAccFromOpp && newAccFromOpp[0] && (
              <Box
                onClick={() => {
                  if (newAccFromOpp && newAccFromOpp[0]) {
                    localStorage.setItem(
                      "newAccFromOpp",
                      JSON.stringify([false, ""])
                    );
                    toggleDrawer();
                  }
                }}
              >
                <BackArrowSVG
                  style={{
                    cursor: "pointer",
                  }}
                />
              </Box>
            )}
            <SearchTableHeader
              onClick={() => {
                backArrow && tableName === "Tasks" && setBackArrow(false);
                if (newAccFromOpp && newAccFromOpp[0]) {
                  localStorage.setItem(
                    "newAccFromOpp",
                    JSON.stringify([false, ""])
                  );
                  toggleDrawer();
                }
              }}
            >
              {!backArrow ? (
                tableName === "Tasks" && !taskState ? (
                  `${taskState ? "Task" : tableName}`
                ) : (
                  `New ${taskState ? "Task" : tableName}`
                )
              ) : (
                <BackArrowSVG
                  style={{
                    cursor: "pointer",
                    paddingRight: "8px",
                  }}
                />
              )}
              {backArrow &&
                tableName === "Tasks" &&
                !taskState &&
                // ` New  ${taskState ? "Task" : tableName}`}
                ` New  Task`}
            </SearchTableHeader>

            <Box color={"#032E61"} paddingTop={"4px"}>
              {getSvgOfTable(taskState ? "Task" : tableName)}
            </Box>
          </SearchDrawerHeaderFlexBox>
          <SearchDrawerHeaderFlexBox>
            {/* {tableName === "Task" && (
              <ClickAwayListener onClickAway={() => setFieldAddDropDown(false)}>
                <Box>
                  <Box
                    onClick={() => {
                      setFieldAddDropDown(!fieldAddDropDown);
                    }}
                    paddingRight={"0.8rem"}
                    display={"flex"}
                    gap={"6px"}
                    flexDirection={"row-reverse"}
                    alignItems={"center"}
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    <FieldSVG
                      ref={fieldRef}
                      cursor={"pointer"}
                      width={"14px"}
                      height={"14px"}
                    />
                  </Box>
                  {fieldAddDropDown && (
                    <FilterColumn
                      header={
                        SearchTableData?.data[name]
                          ? SearchTableData?.data[name]
                          : SearchTableData?.defaultData?.map((data) => {
                              data.isVisible = false;
                              return data;
                            })
                      }
                      activeHeader={
                        SearchTableData?.data[name]
                          ? SearchTableData?.data[name].filter(
                              (data) => data.isVisible !== false
                            )
                          : undefined
                      }
                      filterColumns={(header) => filterColumns(header)}
                      tableName={taskState ? "Task" : tableName}
                      searchViewHeader={true}
                      setFieldAddDropDown={() => {
                        setFieldAddDropDown(false);
                        tableName !== "Task" && setFieldDropDown(true);
                      }}
                    />
                  )}
                </Box>
              </ClickAwayListener>
            )} */}
            <CloseRounded
              onClick={() => closeDrawer()}
              sx={{ fontSize: "20px", cursor: "pointer" }}
            />
          </SearchDrawerHeaderFlexBox>
        </Box>
        {/* <Typography
          color={"#ABADB3"}
          fontSize={"14px"}
          fontFamily={"Inter"}
          padding={"8px 16px"}
          paddingTop={"0px"}
        >{`Add details below to create a new ${tableName}`}</Typography> */}
      </MainSearchHeaderBox>
      <Divider />
      {tableName === "Tasks" && !taskState && (
        <Task
          searchTasksStyle={true}
          setTaskState={setTaskState}
          setBackArrow={setBackArrow}
          backArrow={backArrow}
          setNewTaskAsync={setNewTaskAsync}
          newTaskAsync={newTaskAsync}
          fromSearchBar
        />
      )}

      {tableName === "Task" || taskState ? (
        <EditTask
          tableName={"Task"}
          taskData={createdData}
          taskRelatedData={relatedData}
          taskDataHeader={
            SearchTableData?.data[name]
              ? SearchTableData?.data[name]
              : SearchTableData?.defaultData?.map((data) => {
                  data.isVisible = false;
                  return data;
                })
          }
          taskActiveDataHeader={
            SearchTableData?.data[name]
              ? SearchTableData?.data[name].filter(
                  (data) => data.isVisible || data.selected
                )
              : undefined
          }
          filterColumns={filterColumns}
          syncTask={handleChange}
          onUpdateSuccessOfRow={(value, field, required, whoOrWhatName) => {
            onUpdateSuccessOfRow(value, field, required, whoOrWhatName);
          }}
          createTask={true}
          SearchTableData={SearchTableData}
          name={name}
          error={error}
          handleError={handleError}
        />
      ) : (
        <ScrollableBox style={{ height: "80%" }}>
          {tableName !== "Task" && tableName !== "Tasks" && (
            <Box marginTop={"1rem"}>
              <Stack direction="row" spacing={1} gap={"10px"}>
                {tableName !== "Task" && !handleRenameAndNewTile && (
                  <CustomNameBox>{name}</CustomNameBox>
                )}
                <ClickAwayListener onClickAway={() => setDropdown(false)}>
                  <Box>
                    <CustomNameBox
                      onClick={() => {
                        setDropdown(!dropdown);
                      }}
                    >
                      Views
                      <ExpandMoreRounded
                        style={
                          dropdown
                            ? { transform: "scaleY(-1)" }
                            : { transform: "scaleY(1)" }
                        }
                      />
                    </CustomNameBox>
                    {dropdown ? (
                      <SimpleFilterDropDown
                        value={"All"}
                        handleData={handleChange}
                        header={[
                          ...new Map(
                            SearchTableData.AllDropDownValue.map((item) => [
                              item["metric_display_name"],
                              item,
                            ])
                          ).values(),
                        ]}
                        widthAdjustment={"handleBox"}
                        name={name}
                        searching={true}
                        handleChangeForDuplicate={setChangeForDuplicate}
                        handleDefaultNewTile={() => setApi(true)}
                        noTargetText={true}
                      />
                    ) : null}
                  </Box>
                </ClickAwayListener>
                {tableName !== "Task" && !handleRenameAndNewTile && (
                  <ClickAwayListener
                    onClickAway={() => {
                      setFieldDropDown(false);
                      setFieldAddDropDown(false);
                    }}
                  >
                    <SearchDrawerHeaderRight position={"relative"}>
                      <Box
                        onClick={() => {
                          if (!fieldDropDown && !fieldAddDropDown) {
                            setFieldDropDown(true);
                          } else {
                            setFieldDropDown(false);
                          }
                          setFieldAddDropDown(false);
                        }}
                        paddingRight={"0.8rem"}
                      >
                        <SearchSettingSVG cursor={"pointer"} ref={fieldRef} />
                      </Box>
                      {fieldDropDown ? (
                        <Box
                          display={"flex"}
                          flexDirection={"column"}
                          position={"absolute"}
                          top={"30px"}
                          left={"-222px"}
                          border={"1px solid #E6E6E6"}
                          boxShadow={"8px 18px 12px rgba(0, 0, 0, 0.05)"}
                          borderRadius={"8px"}
                          sx={{
                            background: "#ffffff",
                            width: "260px",
                          }}
                        >
                          {fields.map((value, index) => {
                            if (value === "divider") return <Divider />;
                            else if (
                              changeForDuplicate &&
                              value === "Duplicate"
                            )
                              return <></>;
                            else
                              return (
                                <Box
                                  height={"33px"}
                                  key={index}
                                  padding={"0rem 1.5rem"}
                                  display={"flex"}
                                  alignItems={"center"}
                                  justifyContent={"space-between"}
                                  gap={"10px"}
                                  sx={{
                                    "&:hover": {
                                      // background: "#F2F7FC",
                                      backgroundColor: "#F2F7FC",

                                      color: "#00A1E0",
                                      cursor: "pointer",
                                    },
                                  }}
                                  onClick={(e) => {
                                    if (value === "Rename") {
                                      setHandleRenameAndNewTile(true);
                                      setFieldDropDown(false);
                                      setRenameValue(state);
                                      setRename(true);
                                      setApi(true);
                                    }
                                    if (value === "Delete") {
                                      handleDeleteTiles();
                                      setFieldDropDown(false);
                                    }
                                    if (value === "Duplicate") {
                                      setHandleRenameAndNewTile(true);
                                      setFieldDropDown(false);
                                      setState(`${name} copy`);
                                      setName(`${name} copy`);
                                      handleDuplicateTiles();
                                    }
                                    if (value === "Fields & Related List") {
                                      setFieldDropDown(false);
                                      setFieldAddDropDown(true);
                                    }
                                  }}
                                >
                                  {value}
                                  {value === "Fields & Related List" && (
                                    <ArrowForwardSVG />
                                  )}
                                </Box>
                              );
                          })}
                        </Box>
                      ) : fieldAddDropDown ? (
                        <FilterColumn
                          header={
                            SearchTableData?.data[name]
                              ? SearchTableData?.data[name]
                              : SearchTableData?.defaultData?.map((data) => {
                                  data.isVisible = false;
                                  data.selected = false;
                                  return data;
                                })
                          }
                          activeHeader={
                            SearchTableData?.data[name]
                              ? SearchTableData?.data[name].filter(
                                  (data) => data.isVisible
                                )
                              : SearchTableData?.defaultData
                                  ?.map((data) => {
                                    data.isVisible = false;
                                    data.selected = false;
                                    return data;
                                  })
                                  .filter((d) => d.selected || d.isVisible)
                          }
                          filterColumns={(header) => filterColumns(header)}
                          tableName={taskState ? "Task" : tableName}
                          searchViewHeader={true}
                          setFieldAddDropDown={() => {
                            setFieldAddDropDown(false);
                            tableName !== "Task" && setFieldDropDown(true);
                          }}
                          handleSubFieldsFromSearchDrawer={true}
                          createView={true}
                        />
                      ) : (
                        <></>
                      )}
                    </SearchDrawerHeaderRight>
                  </ClickAwayListener>
                )}
              </Stack>
            </Box>
          )}
          {tableName !== "Tasks" && (
            <SearchDrawerHeaderFlexBox
              marginTop={"1rem"}
              justifyContent={"space-between"}
              marginRight={"8px"}
            >
              {handleRenameAndNewTile || tableName === "Task" ? (
                <ClickAwayListener
                  onClickAway={() => {
                    handleCreateTile();
                  }}
                >
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    gap={"4px"}
                    width={"100%"}
                  >
                    <NameTextField
                      sx={{
                        ".MuiOutlinedInput-root": {
                          width: tableName === "Task" ? "445px" : "100%",
                        },
                        ".MuiInputLabel-root": {
                          background: "#fff",
                        },
                      }}
                      value={state}
                      onChange={(e) => {
                        setState(e.target.value);
                      }}
                      label="Enter tile name"
                      size="small"
                      // onBlur={() => {
                      //   setName(state);
                      //   setHandleRenameAndNewTile(false);
                      //   setChangeForDuplicate(false);
                      //   handleDefaultHeader(state);
                      // }}
                    />
                    {tileNameError && (
                      <TileNameError>{tileNameError}</TileNameError>
                    )}
                  </Box>
                </ClickAwayListener>
              ) : (
                <Typography
                  fontFamily={"Inter"}
                  fontSize={"14px"}
                  fontWeight={"600"}
                >
                  {/* {name} */}
                </Typography>
              )}
            </SearchDrawerHeaderFlexBox>
          )}
          <Box padding={"1rem 0rem"}>
            <RenderComponents
              row={createdData}
              activeColumnHeader={[]}
              rowId=""
              tableName={taskState ? "Task" : tableName}
              onUpdateSuccessOfRow={onUpdateSuccessOfRow}
              rowData=""
              referenceData={referenceData}
              setRowData={""}
              searchView={true}
              SearchTableData={SearchTableData}
              name={name}
              error={error}
              handleError={handleError}
              SearchDrawer={true}
            />
          </Box>
        </ScrollableBox>
      )}

      {tableName !== "Tasks" ? (
        <ButtonBox
          style={{
            padding: "1rem 1rem 1rem 0rem",
            boxShadow: "0px -6px 16px rgba(0, 0, 0, 0.04)",
            width: "97.5",
            marginRight: "1rem",
            flexDirection: "row-reverse",
          }}
        >
          <StyledButton
            variant="contained"
            size="small"
            onClick={() => {
              if (handleRenameAndNewTile) {
                handleCreateTile();
              } else {
                setDataCreationLoader(true);
                DataCreation();
              }
            }}
            sx={{
              background: "#00A1E0",
            }}
          >
            Create{" "}
            {taskState ? "Task" : handleRenameAndNewTile ? "tile" : tableName}
          </StyledButton>
          <StyledButton
            variant="outlined"
            size="small"
            onClick={() => {
              closeDrawer();
              setError(false);
            }}
            style={{
              width: "100px",
            }}
          >
            Cancel
          </StyledButton>
          {dataCreationLoader ? <Loader handleSearchMargin={true} /> : <></>}
        </ButtonBox>
      ) : (
        <></>
      )}
    </Drawer>
  );
};

export default SearchDrawer;
