import styled from "@emotion/styled";
import { Help, Notifications, Photo } from "@mui/icons-material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { logout } from "../../Pages/Services/tableApi";
import General from "./SettingViewMe/General";
import HelpMe from "./SettingViewMe/Help";
import Integrations from "./SettingViewMe/Integrations";
import NotificationsMe from "./SettingViewMe/Notifications";
import PhotoMe from "./SettingViewMe/Photo";
import BillingAndUsage from "./SettingViewSubscription/BillingAndUsage";
import PlansSubscription from "./SettingViewSubscription/Plans";
import ManageShortcuts from "./SettingViewMe/ManageShortcuts";
import { ReactComponent as GeneralSvg } from "../../Assets/SVG/general.svg";
import { ReactComponent as IntegrationSvg } from "../../Assets/SVG/integration.svg";
import { ReactComponent as ShortcutSvg } from "../../Assets/SVG/shortcut.svg";
import { ReactComponent as HelpSvg } from "../../Assets/SVG/help.svg";
import FullScreenLoader from "./FullScreenLoader";

const MainBox = styled(Box)({
  display: "flex",
  height: "100%",
  overflow: "hidden",
  borderRadius: "8px",
  fontFamily: "Inter",
});

const SideBarBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: "20px 0px 20px 20px",
  background: "#F6F8F9",
  width: "30%",
});

const SideBarMenuBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "30px",
});

const SideBarMenuContentBox = styled(Box)({
  display: "flex",
});

const SideBarLogOutBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  gap: "10px",
});

export const SideBarContentBox = styled(Box)({
  background: "#fff",
  padding: "20px",
  width: "100%",
});

export const SideBarSubContentBox = styled(Box)({
  marginBottom: "16px",
  lineHeight: "20px",
});

export const SideBarSubContentHeader = styled(Box)({
  fontSize: "18px",
  lineHeight: "30px",
  color: "#1A1D1F",
});

const SideBarMenuTypoGraphy = styled(Box)({
  color: "#9DA5AD",
  textAlign: "left",
  height: "30px",
  padding: 0,
  textTransform: "uppercase",
  letterSpacing: "1.25px",
  fontSize: "11px",
  fontWeight: 600,
  lineHeight: "20px",
});

const SideBarTypoGraphy = styled(Box)({
  cursor: "pointer",
  textAlign: "left",
  height: "30px",
  padding: 0,
  width: "100%",
  fontSize: "14px",
});

function SettingView({
  openUrlName,
  settingViewClose,
  manageShortCut,
  showOrHideFields,
  setAppShortCut,
  appShortCut,
  setBookmark,
}) {
  const [sideBar, setSideBar] = useState(false);
  const [activeSideMenu, setActiveSideMenu] = useState(
    openUrlName ? openUrlName : "General"
  );
  const [activeSideMenuHeader, setActiveSideMenuHeader] = useState("Me");
  const [logOut, setLogOut] = useState(false);
  const sideBarData = {
    Me: ["General", "Integrations", "Manage Shortcuts", "Help"],
  };
  const svg = [
    <GeneralSvg />,
    <IntegrationSvg />,
    <ShortcutSvg />,
    <HelpSvg />,
  ];

  const LogOutUser = async () => {
    setSideBar(true);
    setLogOut(true);
    // settingViewClose();
    // localStorage.clear();
    // window.location = "/login";
    await logout();
  };

  const sideBarContentDataBox = () => {
    const activeMenu = activeSideMenu;

    switch (activeMenu) {
      case "General":
        return (
          <General
            setBookmark={setBookmark}
            setShowOrHideFields={showOrHideFields}
          />
        );
      case "Help":
        return <HelpMe />;
      case "Manage Shortcuts":
        return (
          <ManageShortcuts
            shortCut={localStorage.getItem("shortCut")}
            manageShortCuts={manageShortCut}
            setAppShortCut={setAppShortCut}
            appShortCut={appShortCut}
          />
        );
      case "Integrations":
        return <Integrations />;
      case "Notifications":
        return <NotificationsMe />;
      case "Photo":
        return <PhotoMe />;
      case "Billing & Usage":
        return <BillingAndUsage />;
      case "Plans":
        return <PlansSubscription />;

      default:
        break;
    }
  };

  return (
    <MainBox>
      {!sideBar ? (
        <SideBarBox>
          <SideBarMenuBox>
            {Object.keys(sideBarData).map((SideBarMenu) => {
              return (
                <Box key={sideBarData}>
                  <SideBarMenuTypoGraphy> {SideBarMenu} </SideBarMenuTypoGraphy>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "7px",
                    }}
                  >
                    {sideBarData[SideBarMenu].map((data, i) => {
                      return (
                        <>
                          <Box
                            sx={{
                              display: "flex",
                              gap: "10px",
                              lineHeight: "30px",
                              color:
                                data === activeSideMenu ? "white" : "#848F99",
                              backgroundColor:
                                data === activeSideMenu && "#00A1E0",
                              alignItems: "center",
                              borderTopLeftRadius:
                                data === activeSideMenu && "21px",
                              borderBottomLeftRadius:
                                data === activeSideMenu && "21px",
                              paddingLeft: "12px",
                              marginLeft: "-10px",
                              svg: {
                                path: {
                                  stroke: data === activeSideMenu && "white",
                                },
                              },
                              "&:hover": {
                                svg: {
                                  path: {
                                    stroke:
                                      data !== activeSideMenu && "#00A1E0",
                                  },
                                },
                              },
                            }}
                          >
                            {svg[i]}

                            <SideBarTypoGraphy
                              sx={{
                                "&:hover": {
                                  color: data !== activeSideMenu && "#00A1E0",
                                },
                              }}
                              onClick={(e) => {
                                setActiveSideMenuHeader(SideBarMenu);
                                setActiveSideMenu(e.target.innerText);
                              }}
                            >
                              {data}
                            </SideBarTypoGraphy>
                          </Box>
                        </>
                      );
                    })}
                  </Box>
                </Box>
              );
            })}
          </SideBarMenuBox>
          <SideBarLogOutBox>
            <Box fontSize={"18px"} textTransform={"capitalize"}>
              {localStorage.getItem("user_name") || ""}
            </Box>
            {!logOut ? (
              <SideBarTypoGraphy
                onClick={LogOutUser}
                sx={{
                  "&:hover": {
                    color: "#00A1E0",
                  },
                }}
              >
                Log out
              </SideBarTypoGraphy>
            ) : (
              <></>
            )}
          </SideBarLogOutBox>
        </SideBarBox>
      ) : (
        <></>
      )}

      <SideBarContentBox>{sideBarContentDataBox()}</SideBarContentBox>


    {  logOut &&
      <FullScreenLoader/>}
    </MainBox>
  );
}

export default SettingView;
