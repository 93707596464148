import { styled } from "@mui/material";

export const NavButton = styled("div")((theme) => ({
  width: "20px",
  height: "20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  color: theme.fontColor,
  "& :hover": {
    stroke: "#00A1E0",
  },
  // "div[title]:hover": {
  //   content: "attr(title)",
  //   padding: "40px 8px",
  //   position: "absolute",
  //   left: 0,
  //   top: "100%",
  //   zIndex: 1,
  //   background: "red",
  // },
}));

export const Search = styled("div")((theme) => ({
  position: "relative",
  borderRadius: "8px",
  height: "33px",
  width: "500px",
  margin: "4px",
  cursor: "pointer",
  // color: theme.fontColor,
  color: "#E6E6E6",
  display: "flex",
  alignItems: "center",
  background: "rgba(255, 255, 255, 0.24)",
  border: "1px solid rgba(235, 235, 235, 0.24)",
  backdropFilter: "blur(2px)",
}));

export const SearchIconWrapper = styled("div")((theme) => ({
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  left: "15px",
  color: theme.fontColor,
}));
