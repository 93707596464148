import React from "react";
import styled from "@emotion/styled/macro";

import { useNotes } from "../../Context/NotesContext";

import { deleteNote } from "../../Pages/Services/notesApi";

const ModalMain = styled.div({
  position: "fixed",
  left: "0",
  top: "0",
  right: "0",
  bottom: "0",
  backgroundColor: "rgba(0,0,0,0.25)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: "7",
});

const ModalContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  backgroundColor: "#FFFFFF",
  width: "350px",
  height: "110px",
  borderRadius: "5px",
  padding: "1rem",
});

const ModlaHeader = styled.div({
  fontSize: "large",
  fontWeight: "700",
  padding: ".5rem 0",
});

const ModalButtonsContainer = styled.div({
  padding: "1rem 0",
  display: "flex",
  width: "100%",
  justifyContent: "flex-end",
  alignItems: "center",
});

const SecondaryButton = styled.div({
  padding: "0 1rem",
  cursor: "pointer",
});

const PrimaryButton = styled.div({
  cursor: "pointer",
  padding: ".5rem 1rem",
  backgroundColor: "#FCEEED",
  color: "#C23934",
  borderRadius: "5px",

  ":hover": {
    backgroundColor: "#C23934",
    color: "#FFFFFF",
  },
});

function DeleteModal({ showModal, onClose, item }) {
  const { dispatchNotes } = useNotes();

  if (!showModal) {
    return null;
  }

  return (
    <ModalMain onClick={() => onClose()}>
      <ModalContainer onClick={(e) => e.stopPropagation()}>
        <ModlaHeader>Delete Note</ModlaHeader>
        <div>Permanently delete this note?</div>
        <ModalButtonsContainer>
          <SecondaryButton onClick={() => onClose()}>Cancel</SecondaryButton>
          <PrimaryButton
            onClick={() => {
              deleteNote(dispatchNotes, item.id);
              onClose();
            }}
          >
            Delete Permanently
          </PrimaryButton>
        </ModalButtonsContainer>
      </ModalContainer>
    </ModalMain>
  );
}

export default DeleteModal;
