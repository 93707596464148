import { combineReducers } from "redux";
import getTableDataReducer from "./getTableDataReducer";
import getTableFieldReducer from "./getTableFieldReducer";
import taskAsyncReducer from "./taskAsyncReducer";
import tableAsyncReducer from "./tableAsyncReducer";
import toggleWishModalReducer from "./toggleWishModalReducer";
import hasAccessReducer from "./hasAccessReducer";

const rootReducer = combineReducers({
  tableData: getTableDataReducer,
  tableField: getTableFieldReducer,
  asyncTask: taskAsyncReducer,
  asyncTable: tableAsyncReducer,
  toggleWish: toggleWishModalReducer,
  hasAccess: hasAccessReducer,
});
export default rootReducer;
