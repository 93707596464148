import { createTheme, ThemeProvider } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { LicenseInfo } from "@mui/x-license-pro";
import { store } from "../src/store";
import { NoteContextProvider } from "./Context/NotesContext";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_DATAGRID_PRO_LICENSE_KEY);

const root = ReactDOM.createRoot(document.getElementById("root"));

const theme = createTheme({
  themeColor: "#F3E392",
  fontColor: "#444647",
  panel: {
    top: " -70px !important",
    left: "10px !important",
  },
});

console.log("Welcome To Tracktable 2")

root.render(
  <ThemeProvider theme={theme}>
    <>
      <BrowserRouter>
        <Provider store={store}>
          <NoteContextProvider>
            <App />
          </NoteContextProvider>
        </Provider>
      </BrowserRouter>
    </>
  </ThemeProvider>
);
