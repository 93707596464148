import React from "react";
import styled from "@emotion/styled/macro";
import { useState } from "react";
import { useEffect } from "react";
import { shareNote } from "../../Pages/Services/notesApi";
import { useNotes } from "../../Context/NotesContext";

import { HiLink } from "react-icons/hi";

const ShareMain = styled.div({
  position: "fixed",
  left: "0",
  top: "0",
  right: "0",
  bottom: "0",

  background: "rgba(0, 81, 112, 0.1)",
  backdropFilter: "blur(4px)",

  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: "7",
});

const ShareContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  backgroundColor: "#FFFFFF",
  width: "400px",
  height: "120px",
  borderRadius: "8px",
  padding: "1rem",

  justifyContent: "space-between",
});

const ShareHeader = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "12px",
});
const ShareInput = styled.input({
  padding: "8px 12px",
  width: "90%",
  borderRadius: "8px",
  backgroundColor: "#F6F6F6",
});
const ShareButton = styled.div({
  cursor: "pointer",
  background: "#00A1E0",
  color: "white",
  padding: "8px 12px",
  borderRadius: "8px",
});

const ShareBody = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "space-between",
});
const ShareList = styled.div({
  height: "16rem",
  width: "100%",
});

const ListContent = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: "4px 0",
  fontWeight: "600",
});

const ShareFooter = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "space-between",
});

const FooterContent = styled.div({
  position: "relative",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "12px",

  " & .copied": {
    color: "#E8E8E8",
    backgroundColor: "#1C1E20",
    position: "absolute",
    bottom: "42px",
    right: "29px",
    padding: "4px",
    fontSize: "75%",
    borderRadius: "8px",
  },
});

const FooterInput = styled.input({
  padding: "8px 12px",
  width: "70%",
  borderRadius: "8px",
});
const FooterButton = styled.div({
  cursor: "pointer",
  color: "#00A1E0",
  backgroundColor: "white",
  padding: "8px 12px",
  fontSize: "80%",
  width: "7.5rem",
  textAlign: "center",
  fontWeight: "600",

  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "8rem",
  borderRadius: "8px",

  ":hover": {
    backgroundColor: "#F2F7FC",
  },
});

function ShareModule({ showShareModule, onClose }) {
  const { stateNotes } = useNotes();

  const { singleNote } = stateNotes;

  const tempList = [
    { name: "Integrated User 1", id: "1" },
    { name: "Integrated User 2", id: "2" },
    { name: "Integrated User 3", id: "3" },
  ];

  const [link, setLink] = useState("");
  let baseURL = process.env.REACT_APP_TRACK_TABLE_BACKEND_REDIRECT_AWS_URL;
  useEffect(() => {
    setLink(
      `${baseURL}/api/v1/generate/pdf/${singleNote.id}`

      // `https://devapi.tracktable.io/api/v1/generate/pdf/${singleNote.id}`
    );
  }, [showShareModule]);

  const [isCopied, setIsCopied] = useState(false);

  async function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }

  const handleCopyClick = () => {
    copyTextToClipboard(link)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [shareEmail, setShareEmail] = useState("");

  const shareFunction = () => {
    let shareId = singleNote.id;
    let shareTitle = singleNote.title;
    shareNote(shareEmail, shareId, shareTitle);
    setShareEmail("");
    onClose();
  };

  if (!showShareModule) {
    return null;
  }

  return (
    <ShareMain onClick={() => onClose()}>
      <ShareContainer onClick={(e) => e.stopPropagation()}>
        <ShareHeader>
          <ShareInput
            placeholder="Type an email..."
            value={shareEmail}
            onChange={(e) => setShareEmail(e.target.value)}
            autoFocus
          />
          <ShareButton onClick={shareFunction}>Invite</ShareButton>
        </ShareHeader>
        {/* <ShareBody>
          <div
            style={{
              padding: "8px 0",
              color: "#80888F",
              fontWeight: "600",
              fontSize: "85%",
            }}
          >
            SELECT A PERSON
          </div>
          <ShareList>
            {tempList.map((item) => (
              <ListContent key={item.id}>{item.name}</ListContent>
            ))}
          </ShareList>
        </ShareBody> */}
        <ShareFooter>
          <div
            style={{
              padding: "8px 0",
              // color: "#80888F",
              fontWeight: "600",
              fontSize: "85%",
            }}
          >
            SHARE NOTE LINK
          </div>
          <FooterContent>
            <FooterInput
              placeholder="current page link"
              value={link}
              onChange={(e) => setLink(e.target.value)}
            />

            {isCopied && <span className="copied">Link copied</span>}

            <FooterButton onClick={handleCopyClick}>
              <HiLink />
              Copy note link
            </FooterButton>
          </FooterContent>
        </ShareFooter>
      </ShareContainer>
    </ShareMain>
  );
}

export default ShareModule;
