import { Divider } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { SideBarSubContentBox, SideBarSubContentHeader } from "../SettingView";

function HelpMe() {
  return (
    <>
      <SideBarSubContentBox>
        <SideBarSubContentHeader>Help</SideBarSubContentHeader>
        <p style={{ color: "#6A727A", margin: "2px 0px" }}>Talk With Us</p>
      </SideBarSubContentBox>

      <Box
        sx={{
          width: "497px",
          height: "264px",
          background: "#FFFFFF",
          border: "1px solid #F0F0F0",
          borderRadius: "12px",
          display: "flex",
          flexDirection: "column",
          padding: "32px",
          justifyContent: "space-around",
        }}
      >
        <Box
          sx={{
            "& :hover": {
              color: "#00A1E0",
            },
          }}
        >
          <Box
            onClick={() => {
              window.location = "mailto:Support@tracktable.io";
            }}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              cursor: "pointer",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <Box
                sx={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "16px",
                  lineHeight: "19px",
                }}
              >
                Email Us
              </Box>
              <Box
                sx={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "17px",
                  color: "#798EA3",
                }}
              >
                We appreciate all of your questions and feedback
              </Box>
            </Box>
            <Box>{">"}</Box>
          </Box>
          <Divider
            sx={{
              marginTop: "1rem",
            }}
          ></Divider>
        </Box>
        <Box
          sx={{
            "& :hover": {
              color: "#00A1E0",
            },
          }}
        >
          <Box
              onClick={() => {
                window.open(
                  "https://meetings.hubspot.com/aryan-tripathi",
                  "_blank"
                );
              }}
    
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              cursor: "pointer",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <Box
                sx={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "16px",
                  lineHeight: "19px",
                }}
              >
                Schedule session
              </Box>
              <Box
                sx={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "17px",
                  color: "#798EA3",
                }}
              >
                We’ll help you at a time convenient to you.
              </Box>
            </Box>
            <Box>{">"}</Box>
          </Box>
          <Divider
            sx={{
              marginTop: "1rem",
            }}
          ></Divider>
        </Box>
        <Box
          sx={{
            "& :hover": {
              color: "#00A1E0",
            },
          }}
        >
          <Box
            onClick={() => {
              window.open(
                "https://www.notion.so/aryan-product/TrackTable-Help-fb0297c0c5c64c96a1ea6a538b1e5ddb",
                "_blank"
              );
            }}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              cursor: "pointer",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <Box
                sx={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "16px",
                  lineHeight: "19px",
                }}
              >
                Help center
              </Box>
              <Box
                sx={{
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "17px",
                  color: "#798EA3",
                }}
              >
                Questions about Tracktable? Find answers quickly.
              </Box>
            </Box>
            <Box>{">"}</Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default HelpMe;
