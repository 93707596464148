import styled from "@emotion/styled";
import { IoMdClose } from "react-icons/io";
import {
  Button,
  Snackbar,
  TextareaAutosize,
  Typography,
  Input,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { openWishEmail, wishEmail } from "../../Pages/Services/tableApi";
import Actions from "../../redux/actions";
import { useDispatch } from "react-redux";

const MainWishBox = styled(Box)({
  height: "100%",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  fontFamily: "Inter",
  gap: "15px",
});

const WishHeadingTypoGraphy = styled(Typography)({
  width: "100%",
  fontWeight: 600,
  fontFamily: "Inter",
});

const TextAreaBox = styled(TextareaAutosize)({
  fontFamily: "Inter",
  minHeight: "100px",
  maxHeight: "100px",
  borderColor: "#E6E9EB",
  resize: "vertical",
  paddingTop: "12px",
  paddingLeft: "12px",
  "&:focus": {
    outline: "none",
    borderColor: "#E6E9EB",
  },
});

const SendButton = styled(Button)({
  fontFamily: "Inter",
  width: "25%",
  color: "#1A1D1F",
  borderColor: "#E6E9EB",
  letterSpacing: "1px",
  textTransform: "none",
  "&:hover": {
    color: "#1976d2",
    outlineColor: "#E6E9EB",
    borderColor: "#E6E9EB",
  },
});

function WishView({ handleSuccessWish, onClose, isEmail }) {
  const [textAreaInput, setTextAreaInput] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [messageForwarding, setMessageForwarding] = useState(false);

  const dispatch = useDispatch();

  const sendWishEmail = async (message) => {
    const res = await wishEmail({ wish_data: message.trim() });
    if (res.data.status === 200) {
      handleSuccessWish();
      setMessageForwarding(false);
      dispatch(Actions.toggleWishModal.wishModalOff(false));
    }
  };

  const sendWishWithEmail = async (message, email) => {
    const res = await openWishEmail({
      wish_data: message.trim(),
      email: email.trim(),
    });
    if (res?.data?.status === 200) {
      handleSuccessWish();
      setMessageForwarding(false);
    } else if (res?.data?.status === 400) {
      setMessageForwarding(false);
      setErrMsg(res?.data?.error);
    } else {
      setMessageForwarding(false);
    }
  };

  return (
    <MainWishBox>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <WishHeadingTypoGraphy>I wish this app could ...</WishHeadingTypoGraphy>
        {onClose && (
          <IoMdClose
            onClick={() => {
              if (onClose) {
                onClose();
              }
            }}
            style={{
              cursor: "pointer",
            }}
          />
        )}
      </Box>
      <TextAreaBox
        aria-label="empty textarea"
        value={textAreaInput}
        placeholder={
          !textAreaInput.length && "Share your feedback or ask questions"
        }
        onChange={(e) => setTextAreaInput(e.target.value)}
        autoFocus
      />
      {isEmail && (
        <>
          {/* <Typography>Enter your email</Typography> */}
          <TextField
            id="email"
            error={errMsg?.length}
            helperText={errMsg}
            value={emailInput}
            label="Email"
            variant="outlined"
            size="small"
            onChange={(e) => setEmailInput(e.target.value)}
          />
        </>
      )}
      <SendButton
        variant="outlined"
        size="small"
        onClick={() => {
          if (isEmail) {
            if (textAreaInput?.length && emailInput?.length) {
              setMessageForwarding(true);
              // setTextAreaInput("");
              // setEmailInput("");
              setErrMsg("");
              sendWishWithEmail(textAreaInput, emailInput);
            } else {
              setErrMsg("Please enter your email");
              setEmailInput("");
            }
          } else {
            if (textAreaInput.length) {
              setMessageForwarding(true);
              setTextAreaInput("");
              sendWishEmail(textAreaInput);
            }
          }
        }}
        sx={{
          cursor: !messageForwarding ? "pointer" : "none",
          pointerEvents: !messageForwarding ? "pointer" : "none",
        }}
      >
        Send
      </SendButton>
    </MainWishBox>
  );
}

export default WishView;
