import secureAxiosInstance from "./apiInterceptors";

export const login = async (JSONdataParams) => {
  try {
    const res = await secureAxiosInstance({
      url: "/login/",
      data: JSONdataParams,
    });
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const getTableDataApi = async (JSONdataParams, sortParams) => {
  try {
    const res = await secureAxiosInstance({
      url: `/table_records/?sort=${sortParams?.sort}${
        sortParams?.dir ? `&dir=${sortParams?.dir}` : ""
      }`,
      data: JSONdataParams,
    });
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const getTableHeadersApi = async (JSONdataParams) => {
  try {
    const res = await secureAxiosInstance({
      url: "/get_table_fields/",
      data: JSONdataParams,
    });
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const updateTableCellApi = async (JSONdataParams) => {
  try {
    const res = await secureAxiosInstance({
      url: "/update_record/",
      data: JSONdataParams,
    });
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const getNewAccessToken = async () => {
  try {
    const res = await secureAxiosInstance({
      url: "/refresh_token/",
      data: {
        refresh_token: localStorage.getItem("refresh_token"),
      },
    });
    return res;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const handleAddFieldInTable = async (JSONdataParams) => {
  try {
    const res = await secureAxiosInstance({
      url: "/update_fields/",
      data: JSONdataParams,
    });
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const getKeyData = async (JSONdataParams) => {
  try {
    const res = await secureAxiosInstance({
      url: "/get_keys_value/",
      data: JSONdataParams,
    });
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const createOrUpdateKey = async (JSONdataParams) => {
  try {
    const res = await secureAxiosInstance({
      url: "/create_or_update_key_in_setting/",
      data: JSONdataParams,
    });
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const deleteKey = async (JSONdataParams) => {
  try {
    const res = await secureAxiosInstance({
      url: "/deletekey_in_setting/",
      data: JSONdataParams,
    });
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const getRelatedRecord = async (JSONdataParams) => {
  try {
    const res = await secureAxiosInstance({
      url: "/related_table_records/",
      data: JSONdataParams,
    });
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const getIdRecord = async (JSONdataParams) => {
  try {
    const res = await secureAxiosInstance({
      url: "/get_record/",
      data: JSONdataParams,
    });
    return res;
  } catch (err) {
    console.log(err);
  }
};
export const addLead = async () => {
  try {
    const res = await secureAxiosInstance({
      url: "/add_lead_convertedstatus/",
    });
    return res;
  } catch (err) {
    console.log(err);
  }
};
export const fieldTest = async () => {
  try {
    const res = await secureAxiosInstance({
      url: "/add_all_fields_in_db/",
    });
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const addForeignTableFields = async () => {
  try {
    const res = await secureAxiosInstance({
      url: "/add_foreign_table_fields/",
    });
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const tableKeyPrefixRecords = async () => {
  try {
    const res = await secureAxiosInstance({
      url: "/add_table_keyprefix_records/",
    });
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const setDefaultOverviewTileData = async () => {
  try {
    const res = await secureAxiosInstance({
      url: "/add_overview/",
    });
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const sync_tile = async () => {
  try {
    const res = await secureAxiosInstance({
      url: "/sync_data_for_tile/",
    });
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const recentSearches = async () => {
  try {
    const res = await secureAxiosInstance({
      url: "/recent_searches/",
    });
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const all_matched_records = async (JSONdataParams) => {
  try {
    const res = await secureAxiosInstance({
      url: "/search_record/",
      data: JSONdataParams,
    });
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const create_record = async (JSONdataParams) => {
  try {
    const res = await secureAxiosInstance({
      url: "/create_record/",
      data: JSONdataParams,
    });
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const delete_record = async (JSONdataParams) => {
  try {
    const res = await secureAxiosInstance({
      url: "/delete_record/",
      data: JSONdataParams,
    });
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const update_record = async (JSONdataParams) => {
  try {
    const res = await secureAxiosInstance({
      url: "/update_record/",
      data: JSONdataParams,
    });
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const lead_data = async (JSONdataParams) => {
  try {
    const res = await secureAxiosInstance({
      url: "/get_record/",
      data: JSONdataParams,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const search_records_convert_lead = async (JSONdataParams) => {
  try {
    const res = await secureAxiosInstance({
      url: "/search_records_for_convert_lead/",
      data: JSONdataParams,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const convert_lead_api = async (JSONdataParams) => {
  try {
    const res = await secureAxiosInstance({
      url: "/convert_lead/",
      data: JSONdataParams,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getAllTiles = async (JSONdataParams) => {
  try {
    const res = await secureAxiosInstance({
      url: "/get_all_tiles/",
      data: JSONdataParams,
    });
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const updateTile = async (JSONdataParams) => {
  try {
    const res = await secureAxiosInstance({
      url: "/update_tile/",
      data: JSONdataParams,
    });
    return res;
  } catch (err) {
    console.log(err);
    return err;
  }
};
export const deleteTile = async (JSONdataParams) => {
  try {
    const res = await secureAxiosInstance({
      url: "/delete_tile/",
      data: JSONdataParams,
    });
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const createTile = async (JSONdataParams) => {
  try {
    const res = await secureAxiosInstance({
      url: "/create_tile/",
      data: JSONdataParams,
    });
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const duplicateTile = async (JSONdataParams) => {
  try {
    const res = await secureAxiosInstance({
      url: "/create_duplicate_tile/",
      data: JSONdataParams,
    });
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const logout = async (JSONdataParams) => {
  try {
    const res = await secureAxiosInstance({
      url: "/logout/",
      data: JSONdataParams,
    });
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const relatedListData = async (JSONdataParams) => {
  try {
    const res = await secureAxiosInstance({
      url: "/select_unselect_related_sobject/",
      data: JSONdataParams,
    });
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const relatedListTableRecords = async (JSONdataParams) => {
  try {
    const res = await secureAxiosInstance({
      url: "/related_table_records/",
      data: JSONdataParams,
    });
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const relatedListTableHeaderApi = async (JSONdataParams) => {
  try {
    const res = await secureAxiosInstance({
      url: "/update_related_table_fields/",
      data: JSONdataParams,
    });
    return res;
  } catch (err) {
    console.log(err);
  }
};

export const wishEmail = async (JSONdataParams) => {
  try {
    const res = await secureAxiosInstance({
      url: "/send_wish_email/",
      data: JSONdataParams,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const openWishEmail = async (JSONdataParams) => {
  try {
    const res = await secureAxiosInstance({
      url: "/send_open_email/",
      data: JSONdataParams,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getTileRecords = async (JSONdataParams) => {
  try {
    const res = await secureAxiosInstance({
      url: "/get_tile_records/",
      data: JSONdataParams,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getImageData = async () => {
  try {
    const res = await secureAxiosInstance({
      url: "/get_iamage_link/",
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getTasksData = async () => {
  const d = new Date();
  const month = d.getMonth() < 9 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
  const day = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate();
  const date = `${d.getFullYear()}-${month}-${day}`;
  try {
    const res = await secureAxiosInstance({
      url: `/get_all_task/?date=${date}`,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const searchRecordsFromAllTheTables = async (JSONdataParams) => {
  try {
    const res = await secureAxiosInstance({
      url: "/search_records_for_update/",
      data: JSONdataParams,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const searchRecordsForMainTables = async (JSONdataParams) => {
  try {
    const res = await secureAxiosInstance({
      url: "/search_records_for_notes/",
      data: JSONdataParams,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const inviteSend = async (JSONdataParams) => {
  try {
    const res = await secureAxiosInstance({
      url: "/send_invite_email/",
      data: JSONdataParams,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};
