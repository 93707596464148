export const customHeader = (dataType) => {
  let header;
  switch (dataType) {
    case "string":
      header = [
        {
          editable: false,
          isVisible: true,
          metric_display_name: "is",
          metric_name: "is",
          metric_datatype: "string",
          filter_datatype: "string",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "is not",
          metric_name: "is_not",
          metric_datatype: "string",
          filter_datatype: "string",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "contains",
          metric_name: "contains",
          metric_datatype: "string",
          filter_datatype: "string",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "does not contains",
          metric_name: "does_not_contain",
          metric_datatype: "string",
          filter_datatype: "string",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "is empty",
          metric_name: "is_empty",
          metric_datatype: "string",
          filter_datatype: "string",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "is not empty",
          metric_name: "is_not_empty",
          metric_datatype: "string",
          filter_datatype: "string",
        },
      ];
      break;
    case "date":
      header = [
        {
          editable: false,
          isVisible: true,
          metric_display_name: "is",
          metric_name: "is",
          metric_datatype: "date",
          filter_datatype: "date",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "is not",
          metric_name: "is_not",
          metric_datatype: "date",
          filter_datatype: "date",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "is before",
          metric_name: "is_before",
          metric_datatype: "date",
          filter_datatype: "date",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "is after",
          metric_name: "is_after",
          metric_datatype: "date",
          filter_datatype: "date",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "is on or before",
          metric_name: "is_on_or_before",
          metric_datatype: "date",
          filter_datatype: "date",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "is on or after",
          metric_name: "is_on_or_after",
          metric_datatype: "date",
          filter_datatype: "date",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "is empty",
          metric_name: "is_empty",
          metric_datatype: "date",
          filter_datatype: "date",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "is not empty",
          metric_name: "is_not_empty",
          metric_datatype: "date",
          filter_datatype: "date",
        },
      ];
      break;
    case "datetime":
      header = [
        {
          editable: false,
          isVisible: true,
          metric_display_name: "is",
          metric_name: "is",
          metric_datatype: "datetime",
          filter_datatype: "date",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "is not",
          metric_name: "is_not",
          metric_datatype: "datetime",
          filter_datatype: "date",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "is before",
          metric_name: "is_before",
          metric_datatype: "datetime",
          filter_datatype: "date",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "is after",
          metric_name: "is_after",
          metric_datatype: "datetime",
          filter_datatype: "date",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "is on or before",
          metric_name: "is_on_or_before",
          metric_datatype: "datetime",
          filter_datatype: "date",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "is on or after",
          metric_name: "is_on_or_after",
          metric_datatype: "datetime",
          filter_datatype: "date",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "is empty",
          metric_name: "is_empty",
          metric_datatype: "datetime",
          filter_datatype: "date",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "is not empty",
          metric_name: "is_not_empty",
          metric_datatype: "datetime",
          filter_datatype: "date",
        },
      ];
      break;
    case "time":
      header = [
        {
          editable: false,
          isVisible: true,
          metric_display_name: "is",
          metric_name: "is",
          metric_datatype: "time",
          filter_datatype: "date",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "is not",
          metric_name: "is_not",
          metric_datatype: "time",
          filter_datatype: "date",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "is before",
          metric_name: "is_before",
          metric_datatype: "time",
          filter_datatype: "date",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "is after",
          metric_name: "is_after",
          metric_datatype: "time",
          filter_datatype: "date",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "is on or before",
          metric_name: "is_on_or_before",
          metric_datatype: "time",
          filter_datatype: "date",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "is on or after",
          metric_name: "is_on_or_after",
          metric_datatype: "time",
          filter_datatype: "date",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "is empty",
          metric_name: "is_empty",
          metric_datatype: "time",
          filter_datatype: "date",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "is not empty",
          metric_name: "is_not_empty",
          metric_datatype: "time",
          filter_datatype: "date",
        },
      ];
      break;
    case "picklist":
      header = [
        {
          editable: false,
          isVisible: true,
          metric_display_name: "is",
          metric_name: "is",
          metric_datatype: "picklist",
          filter_datatype: "picklist",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "is not",
          metric_name: "is_not",
          metric_datatype: "picklist",
          filter_datatype: "picklist",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "is empty",
          metric_name: "is_empty",
          metric_datatype: "picklist",
          filter_datatype: "picklist",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "is not empty",
          metric_name: "is_not_empty",
          metric_datatype: "picklist",
          filter_datatype: "picklist",
        },
      ];
      break;
    case "multipicklist":
      header = [
        {
          editable: false,
          isVisible: true,
          metric_display_name: "is",
          metric_name: "is",
          metric_datatype: "multipicklist",
          filter_datatype: "multipicklist",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "is not",
          metric_name: "is_not",
          metric_datatype: "multipicklist",
          filter_datatype: "multipicklist",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "contains",
          metric_name: "contains",
          metric_datatype: "multipicklist",
          filter_datatype: "multipicklist",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "does not contain",
          metric_datatype: "multipicklist",
          filter_datatype: "multipicklist",
        },
      ];
      break;
    case "numeric":
      header = [
        {
          editable: false,
          isVisible: true,
          metric_display_name: "is",
          metric_name: "is",
          metric_datatype: "numeric",
          filter_datatype: "numeric",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "is not",
          metric_name: "is_not",
          metric_datatype: "numeric",
          filter_datatype: "numeric",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "less than",
          metric_name: "less_than",
          metric_datatype: "numeric",
          filter_datatype: "numeric",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "less than or equal to",
          metric_name: "less_than_or_equal_to",
          metric_datatype: "numeric",
          filter_datatype: "numeric",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "greater than",
          metric_name: "greater_than",
          metric_datatype: "numeric",
          filter_datatype: "numeric",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "greater than or equal to",
          metric_name: "greater_than_or_equal_to",
          metric_datatype: "numeric",
          filter_datatype: "numeric",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "is empty",
          metric_name: "is_empty",
          metric_datatype: "numeric",
          filter_datatype: "numeric",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "is not empty",
          metric_name: "is_not_empty",
          metric_datatype: "numeric",
          filter_datatype: "numeric",
        },
      ];
      break;
    case "checkbox":
      header = [
        {
          editable: false,
          isVisible: true,
          metric_display_name: "is",
          metric_name: "is",
          metric_datatype: "boolean",
          filter_datatype: "checkbox",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "is empty",
          metric_name: "is_empty",
          metric_datatype: "boolean",
          filter_datatype: "checkbox",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "is not empty",
          metric_name: "is_not_empty",
          metric_datatype: "boolean",
          filter_datatype: "checkbox",
        },
      ];
      break;
    case "reference":
      header = [
        {
          editable: false,
          isVisible: true,
          metric_display_name: "is",
          metric_name: "is",
          metric_datatype: "string",
          filter_datatype: "string",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "is not",
          metric_name: "is_not",
          metric_datatype: "string",
          filter_datatype: "string",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "is empty",
          metric_name: "is_empty",
          metric_datatype: "string",
          filter_datatype: "string",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "is not empty",
          metric_name: "is_not_empty",
          metric_datatype: "string",
          filter_datatype: "string",
        },
      ];
      break;
    default:
      header = [
        {
          editable: false,
          isVisible: true,
          metric_display_name: "is",
          metric_name: "is",
          metric_datatype: "string",
          filter_datatype: "string",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "is not",
          metric_name: "is_not",
          metric_datatype: "string",
          filter_datatype: "string",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "contains",
          metric_name: "contains",
          metric_datatype: "string",
          filter_datatype: "string",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "is not contains",
          metric_name: "is not contains",
          metric_datatype: "string",
          filter_datatype: "string",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "is empty",
          metric_name: "is_empty",
          metric_datatype: "string",
          filter_datatype: "string",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "is not empty",
          metric_name: "is_not_empty",
          metric_datatype: "string",
          filter_datatype: "string",
        },
      ];
      break;
  }
  return header;
};

export const customWidthForFilterType = (dataType) => {
  const getWidth = {
    string: "171px",
    date: "149px",
    datetime: "150px",
    time: "149px",
    picklist: "135px",
    multipicklist: "150px",
    numeric: "210px",
    checkbox: "135px",
    reference: "135px",
    boolean:"135px",
  };

  return getWidth[dataType] || "100px";
};

export const customDropDown = (
  dataType,
  dropDownValues,
  value,
  mainDataType,
  handleDateType
) => {
  let DropDown;
  const dropdownValues =
    typeof dropDownValues === "string" && dropDownValues != ""
      ? JSON.parse(dropDownValues)
      : dropDownValues;
  if (dataType === "picklist" || dataType === "multipicklist") {
    DropDown = dropdownValues.map((data) => {
      return {
        editable: false,
        isVisible: value ? (value.includes(data) ? true : false) : false,
        metric_display_name: data,
        metric_name: data,
        metric_datatype: dataType,
        filter_datatype: dataType,
      };
    });
    return DropDown;
  }
  // if (dataType === "multipicklist") {
  //   DropDown = dropDownValues.map((data) => {
  //     return {
  //       editable: false,
  //       isVisible: value ? (value.includes(data) ? true : false) : false,
  //       metric_display_name: data,
  //       metric_name: data,
  //       metric_datatype: dataType,
  //     };
  //   });
  //   return DropDown;
  // }
  switch (dataType) {
    case "dateDropDown":
      DropDown = [
        {
          editable: false,
          isVisible: true,
          metric_display_name: "Yesterday",
          metric_name: "Yesterday",
          metric_datatype: handleDateType,
          filter_datatype: mainDataType,
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "Today",
          metric_name: "Today",
          metric_datatype: handleDateType,
          filter_datatype: mainDataType,
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "Tomorrow",
          metric_name: "Tomorrow",
          metric_datatype: handleDateType,
          filter_datatype: mainDataType,
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "Last 90 days",
          metric_name: "Last 90 days",
          metric_datatype: handleDateType,
          filter_datatype: mainDataType,
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "Next 90 days",
          metric_name: "Next 90 days",
          metric_datatype: handleDateType,
          filter_datatype: mainDataType,
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "Last Quarter",
          metric_name: "Last Quarter",
          metric_datatype: handleDateType,
          filter_datatype: mainDataType,
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "This Quarter",
          metric_name: "This Quarter",
          metric_datatype: handleDateType,
          filter_datatype: mainDataType,
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "Next Quarter",
          metric_name: "Next Quarter",
          metric_datatype: handleDateType,
          filter_datatype: mainDataType,
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "Last Year",
          metric_name: "Last Year",
          metric_datatype: handleDateType,
          filter_datatype: mainDataType,
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "Next Year",
          metric_name: "Next Year",
          metric_datatype: handleDateType,
          filter_datatype: mainDataType,
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "This Year",
          metric_name: "This Year",
          metric_datatype: handleDateType,
          filter_datatype: mainDataType,
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "Last Week",
          metric_name: "Last Week",
          metric_datatype: handleDateType,
          filter_datatype: mainDataType,
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "Next Week",
          metric_name: "Next Week",
          metric_datatype: handleDateType,
          filter_datatype: mainDataType,
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "This Week",
          metric_name: "This Week",
          metric_datatype: handleDateType,
          filter_datatype: mainDataType,
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "Number of days ago",
          metric_name: "Number of days ago",
          metric_datatype: handleDateType,
          filter_datatype: mainDataType,
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "Number of days from now",
          metric_name: "Number of days from now",
          metric_datatype: handleDateType,
          filter_datatype: mainDataType,
        },
      ];
      break;
    case "BooleanDropDown":
      DropDown = [
        {
          editable: false,
          isVisible: true,
          metric_display_name: "True",
          metric_name: "True",
          metric_datatype: "boolean",
          filter_datatype: "boolean",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "False",
          metric_name: "False",
          metric_datatype: "boolean",
          filter_datatype: "boolean",
        },
      ];
      break;
  }
  return DropDown;
};

export const RowResizeHeader = (value) => {
  let header;
  switch (value) {
    case "RowResize":
      header = [
        {
          editable: false,
          isVisible: true,
          metric_display_name: "Short",
          metric_name: "Short",
          metric_datatype: "string",
          filter_datatype: "string",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "Medium",
          metric_name: "Medium",
          metric_datatype: "string",
          filter_datatype: "string",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "Tall",
          metric_name: "Tall",
          metric_datatype: "string",
          filter_datatype: "string",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "Extra Tall",
          metric_name: "Extra Tall",
          metric_datatype: "string",
          filter_datatype: "string",
        },
      ];
      break;
    default:
      header = [
        {
          editable: false,
          isVisible: true,
          metric_display_name: "Short",
          metric_name: "Short",
          metric_datatype: "string",
          filter_datatype: "string",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "Medium",
          metric_name: "Medium",
          metric_datatype: "string",
          filter_datatype: "string",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "Tall",
          metric_name: "Tall",
          metric_datatype: "string",
          filter_datatype: "string",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "Extra Tall",
          metric_name: "Extra Tall",
          metric_datatype: "string",
          filter_datatype: "string",
        },
      ];
      break;
  }
  return header;
};

export const TaskFilterHeader = (value) => {
  let header;
  switch (value) {
    case "taskFilterHeader":
      header = [
        {
          editable: false,
          isVisible: true,
          metric_display_name: "Today and Overdue",
          metric_name: "Today and Overdue",
          metric_datatype: "string",
          filter_datatype: "string",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "Next 7 days",
          metric_name: "Next 7 days",
          metric_datatype: "string",
          filter_datatype: "string",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "All",
          metric_name: "All",
          metric_datatype: "string",
          filter_datatype: "string",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "Completed",
          metric_name: "Completed",
          metric_datatype: "string",
          filter_datatype: "string",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "Custom",
          metric_name: "Custom",
          metric_datatype: "string",
          filter_datatype: "string",
        },
      ];
      break;
    default:
      header = [
        {
          editable: false,
          isVisible: true,
          metric_display_name: "Today and Overdue",
          metric_name: "Today and Overdue",
          metric_datatype: "string",
          filter_datatype: "string",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "Next 7 days",
          metric_name: "Next 7 days",
          metric_datatype: "string",
          filter_datatype: "string",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "All",
          metric_name: "All",
          metric_datatype: "string",
          filter_datatype: "string",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "Completed",
          metric_name: "Completed",
          metric_datatype: "string",
          filter_datatype: "string",
        },
        {
          editable: false,
          isVisible: true,
          metric_display_name: "Custom",
          metric_name: "Custom",
          metric_datatype: "string",
          filter_datatype: "string",
        },
      ];
      break;
  }
  return header;
};