import { Box, styled } from "@mui/system";
import React, { useState } from "react";
import { NavButton } from "./Common";
import { ReactComponent as SvgFooter } from "../Assets/SVG/Footer.svg";
import { ReactComponent as SvgSettingFooter } from "../Assets/SVG/SvgSettingFooter.svg";
import { ReactComponent as GiftSVG } from "../Assets/SVG/Gift.svg";
import { ReactComponent as HomeGiftSVG } from "../Assets/SVG/HomeGift.svg";
import {
  ClickAwayListener,
  Popover,
  Snackbar,
  Typography,
  Tooltip,
} from "@mui/material";
import SettingView from "./SettingView/SettingView";
import WishView from "./FooterChildComponents/WishView";
import InviteView from "./FooterChildComponents/InviteView";

// const Logo = styled("div")({
//   fontStyle: "normal",
//   fontSize: "20px",
//   lineHeight: "24px",
//   textTransform: "uppercase",
//   margin: "10px",
//   color: "#AFCADF",
// });

const StyledFooter = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "12px 28px",
  height: "50px",
});

export const FooterTypoGraphy = styled(Typography)({
  // color: "#444647",
  color: "white",
  cursor: "pointer",
  fontWeight: 600,
  letterSpacing: "1px",
  fontFamily: "Inter",
  textAlign: "center",
});

export const FooterButtonTypoGraphy = styled(Typography)({
  // color: "#444647",
  color: "white",
  cursor: "pointer",
  fontWeight: 600,
  letterSpacing: "1px",
  fontFamily: "Inter",
  textAlign: "center",

  stroke: "white",

  "&:hover": {
    color: "#00A1E0",
    stroke: "#00A1E0",
  },
});

export const SettingPopover = styled(Popover)({
  background: "rgba(0, 0, 0, 0.3)",
  ".MuiPaper-root": {
    borderRadius: "8px",
    boxShadow:
      "20px 28px 24px rgba(0, 0, 0, 0.1), inset -2px -2px 4px rgba(0, 0, 0, 0.06)",
    height: "80%",
    width: "900px",
  },
});

export const WishBox = styled(Box)({
  background: "#fff",
  borderRadius: "8px",
  border: "1px solid #E6E9EB",
  boxShadow: "0 3px 10px rgb(0 0 0 / 10%)",
  minHeight: "200px",
  maxHeight: "300px",
  width: "340px",
  padding: "20px",
  position: "fixed",
  bottom: "60px",
  right: "26px",
  zIndex: 9,
});

export const InviteBox = styled(Box)({
  background: "#fff",
  borderRadius: "8px",
  border: "1px solid #E6E9EB",
  boxShadow: "0 3px 10px rgb(0 0 0 / 10%)",
  width: "665px",
  position: "fixed",
  zIndex: 2,
});

export const WishSnackBar = styled(Snackbar)({
  ".MuiPaper-root": {
    boxShadow: "0 3px 10px rgb(0 0 0 / 10%)",
    background: "white",
    color: "black",
    fontFamily: "Inter",
    position: "absolute",
  },
});

function Footer({
  imageData,
  location,
  showOrHide,
  showOrHideFields,
  appShortCut,
  setAppShortCut,
  setBookmark,
}) {
  const [openPhotoDetails, setOpenPhotoDetails] = useState(false);
  const [openSuccessToaster, setOpenSuccessToaster] = useState(true);
  const [SettingAnchorEl, setSettingAnchorEl] = useState(null);
  const [messageSent, setMessageSent] = useState(false);
  const [openWishView, setOpenWishView] = useState(false);
  const [openInviteView, setOpenInviteView] = useState(false);

  //--- settingView Open ---//
  const handleSettingClick = () => {
    setSettingAnchorEl(document.getElementById("root"));
  };
  const handleSettingClose = () => {
    setSettingAnchorEl(null);
    if (!localStorage.getItem("access_token")) {
      window.location = "/login";
    }
  };
  const open = Boolean(SettingAnchorEl);
  const settingId = open ? "simple-popover" : null;
  // --- //
  return (
    location.pathname === "/" && (
      <StyledFooter spacing={2}>
        <Box display={"flex"} gap={"10px"} alignItems={"center"}>
          <Tooltip
            className="iconHover"
            PopperProps={{
              disablePortal: true,
              sx: {
                "& .MuiTooltip-tooltip": {
                  width: "50px",
                  height: "16px",
                  background: "rgba(3, 46, 97, 0.72)",
                  borderRadius: "4px",
                  fontFamily: "Inter",
                  textAlign: "center",
                },
              },
            }}
            placement="right"
            title="Settings"
          >
            <NavButton
              style={{
                borderRadius: "50%",
                marginRight: "12px",
              }}
            >
              <SvgSettingFooter onClick={handleSettingClick} />
            </NavButton>
          </Tooltip>
          <SettingPopover
            id={settingId}
            open={open}
            anchorEl={SettingAnchorEl}
            onClose={handleSettingClose}
            anchorOrigin={{
              vertical: "center",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "center",
            }}
          >
            <SettingView
              settingViewClose={() => handleSettingClose()}
              manageShortCut={showOrHide}
              showOrHideFields={showOrHideFields}
              setAppShortCut={setAppShortCut}
              appShortCut={appShortCut}
              setBookmark={setBookmark}
            />
          </SettingPopover>
        </Box>
        <FooterTypoGraphy
          display={"flex"}
          flexDirection={"column"}
          onMouseEnter={() => {
            setOpenPhotoDetails(true);
          }}
          onMouseLeave={() => {
            setOpenPhotoDetails(false);
          }}
        >
          <FooterTypoGraphy style={{ fontWeight: 500, fontSize: "14px" }}>
            {imageData.location}
          </FooterTypoGraphy>
          {(!imageData.location || openPhotoDetails) && (
            <FooterTypoGraphy
              style={{
                fontWeight: 500,
                fontSize: "14px",
                cursor: "default",
              }}
            >
              Photo by{" "}
              <a
                href={imageData.pgProfileUrl}
                target="_blank"
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  color: "#ffffff",
                }}
              >
                {imageData.pgName}
              </a>
            </FooterTypoGraphy>
          )}
        </FooterTypoGraphy>
        <Box display={"flex"} gap={"16px"}>
          <ClickAwayListener
            onClickAway={() => {
              if (openInviteView) {
                setOpenInviteView(false);
              }
            }}
          >
            <Box>
              <Tooltip
                className="iconHover"
                PopperProps={{
                  disablePortal: true,
                  sx: {
                    "& .MuiTooltip-tooltip": {
                      width: "auto",
                      background: "rgba(3, 46, 97, 0.72)",
                      borderRadius: "4px",
                      fontFamily: "Inter",
                      textAlign: "center",
                    },
                  },
                }}
                placement="left"
                title="Invite"
              >
                <FooterButtonTypoGraphy
                  style={{
                    lineHeight: "normal",
                  }}
                  onClick={() => setOpenInviteView(!openInviteView)}
                >
                  Invite
                </FooterButtonTypoGraphy>
              </Tooltip>
              {openInviteView ? (
                <InviteBox
                  sx={{
                    bottom: "60px",
                    right: "62px",
                  }}
                >
                  <InviteView />
                </InviteBox>
              ) : (
                <></>
              )}
            </Box>
          </ClickAwayListener>
          {!messageSent ? (
            <ClickAwayListener
              onClickAway={() => {
                if (openWishView) {
                  setOpenWishView(false);
                }
              }}
            >
              <Box>
                <Tooltip
                  className="iconHover"
                  PopperProps={{
                    disablePortal: true,
                    sx: {
                      "& .MuiTooltip-tooltip": {
                        width: "120px",
                        height: "16px",
                        background: "rgba(3, 46, 97, 0.72)",
                        borderRadius: "4px",
                        fontFamily: "Inter",
                        textAlign: "center",
                      },
                    },
                  }}
                  placement="left"
                  title="Request a Feature"
                >
                  <FooterButtonTypoGraphy
                    style={{
                      lineHeight: "normal",
                    }}
                    onClick={() => setOpenWishView(!openWishView)}
                  >
                    <HomeGiftSVG />
                  </FooterButtonTypoGraphy>
                </Tooltip>
                {openWishView ? (
                  <WishBox
                    sx={{
                      bottom: "60px",
                      right: "26px",
                    }}
                  >
                    <WishView
                      handleSuccessWish={() => {
                        setMessageSent(true);
                        setOpenSuccessToaster(true);
                      }}
                    />
                  </WishBox>
                ) : (
                  <></>
                )}
              </Box>
            </ClickAwayListener>
          ) : (
            <Box>
              <FooterTypoGraphy>
                <HomeGiftSVG />
              </FooterTypoGraphy>
              <WishSnackBar
                open={openSuccessToaster}
                autoHideDuration={2000}
                onClose={() => {
                  setOpenWishView(false);
                  setMessageSent(false);
                  setOpenSuccessToaster(false);
                }}
                message="Thank you! We will be in touch."
                sx={{
                  ".MuiPaper-root": {
                    top: "-85px",
                    left: "1120px",
                  },
                }}
              />
            </Box>
          )}
        </Box>
      </StyledFooter>
    )
  );
}

export default Footer;
