// import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LockIcon from "@mui/icons-material/Lock";
import { Box, Divider, Input, styled, Tooltip } from "@mui/material";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useDispatch } from "react-redux";
import { ReactComponent as ArrowBackwardSVG } from "../../Assets/SVG/ArrowBackward.svg";
import { ReactComponent as ArrowForwardSVG } from "../../Assets/SVG/ArrowForward.svg";
import { ReactComponent as CheckBoxSVG } from "../../Assets/SVG/CheckBox.svg";
import { ReactComponent as DragSVG } from "../../Assets/SVG/Drag.svg";
import { ReactComponent as FieldSVG } from "../../Assets/SVG/Field.svg";
import { ReactComponent as SearchSVG } from "../../Assets/SVG/Search.svg";
import { ReactComponent as SelectedCheckBoxSVG } from "../../Assets/SVG/SelectedCheckbox.svg";
import { ReactComponent as FieldSettingSVG } from "../../Assets/SVG/SettingField.svg";
import mainShortCutContext from "../../Context/Context";
import {
  createOrUpdateKey,
  getKeyData,
  updateTile,
} from "../../Pages/Services/tableApi";
import Actions from "../../redux/actions";
import Loader from "../Loader";

const TableFilter = styled("div")({
  display: "flex",
  flexFlow: "column",
  width: "max-content",
  position: "relative",
  zIndex: 2,
  // marginLeft: "12px",
});

export const DefaultValue = styled("div")({
  width: "90%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  cursor: "pointer",
  // whiteSpace: "nowrap",
});

const ColumnItems = styled("div")({
  position: "absolute",
  maxHeight: "428px",
  overflow: "hidden",
  display: "block",
  top: "40px",
  left: "-9px",
  marginTop: "0.2rem",
  background: "#ffffff",
  border: "1px solid #F7F7F7",
  boxSizing: "border-box",
  boxShadow: "8px 18px 12px rgb(0 0 0 / 5%)",
  borderRadius: "12px",
  height: "260px",
  width: "260px",
});

const Legend = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "1px 5px",
  // zoom: "0.9",
  "&:hover": {
    cursor: "pointer",
    backgroundColor: "#F2F7FC",

    color: "#00A1E0",
  },
});

const DragSvg = styled(DragSVG)({
  "&:hover": {
    cursor: "grab",
    background: "#EDF1F2",
  },
  "&:active": {
    cursor: "grabbing",
  },
});

const MainFieldBox = styled(Box)({
  // height: "241px",
  margin: "4px 0px",
  marginRight: "4px",
  overflowY: "scroll",
  //scrollBehavior: "smooth",
  position: "relative",
  "&::-webkit-scrollbar": {
    width: "0.5em",
    marginRight: "4px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(181,188,194,0.7)",
    borderRadius: "10px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(181,188,194,0.7)",
    borderRadius: "10px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "rgba(181,188,194,0.9)",
  },
});

const NoRelatedFields = styled(Box)({
  textAlign: "center",
  padding: "16px",
  color: "#798ea3",
});

const DataFieldBox = styled(Box)({
  textOverflow: "ellipsis",
  overflow: "hidden",
  width: "100%",
  display: "flex",
  alignItems: "center",
  whiteSpace: "nowrap",
  // padding: "0px 12px",
  height: "31px",
  gap: "8px",
});

const SearchText = styled(Input)({
  width: "100%",
  fontSize: "14px",
  padding: "0px 12px",
  position: "sticky",
  top: 0,
  fontFamily: "Inter",
  ".MuiInputBase-input": {
    fontSize: "12px",
  },
});

const StyledBox = styled(Box)({
  ":hover": {
    cursor: "pointer",
  },
});

const SearchViewFieldBox = styled(Box)({
  fontFamily: "Inter",
  fontSize: "14px",
  height: "35px",
  display: "flex",
  alignItems: "center",
  padding: "0px 12px",
  gap: "15px",
});

const SearchingBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  margin: "8px",
  padding: "0px 4px",
  background: "#FAFAFA",
  borderRadius: "8px",
  height: "31px",
});

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

function FilterColumn({
  fields,
  header,
  filterColumns,
  tableName,
  addOrRemoveFieldInColumn,
  activeHeader,
  iconChange = true,
  HandleExpandViewHeader = false,
  searchViewHeader = false,
  setFieldAddDropDown,
  expandViewRelated,
  relatedFields,
  allTilesDropDown,
  handleExpandViewTileChange,
  expandViewCustomTile,
  relatedTableHeader,
  rowId,
  key,
  relatedTableLoader,
  relatedFieldsLoader,
  expandViewFirst,
  taskView,
  leadView,
  subFields,
  addOrRemoveSubField,
  setSubFields,
  handleSubFieldsFromSearchDrawer,
  setTilesData,
  tilesData,
  createView = false,
}) {
  const [optionState, setOptionState] = useState(false);
  const [handleHeaders, setHandleHeaders] = useState(null);
  const [currentHeaders, setCurrentHeaders] = useState([]);
  const [searchHeader, setSearchHeaders] = useState([]);
  const [relatedField, setRelatedField] = useState(
    localStorage.getItem(`Expand${tableName}RelatedList`) !== "undefined"
      ? JSON.parse(localStorage.getItem(`Expand${tableName}RelatedList`))
      : []
  );
  const [search, setSearch] = useState("");
  const [overId, setOverId] = useState("");
  const [selectedSubFieldData, setSelectedSubFieldData] = useState(null);
  const [searchViewFieldOrRelated, setSearchViewFieldOrRelated] =
    useState("Fields");
  const dispatch = useDispatch();
  const [drag_end, setDragEnd] = useState(false);
  const [headerChangeLoader, setHeaderChangeLoader] = useState(false);
  const [scroll, setScroll] = useState(false);

  const [relatedIndex, setRelatedIndex] = useState(0);

  const { idFields } = useContext(mainShortCutContext);

  const searchInputRef = useRef();

  const currentRef = useRef(null);
  let shouldPopClose = true;

  async function getKeyApiData(name) {
    await getKeyData(
      JSON.stringify({
        key: name,
      })
    );
  }

  async function createCall(name, value) {
    if (!value?.length) return;
    const data = value
      .filter((data) => data && data.selected)
      ?.map(({ call_parent, metric_name }) =>
        `${call_parent ? `${call_parent}.${metric_name}` : metric_name}`.trim()
      );

    // we can pass distinct data through set method so we didn't have duplicate fields

    const uniqueData = [...new Set(data)];

    data?.length &&
      (await createOrUpdateKey(
        JSON.stringify({
          key: name,
          value: uniqueData,
        })
      ));
  }

  const expandViewApiCallHandler = async (tempHeader, flag) => {
    // const gridHeaders = await getKeyData(
    //   JSON.stringify({
    //     key: `ExpandGrid${tableName}Headers`,
    //   })
    // );
    // let fields = [];
    // if (subFields) {
    //   fields = Object.keys(subFields)
    //     .map((field) =>
    //       subFields[field]?.filter(
    //         ({ isVisible, selected }) => isVisible || selected
    //       )
    //     )
    //     .flat();
    // } else {
    //   fields = localStorage.getItem("ActiveSubFields")?.length
    //     ? JSON.parse(localStorage.getItem("ActiveSubFields"))
    //     : [];
    // }

    // localStorage.getItem(`Expand${tile}${tableName}Headers`)?.length &&
    //   JSON.parse(
    //     localStorage.getItem(`Expand${tile}${tableName}Headers`)
    //   );

    let headers = [
      ...(flag ? tempHeader : header),
      drag_end
        ? activeHeader.filter(
            (data) =>
              !tempHeader
                .filter(
                  ({ isVisible, selected, sobject, call_parent }) =>
                    isVisible || selected
                )
                .map(({ call_parent }) => call_parent)
                .includes(data.call_parent)
          )
        : [],
    ].flat();

    headers = headers.filter((d) => (d.call_parent ? d.selected : d));

    headers.sort((a, b) => {
      if (a.selected === b.selected) return 0;
      if (a.selected > b.selected) return -1;
      return 1;
    });

    const uniqueHeaders = selectedSubFieldData ? [...tempHeader] : [...headers];

    setCurrentHeaders(
      [
        ...new Set(
          uniqueHeaders?.filter((d) => d).map((o) => JSON.stringify(o))
        ),
      ].map((s) => JSON.parse(s))
    );
    setDragEnd(false);
    setHeaderChangeLoader(false);
  };

  const headerManageMent = async () => {
    let storedTableFilter;
    if (
      HandleExpandViewHeader ||
      relatedTableHeader ||
      searchViewHeader ||
      handleExpandViewTileChange ||
      tableName === "Task"
    ) {
      storedTableFilter = undefined;
    } else {
      storedTableFilter = await getKeyData(
        JSON.stringify({
          key: `${tableName}Headers`,
        })
      );

      // createCall(`ExpandGrid${tableName}Headers`, [...header]);

      // storedTableFilter = await getKeyApiData(`${tableName}Headers`);
      storedTableFilter = storedTableFilter?.data?.value;

      // .then((resp) => console.log(resp))
      // .catch((error) => console.log(error));
      //  JSON.parse(
      //   localStorage.getItem(`${tableName}Headers`)
      // );
    }

    let initialHeader =
      storedTableFilter &&
      (await getKeyData(
        JSON.stringify({
          key: `initial${tableName}Headers`,
        })
      ));

    // JSON.stringify(header?.map((data) => data.metric_display_name)) !==
    //   JSON.stringify(
    //     initialHeader?.data?.value?.map((data) =>
    //   ) data.metric)

    if (storedTableFilter) {
      if (
        JSON.stringify(header?.map((data) => data.metric_display_name)) !==
        JSON.stringify(
          initialHeader?.data?.value?.map((data) => data.metric_name)
        )
      ) {
        let tempHeaders = selectedSubFieldData
          ? subFields?.[selectedSubFieldData.metric_name]
            ? subFields?.[selectedSubFieldData.metric_name]
            : header.filter(
                (data) =>
                  data.metric_display_name ===
                  selectedSubFieldData.metric_display_name
              )?.[0]?.parent_table_fields
          : [
              ...header,
              // !drag_end &&
              //   activeHeader.filter(
              //     ({ isVisible, selected, sobject, metric_display_name }) =>
              //       header
              //         .filter(
              //           ({ isVisible, selected, sobject }) =>
              //             isVisible || selected
              //         )
              //         .map(({ metric_display_name }) => metric_display_name)
              //         .includes(metric_display_name) && sobject !== tableName
              //   ),
            ].flat();

        tempHeaders = tempHeaders.filter((data) => data);

        let gridOrTileHeader;
        if (!selectedSubFieldData) {
          gridOrTileHeader = await getKeyData(
            JSON.stringify({
              key: `Expand${
                HandleExpandViewHeader
                  ? localStorage.getItem("manageHeader")
                  : "Grid"
              }${tableName}Headers`,
            })
          );
          tempHeaders = tempHeaders.map((data) => {
            const flag = gridOrTileHeader?.data?.value?.includes(
              data?.call_parent
                ? `${data?.call_parent}.${data?.metric_name}`
                : data?.metric_name
            );
            return {
              ...data,
              isVisible: flag ?? data.selected,
              selected: flag ?? data.selected,
            };
          });
        }

        // [
        //   ...header,
        // ...activeHeader.filter(data => !header
        //   .filter(
        //     ({ isVisible, selected, sobject,call_parent }) =>
        //       isVisible || selected
        //   )
        //   .map(({call_parent }) => call_parent).includes(data.call_parent))
        // ];
        // allHeaders = [
        //   ...tempHeaders,
        // ...activeHeader.filter(
        //     (data) =>
        //       !tempHeaders
        //         .filter(
        //           ({ isVisible, selected, sobject, call_parent }) =>
        //             isVisible || selected
        //         )
        //         .map(({ call_parent }) => call_parent)
        //         .includes(data.call_parent)
        //   ),
        // ];
        tempHeaders.forEach((data, index) => {
          // const tempFilter =
          //   !selectedSubFieldData &&
          //   storedTableFilter.find(
          //     (filter) => filter.metric_name === data.metric_name
          //   );
          tempHeaders[index] = {
            ...tempHeaders[index],
            // isVisible:
            //   (selectedSubFieldData ? data : tempFilter)?.isVisible ??
            //     (selectedSubFieldData ? data : tempFilter)?.selected
            //     ? (selectedSubFieldData ? data : tempFilter).isVisible ??
            //     (selectedSubFieldData ? data : tempFilter).selected
            //     : false,
            parent: selectedSubFieldData ? data.child_field : null,
            selected: data.selected,
            isVisible: data.selected,
          };
          // tempHeaders[index].isVisible = tempFilter?.isVisible !== false;
        });
        tempHeaders.sort((a, b) => {
          if (a.isVisible === b.isVisible) return 0;
          if (a.isVisible > b.isVisible) return -1;
          return 1;
        });

        let allHeaders = [];
        if (!selectedSubFieldData) {
          // allHeaders = [...tempHeaders, ...fields];
          allHeaders = [
            ...tempHeaders,
            ...(drag_end
              ? activeHeader.filter(
                  (data) =>
                    !tempHeaders
                      .filter(
                        ({ isVisible, selected, sobject, call_parent }) =>
                          isVisible || selected
                      )
                      .map(({ call_parent }) => call_parent)
                      .includes(data.call_parent)
                )
              : activeHeader.filter(
                  (data) =>
                    !tempHeaders
                      .filter(
                        ({ isVisible, selected, sobject, call_parent }) =>
                          isVisible || selected
                      )
                      .map(
                        ({ call_parent, metric_name }) =>
                          `${call_parent}.${metric_name}`
                      )
                      .includes(`${data?.call_parent}.${data?.metric_name}`)
                )),
          ];
          allHeaders = allHeaders.filter((data) => data);

          allHeaders = allHeaders
            .map((data) => {
              const flag = gridOrTileHeader?.data?.value?.includes(
                data?.call_parent
                  ? `${data?.call_parent}.${data?.metric_name}`
                  : data?.metric_name
              );
              return {
                ...data,
                isVisible: flag ?? data.selected,
                selected: flag ?? data.selected,
              };
            })
            .filter((d) => (d.call_parent ? d.selected : d));

          allHeaders.sort((a, b) => {
            if (a.selected === b.selected) return 0;
            if (a.selected > b.isVisible) return -1;
            return 1;
          });
        }

        if (!selectedSubFieldData) {
          let sortedFields = gridOrTileHeader?.data?.value?.length
            ? [...new Set(gridOrTileHeader?.data?.value)]
                .map((name) =>
                  (allHeaders?.length ? allHeaders : tempHeaders).find(
                    (data) =>
                      (data?.call_parent
                        ? `${data?.call_parent}.${data?.metric_name}`
                        : data?.metric_name) === name
                  )
                )
                .filter((data) => data)
            : [];

          if (sortedFields?.length) {
            tempHeaders.splice(0, sortedFields.length, sortedFields);
            tempHeaders = tempHeaders.flat();
          }
        }
        let fields = [];
        if (subFields) {
          fields = Object.keys(subFields)
            .map((field) =>
              subFields[field]?.filter(
                ({ isVisible, selected }) => isVisible || selected
              )
            )
            .flat();
        } else {
          fields = localStorage.getItem("ActiveSubFields")?.length
            ? JSON.parse(localStorage.getItem("ActiveSubFields"))
            : [];
        }

        if (allHeaders?.length) {
          let sortedFields = gridOrTileHeader?.data?.value?.length
            ? gridOrTileHeader.data.value
                .map((name) =>
                  allHeaders.find(
                    (data) =>
                      (data?.call_parent
                        ? `${data?.call_parent}.${data?.metric_name}`
                        : data?.metric_name) === name
                  )
                )
                .filter((data) => data)
            : [];

          if (sortedFields?.length) {
            allHeaders.sort((a, b) => {
              if (a.selected === b.selected) return 0;
              if (a.selected > b.selected) return -1;
              return 1;
            });
            allHeaders.splice(0, sortedFields.length, sortedFields);
            allHeaders = allHeaders.flat();
          }
        }

        const uniqueHeaders = selectedSubFieldData
          ? [...tempHeaders]
          : [...allHeaders];

        setCurrentHeaders(
          [
            ...new Set(
              uniqueHeaders?.filter((d) => d).map((o) => JSON.stringify(o))
            ),
          ].map((s) => JSON.parse(s))
        );
        // setCurrentHeaders(
        //   selectedSubFieldData ? [...tempHeaders] : [...allHeaders]
        // );

        // !selectedSubFieldData &&
        //   createCall(
        //     `Expand${
        //       HandleExpandViewHeader
        //         ? localStorage.getItem("manageHeader")
        //         : "Grid"
        //     }${tableName}Headers`,
        //     // selectedSubFieldData ? [...tempHeaders] : [...allHeaders]
        //     [...tempHeaders]
        //   );

        // !selectedSubFieldData && apiCall();
        tempHeaders = tempHeaders.filter(
          (data) => data.isVisible || data.selected
        );
        // !selectedSubFieldData &&
        //   localStorage.setItem(
        //     `ExpandActiveGrid${tableName}Headers`,
        //     JSON.stringify([...tempHeaders])
        //   );

        // let fields = [];
        // if (subFields) {
        //   fields = Object.keys(subFields)
        //     .map((field) =>
        //       subFields[field]?.filter(
        //         ({ isVisible, selected }) => isVisible || selected
        //       )
        //     )
        //     .flat();
        // } else {
        //   fields = localStorage.getItem("ActiveSubFields")?.length
        //     ? JSON.parse(localStorage.getItem("ActiveSubFields"))
        //     : [];
        // }

        // const gridHeaders = await getKeyData(
        //   JSON.stringify({
        //     key: `ExpandGrid${tableName}Headers`,
        //   })
        // );
        let gridHeaders;
        let activeGridHeaders = [];
        if (selectedSubFieldData) {
          gridHeaders = await getKeyData(
            JSON.stringify({
              key: `Expand${
                HandleExpandViewHeader
                  ? localStorage.getItem("manageHeader")
                  : "Grid"
              }${tableName}Headers`,
            })
          );

          let h = header.map((data) => {
            const flag = gridHeaders?.data?.value?.includes(
              data?.call_parent
                ? `${data?.call_parent}.${data?.metric_name}`
                : data?.metric_name
            );
            return {
              ...data,
              isVisible: flag ?? data.selected,
              selected: flag ?? data.selected,
            };
          });

          h.sort((a, b) => {
            if (a.selected === b.selected) return 0;
            if (a.selected > b.selected) return -1;
            return 1;
          });
          let sortedFields = gridHeaders?.data?.value?.length
            ? [...new Set(gridHeaders.data.value)]
                .map((name) =>
                  h.find(
                    (data) =>
                      (data?.call_parent
                        ? `${data?.call_parent}.${data?.metric_name}`
                        : data?.metric_name) === name
                  )
                )
                .filter((data) => data)
            : [];

          if (sortedFields?.length) {
            h.splice(0, sortedFields.length, sortedFields);
            h = h.flat();
          }
          activeGridHeaders = h?.filter(
            ({ isVisible, selected }) => isVisible || selected
          );
        }

        // localStorage.getItem(`ExpandGrid${tableName}Headers`)?.length &&
        //   JSON.parse(localStorage.getItem(`ExpandGrid${tableName}Headers`));
        let headers = [
          ...(selectedSubFieldData
            ? activeGridHeaders
            : allHeaders?.length
            ? allHeaders.filter((data) => data.selected)
            : tempHeaders),
          // !drag_end  && fields,
        ].flat();
        // filterColumns(selectedSubFieldData ? [...headers] : []);
        // !selectedSubFieldData && filterColumns([...headers]);

        filterColumns(
          [
            ...new Set(
              [...headers]?.filter((d) => d).map((o) => JSON.stringify(o))
            ),
          ].map((s) => JSON.parse(s))
        );
        // filterColumns([...headers]);
        setHeaderChangeLoader(false);
        setDragEnd(false);
      } else {
        header?.forEach((data, index) => {
          const presentData = storedTableFilter?.find(
            (filter) => filter.metric_name === data.metric_name
          );
          if (!presentData) {
            storedTableFilter.splice(index, 0, data);
          }
          header[index] = {
            ...header[index],
            isVisible: storedTableFilter ? storedTableFilter.isVisible : true,
          };
          // header[index].isVisible = storedTableFilter
          //   ? storedTableFilter.isVisible
          //   : true;
        });

        // await createOrUpdateKey(
        //   JSON.stringify({
        //     key: `${tableName}Headers`,
        //     value: [...storedTableFilter],
        //   })
        // );
        createCall(`${tableName}Headers`, [...storedTableFilter]);

        // await createOrUpdateKey(
        //   JSON.stringify({
        //     key: `ExpandGrid${tableName}Headers`,
        //     value: [...storedTableFilter],
        //   })
        // );
        createCall(`ExpandGrid${tableName}Headers`, [...storedTableFilter]);

        // localStorage.setItem(
        //   `${tableName}Headers`,
        //   JSON.stringify([...storedTableFilter])
        // );

        // localStorage.setItem(
        //   `ExpandGrid${tableName}Headers`,
        //   JSON.stringify([...storedTableFilter])
        // );
        let subFieldsHeader = [];
        if (selectedSubFieldData) {
          subFieldsHeader = subFields?.[selectedSubFieldData.metric_name]
            ? subFields?.[selectedSubFieldData.metric_name]
            : header.filter(
                (data) =>
                  data.metric_display_name ===
                  selectedSubFieldData.metric_display_name
              )?.[0]?.parent_table_fields;

          subFieldsHeader.sort((a, b) => {
            if (a.isVisible || a.selected === b.isVisible || b.selected)
              return 0;
            if (a.isVisible || a.selected > b.isVisible || b.selected)
              return -1;
            return 1;
          });
        } else {
          storedTableFilter.sort((a, b) => {
            if (a.selected || a.isVisible === b.selected || b.isVisible)
              return 0;
            if (a.selected || a.isVisible > b.selected || b.isVisible)
              return -1;
            return 1;
          });
        }
        setCurrentHeaders(
          selectedSubFieldData ? [...subFieldsHeader] : [...storedTableFilter]
        );
        storedTableFilter = storedTableFilter.filter(
          (data) => data.isVisible || data.selected
        );
        // localStorage.setItem(
        //   `ExpandActiveGrid${tableName}Headers`,
        //   JSON.stringify([...storedTableFilter])
        // );
        setHeaderChangeLoader(false);
        !selectedSubFieldData && filterColumns([...storedTableFilter]);
      }
    } else {
      // let tempHeader = selectedSubFieldData
      //   ? localStorage.getItem(
      //       `${selectedSubFieldData?.parentSobject}SubFields`
      //     )
      //     ? JSON.parse(
      //         localStorage.getItem(
      //           `${selectedSubFieldData?.parentSobject}SubFields`
      //         )
      //       )
      //     : header.filter(
      //         ({ metric_display_name }) =>
      //           metric_display_name ===
      //           selectedSubFieldData.metric_display_name
      //       )?.[0]?.parent_table_fields
      //   : [...header];
      // let tempAllHeader = []
      // if (!selectedSubFieldData) {
      //   tempAllHeader = await getKeyData(
      //     JSON.stringify({
      //       key: `Expand${localStorage.getItem("manageHeader")}${tableName}Headers`,
      //     })
      //   )
      // }
      let tempHeader = selectedSubFieldData
        ? subFields?.[selectedSubFieldData?.metric_name]
          ? subFields?.[selectedSubFieldData?.metric_name]
          : header?.filter(
              ({ metric_display_name }) =>
                metric_display_name ===
                selectedSubFieldData?.metric_display_name
            )?.[0]?.parent_table_fields
        : !searchViewHeader
        ? [
            ...header,
            ...activeHeader?.filter(
              (data) =>
                !header
                  ?.filter(
                    ({ isVisible, selected, sobject, call_parent }) =>
                      isVisible || selected
                  )
                  .map(({ call_parent }) => call_parent)
                  .includes(data.call_parent)
            ),
          ]
        : [...header];

      // [
      //   ...header,
      //   ...activeHeader.filter(({ sobject }) => sobject !== tableName),
      // ];

      if (!searchViewHeader && tableName !== "Task") {
        let expandViewHeader;
        if (
          !selectedSubFieldData &&
          searchViewFieldOrRelated !== "Related" &&
          !relatedTableHeader &&
          localStorage.getItem("manageHeader") !== "Grid"
        ) {
          expandViewHeader = await getKeyData(
            JSON.stringify({
              key: `Expand${
                tableName === "Task"
                  ? "Grid"
                  : localStorage.getItem("manageHeader")
              }${tableName}Headers`,
            })
          );
          tempHeader = tempHeader.map((data) => {
            const flag = expandViewHeader?.data?.value?.includes(
              data?.call_parent
                ? `${data?.call_parent}.${data?.metric_name}`
                : data?.metric_name
            );
            return {
              ...data,
              isVisible: flag ?? data.selected,
              selected: flag ?? data.selected,
            };
          });
        }
        // else {
        //   tempHeader = tempHeader.map((data) => {
        //     if (
        //       currentHeaders
        //         .filter((d) => d.selected)
        //         .map(({ metric_name }) => metric_name)
        //         .includes(data.metric_name)
        //     ) {
        //       data = {...data,selected:true,isVisible:true}
        //     }
        //     return data
        //   });
        // }

        // !relatedTableHeader &&
        //   tempHeader?.forEach((data, index) => {
        //     tempHeader[index] = {
        //       ...tempHeader[index],
        //       isVisible: selectedSubFieldData
        //         ? data.isVisible || data.selected
        //           ? true
        //           : false
        //         : activeHeader?.length
        //           ? activeHeader
        //             ?.map(({ metric_name }) => metric_name)
        //             .includes(data.metric_name)
        //           : false,
        //     };
        //   });

        tempHeader.sort((a, b) => {
          if (a.selected === b.selected) return 0;
          if (a.selected > b.selected) return -1;
          return 1;
        });

        if (!selectedSubFieldData) {
          let sortedFields = expandViewHeader?.data?.value?.length
            ? expandViewHeader.data.value
                .map((name) =>
                  tempHeader.find(
                    (data) =>
                      (data?.call_parent
                        ? `${data?.call_parent}.${data?.metric_name}`
                        : data?.metric_name) === name
                  )
                )
                .filter((d) => d)
            : [];

          if (sortedFields?.length) {
            tempHeader.splice(0, sortedFields.length, sortedFields);
            tempHeader = tempHeader.flat();
          }
        }
      }
      if (HandleExpandViewHeader) {
        tempHeader.sort((a, b) => {
          if (a.selected === b.selected) return 0;
          if (a.selected > b.selected) return -1;
          return 1;
        });

        let expandRelatedFields = localStorage.getItem(
          `Expand${tableName}RelatedList`
        )
          ? JSON.parse(localStorage.getItem(`Expand${tableName}RelatedList`))
          : relatedFields;
        expandRelatedFields = expandRelatedFields?.sort((a, b) => {
          if (a.selected === b.selected) return 0;
          if (a.selected > b.selected) return -1;
          return 1;
        });

        localStorage.setItem(
          `Expand${tableName}RelatedList`,
          JSON.stringify(expandRelatedFields)
        );
        localStorage.setItem(
          `ExpandActive${tableName}RelatedList`,
          JSON.stringify(expandRelatedFields?.filter((data) => data.selected))
        );

        const tile = localStorage.getItem("manageHeader");

        // localStorage.setItem(
        //   `Expand${tile}${tableName}Headers`,
        //   JSON.stringify([...tempHeader])
        // );

        // for grid, we don't need to update the key because that's happening in backend side
        !selectedSubFieldData &&
          searchViewFieldOrRelated !== "Related" &&
          tile !== "Grid" &&
          createCall(`Expand${tile}${tableName}Headers`, [...tempHeader]);

        // !selectedSubFieldData &&
        //   localStorage.setItem(
        //     `ExpandActive${tile}${tableName}Headers`,
        //     JSON.stringify(tempHeader.filter((data) => data.isVisible))
        //   );

        // let fields = [];
        // if (subFields) {
        //   fields = Object.keys(subFields)
        //     .map((field) =>
        //       subFields[field]?.filter(
        //         ({ isVisible, selected }) => isVisible || selected
        //       )
        //     )
        //     .flat();
        // } else {
        //   fields = localStorage.getItem("ActiveSubFields")?.length
        //     ? JSON.parse(localStorage.getItem("ActiveSubFields"))
        //     : [];
        // }

        // const gridHeaders = await getKeyData(
        //   JSON.stringify({
        //     key: `Expand${tile}${tableName}Headers`,
        //   })
        // );
        expandViewApiCallHandler(tempHeader, true);
        // filterColumns([...tempHeader]);
      } else if (handleExpandViewTileChange) {
        tempHeader.sort((a, b) => {
          if (a.isVisible === b.isVisible) return 0;
          if (a.isVisible > b.isVisible) return -1;
          return 1;
        });
        const tile = localStorage.getItem("manageHeader");
        // localStorage.setItem(
        //   `Expand${tile}${tableName}Headers`,
        //   JSON.stringify([...tempHeader])
        // );

        // await createOrUpdateKey(
        //   JSON.stringify({
        //     key: `Expand${tile}${tableName}Headers`,
        //     value: [...tempHeader],
        //   })
        // );

        !selectedSubFieldData &&
          searchViewFieldOrRelated !== "Related" &&
          createCall(`Expand${tile}${tableName}Headers`, [...tempHeader]);

        // localStorage.setItem(
        //   `ExpandActive${tile}${tableName}Headers`,
        //   JSON.stringify(tempHeader.filter((data) => data.isVisible))
        // );
        expandViewApiCallHandler(
          [...tempHeader],
          selectedSubFieldData ? false : true
        );
      } else if (searchViewHeader) {
        tempHeader.map((data) => {
          if (data.isVisible === undefined) {
            data.isVisible = false;
            return data;
          }
          return data;
        });
        tempHeader.sort((a, b) => {
          if (a.isVisible === b.isVisible) return 0;
          if (a.isVisible > b.isVisible) return -1;
          return 1;
        });
        tempHeader.sort((a, b) => {
          if (a.selected === b.selected) return 0;
          if (a.selected > b.selected) return -1;
          return 1;
        });
        // localStorage.setItem(
        //   `Search${tableName}Headers`,
        //   JSON.stringify([...tempHeader])
        // );

        // await createOrUpdateKey(
        //   JSON.stringify({
        //     key: `Search${tableName}Headers`,
        //     value: [...tempHeader],
        //   })
        // );
        createCall(`Search${tableName}Headers`, [...tempHeader]);

        // await createOrUpdateKey(
        //   JSON.stringify({
        //     key: `SearchActive${tableName}Headers`,
        //     value: [...tempHeader.filter((data) => data.isVisible)],
        //   })
        // );
        // createCall(`SearchActive${tableName}Headers`, [
        //   ...tempHeader.filter((data) => data.isVisible),
        // ]);

        // localStorage.setItem(
        //   `SearchActive${tableName}Headers`,
        //   JSON.stringify(tempHeader.filter((data) => data.isVisible))
        // );
        setCurrentHeaders([...tempHeader]);
        filterColumns([...tempHeader]);
      } else if (relatedTableHeader) {
        tempHeader.sort((a, b) => {
          if (a.selected === b.selected) return 0;
          if (a.selected > b.selected) return -1;
          return 1;
        });
        setCurrentHeaders([...tempHeader]);
        // filterColumns([...tempHeader]);
      } else {
        if (currentHeaders?.length) tempHeader = [...currentHeaders];
        tempHeader.sort((a, b) => {
          if (a.selected || a.isVisible === b.selected || b.isVisible) return 0;
          if (a.selected || a.isVisible > b.selected || b.isVisible) return -1;
          return 1;
        });

        if (tableName === "Task") {
          tempHeader.sort((a, b) => {
            if (a.selected === b.selected) return 0;
            if (a.selected > b.selected) return -1;
            return 1;
          });
        }
        // localStorage.setItem(
        //   `${tableName}Headers`,
        //   JSON.stringify([...tempHeader])
        // );

        // await createOrUpdateKey(
        //   JSON.stringify({
        //     key: `${tableName}Headers`,
        //     value: [...tempHeader],
        //   })
        // );
        createCall(`${tableName}Headers`, [...tempHeader]);

        // localStorage.setItem(
        //   `ExpandGrid${tableName}Headers`,
        //   JSON.stringify([...tempHeader])
        // );
        // localStorage.setItem(
        //   `ExpandActiveGrid${tableName}Headers`,
        //   JSON.stringify(tempHeader.filter((data) => data.isVisible))
        // );
        // localStorage.setItem(
        //   `${tableName}Headers`,
        //   JSON.stringify([
        //     ...tempHeader.filter((data) => data.sobject === tableName),
        //   ])
        // );
        // localStorage.setItem(
        //   `ExpandGrid${tableName}Headers`,
        //   JSON.stringify([...tempHeader])
        // );

        // await createOrUpdateKey(
        //   JSON.stringify({
        //     key: `ExpandGrid${tableName}Headers`,
        //     value: [...tempHeader],
        //   })
        // );
        createCall(`ExpandGrid${tableName}Headers`, [...tempHeader]);

        // localStorage.setItem(
        //   `ExpandActiveGrid${tableName}Headers`,
        //   JSON.stringify(tempHeader.filter((data) => data.isVisible))
        // );
        setCurrentHeaders([...tempHeader]);
        // tableName === "Task"
        //   ? filterColumns(tempHeader.filter((data) => data.isVisible))
        //   : filterColumns([...header]);
        tableName === "Task"
          ? filterColumns(tempHeader)
          : filterColumns(
              tempHeader.filter((data) => data.isVisible || data.selected)
            );
      }
    }
    setHeaderChangeLoader(false);
  };

  useEffect(() => {
    if (header?.length) {
      headerManageMent();
    }
  }, [
    JSON.stringify(header),
    JSON.stringify(activeHeader),
    selectedSubFieldData,
    relatedFieldsLoader,
  ]);

  useEffect(() => {
    // debugger;
    if (searchViewFieldOrRelated === "Fields") {
      // let subColumns = [];
      // if (subFields?.length) {
      //   subColumns = Object.keys(subFields)
      //     .map((field) =>
      //       subFields[field]?.filter(
      //         ({ isVisible, selected }) => isVisible || selected
      //       )
      //     )
      //     .flat();
      // }

      let tempHeaders = [...currentHeaders];

      if (search.length) {
        tempHeaders = tempHeaders.filter((data) => {
          if (
            data?.metric_display_name
              ?.toLowerCase()
              .includes(search.toLowerCase())
          )
            return true;
        });
      }

      let a = [];
      tempHeaders = tempHeaders
        ?.map((data) => {
          let name = data?.child_field
            ? `${data.child_field}.${data.metric_name}`
            : data.metric_name;
          if (!a.includes(name)) {
            a.push(name);
            return data;
          }
        })
        .filter((d) => d);

      a = [];
      // unique column only
      tempHeaders = [...new Set(tempHeaders.map((o) => JSON.stringify(o)))].map(
        (s) => JSON.parse(s)
      );
      setSearchHeaders(tempHeaders);
    } else {
      let tempRelatedFields = localStorage.getItem(
        `Expand${tableName}RelatedList`
      )
        ? JSON.parse(localStorage.getItem(`Expand${tableName}RelatedList`))
        : currentHeaders && [...currentHeaders];
      // let tempRelatedFields = [...currentHeaders];
      if (search.length) {
        tempRelatedFields = tempRelatedFields.filter((data) => {
          if (data.label.toLowerCase().includes(search.toLowerCase()))
            return true;
        });
      }

      if (relatedField?.length === 0) {
        setRelatedField(tempRelatedFields);
      }
    }
  }, [currentHeaders, search]);

  // Refactored version using useMemo and useCallback
  // const searchHeader = useMemo(() => {
  //   let tempHeaders = [...currentHeaders];

  //   if (search.length) {
  //     tempHeaders = tempHeaders.filter((data) => {
  //       if (
  //         data?.metric_display_name
  //           ?.toLowerCase()
  //           .includes(search.toLowerCase())
  //       )
  //         return true;
  //     });
  //   }

  //   let a = [];
  //   tempHeaders = tempHeaders
  //     ?.map((data) => {
  //       let name = data?.child_field
  //         ? `${data.child_field}.${data.metric_name}`
  //         : data.metric_name;
  //       if (!a.includes(name)) {
  //         a.push(name);
  //         return data;
  //       }
  //     })
  //     .filter((d) => d);

  //   a = [];
  //   // unique column only
  //   return [...new Set(tempHeaders.map((o) => JSON.stringify(o)))].map((s) =>
  //     JSON.parse(s)
  //   );
  // }, [currentHeaders, search]);

  // const relatedFields = useMemo(() => {
  //   let tempRelatedFields = localStorage.getItem(
  //     `Expand${tableName}RelatedList`
  //   )
  //     ? JSON.parse(localStorage.getItem(`Expand${tableName}RelatedList`))
  //     : [...currentHeaders];

  //   if (search.length) {
  //     tempRelatedFields = tempRelatedFields.filter((data) => {
  //       if (data.label.toLowerCase().includes(search.toLowerCase()))
  //         return true;
  //     });
  //   }
  //   tempRelatedFields = tempRelatedFields.sort((a, b) => {
  //     if (a.selected === b.selected) return 0;
  //     if (a.selected > b.selected) return -1;
  //     return 1;
  //   });

  //   return tempRelatedFields;
  // }, [currentHeaders, search]);

  const handelChecked = async (
    e,
    fieldName,
    visible,
    HandleExpandViewHeader,
    searchViewHeader,
    handleExpandViewTileChange,
    relatedTableHeader,
    parentFieldName,
    addOrRemoveTileFields,
    child_field
  ) => {
    const field = e.target.name || fieldName;
    const tile = localStorage.getItem("manageHeader");

    // if (!selectedSubFieldData && parentFieldName && child_field) {
    // }
    if (field !== "Id") {
      let tempHeader = [];
      if (
        (HandleExpandViewHeader && !selectedSubFieldData) ||
        searchViewHeader ||
        handleExpandViewTileChange
      ) {
        tempHeader = await getKeyData(
          JSON.stringify({
            key:
              HandleExpandViewHeader && !selectedSubFieldData
                ? `Expand${tile}${tableName}Headers`
                : searchViewHeader
                ? `Search${tableName}Headers`
                : handleExpandViewTileChange &&
                  `Expand${tile}${tableName}Headers`,
          })
        );
      }

      tempHeader = [...currentHeaders];

      // HandleExpandViewHeader && !addOrRemoveTileFields
      //   ? JSON.parse(localStorage.getItem(`Expand${tile}${tableName}Headers`))
      //   : searchViewHeader
      //   ? JSON.parse(localStorage.getItem(`Search${tableName}Headers`))
      //   : handleExpandViewTileChange
      //   ? JSON.parse(localStorage.getItem(`Expand${tile}${tableName}Headers`))
      //   : [...currentHeaders];
      const selectedHeader = tempHeader
        .filter((data) => data.isVisible || data.selected)
        .map((data) =>
          selectedSubFieldData
            ? data.metric_name
            : `${
                data.child_field
                  ? `${data.child_field}.${data.metric_name}`
                  : data.metric_name
              }`
        );

      const index = child_field
        ? tempHeader.findIndex(
            (data) =>
              data.child_field === child_field && data.metric_name === field
          )
        : tempHeader.findIndex((data) => data.metric_name === field);

      let a;
      if (relatedTableHeader) {
        tempHeader[index] = {
          ...tempHeader[index],
          selected: e.target.checked || !visible,
        };
      } else {
        tempHeader[index] = {
          ...tempHeader[index],
          // isVisible: e.target.checked,
          isVisible: e.target.checked || !visible,
          selected: e.target.checked || !visible,
        };
        a = tempHeader[index];
      }

      let tileHeader = [];
      let sortedHeader = [];
      if (HandleExpandViewHeader || handleExpandViewTileChange) {
        if (selectedSubFieldData) {
          tileHeader = await getKeyData(
            JSON.stringify({
              key: `Expand${tile}${tableName}Headers`,
            })
          );
          // tileHeader = tileHeader?.data?.value;

          let h = header.map((data) => {
            const flag = tileHeader?.data?.value?.includes(
              data?.call_parent
                ? `${data?.call_parent}.${data?.metric_name}`
                : data?.metric_name
            );
            return {
              ...data,
              isVisible: flag ?? data.selected,
              selected: flag ?? data.selected,
            };
          });

          h.sort((a, b) => {
            if (a.selected === b.selected) return 0;
            if (a.selected > b.selected) return -1;
            return 1;
          });

          let sortedFields = tileHeader?.data?.value?.length
            ? tileHeader.data.value
                .map((name) =>
                  h.find(
                    (data) =>
                      (data?.call_parent
                        ? `${data?.call_parent}.${data?.metric_name}`
                        : data?.metric_name) === name
                  )
                )
                .filter((data) => data)
            : [];

          sortedHeader = h;
          if (sortedFields?.length) {
            sortedHeader.splice(0, sortedFields.length, sortedFields);
            sortedHeader = sortedHeader.flat();
          }

          const findIndex = sortedHeader?.findIndex(
            (Data) =>
              Data.metric_name === fieldName && Data.child_field === child_field
          );
          if (findIndex && findIndex !== -1) {
            sortedHeader[findIndex] = {
              ...sortedHeader[findIndex],
              isVisible: e.target.checked || !visible,
              selected: e.target.checked || !visible,
            };
          } else {
            sortedHeader.push({
              ...tempHeader[index],
              // isVisible: e.target.checked,
              isVisible: e.target.checked || !visible,
              selected: e.target.checked || !visible,
            });
          }
        }
        if (!selectedSubFieldData && child_field) {
          tempHeader = tempHeader.filter(
            (data) =>
              !(data.child_field === child_field && data.metric_name === field)
          );
        }
      }

      if (HandleExpandViewHeader) {
        // let tileHeader = [];
        // let sortedHeader = [];
        // if (selectedSubFieldData) {

        //   tileHeader = await getKeyData(
        //     JSON.stringify({
        //       key: `Expand${tile}${tableName}Headers`,
        //     })
        //   );
        //   // tileHeader = tileHeader?.data?.value;

        //   let h = header.map((data) => {
        //     const flag = tileHeader?.data?.value?.includes(
        //       data?.call_parent
        //         ? `${data?.call_parent}.${data?.metric_name}`
        //         : data?.metric_name
        //     );
        //     return {
        //       ...data,
        //       isVisible: flag ?? data.selected,
        //       selected: flag ?? data.selected,
        //     };
        //   });

        //   h.sort((a, b) => {
        //     if (a.selected === b.selected) return 0;
        //     if (a.selected > b.selected) return -1;
        //     return 1;
        //   });

        //   let sortedFields = tileHeader?.data?.value?.length
        //     ? tileHeader.data.value
        //         .map((name) =>
        //           h.find(
        //             (data) =>
        //               (data?.call_parent
        //                 ? `${data?.call_parent}.${data?.metric_name}`
        //                 : data?.metric_name) === name
        //           )
        //         )
        //         .filter((data) => data)
        //     : [];

        //   sortedHeader = h;
        //   if (sortedFields?.length) {
        //     sortedHeader.splice(0, sortedFields.length, sortedFields);
        //     sortedHeader = sortedHeader.flat();
        //   }

        //   const findIndex = sortedHeader?.findIndex(
        //     (Data) =>
        //       Data.metric_name === fieldName && Data.child_field === child_field
        //   );
        //   if (findIndex && findIndex !== -1) {
        //     sortedHeader[findIndex] = {
        //       ...sortedHeader[findIndex],
        //       isVisible: e.target.checked || !visible,
        //       selected: e.target.checked || !visible,
        //     };
        //   } else {
        //     sortedHeader.push({
        //       ...tempHeader[index],
        //       // isVisible: e.target.checked,
        //       isVisible: e.target.checked || !visible,
        //       selected: e.target.checked || !visible,
        //     });
        //   }
        // }

        // !selectedSubFieldData &&
        //   child_field &&
        //   parentFieldName &&
        //   tempHeader.splice(index, 1, tempHeader[index + 1]);
        tempHeader.sort((a, b) => {
          if (a.selected === b.selected) return 0;
          if (a.selected > b.selected) return -1;
          return 1;
        });
        selectedSubFieldData &&
          sortedHeader.sort((a, b) => {
            if (a.selected === b.selected) return 0;
            if (a.selected > b.selected) return -1;
            return 1;
          });

        // !addOrRemoveTileFields &&

        tile !== "Grid" &&
          createCall(
            `Expand${tile}${tableName}Headers`,
            !selectedSubFieldData ? [...tempHeader] : [...sortedHeader]
          );
        // localStorage.setItem(
        //   `Expand${tile}${tableName}Headers`,
        //   JSON.stringify([...tempHeader])
        // );

        // !selectedSubFieldData &&
        //   !addOrRemoveTileFields &&
        //   localStorage.setItem(
        //     `ExpandActive${tile}${tableName}Headers`,
        //     JSON.stringify(tempHeader.filter((data) => data.isVisible))
        //   );
        // selectedSubFieldData &&
        //   localStorage.setItem(
        //     `${selectedSubFieldData?.parentSobject}SubFields`,
        //     JSON.stringify([...tempHeader])
        //   );
        setCurrentHeaders([...tempHeader]);

        if (tile === "Grid") {
          switch (tableName) {
            case "Opportunity":
              dispatch(
                Actions.getTableField.opportunityTableField(
                  !selectedSubFieldData ? [...tempHeader] : [...sortedHeader]
                )
              );
              break;
            case "Account":
              dispatch(
                Actions.getTableField.accountTableField(
                  !selectedSubFieldData ? [...tempHeader] : [...sortedHeader]
                )
              );
              break;
            case "Lead":
              dispatch(
                Actions.getTableField.leadTableField(
                  !selectedSubFieldData ? [...tempHeader] : [...sortedHeader]
                )
              );
              break;
            case "Contact":
              dispatch(
                Actions.getTableField.contactTableField(
                  !selectedSubFieldData ? [...tempHeader] : [...sortedHeader]
                )
              );
              break;
            case "Event":
              dispatch(
                Actions.getTableField.eventTableField(
                  !selectedSubFieldData ? [...tempHeader] : [...sortedHeader]
                )
              );
              break;
            default:
              break;
          }
        }

        filterColumns(
          [...tempHeader],
          // tile !== "Grid" ? false : true,
          false,
          null,
          null,
          selectedSubFieldData ? true : false,
          selectedSubFieldData && sortedHeader
        );
      } else if (handleExpandViewTileChange) {
        // if (!selectedSubFieldData && child_field) {
        //   tempHeader = tempHeader.filter(
        //     (data) =>
        //       !(data.child_field === child_field && data.metric_name === field)
        //   );
        // }
        tempHeader.sort((a, b) => {
          if (a.isVisible === b.isVisible) return 0;
          if (a.isVisible > b.isVisible) return -1;
          return 1;
        });

        selectedSubFieldData &&
          sortedHeader.sort((a, b) => {
            if (a.selected === b.selected) return 0;
            if (a.selected > b.selected) return -1;
            return 1;
          });
        const tile = localStorage.getItem("manageHeader");
        // localStorage.setItem(
        //   `Expand${tile}${tableName}Headers`,
        //   JSON.stringify([...tempHeader])
        // );

        tile !== "Grid" &&
          createCall(
            `Expand${tile}${tableName}Headers`,
            !selectedSubFieldData ? [...tempHeader] : [...sortedHeader]
          );
        // localStorage.setItem(
        //   `ExpandActive${tile}${tableName}Headers`,
        //   JSON.stringify(tempHeader.filter((data) => data.isVisible))
        // );
        setCurrentHeaders([...tempHeader]);
        filterColumns(
          [...tempHeader],
          // tile !== "Grid" ? false : true,
          false,
          null,
          null,
          selectedSubFieldData ? true : false,
          selectedSubFieldData && sortedHeader
        );
      } else if (searchViewHeader) {
        tempHeader.sort((a, b) => {
          if (a.isVisible === b.isVisible) return 0;
          if (a.isVisible > b.isVisible) return -1;
          return 1;
        });
        tempHeader.sort((a, b) => {
          if (a.selected === b.selected) return 0;
          if (a.selected > b.selected) return -1;
          return 1;
        });
        // localStorage.setItem(
        //   `Search${tableName}Headers`,
        //   JSON.stringify([...tempHeader])
        // );
        createCall(`Search${tableName}Headers`, [...tempHeader]);

        // localStorage.setItem(
        //   `SearchActive${tableName}Headers`,
        //   JSON.stringify(tempHeader.filter((data) => data.isVisible))
        // );
        // createCall(`SearchActive${tableName}Headers`, [
        //   ...tempHeader.filter((data) => data.isVisible),
        // ]);
        setCurrentHeaders([...tempHeader]);
        filterColumns([...tempHeader]);
        return;
      } else if (relatedTableHeader) {
        tempHeader.sort((a, b) => {
          if (a.selected === b.selected) return 0;
          if (a.selected > b.selected) return -1;
          return 1;
        });
        setCurrentHeaders([...tempHeader]);
        // filterColumns([...tempHeader]);
      } else {
        tempHeader.sort((a, b) => {
          if (a.selected === b.selected) return 0;
          if (a.selected > b.selected) return -1;
          return 1;
        });

        if (tableName === "Task") {
          tempHeader.sort((a, b) => {
            if (a.selected === b.selected) return 0;
            if (a.selected > b.selected) return -1;
            return 1;
          });
        }
        // const tableHeaders = await getKeyData(
        //   JSON.stringify({
        //     key: `${tableName}Headers`,
        //   })
        // );
        // JSON.parse(localStorage.getItem(`${tableName}Headers`));
        // !selectedSubFieldData &&
        //   localStorage.setItem(
        //     `${tableName}Headers`,
        //     JSON.stringify([...tempHeader])
        //   );
        // !selectedSubFieldData &&
        //   localStorage.setItem(
        //     `ExpandGrid${tableName}Headers`,
        //     JSON.stringify([...tempHeader])
        //   );
        // !selectedSubFieldData &&
        //   createCall(`${tableName}Headers`, [...tempHeader]);
        // !selectedSubFieldData &&
        //   createCall(`ExpandGrid${tableName}Headers`, [...tempHeader]);
        // selectedSubFieldData &&
        //   localStorage.setItem(
        //     `${selectedSubFieldData?.parentSobject}SubFields`,
        //     JSON.stringify([...tempHeader])
        //   );

        const uniquelyHeaders = !selectedSubFieldData
          ? child_field
            ? [
                ...tempHeader.filter(
                  (data) =>
                    !(
                      data.child_field === child_field &&
                      data.metric_name === field
                    )
                ),
              ]
            : [...tempHeader]
          : [...tempHeader];

        setCurrentHeaders(
          [
            ...new Set(
              uniquelyHeaders?.filter((d) => d).map((o) => JSON.stringify(o))
            ),
          ].map((s) => JSON.parse(s))
        );
        // setCurrentHeaders(
        //   !selectedSubFieldData
        //     ? child_field
        //       ? [
        //           ...tempHeader.filter(
        //             (data) =>
        //               !(
        //                 data.child_field === child_field &&
        //                 data.metric_name === field
        //               )
        //           ),
        //         ]
        //       : [...tempHeader]
        //     : [...tempHeader]
        // );

        // const dispatchHeaders = !selectedSubFieldData
        //   ? child_field
        //     ? [
        //         ...tempHeader.filter(
        //           (data) =>
        //             !(
        //               data.child_field === child_field &&
        //               data.metric_name === field
        //             )
        //         ),
        //       ]
        //     : [...tempHeader]
        //   : [...tempHeader];

        const dispatchHeaders = uniquelyHeaders;

        let h = [];
        if (selectedSubFieldData) {
          h = [...header];
          if (visible) {
            h = h.filter((data) =>
              data.call_parent
                ? !(
                    data.call_parent === parentFieldName &&
                    data.metric_name === fieldName
                  )
                : data.metric_name !== fieldName
            );
          } else {
            h.push(a);
            h.sort((a, b) => {
              if (a.selected === b.selected) return 0;
              if (a.selected > b.selected) return -1;
              return 1;
            });
          }
          // createCall(`ExpandGrid${tableName}Headers`, [...h]);
        }

        switch (tableName) {
          case "Opportunity":
            dispatch(
              Actions.getTableField.opportunityTableField(
                !selectedSubFieldData ? dispatchHeaders : h
              )
            );
            break;
          case "Account":
            dispatch(
              Actions.getTableField.accountTableField(
                !selectedSubFieldData ? dispatchHeaders : h
              )
            );
            break;
          case "Lead":
            dispatch(
              Actions.getTableField.leadTableField(
                !selectedSubFieldData ? dispatchHeaders : h
              )
            );
            break;
          case "Contact":
            dispatch(
              Actions.getTableField.contactTableField(
                !selectedSubFieldData ? dispatchHeaders : h
              )
            );
            break;
          case "Event":
            dispatch(
              Actions.getTableField.eventTableField(
                !selectedSubFieldData ? dispatchHeaders : h
              )
            );
            break;
          default:
            break;
        }

        tableName === "Task" && filterColumns([...tempHeader]);

        if (selectedSubFieldData) {
          setSubFields([...tempHeader], selectedSubFieldData);
        } else if (child_field) {
          let subHeaders = subFields[child_field];
          const index = subHeaders?.findIndex(
            ({ metric_name }) => metric_name === fieldName
          );
          subHeaders[index] = {
            ...subHeaders[index],
            // isVisible: e.target.checked,
            isVisible: e.target.checked || !visible,
            selected: e.target.checked || !visible,
          };
          setSubFields([...subHeaders], { metric_name: child_field });
        }
        tempHeader = tempHeader.filter(
          (data) => data.isVisible || data.selected
        );

        let gridHeaders;
        let activeGridHeaders = [];

        if (selectedSubFieldData) {
          // gridHeaders = await getKeyData(
          //   JSON.stringify({
          //     key:
          //       HandleExpandViewHeader && !selectedSubFieldData
          //         ? `Expand${tile}${tableName}Headers`
          //         : searchViewHeader
          //         ? `Search${tableName}Headers`
          //         : handleExpandViewTileChange
          //         ? `Expand${tile}${tableName}Headers`
          //         : `ExpandGrid${tableName}Headers`,
          //   })
          // );

          // let h = header.map((data) => {
          //   const flag = gridHeaders?.data?.value?.includes(
          //     data?.call_parent
          //       ? `${data?.call_parent}.${data?.metric_name}`
          //       : data?.metric_name
          //   );
          //   return {
          //     ...data,
          //     isVisible: flag ?? data.selected,
          //     selected: flag ?? data.selected,
          //   };
          // });

          h.sort((a, b) => {
            if (a.selected === b.selected) return 0;
            if (a.selected > b.selected) return -1;
            return 1;
          });

          // let sortedFields = gridHeaders?.data?.value?.length
          //   ? gridHeaders.data.value
          //       .map((name) =>
          //         h.find(
          //           (data) =>
          //             (data?.call_parent
          //               ? `${data?.call_parent}.${data?.metric_name}`
          //               : data?.metric_name) === name
          //         )
          //       )
          //       .filter((data) => data)
          //   : [];

          gridHeaders = h;
          // if (sortedFields?.length) {
          //   gridHeaders.splice(0, sortedFields.length, sortedFields);
          //   gridHeaders = gridHeaders.flat();
          // }
          activeGridHeaders = gridHeaders.filter((data) => data.selected);
        }

        const headers = [
          ...activeGridHeaders,
          // ...tempHeader.filter(
          //   ({ metric_display_name }) =>
          //     !tableHeaders?.data?.value
          //       .filter(({ isVisible, selected }) => isVisible || selected)
          //       .map((data) => data.metric_display_name)
          //       .includes(metric_display_name)
          // ),
        ].flat();

        const prevSelectedFields = localStorage.getItem("ActiveSubFields")
          ?.length
          ? JSON.parse(localStorage.getItem("ActiveSubFields"))
          : [];

        const filterFields = prevSelectedFields?.filter(
          (data) => data.parent !== selectedSubFieldData?.parentSobject
        );

        let fields = [];
        if (subFields) {
          fields = Object.keys(subFields)
            .map((field) =>
              Object.values(field).filter(
                ({ isVisible, selected }) => isVisible || selected
              )
            )
            .flat();

          fields = fields.filter(({ metric_name }) =>
            tempHeader
              .map(({ metric_name }) => metric_name)
              .includes(metric_name)
          );
        } else {
          fields = localStorage.getItem("ActiveSubFields")?.length
            ? JSON.parse(localStorage.getItem("ActiveSubFields"))
            : [];
        }

        if (selectedSubFieldData) {
          // createCall(`ExpandGrid${tableName}Headers`, [...headers]);
        }

        const filterUniqueHeaders = selectedSubFieldData
          ? [...headers]
          : [...tempHeader];

        tableName !== "Task" &&
          filterColumns(
            [
              ...new Set(
                filterUniqueHeaders
                  ?.filter((d) => d)
                  .map((o) => JSON.stringify(o))
              ),
            ].map((s) => JSON.parse(s))
          );

        // tableName !== "Task" &&
        //   filterColumns(selectedSubFieldData ? [...headers] : [...tempHeader]);

        // tempHeaders.map((datas) => {
        //   if (
        //     JSON.stringify(datas) ===
        //     JSON.stringify(
        //       activeHeader.filter(
        //         (data) => data.metric_display_name === datas.metric_display_name
        //       )
        //     )
        //   ) {
        //     return;
        //   } else {
        //     return datas;
        //   }
        // });

        // if (selectedSubFieldData) {
        //
        //   let _subFields = { ...subFields };
        //   _subFields = Object.keys(_subFields).map((field) =>
        //     _subFields[field].map((data, i) => {
        //       if (index === i) {
        //         return {
        //           ...data,
        //           isVisible: !data.isVisible,
        //           selected: !data.selected,
        //         };
        //       } else return data;
        //     })
        //   );
        //   localStorage.setItem(`AllSubField`, JSON.stringify([..._subFields]));
        // }
        selectedSubFieldData &&
          localStorage.setItem(
            `ActiveSubFields`,
            JSON.stringify([...tempHeader])
          );
        // filterColumns(
        //   selectedSubFieldData ? [...headers, ...filterFields] : [...tempHeader]
        // );
      }

      // if (!handleExpandViewTileChange) {
      const flag = !selectedHeader.includes(
        selectedSubFieldData
          ? field
          : child_field
          ? `${child_field}.${field}`
          : field
      );

      if (relatedTableHeader) {
        addOrRemoveFieldInColumn(
          field,
          flag ? "True" : "False",
          tableName,
          true,
          tempHeader[index]
        );
      } else {
        addOrRemoveFieldInColumn(
          field,
          flag ? "True" : "False",
          parentFieldName,
          child_field
        );
      }
      // }
    }
  };

  const handleRelatedListChecked = async (e, data, tableName, field) => {
    if (field === "Id") return;
    relatedTableLoader();
    let oldRelatedList = JSON.parse(
      localStorage.getItem(`Expand${tableName}RelatedList`)
    );
    const selectedRelatedList = oldRelatedList
      .filter((data) => data.selected)
      .map((data) => data.label);

    const index = oldRelatedList.findIndex((data) => data.label === field);

    setRelatedIndex(index);

    oldRelatedList[index] = {
      ...oldRelatedList[index],
      selected: !data.selected,
    };

    setCurrentHeaders(oldRelatedList);
    setRelatedField((prevFields) => {
      let objIndex = prevFields?.findIndex((d) => d.label === data.label);
      let newObj = prevFields?.find((d) => d.label === data.label);
      newObj.selected = !newObj.selected;
      prevFields.splice(objIndex, 1, newObj);
      return prevFields;
    });

    // setRelatedField(oldRelatedList);

    const currentTile = localStorage.getItem("manageHeader");

    const dataParams = {
      id: rowId,
      table_name: tableName,
      tile_name: currentTile,
      updatevalue: !data.selected,
      operation: "tile_Related_table_update",
      objectapiname: data.objectApiName,
      fieldapiname: data.fieldApiName,
    };
    const JSONdataParams = JSON.stringify(dataParams);

    const res = await updateTile(JSONdataParams);
    if (res.data.status === 200) {
      setTilesData((prevTiles) => {
        const index = prevTiles.findIndex(
          (data) => Object.keys(data)[0] === currentTile
        );

        if (index !== -1) {
          prevTiles[index][currentTile] = res.data.tile_data[0][currentTile];
          // console.log(prevTiles);
          return prevTiles;
        } else {
          return prevTiles;
        }
      });

      let header = [
        ...res.data.tile_data[0][localStorage.getItem("manageHeader")]
          .tile_related_table,
      ];
      header?.length &&
        header.sort((a, b) => {
          if (a.selected === b.selected) return 0;
          if (a.selected > b.selected) return -1;
          return 1;
        });

      const activeRelatedData =
        header?.length && header.filter((data) => data.selected);
      localStorage.setItem(
        `Expand${tableName}RelatedList`,
        JSON.stringify(header?.length ? header : oldRelatedList)
      );
      // setCurrentHeaders(header?.length ? header : oldRelatedList);
      localStorage.setItem(
        `ExpandActive${tableName}RelatedList`,
        JSON.stringify(activeRelatedData)
      );
      filterColumns(header?.length ? header : oldRelatedList, "RelatedList");
      localStorage.setItem(`tableData`, JSON.stringify(res.data.tile_record));
      localStorage.setItem(
        `relatedTablesHeaders`,
        JSON.stringify(
          res.data.tile_data[0][localStorage.getItem("manageHeader")]
            .tile_related_table_fields
        )
      );
      addOrRemoveFieldInColumn(field, "handleRelated");
      // if (selectedRelatedList.length && !selectedRelatedList.includes(field))
      //   addOrRemoveFieldInColumn(field, "True", tableName, null, null, true);
      // else
      //   addOrRemoveFieldInColumn(field, "False", tableName, null, null, true);
    }
  };

  const dragStart = (event) => {
    if (search.length) return;
    const dragEl = event.currentTarget;
    const dataTrans = event.dataTransfer;
    dataTrans.effectAllowed = "move";
    event.dataTransfer.setData("text/html", dragEl);
  };

  const dragOver = (event) => {
    if (search.length) return;
    event.preventDefault();
    const dragEl = event.currentTarget;
    setOverId(dragEl.id);
  };

  const onDragEnd = async (
    result,
    HandleExpandViewHeader,
    searchViewHeader,
    handleExpandViewTileChange,
    relatedTableList,
    relatedTableIndividualHeader,
    taskTableHeader
  ) => {
    if (search.length) return;
    setDragEnd(true);
    // setHeaderChangeLoader(true);

    const tile = localStorage.getItem("manageHeader");

    if (result.source.index !== result.destination.index) {
      let tempHeaders = relatedTableList
        ? [...relatedFields]
        : [...currentHeaders];
      tempHeaders.splice(result.source.index, 1);
      tempHeaders.splice(
        result.destination.index,
        0,
        relatedTableList
          ? relatedFields[result.source.index]
          : currentHeaders[result.source.index]
      );
      relatedTableList
        ? setRelatedField(tempHeaders)
        : setSearchHeaders(tempHeaders);
      if (HandleExpandViewHeader) {
        tempHeaders.sort((a, b) => {
          if (a.isVisible === b.isVisible) return 0;
          if (a.isVisible > b.isVisible) return -1;
          return 1;
        });

        setCurrentHeaders([...tempHeaders]);
        if (tile === "Grid") {
          // localStorage.setItem(
          //   `${tableName}Headers`,
          //   JSON.stringify([...tempHeaders])
          // );
          createCall(`${tableName}Headers`, [...tempHeaders]);
          switch (tableName) {
            case "Opportunity":
              dispatch(
                Actions.getTableField.opportunityTableField(tempHeaders)
              );
              break;
            case "Account":
              dispatch(Actions.getTableField.accountTableField(tempHeaders));
              break;
            case "Lead":
              dispatch(Actions.getTableField.leadTableField(tempHeaders));
              break;
            case "Contact":
              dispatch(Actions.getTableField.contactTableField(tempHeaders));
              break;
            case "Event":
              dispatch(Actions.getTableField.eventTableField(tempHeaders));
              break;
            default:
              break;
          }
        }
        // localStorage.setItem(
        //   `Expand${tile}${tableName}Headers`,
        //   JSON.stringify([...tempHeaders])
        // );
        await createCall(`Expand${tile}${tableName}Headers`, [...tempHeaders]);
        // localStorage.setItem(
        //   `ExpandActive${tile}${tableName}Headers`,
        //   JSON.stringify(tempHeaders.filter((data) => data.isVisible))
        // );

        filterColumns([...tempHeaders]);
      } else if (handleExpandViewTileChange) {
        tempHeaders.sort((a, b) => {
          if (a.isVisible === b.isVisible) return 0;
          if (a.isVisible > b.isVisible) return -1;
          return 1;
        });
        setCurrentHeaders([...tempHeaders]);
        // localStorage.setItem(
        //   `Expand${tile}${tableName}Headers`,
        //   JSON.stringify([...tempHeaders])
        // );
        // await createOrUpdateKey(
        //   JSON.stringify({
        //     key: `Expand${tile}${tableName}Headers`,
        //     value: [...tempHeaders],
        //   })
        // );
        createCall(`Expand${tile}${tableName}Headers`, [...tempHeaders]);
        // localStorage.setItem(
        //   `ExpandActive${tile}${tableName}Headers`,
        //   JSON.stringify(tempHeaders.filter((data) => data.isVisible))
        // );

        filterColumns([...tempHeaders]);
      } else if (searchViewHeader) {
        tempHeaders.sort((a, b) => {
          if (a.isVisible === b.isVisible) return 0;
          if (a.isVisible > b.isVisible) return -1;
          return 1;
        });
        tempHeaders.sort((a, b) => {
          if (a.selected === b.selected) return 0;
          if (a.selected > b.selected) return -1;
          return 1;
        });
        setCurrentHeaders([...tempHeaders]);
        // localStorage.setItem(
        //   `Search${tableName}Headers`,
        //   JSON.stringify([...tempHeaders])
        // );
        // await createOrUpdateKey(
        //   JSON.stringify({
        //     key:,
        //     value: [...tempHeaders],
        //   })
        // );
        createCall(`Search${tableName}Headers`, [...tempHeaders]);
        // localStorage.setItem(
        //   `SearchActive${tableName}Headers`,
        //   JSON.stringify(tempHeaders.filter((data) => data.isVisible))
        // );
        // await createOrUpdateKey(
        //   JSON.stringify({
        //     key: `SearchActive${tableName}Headers`,
        //     value: [...tempHeaders.filter((data) => data.isVisible)],
        //   })
        // );
        createCall(`Search${tableName}Headers`, [
          ...tempHeaders.filter((data) => data.isVisible),
        ]);
        filterColumns([...tempHeaders]);
      } else if (relatedTableList) {
        tempHeaders.sort((a, b) => {
          if (a.selected === b.selected) return 0;
          if (a.selected > b.selected) return -1;
          return 1;
        });

        localStorage.setItem(
          `Expand${tableName}RelatedList`,
          JSON.stringify(tempHeaders)
        );
        localStorage.setItem(
          `ExpandActive${tableName}RelatedList`,
          JSON.stringify(tempHeaders.filter((data) => data.selected))
        );
        setCurrentHeaders([...tempHeaders]);
        filterColumns([...tempHeaders], "RelatedList");
        setHeaderChangeLoader(false);
      } else if (relatedTableIndividualHeader) {
        tempHeaders.sort((a, b) => {
          if (a.selected === b.selected) return 0;
          if (a.selected > b.selected) return -1;
          return 1;
        });
        setCurrentHeaders([...tempHeaders]);
        filterColumns([...tempHeaders], null, true);
      } else if (taskTableHeader) {
        tempHeaders.sort((a, b) => {
          if (a.selected === b.selected) return 0;
          if (a.selected > b.selected) return -1;
          return 1;
        });
        localStorage.setItem(
          `ExpandGrid${tableName}Headers`,
          JSON.stringify([...tempHeaders])
        );
        // await createOrUpdateKey(
        //   JSON.stringify({
        //     key: `ExpandGrid${tableName}Headers`,
        //     value: [...tempHeaders],
        //   })
        // );
        createCall(`ExpandGrid${tableName}Headers`, [...tempHeaders]);

        localStorage.setItem(
          `ExpandActiveGrid${tableName}Headers`,
          JSON.stringify(
            tempHeaders.filter((data) => data.isVisible || data.selected)
          )
        );
        setCurrentHeaders([...tempHeaders]);

        setDragEnd(false);
        // setHeaderChangeLoader(false);
        filterColumns([...tempHeaders]);
      } else {
        tempHeaders.sort((a, b) => {
          if (a.isVisible === b.isVisible) return 0;
          if (a.isVisible > b.isVisible) return -1;
          return 1;
        });
        setCurrentHeaders([...tempHeaders]);
        // await createOrUpdateKey(
        //   JSON.stringify({
        //     key: `${tableName}Headers`,
        //     value: [...tempHeaders],
        //   })
        // );

        // let getDragData = !relatedTableList && await getKeyData(
        //   JSON.stringify({
        //     key: `Expand${tile}${tableName}Headers`,
        //   })
        // );
        // getDragData.splice(from, 1);
        // getDragData.splice(
        //   to,
        //   0,
        //   !relatedTableList && getDragData[from]
        // );
        createCall(`${tableName}Headers`, [...tempHeaders]);
        createCall(`ExpandGrid${tableName}Headers`, [...tempHeaders]);
        // localStorage.setItem(
        //   `${tableName}Headers`,
        //   JSON.stringify([...tempHeaders])
        // );
        switch (tableName) {
          case "Opportunity":
            dispatch(Actions.getTableField.opportunityTableField(tempHeaders));
            break;
          case "Account":
            dispatch(Actions.getTableField.accountTableField(tempHeaders));
            break;
          case "Lead":
            dispatch(Actions.getTableField.leadTableField(tempHeaders));
            break;
          case "Contact":
            dispatch(Actions.getTableField.contactTableField(tempHeaders));
            break;
          case "Event":
            dispatch(Actions.getTableField.eventTableField(tempHeaders));
            break;
          default:
            break;
        }

        // await createOrUpdateKey(
        //   JSON.stringify({
        //     key: `initial${tableName}Headers`,
        //     value: [...header],
        //   })
        // );
        createCall(`initial${tableName}Headers`, [...header]);

        // localStorage.setItem(
        //   `initial${tableName}Headers`,
        //   JSON.stringify([...header])
        // );
        tempHeaders = tempHeaders.filter(
          (data) => data.isVisible || data.selected
        );
        filterColumns([...tempHeaders]);
      }
    }
  };

  const handleCurrentHeaders = async (fieldOrRelated) => {
    if (fieldOrRelated === searchViewFieldOrRelated) return;
    if (fieldOrRelated === "Fields") {
      // let currHeaders = await getKeyData(
      //   JSON.stringify({
      //     key: `Expand${localStorage.getItem(
      //       "manageHeader"
      //     )}${tableName}Headers`,
      //   })
      // );

      // localStorage.getItem(
      //   `Expand${localStorage.getItem("manageHeader")}${tableName}Headers`
      // )
      //   ? JSON.parse(
      //       localStorage.getItem(
      //         `Expand${localStorage.getItem("manageHeader")}${tableName}Headers`
      //       )
      //     )
      setCurrentHeaders([...handleHeaders]);
    } else if (fieldOrRelated === "Related") {
      setHandleHeaders(currentHeaders);
      const currHeaders = localStorage.getItem(`Expand${tableName}RelatedList`)
        ? JSON.parse(localStorage.getItem(`Expand${tableName}RelatedList`))
        : relatedFields && [...relatedFields];
      setCurrentHeaders(currHeaders);
    }
  };

  return (
    <TableFilter
      style={
        searchViewHeader || relatedTableHeader ? { marginLeft: "0px" } : {}
      }
      key={key}
    >
      {searchViewHeader ? (
        <></>
      ) : allTilesDropDown ? (
        <></>
      ) : relatedTableHeader ? (
        <></>
      ) : tableName === "Task" ? (
        <></>
      ) : (
        // -------------------
        <Tooltip
          className="iconHover"
          PopperProps={{
            sx: {
              "& .MuiTooltip-tooltip": {
                width: "auto",
                height: "16px",
                background: "rgba(3, 46, 97, 0.72)",
                borderRadius: "4px",
                fontFamily: "Inter",
                textAlign: "center",
                fontSize: "12px",
              },
            },
          }}
          placement="bottom"
          title={"Manage Fields"}
        >
          <DefaultValue
            role="button"
            tabIndex={0}
            onKeyPress={() => {
              setOptionState((state) => !state);
            }}
            onClick={() => {
              setSearchViewFieldOrRelated("Fields");
              setOptionState((state) => !state);

              // setTimeout(() => currentRef?.current?.focus(), 100);

              searchInputRef?.current?.focus();
            }}
            sx={{
              "&:hover": {
                color: "#00A1E0",
                svg: {
                  path: {
                    stroke: "#00A1E0",
                  },
                },
              },
            }}
          >
            {iconChange ? <FieldSVG /> : <FieldSettingSVG />}
            {!expandViewFirst && !taskView && !leadView && (
              <p style={{ paddingLeft: "0.4rem" }}>Fields</p>
            )}
          </DefaultValue>
        </Tooltip>

        // -----------------
        // <DefaultValue
        //   role="button"
        //   tabIndex={0}
        //   onKeyPress={() => {
        //     setOptionState((state) => !state);
        //   }}
        //   onClick={() => {
        //     setSearchViewFieldOrRelated("Fields");
        //     setOptionState((state) => !state);

        //     // setTimeout(() => currentRef?.current?.focus(), 100);

        //     searchInputRef?.current?.focus();
        //   }}
        //   sx={{
        //     "&:hover": {
        //       color: "#00A1E0",
        //       svg: {
        //         path: {
        //           stroke: "#00A1E0",
        //         },
        //       },
        //     },
        //   }}
        // >
        //   {iconChange ? <FieldSVG /> : <FieldSettingSVG />}
        //   {!expandViewFirst && !taskView && !leadView && (
        //     <p style={{ paddingLeft: "0.4rem" }}>Fields</p>
        //   )}
        // </DefaultValue>

        // ----------------
      )}
      {optionState ||
      searchViewHeader ||
      handleExpandViewTileChange ||
      relatedTableHeader ||
      tableName === "Task" ? (
        <ColumnItems
          ref={currentRef}
          role="button"
          tabIndex={0}
          onBlur={(event) => {
            if (!event.currentTarget.contains(event.relatedTarget)) {
              //e.stopPropagation();
              //
              setTimeout(() => {
                if (searchViewFieldOrRelated === "Related") {
                  setSearchViewFieldOrRelated("Fields");
                  setCurrentHeaders([...handleHeaders]);
                }
                setOptionState(!shouldPopClose);
              }, 200);
              // selectedSubFieldData && setHeaderChangeLoader(true);
              selectedSubFieldData && setSelectedSubFieldData(null);
            }
          }}
          onMouseDown={() => {
            shouldPopClose = false;
            setTimeout(() => {
              shouldPopClose = true;
            }, 300);
          }}
          key={key}
          style={
            searchViewHeader
              ? {
                  top: "4px",
                  left: "-242px",
                  boxShadow: "8px 18px 12px rgba(0, 0, 0, 0.05)",
                  height: "295px",
                }
              : allTilesDropDown
              ? {
                  top: "15px",
                  left: "-268px",
                }
              : relatedTableHeader
              ? {
                  top: 0,
                  left: 0,
                  marginTop: 0,
                }
              : expandViewFirst
              ? { height: "298px", left: "-240px", top: "25px" }
              : tableName === "Task"
              ? {
                  top: fields?.length === 2 ? "70px" : "0px",
                  left: "-225px",
                  boxShadow: "0 3px 10px rgb(0 0 0 / 10%)",
                  borderRadius: "8px",
                  width: "240px",
                }
              : taskView
              ? { top: taskView && "25px", left: "-238px" }
              : selectedSubFieldData && handleExpandViewTileChange
              ? { height: "295px", left: "-238px", top: "30px" }
              : leadView
              ? { left: "-238px", top: "30px" }
              : {}
          }
        >
          {expandViewRelated || searchViewHeader || selectedSubFieldData ? (
            <>
              <SearchViewFieldBox>
                {!expandViewRelated || selectedSubFieldData ? (
                  <Box
                    sx={{
                      cursor: "pointer",
                      display: "flex",
                      gap: "4px",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <ArrowBackwardSVG
                      cursor={"pointer"}
                      onClick={(e) => {
                        selectedSubFieldData && setHeaderChangeLoader(true);
                        selectedSubFieldData
                          ? setSelectedSubFieldData(null)
                          : setFieldAddDropDown();
                      }}
                    />
                    {selectedSubFieldData && (
                      <StyledBox>
                        {selectedSubFieldData.metric_datatype === "reference"
                          ? `${
                              selectedSubFieldData.metric_display_name.split(
                                "ID"
                              )[0]
                            }`
                          : selectedSubFieldData.metric_display_name}
                      </StyledBox>
                    )}
                  </Box>
                ) : (
                  <></>
                )}

                {!selectedSubFieldData && (
                  <>
                    <StyledBox
                      onClick={() => {
                        setSearchViewFieldOrRelated("Fields");
                        handleCurrentHeaders("Fields");

                        searchInputRef?.current?.focus();
                        // setCurrentHeaders([...header]);
                      }}
                      sx={
                        searchViewFieldOrRelated === "Fields" && {
                          color: "#00A1E0",
                          borderBottom: "2px solid #00A1E0",
                          fontWeight: "bold",
                        }
                      }
                    >
                      {"Fields"}
                    </StyledBox>
                    <StyledBox
                      onClick={() => {
                        setSearchViewFieldOrRelated("Related");

                        handleCurrentHeaders("Related");
                        searchInputRef?.current?.focus();

                        // setCurrentHeaders([...header]);
                      }}
                      sx={
                        searchViewFieldOrRelated === "Related" && {
                          color: "#00A1E0",
                          borderBottom: "2px solid #00A1E0",
                          fontWeight: "bold",
                        }
                      }
                    >
                      {"Related"}
                    </StyledBox>
                  </>
                )}
              </SearchViewFieldBox>
              <Divider />
            </>
          ) : (
            <></>
          )}
          {HandleExpandViewHeader ||
          relatedTableHeader ||
          searchViewFieldOrRelated === "Fields" ? (
            <SearchingBox
              sx={{
                width: tableName === "Task" ? "auto" : "235px",
              }}
            >
              <SearchSVG height={"12px"} />
              <SearchText
                inputRef={searchInputRef}
                size="normal"
                value={search}
                placeholder={searchViewHeader ? "Search" : "Find a Field"}
                disableUnderline
                onChange={(e) => setSearch(e.target.value)}
                autoFocus
              />
            </SearchingBox>
          ) : (
            <></>
          )}
          <Divider
            sx={{
              background: "#00A1E0",
            }}
          />
          {HandleExpandViewHeader ||
          relatedTableHeader ||
          searchViewFieldOrRelated === "Fields" ||
          searchViewFieldOrRelated === "Related" ? (
            <MainFieldBox
              style={{
                height: allTilesDropDown
                  ? "198px"
                  : searchViewHeader
                  ? "200px"
                  : "200px",
                marginLeft: "4px",
              }}
            >
              <DragDropContext
                onDragEnd={(e) =>
                  searchViewFieldOrRelated === "Fields"
                    ? onDragEnd(
                        e,
                        HandleExpandViewHeader,
                        searchViewHeader,
                        handleExpandViewTileChange,
                        null,
                        relatedTableHeader ? true : false,
                        tableName === "Task" ? true : false
                      )
                    : onDragEnd(e, null, searchViewHeader, null, true)
                }
              >
                <Droppable droppableId={"droppable"}>
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={{
                        height: "100%",
                      }}
                    >
                      {searchHeader && searchViewFieldOrRelated === "Fields" ? (
                        !headerChangeLoader && searchHeader?.length ? (
                          searchHeader.map((data, index) => {
                            const toolTip = () => {
                              return data.metric_display_name?.length >=
                                (data.editable ? 14 : 19)
                                ? data.metric_display_name.split("ID")["0"]
                                : "";
                            };

                            if (
                              data.metric_name === "Id" &&
                              !(
                                idFields ||
                                localStorage.getItem("idFieldVisibility")
                              )
                            )
                              return;
                            if (data.metric_name === "Id") return;
                            return (
                              <Draggable
                                key={`${data?.metric_name}_${index}`}
                                draggableId={`${data?.metric_name}_${index}`}
                                index={index}
                                isDragDisabled={
                                  search.length || !data.selected ? true : false
                                }
                              >
                                {(provided, snapshot) => (
                                  <Tooltip
                                    title={
                                      !selectedSubFieldData
                                        ? data?.child_field
                                          ? `${data.child_field}:${data.metric_display_name}`
                                              ?.length >= 16
                                            ? data.metric_display_name.split(
                                                "ID"
                                              )[0]
                                            : ""
                                          : toolTip()
                                        : toolTip()
                                    }
                                    PopperProps={{
                                      disablePortal: true,
                                    }}
                                  >
                                    <Legend
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      sx={{
                                        borderRadius: "8px",
                                        flexDirection: "column",
                                        overflow: "hidden",
                                        fontSize: "16px",
                                        opacity:
                                          searchViewHeader && !data.nullable
                                            ? 0.5
                                            : data.metric_name === "Id"
                                            ? 0.5
                                            : 1,
                                        "&:hover": {
                                          backgroundColor: "#F2F7FC",
                                          color: "#00A1E0",

                                          cursor:
                                            searchViewHeader && !data.nullable
                                              ? "not-allowed"
                                              : data.metric_name === "Id"
                                              ? "not-allowed"
                                              : "pointer",
                                        },
                                      }}
                                      id={`${index}`}
                                      //draggable={!search.length}
                                      //onDragStart={(event) => dragStart(event)}
                                      //onDragOver={(event) => dragOver(event)}
                                      // onDragEnd={(event) => {
                                      //   if (overId) {
                                      //     dragEnd(
                                      //       event,
                                      //       HandleExpandViewHeader,
                                      //       searchViewHeader,
                                      //       handleExpandViewTileChange,
                                      //       null,
                                      //       relatedTableHeader ? true : false,
                                      //       tableName === "Task" ? true : false
                                      //     );
                                      //   }
                                      // }}
                                      onClick={(e) => {
                                        setSearch("");
                                        searchInputRef?.current?.focus();

                                        if (searchViewHeader && !data.nullable)
                                          return;
                                        data?.child_field
                                          ? handelChecked(
                                              e,
                                              data.metric_name,
                                              relatedTableHeader
                                                ? data.selected
                                                : data.isVisible ||
                                                    data.selected,
                                              relatedTableHeader
                                                ? null
                                                : HandleExpandViewHeader,
                                              relatedTableHeader
                                                ? null
                                                : searchViewHeader
                                                ? searchViewHeader
                                                : undefined,
                                              relatedTableHeader
                                                ? null
                                                : searchViewHeader
                                                ? undefined
                                                : handleExpandViewTileChange,
                                              relatedTableHeader
                                                ? relatedTableHeader
                                                : searchViewHeader
                                                ? undefined
                                                : undefined,
                                              relatedTableHeader ||
                                                searchViewHeader
                                                ? undefined
                                                : selectedSubFieldData?.parentSobject ??
                                                    data.call_parent,
                                              addOrRemoveSubField
                                                ? true
                                                : false,
                                              // addOrRemoveSubField ? data?.child_field : null
                                              data?.child_field
                                            )
                                          : handelChecked(
                                              e,
                                              data.metric_name,
                                              data.isVisible || data.selected,
                                              HandleExpandViewHeader,
                                              relatedTableHeader
                                                ? null
                                                : searchViewHeader
                                                ? searchViewHeader
                                                : undefined,
                                              handleExpandViewTileChange,
                                              relatedTableHeader
                                                ? relatedTableHeader
                                                : searchViewHeader
                                                ? undefined
                                                : undefined,
                                              data.call_parent
                                            );
                                      }}
                                      // title={data?.metric_display_name}
                                    >
                                      <DataFieldBox className="label">
                                        <Box
                                          display="flex"
                                          justifyContent="space-around"
                                          alignItems="center"
                                          width="100%"
                                          gap="7px"
                                          maxWidth="15%"
                                        >
                                          {!selectedSubFieldData && <DragSvg />}
                                          {(
                                            relatedTableHeader
                                              ? !data.selected
                                              : !(
                                                  data.isVisible ||
                                                  data.selected
                                                )
                                          ) ? (
                                            <CheckBoxSVG />
                                          ) : (
                                            <SelectedCheckBoxSVG />
                                          )}
                                        </Box>
                                        <Box
                                          width="100%"
                                          display="flex"
                                          maxWidth={
                                            tableName !== "Task" &&
                                            !relatedTableHeader &&
                                            !handleSubFieldsFromSearchDrawer &&
                                            data.parentSobject &&
                                            !selectedSubFieldData
                                              ? "65%"
                                              : "75%"
                                          }
                                        >
                                          {!data.editable && (
                                            <LockIcon
                                              sx={{
                                                fontSize: "17px",
                                                color: "#9E9E9E",
                                                marginRight: "0.6rem",
                                              }}
                                            />
                                          )}

                                          <Box
                                            sx={{
                                              textOverflow: "ellipsis",
                                              whiteSpace: "nowrap",
                                              overflow: "hidden",
                                              width: "100%",
                                            }}
                                          >
                                            {!selectedSubFieldData &&
                                            data?.child_field
                                              ? `${
                                                  data.child_field.split(
                                                    "Id"
                                                  )[0]
                                                }:${data.metric_display_name}`
                                              : data.metric_datatype ===
                                                "reference"
                                              ? `${
                                                  data.metric_display_name.split(
                                                    "ID"
                                                  )[0]
                                                }`
                                              : data.metric_display_name}
                                          </Box>
                                        </Box>
                                        {tableName !== "Task" &&
                                          !relatedTableHeader &&
                                          !handleSubFieldsFromSearchDrawer &&
                                          !data?.call_parent &&
                                          data.parentSobject &&
                                          data.parent_table_fields &&
                                          !selectedSubFieldData && (
                                            <Box
                                              display="grid"
                                              height="22px"
                                              minWidth="24px"
                                              bgcolor="rgba(3, 46, 97, 0.24)"
                                              backdropFilter="blur(12px)"
                                              borderRadius="8px"
                                              onClick={(e) => {
                                                //
                                                setHeaderChangeLoader(true);
                                                setSelectedSubFieldData(data);
                                                localStorage.setItem(
                                                  "SubFieldData",
                                                  JSON.stringify(data)
                                                );
                                                e.stopPropagation();
                                              }}
                                              sx={{
                                                placeItems: "center",
                                              }}
                                            >
                                              <ArrowForwardSVG
                                                style={{
                                                  scale: "0.8",
                                                  "& :hover": {
                                                    background:
                                                      "rgba(157, 165, 173, 0.25)",
                                                  },
                                                }}
                                              />
                                            </Box>
                                          )}
                                      </DataFieldBox>
                                    </Legend>
                                  </Tooltip>
                                )}
                              </Draggable>
                            );
                          })
                        ) : search?.length ? (
                          <Box
                            display="flex"
                            justifyContent={"center"}
                            alignItems={"center"}
                            // marginRight={"36px"}
                            height={"100%"}
                          >
                            No records found
                          </Box>
                        ) : (
                          <Box
                            display="flex"
                            justifyContent={"center"}
                            // marginRight={"36px"}
                            height={"100%"}
                          >
                            <Loader />
                          </Box>
                        )
                      ) : !(localStorage.getItem("manageHeader") === "Grid") ? (
                        relatedField?.length && !createView ? (
                          relatedField.map((data, index) => {
                            //
                            return (
                              <Draggable
                                key={`${data?.label}_${index}`}
                                draggableId={`${data?.label}_${index}`}
                                index={index}
                                isDragDisabled={search?.length ? true : false}
                              >
                                {(provided, snapshot) => (
                                  <Legend
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    id={`${index}`}
                                    // draggable={!search.length}
                                    //onDragStart={(event) => dragStart(event)}
                                    //onDragOver={(event) => dragOver(event)}
                                    // onDragEnd={(event) => {
                                    //   if (overId) {
                                    //     dragEnd(
                                    //       event,
                                    //       null,
                                    //       searchViewHeader,
                                    //       null,
                                    //       true
                                    //     );
                                    //   }
                                    // }}
                                    onClick={(e) => {
                                      handleRelatedListChecked(
                                        e,
                                        data,
                                        tableName,
                                        data?.label
                                      );
                                    }}
                                    title={data?.label}
                                  >
                                    <DataFieldBox className="label">
                                      <Box
                                        display="flex"
                                        justifyContent="space-around"
                                        alignItems="center"
                                        width="100%"
                                        gap="7px"
                                        maxWidth="15%"
                                      >
                                        <DragSvg />
                                        {!data?.selected ? (
                                          <CheckBoxSVG />
                                        ) : (
                                          <SelectedCheckBoxSVG />
                                        )}
                                      </Box>
                                      <Box width="100%" display="flex">
                                        <Box
                                          textOverflow={"ellipsis"}
                                          whiteSpace={"nowrap"}
                                          overflow={"hidden"}
                                          width={"75%"}
                                        >
                                          {data?.label}
                                        </Box>
                                      </Box>
                                    </DataFieldBox>
                                  </Legend>
                                )}
                              </Draggable>
                            );
                          })
                        ) : (
                          <Box
                            display="flex"
                            justifyContent={"center"}
                            alignItems={"center"}
                            // marginRight={"36px"}
                            height={"100%"}
                          >
                            <NoRelatedFields>
                              Related lists is not available on this tile
                            </NoRelatedFields>
                          </Box>
                        )
                      ) : (
                        <MainFieldBox
                          textAlign={"center"}
                          display={"grid"}
                          sx={{ placeItems: "center" }}
                          marginLeft={"8px"}
                          height={"100%"}
                        >
                          Related lists is not available on this tile
                        </MainFieldBox>
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </MainFieldBox>
          ) : (
            <MainFieldBox
              textAlign={"center"}
              display={"grid"}
              sx={{ placeItems: "center" }}
              marginLeft={"8px"}
              height={"100%"}
            >
              Related lists is not available on this tile
            </MainFieldBox>
          )}
        </ColumnItems>
      ) : (
        <></>
      )}
    </TableFilter>
  );
}

export default FilterColumn;
