export function requestFullscreen(element) {
  if (element.requestFullscreen) {
    element.requestFullscreen();
  } else if (element.mozRequestFullScreen) {
    /* Firefox */
    element.mozRequestFullScreen();
  } else if (element.webkitRequestFullscreen) {
    /* Chrome, Safari and Opera */
    element.webkitRequestFullscreen();
  } else if (element.msRequestFullscreen) {
    /* IE/Edge */
    element.msRequestFullscreen();
  }
}

export function exitFullscreen() {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.mozCancelFullScreen) {
    /* Firefox */
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) {
    /* Chrome, Safari and Opera */
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) {
    /* IE/Edge */
    document.msExitFullscreen();
  }
}

export function splitFirstOccurrence(str, separator) {
  const [key, ...rest] = str.split(separator);

  const value = rest.join("-");

  return { key, value };
}

export function parseCookies() {
  const cookieObj = {};
  const cookies = document.cookie.split("; ");

  cookies.forEach((cookie) => {
    const result = splitFirstOccurrence(cookie, "=");
    cookieObj[result.key] = decodeURIComponent(result.value); // Decode the value (cookies are URL-encoded)
  });

  return cookieObj;
}
