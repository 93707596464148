import React, { useState } from "react";
import styled from "@emotion/styled/macro";

import { BiSearch } from "react-icons/bi";

import { IoCloseOutline } from "react-icons/io5";
import { useNotes } from "../../Context/NotesContext";
import { editNote, editNoteTitle } from "../../Pages/Services/notesApi";

const TemplateMain = styled.div({
  position: "fixed",
  left: "0",
  top: "0",
  right: "0",
  bottom: "0",
  // backgroundColor: "rgba(0,0,0,0.25)",

  background: "rgba(0, 81, 112, 0.1)",
  backdropFilter: "blur(4px)",

  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: "7",
});

const TemplateContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  backgroundColor: "#FFFFFF",
  width: "700px",
  maxHeight: "200px",
  borderRadius: "8px",
  padding: "1rem",
});

const TemplateHeader = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "4px",
  color: "#C4C5CA",
  borderBottom: "1px solid #C4C5CA",
});

const SearchInput = styled.input({
  border: "none",
  outline: "none",
  width: "100%",
  paddingLeft: "12px",
});
const Templates = styled.div({
  height: "100%",
  overflowY: "auto",
});

const TemplateContent = styled.div({
  padding: ".5rem 0",
  cursor: "pointer",
});

function TemplateModal({ id, showTemplate, onClose }) {
  const { stateNotes, dispatchNotes } = useNotes();

  const { templates } = stateNotes;

  const [searchText, setSearchText] = useState("");

  let displayTemplates = [];

  if (!searchText) {
    displayTemplates = templates;
  } else {
    const tempTemplates = templates.filter((item) => {
      if (
        item.title.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
      ) {
        return item;
      }
    });

    displayTemplates = tempTemplates;
  }

  const addNoteWithTemplate = (item) => {
    const NewNote = {
      id: id,
      parent: null,
      title: item.title,
      content: item.content,
      template: item.template + 1,
    };

    editNote(dispatchNotes, NewNote);

    editNoteTitle(dispatchNotes, NewNote);

    onClose();
  };

  if (!showTemplate) {
    return null;
  }

  return (
    <TemplateMain onClick={() => onClose()}>
      <TemplateContainer onClick={(e) => e.stopPropagation()}>
        <TemplateHeader>
          <BiSearch />
          <SearchInput
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            placeholder="Search templates and notes"
            autoFocus
          />
          <IoCloseOutline
            style={{ cursor: "pointer", fontSize: "150%" }}
            onClick={() => onClose()}
          />
        </TemplateHeader>
        <div style={{ padding: ".5rem 0", color: "#C4C5CA" }}>TEMPLATES</div>
        <Templates>
          {displayTemplates.length !== 0
            ? displayTemplates.map((item) => (
                <TemplateContent
                  key={item.id}
                  onClick={() => addNoteWithTemplate(item)}
                >
                  {item.title}
                </TemplateContent>
              ))
            : "No Templates found"}
        </Templates>
      </TemplateContainer>
    </TemplateMain>
  );
}

export default TemplateModal;
