import { Checkbox, Divider } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useState } from "react";
import { SideBarSubContentBox, SideBarSubContentHeader } from "../SettingView";

import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Select from "@mui/material/Select";
import {
  createOrUpdateKey,
  getKeyData,
} from "../../../Pages/Services/tableApi";
import { ReactComponent as CheckBoxSVG } from "../../../Assets/SVG/CheckBox.svg";
import { ReactComponent as SelectedCheckBoxSVG } from "../../../Assets/SVG/SelectedCheckbox.svg";
import mainShortCutContext from "../../../Context/Context";

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: "60px",
  height: "31px",
  padding: 0,

  "& .MuiSwitch-switchBase": {
    padding: "6px 8px",
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(25px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "bule",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33CF4D",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: "19.2px",
    height: "19.2px",
  },
  "& .MuiSwitch-track": {
    borderRadius: "37px",
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const MainStyledBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  position: "relative",
  width: "100%",
  height: "40px",
  justifyContent: "space-between",
});

const StyledBox = styled(Box)({
  width: "max-content",
  height: "19px",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "600",
  fontSize: "16px",
  lineHeight: "19px",
  color: "#444647",
});

const StyledSubBox = styled(Box)({
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "17px",
  color: "#798EA3",
});

const StyledSelect = styled(Select)({
  width: "165px",
  height: "33px",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "17px",
  outline: "none",
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #E9EEF0",
  },
});

function General({ setBookmark, setShowOrHideFields }) {
  const [timeFormat, setTimeFormat] = useState(
    localStorage.getItem("TimeFormat")
  );

  const { idFields } = useContext(mainShortCutContext);

  const timeFormatHandleChange = async (e) => {
    const getJsonData = await getKeyData(
      JSON.stringify({
        key: `settings`,
      })
    );

    const JSONdataParams = {
      shortcut: getJsonData?.data?.value.shortcut,
      shortCutData: getJsonData?.data?.value.shortCutData,
      timeFormat: e.target.value,
    };
    await createOrUpdateKey(
      JSON.stringify({
        key: `settings`,
        value: JSONdataParams,
      })
    );
    localStorage.setItem("TimeFormat", e.target.value);
    setTimeFormat(localStorage.getItem("TimeFormat"));
  };

  const userIdFieldsHandle = (value) => {
    createOrUpdateKey(
      JSON.stringify({
        key: "idFieldVisibility",
        value: value,
      })
    );

    localStorage.setItem("idFieldVisibility", value);
    setShowOrHideFields();
  };

  return (
    <>
      <SideBarSubContentBox>
        <SideBarSubContentHeader>General</SideBarSubContentHeader>
        <p style={{ color: "#6A727A", margin: "2px 0px" }}>
          Manage your preferences
        </p>
        <Divider
          sx={{
            marginTop: "1rem",
          }}
        ></Divider>
      </SideBarSubContentBox>

      {/* this is for bookmarks*/}
      {/* 
      <MainStyledBox>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <StyledBox>
            Show bookmark bar
            <StyledSubBox>
              We can show your bookmark just on the TrackTable new tab.
            </StyledSubBox>
          </StyledBox>
        </Box>
        <Box>
          <IOSSwitch
            onChange={setBookmark}
            defaultChecked={JSON.parse(
              localStorage.getItem("ShowOrHideBookMark")
            )}
          />
        </Box>
      </MainStyledBox>
      <Divider
        sx={{
          marginTop: "1rem",
        }}
      /> */}

      {/* this is for time format */}
      <MainStyledBox marginTop={"16px"}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <StyledBox>
            Time Format
            <StyledSubBox>Select your preferred time format.</StyledSubBox>
          </StyledBox>
        </Box>
        <Box>
          <StyledSelect
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Age"
            displayEmpty
            onChange={timeFormatHandleChange}
            value={timeFormat}
            MenuProps={{
              PaperProps: {
                sx: {
                  bgcolor: "white",
                  border: "1px solid #EFF5F8",
                  boxShadow: "8px 18px 12px rgba(0, 0, 0, 0.05)",
                  borderRadius: "8px",
                  fontFamily: "Inter",
                  "& .MuiMenuItem-root": {
                    fontFamily: "Inter",
                  },
                },
              },
            }}
          >
            <MenuItem value="" sx={{ color: "#ABADB3" }}>
              select time format
            </MenuItem>
            <MenuItem value="12">12 hours format</MenuItem>
            <MenuItem value="24">24 hours format</MenuItem>
          </StyledSelect>
          {/* <IOSSwitch sx={{ m: 1 }} defaultChecked /> */}
        </Box>
      </MainStyledBox>
      <Divider
        sx={{
          marginTop: "1rem",
        }}
      />
      {/* this is for setting pipeline id*/}
      {/* <MainStyledBox marginTop={"16px"}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <StyledBox>
            Pipeline Id Fields
            <StyledSubBox>
              do you want to see the Id fields in pipeline?
            </StyledSubBox>
          </StyledBox>
        </Box>
        <Box
          sx={{
            zoom: 1.2,
          }}
        >
          <Checkbox
            onClick={(e) => userIdFieldsHandle(e.target.checked)}
            defaultChecked={
              idFields || JSON.parse(localStorage.getItem("idFieldVisibility"))
            }
            value={
              idFields || JSON.parse(localStorage.getItem("idFieldVisibility"))
            }
            checkedIcon={<SelectedCheckBoxSVG />}
            icon={<CheckBoxSVG />}
          />
        </Box>
      </MainStyledBox> */}
    </>
  );
}

export default General;
