import styled from "@emotion/styled";
import { Box, ClickAwayListener, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import SimpleFilterDropDown from "../../Components/FilterOpportunity/SimpleFilterDropDown";
import { TaskFilterHeader } from "../../Components/FilterOpportunity/FilterDropDownHeader";
import { ReactComponent as AddTaskSVG } from "../../Assets/SVG/AddTask.svg";
import {
  create_record,
  delete_record,
  getIdRecord,
  getTableHeadersApi,
  getTasksData,
  handleAddFieldInTable,
  createOrUpdateKey,
  update_record,
  getAllTiles,
} from "../Services/tableApi";
import Loader from "../../Components/Loader";
import TaskData from "../../Components/TaskView/TaskData";
import EditTask from "../../Components/TaskView/EditTask";
import Toaster from "../../Components/Toaster";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Actions from "../../redux/actions";

export const ScrollableContainer = styled(Box)({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "8px",

  "&::-webkit-scrollbar": {
    width: "0.5em",
    marginRight: "4px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(181,188,194,0.7)",
    borderRadius: "10px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "rgba(181,188,194,0.9)",
  },
});

export const MainTasksBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  minWidth: "500px",
  //overflow: "auto",
  borderRadius: "6px",
  background: "#ffffff",
  overflow: "visible",
  // zoom: "0.9",
});

export const TaskBoxHeader = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0.25rem 1rem",
  height: "60px",
  position: "relative",
});

export const TasksDataBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  height: "91%",
  overflow: "auto",
  padding: "0.5rem 0rem",
  marginRight: "0.25rem",
  //   marginBottom: "0.5rem",
  "&::-webkit-scrollbar": {
    width: "0.5em",
    marginRight: "4px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(181,188,194,0.7)",
    borderRadius: "10px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "rgba(181,188,194,0.9)",
  },
});

export const TaskRow = styled(Box)({
  display: "flex",
  gap: "12px",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0.5rem 2rem",
});

export const TaskRowTypoGraphy = styled(Typography)({
  fontFamily: "Inter",
  fontSize: "14px",
  textOverflow: "ellipsis",
  overflow: "hidden",
});

export const HeaderBox = styled(Box)({
  display: "flex",
  width: "100%",
  flexDirection: "column",
});

export const CompletedBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0 20px 0 61px",
  backgroundColor: "#E8F9F7",
  color: "#1DC4B4",
  overflow: "hidden",
  height: "40px",
  minHeight: "40px",
  marginBottom: "8x",
});

const HeaderTypoGraphy = styled(Typography)({
  fontSize: "16px",
  fontFamily: "Inter",
});

function Task({
  searchTasksStyle,
  setTaskState,
  setBackArrow,
  backArrow,
  setNewTaskAsync,
  newTaskAsync,
  fromSearchBar,
}) {
  const [newTask, setNewTask] = useState({});
  const [taskData, setTaskData] = useState(null);
  const [taskFilter, setTaskFilter] = useState(null);
  const [filterLoader, setFilterLoader] = useState(false);
  const [filterDataLoader, setFilterDataLoader] = useState(false);
  const [activeEditTask, setActiveEditTask] = useState("");
  const [activeEditTaskData, setActiveEditTaskData] = useState(null);
  const [activeEditTaskId, setActiveEditTaskID] = useState(null);
  const [taskEditLoader, setTaskEditLoader] = useState(false);
  const [header, setHeader] = useState(null);
  const [activeHeader, setActiveHeader] = useState(null);
  const [handleSyncTask, setHandleSyncTask] = useState(false);
  const [toaster, setToaster] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [expandHeader, setExpandHeader] = useState("");
  const [titleRowData, setTitleRowData] = useState("");
  const [tilesData, setTilesData] = useState([]);
  const { taskId } = useParams();
  let tableArray = ["Opportunity", "Account", "Contact", "Lead", "Task"];
  const [allCompletedTaskIds, setAllCompletedTaskIds] = useState([]);

  const [searchEditTask, setSearchEditTask] = useState(false);
  const [searchTikClose, setSearchTikClose] = useState(false);

  const navigate = useNavigate();

  const [referenceValue, setReferenceValue] = useState({});
  const dispatch = useDispatch();
  const asyncTask = useSelector((state) => {
    return state.asyncTask.taskAsync;
  });
  useEffect(() => {
    !backArrow && setSearchEditTask(false);
  }, [backArrow]);

  useEffect(() => {
    if (asyncTask) {
      getData();
      dispatch(Actions.getAsyncTask.asyncTask(false));
    }
  }, [asyncTask]);

  const addActiveEditTaskData = async (response, id) => {
    const checkData = response?.data?.data?.filter((data) => data.Id === id)[0];
    if (checkData) {
      return checkData;
    } else {
      const config = {
        table_name: "Task",
        record_id: id,
        fields: JSON.parse(localStorage.getItem(`ExpandActiveGridTaskHeaders`))
          ?.length
          ? JSON.parse(
              localStorage.getItem(`ExpandActiveGridTaskHeaders`)
            )?.map((data) => data.metric_name)
          : activeHeader.map((data) => data.metric_name),
      };
      const res = await getIdRecord(JSON.stringify(config));
      return res.data.rows[0];
    }
  };

  const getData = async (id) => {
    const dataParams = {
      table_name: "Task",
    };

    const response = await getTasksData();
    if (response?.data?.status === 200) {
      if (id) {
        setActiveEditTaskData(
          response?.data?.data?.filter((data) => data.Id === id)[0]
        );
      }
      setTaskData(response.data.data);
      setTaskFilter(response.data.filter);
      id && updateRecords(id);

      if (response.data.filter.view === "Completed") {
        const ids = response.data.data.map((data) => data.Id);
        setAllCompletedTaskIds(ids);
      }

      if (taskId) {
        const data = await addActiveEditTaskData(response, taskId);
        navigate("/tasks");
        setActiveEditTaskData(data);
        setActiveEditTask(data.Id);
        updateRecords(data.Id, data);
      }
      setFilterLoader(false);
      setFilterDataLoader(false);
      setTaskEditLoader(false);
    } else {
      setFilterLoader(false);
      setFilterDataLoader(false);
      setTaskEditLoader(false);
    }
    if (!header) {
      const JSONdataParams = JSON.stringify(dataParams);
      const headerResponse = await getTableHeadersApi(JSONdataParams);
      if (headerResponse.data.status === 200) {
        setHeader(headerResponse.data.headers);
        setActiveHeader(
          headerResponse.data.headers.filter((data) => data.selected)
        );
        !localStorage.getItem("ExpandActiveGridTaskHeaders") &&
          localStorage.setItem(
            "ExpandActiveGridTaskHeaders",
            JSON.stringify(
              headerResponse.data.headers.filter((data) => data.selected)
            )
          );
        !localStorage.getItem("ExpandActiveGridTaskHeaders") &&
          localStorage.setItem(
            "ExpandGridTaskHeaders",
            JSON.stringify(headerResponse.data.headers)
          );

        const data = headerResponse?.data?.headers
          ?.filter((data) => data && data.selected)
          ?.map(({ call_parent, metric_name }) =>
            `${
              call_parent ? `${call_parent}.${metric_name}` : metric_name
            }`.trim()
          );

        data?.length &&
          (await createOrUpdateKey(
            JSON.stringify({
              key: `ExpandGridTaskHeaders`,
              value: data,
            })
          ));
      }
    }
  };

  const updateRecords = async (id, data) => {
    setTaskEditLoader(true);
    if (!id) {
      setFilterLoader(false);
      setTaskEditLoader(false);
      return;
    }
    let config = {
      table_name: "Task",
      record_id: id,
      fields: JSON.parse(localStorage.getItem(`ExpandActiveGridTaskHeaders`))
        ?.length
        ? JSON.parse(localStorage.getItem(`ExpandActiveGridTaskHeaders`))?.map(
            (data) => data.metric_name
          )
        : activeHeader.map((data) => data.metric_name),
    };

    !config.fields.includes("ActivityDate") &&
      (config.fields = [...config.fields, "ActivityDate"]);

    const JSONdataParams = JSON.stringify(config);

    const filter = taskData?.filter((task) => task?.Id === data?.Id);

    const task = filter?.length && filter[0];

    let whatTableName = task?.What?.attributes?.url?.split("/")[5];
    let whoTableName = task?.Who?.attributes?.url?.split("/")[5];
    let tableName = whoTableName ?? whatTableName;
    let _manageHeader = localStorage.getItem("manageHeader") ?? "Overview";
    if (
      task &&
      tableArray.includes(tableName) &&
      (task?.Who?.Id || task?.What?.Id)
    ) {
      const [getDataResponse, tableHeaderResponse, tilesResponse] =
        await Promise.all([
          getIdRecord(JSONdataParams),
          getTableHeadersApi(
            JSON.stringify({
              table_name: tableName,
            })
          ),
          getAllTiles(
            JSON.stringify({
              table_name: tableName,
              id: task?.Who?.Id ?? task?.What?.Id,
            })
          ),
        ]);

      if (getDataResponse?.data?.status === 200) {
        setNewTask(
          getDataResponse.data?.rows[0] ? getDataResponse.data.rows[0] : {}
        );
        localStorage.setItem(
          `taskTableData`,
          JSON.stringify(
            getDataResponse.data?.rows[0] ? getDataResponse.data.rows[0] : {}
          )
        );
      }
      if (tableHeaderResponse?.data?.status === 200) {
        setExpandHeader(tableHeaderResponse.data.headers);
        // localStorage.setItem(
        //   `Expand${_manageHeader}${tableName}Headers`,
        //   JSON.stringify(tableHeaderResponse.data.headers)
        // );
        // await createOrUpdateKey(
        //   JSON.stringify({
        //     key: `Expand${_manageHeader}${tableName}Headers`,
        //     value: [
        //       ...tableHeaderResponse.data.headers.map(
        //         (data) => data.metric_name
        //       ),
        //     ],
        //   })
        // );
        // localStorage.setItem(
        //   `ExpandActive${_manageHeader}${tableName}Headers`,
        //   JSON.stringify(
        //     tableHeaderResponse.data.headers.filter(
        //       (data) => data.Visible || data.selected
        //     )
        //   )
        // );
        await createOrUpdateKey(
          JSON.stringify({
            key: `ExpandActive${_manageHeader}${tableName}Headers`,
            value: [
              ...tableHeaderResponse.data.headers.filter(
                (data) => data.Visible || data.selected
              ),
            ],
          })
        );
      } else {
        setExpandHeader(null);
        setTaskEditLoader(false);
      }
      if (tilesResponse?.data?.status === 200) {
        setTitleRowData(tilesResponse?.data?.tile_records);
        setTilesData(tilesResponse.data.tile_data);
        // localStorage.setItem(
        //   `${tableName}TilesApiData`,
        //   JSON.stringify(tilesResponse.data.tile_data)
        // );
        localStorage.setItem(
          `tableData`,
          JSON.stringify(tilesResponse.data.tile_records)
        );

        const getRelatedList = tilesResponse?.data?.tile_data?.filter((data) =>
          data.hasOwnProperty(_manageHeader)
        );
        localStorage.setItem(
          `relatedTablesHeaders`,
          JSON.stringify(
            getRelatedList?.[0]?.[_manageHeader]?.tile_related_table_fields
          )
        );

        localStorage.setItem(
          `Expand${tableName}RelatedList`,
          JSON.stringify(
            getRelatedList?.[0]?.[_manageHeader]?.tile_related_table
          )
        );
        localStorage.setItem(
          `ExpandActive${tableName}RelatedList`,
          JSON.stringify(
            getRelatedList?.[0]?.[_manageHeader]?.tile_related_table.filter(
              (data) => data.selected
            )
          )
        );
      }
      setFilterLoader(false);
      setFilterDataLoader(false);
      setTaskEditLoader(false);
    } else {
      const getDataResponse = await getIdRecord(JSONdataParams);
      if (getDataResponse?.data?.status === 200) {
        setNewTask(
          getDataResponse.data?.rows[0] ? getDataResponse.data.rows[0] : {}
        );
        localStorage.setItem(
          `taskTableData`,
          JSON.stringify(
            getDataResponse.data?.rows[0] ? getDataResponse.data.rows[0] : {}
          )
        );
      }
    }
    setFilterLoader(false);
    setFilterDataLoader(false);
    setTaskEditLoader(false);
  };

  const handleTaskViewFilter = async (value, filterOrSort) => {
    setFilterLoader(true);
    setFilterDataLoader(true);
    const dataParams = {
      key: "task_filter",
      value: {
        view: filterOrSort === "view" ? value : taskFilter.view,
        sort: filterOrSort === "sort" ? value : taskFilter.sort,
      },
    };
    const JSONdataParams = JSON.stringify(dataParams);
    await createOrUpdateKey(JSONdataParams);
    setNewTask({});
    setAllCompletedTaskIds([]);
    setActiveEditTask("");
    getData();
  };

  const syncTask = async (taskName, createOrUpdate, taskData) => {
    // debugger;
    if (!taskName.length) return;
    if (activeEditTask === taskName) return;
    setTaskEditLoader(true);
    //const d = new Date();
    //const month = d.getMonth() < 9 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
    //const day = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate();
    //const date = `${d.getFullYear()}-${month}-${day}`;
    let LocalDate = new Date();
    LocalDate =
      LocalDate.getFullYear() +
      "-" +
      ("0" + (LocalDate.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + LocalDate.getDate()).slice(-2);
    let dataParams = {
      table_name: "Task",
    };
    createOrUpdate
      ? newTask.ActivityDate === LocalDate
        ? (dataParams = {
            ...dataParams,
            fields: {
              Subject: taskName,
            },
          })
        : (dataParams = {
            ...dataParams,
            fields: {
              Subject: taskName,
              ActivityDate: newTask.ActivityDate
                ? newTask.ActivityDate
                : LocalDate,
            },
          })
      : (dataParams = {
          ...dataParams,
          id: newTask.Id,
          field: {
            Subject: taskName,
          },
        });
    const JSONdataParams = JSON.stringify(dataParams);
    const response = await (createOrUpdate
      ? create_record(JSONdataParams)
      : update_record(JSONdataParams));
    if (response.data.status === 200) {
      const Id = response.data.id;
      setActiveEditTask(taskName);
      getData(Id ?? newTask.Id);
      setHandleSyncTask(false);
    } else {
      setTaskEditLoader(false);
    }
    if (!createOrUpdate && response?.data?.status === 400) {
      setErrorMsg(response?.data?.error || response?.data?.message);
      setToaster(true);
    }
  };

  const deleteTask = async (tData) => {
    const dataParams = {
      table_name: "Task",
      id: tData.Id,
    };
    const JSONdataParams = JSON.stringify(dataParams);
    const response = await delete_record(JSONdataParams);
    if (response.data.status === 200) {
      setNewTask({});
      setActiveEditTask("");
      setActiveEditTaskID(null);
      const getResponse = await getTasksData();
      if (getResponse.data.status === 200) {
        setTaskData(getResponse.data.data);
        setTaskFilter(getResponse.data.filter);
      }
    }
  };

  const handleCompletedTaskList = (allCompletedListIds) => {
    setAllCompletedTaskIds(allCompletedListIds);
  };

  const handleAddOrRemoveTaskField = async (field, headerFlag, tableName) => {
    setFilterLoader(true);
    const dataParams = JSON.stringify({
      field: field,
      sobject: tableName,
      value: headerFlag,
    });
    const res = await handleAddFieldInTable(dataParams);
    if (res.data.status === 200) {
      updateRecords(newTask.Id);
    }
  };

  const filterColumns = async (header) => {
    localStorage.setItem(
      "ExpandActiveGridTaskHeaders",
      JSON.stringify(header.filter((data) => data.isVisible || data.selected))
    );
    localStorage.setItem("ExpandGridTaskHeaders", JSON.stringify(header));
    setHeader(header);
    setActiveHeader(header.filter((data) => data.isVisible || data.selected));
  };

  const updateFieldData = async (
    value,
    field,
    required,
    whoOrWhatName,
    taskCompletionId,
    data
  ) => {
    // debugger;
    setTaskEditLoader(false);
    if (
      (data?.Id ? newTask.Id === data?.Id : true) &&
      newTask[field] === (value ? value : value === 0 ? 0 : null)
    )
      return;
    if (!taskCompletionId && !newTask.Id) return;
    // if (newTask[field] === value) return;
    // if (newTask.Id === data?.Id) return;

    // if (newTask.Id === data?.Id && newTask[field] === value) return;
    // if (!data?.Id && newTask[field] === value) return;
    if (field === "Status") {
      if (value !== "Completed") {
        const __taskListIds = allCompletedTaskIds.filter(
          (id) => id !== newTask?.Id
        );
        setAllCompletedTaskIds(__taskListIds);
      } else {
        setAllCompletedTaskIds([...allCompletedTaskIds, newTask?.Id]);
      }
    }

    let dataParams;
    // if(targetedTask[field]!==value){
    setTaskEditLoader(true);
    dataParams = {
      table_name: "Task",
      id:
        taskCompletionId && taskCompletionId !== "Update"
          ? taskCompletionId?.Id ?? taskCompletionId
          : newTask.Id,
      field: {
        [field]: value,
      },
    };
    // }
    if (!dataParams.id) {
      let data = {};
      !activeHeader?.length
        ? (data = { Subject: "", Description: "" })
        : activeHeader.map(
            ({ metric_name }) =>
              (data = { ...data, [metric_name]: newTask[metric_name] || "" })
          );

      data = { ...data, [field]: value };
      setNewTask(data);

      if (field.includes("Id")) {
        setTaskEditLoader(false);
        let _referenceValue = Object.keys(referenceValue)?.length
          ? { ...referenceValue }
          : {};

        _referenceValue = { ...referenceValue, [field]: whoOrWhatName };
        setReferenceValue(_referenceValue);
      }
      setTaskEditLoader(false);
      return;
    }

    const JSONdataParams = JSON.stringify(dataParams);
    try {
      const response = await update_record(JSONdataParams);
      if (response?.data?.status === 200) {
        field === "Subject" && setActiveEditTask(value);
        setActiveEditTaskID(taskCompletionId ? taskCompletionId : null);
        if (taskCompletionId === "Update") {
          getData(newTask.Id);
          // if (!allCompletedTaskIds.includes(newTask.Id)) {
          //   handleCompletedTaskList([...allCompletedTaskIds, newTask.Id]);
          // } else {
          //   const __taskListIds = allCompletedTaskIds.filter(
          //     (id) => id !== newTask.Id
          //   );
          //   handleCompletedTaskList(__taskListIds);
          // }
        } else {
          taskCompletionId
            ? updateRecords(taskCompletionId, data)
            : updateRecords(newTask.Id, data);

          // if (field === "Status") {
          //   if (!allCompletedTaskIds.includes(newTask.Id)) {
          //     handleCompletedTaskList([...allCompletedTaskIds, newTask.Id]);
          //   } else {
          //     const __taskListIds = allCompletedTaskIds.filter(
          //       (id) => id !== newTask.Id
          //     );
          //     handleCompletedTaskList(__taskListIds);
          //   }
          // }
        }
        // taskCompletionId && setActiveEditTask(newTask.Subject);
      }
      if (response?.data?.status === 400) {
        setErrorMsg(response?.data?.error || response?.data?.message);
        setToaster(true);
        setTaskEditLoader(false);
      }
    } catch {
      setTaskEditLoader(false);
    }
  };

  const handleTaskCompletion = (data) => {
    setActiveEditTaskData(data);
    setActiveEditTaskID(data ? data.Id : null);
    // data && setActiveEditTask(data.Subject);
    updateFieldData(
      data && data.Id
        ? // taskFilter.view === "Completed"
          //   ? "In Progress"
          //   :
          allCompletedTaskIds.includes(data.Id)
          ? "In Progress"
          : "Completed"
        : allCompletedTaskIds.includes(data?.Id) || !data?.Id
        ? "In Progress"
        : "Completed",
      "Status",
      true,
      null,
      data?.Id ?? null,
      data
    );
  };

  useEffect(() => {
    getData();
  }, [taskId]);

  // useEffect(() => {
  //   console.log("is it calling me", activeEditTask);
  //   console.log("is it calling me", allCompletedTaskIds);
  // }, [activeEditTask, JSON.stringify(allCompletedTaskIds)]);

  return (
    <ClickAwayListener
      onClickAway={() => {
        if (!fromSearchBar) {
          setNewTask({});
          dispatch(Actions.getAsyncTask.asyncTask(true));
          searchTasksStyle && setSearchEditTask();
        }
      }}
    >
      <Box
        display={"flex"}
        gap={!searchTasksStyle && "8px"}
        height={searchTasksStyle ? "calc(100vh - 82px)" : "calc(100vh - 145px)"}
        // height={"725px"}
        justifyContent={"center"}
        width={"100%"}
        position={"relative"}
      >
        {taskEditLoader && (
          <Box
            sx={{
              position: "absolute",
              width: "90%",
              height: "100%",
              top: "50%",
              left: "55%",
              transform: "translate(-55%, -50%)",
            }}
          >
            <Loader />
          </Box>
        )}
        {!searchEditTask && taskData ? (
          <MainTasksBox
            flex={
              !Object.keys(newTask).length && !searchTasksStyle
                ? "0 0 650px"
                : "0 0 500px"
            }
            sx={{
              pointerEvents: taskEditLoader ? "none" : "pointer",
            }}
          >
            <TaskBoxHeader>
              <Box
                display={"flex"}
                gap={"8px"}
                alignItems={"center"}
                sx={{
                  cursor: "pointer",
                  color: "#9DA5AD",
                  "&:hover": {
                    color: "#00A1E0",
                  },
                }}
                onClick={() => {
                  const __header = [...header];
                  let activeHeader = __header?.filter(
                    ({ selected }) => selected
                  );

                  let data = {};
                  !activeHeader?.length
                    ? (data = { Subject: "", Description: "" })
                    : __header
                        .filter((data) => data.selected)
                        .map(
                          ({ metric_name }) =>
                            (data = { ...data, [metric_name]: "" })
                        );

                  // setNewTask(Object.keys(newTask)?.length ? newTask : data);
                  searchTasksStyle && setSearchEditTask(true);
                  setSearchTikClose(false);
                  setNewTask(data);
                  setActiveEditTask("");
                  setActiveEditTaskData(null);
                  setExpandHeader("");
                  setBackArrow && setBackArrow(true);
                }}
              >
                <AddTaskSVG height={"20px"} cursor={"pointer"} />
                <HeaderTypoGraphy fontSize={"16px"} fontFamily={"Inter"}>
                  Add Task
                </HeaderTypoGraphy>
              </Box>
              {filterLoader ? (
                <Box marginLeft={"auto"} marginRight={"12px"}>
                  <Loader handleSearchMargin={true}></Loader>
                </Box>
              ) : (
                <></>
              )}
              <SimpleFilterDropDown
                header={TaskFilterHeader("taskFilterHeader")}
                handleData={(value, filterOrSort) =>
                  handleTaskViewFilter(value, filterOrSort)
                }
                value={taskFilter.view}
                //   value={"All"}
                taskSortValue={taskFilter.sort ? taskFilter.sort : "None"}
                taskFilterLoader={filterLoader}
                widthAdjustment={"task"}
                taskFilterLeftAlignmentValue={
                  taskFilter.view === "Completed"
                    ? "-73px"
                    : taskFilter.view === "All"
                    ? "-130px"
                    : taskFilter.view === "Next 7 days"
                    ? "-68px"
                    : taskFilter.view === "Today and Overdue"
                    ? "-17px"
                    : "-63px"
                }
              />
            </TaskBoxHeader>
            <Divider />
            <TaskData
              taskData={taskData}
              taskFilter={taskFilter}
              taskDataFilter={filterDataLoader}
              taskActive={activeEditTask}
              taskRowClick={(data) => {
                setActiveEditTaskData(data);
                setActiveEditTask(data.Id);
                updateRecords(data.Id, data);
              }}
              setActiveEditTask={(task) => {
                setActiveEditTask(task);
              }}
              taskCompletion={handleTaskCompletion}
              taskActiveCompleteId={activeEditTaskId}
              manageEllipsis={!Object.keys(newTask).length}
              searchTasksStyle={searchTasksStyle}
              completedTaskList={allCompletedTaskIds}
              handleCompletedTaskList={handleCompletedTaskList}
              setSearchEditTask={setSearchEditTask}
              setSearchTikClose={setSearchTikClose}
              setNewTaskAsync={setNewTaskAsync}
            />
          </MainTasksBox>
        ) : (
          !searchEditTask && (
            <MainTasksBox
              flex={
                !Object.keys(newTask).length && !searchTasksStyle
                  ? "0 0 650px"
                  : "0 0 500px"
              }
            >
              <Loader />
            </MainTasksBox>
          )
        )}
        {Object.keys(newTask).length || searchEditTask ? (
          !searchTikClose && (
            <EditTask
              setNewTask={async (newTask) => {
                setNewTask(newTask ? { Subject: "", Description: "" } : {});
                setActiveEditTaskData(null);
                setActiveEditTask("");
                if (newTask) {
                  setTaskEditLoader(true);
                  const response = await getTasksData();
                  if (response?.data?.status === 200) {
                    setTaskData(response.data.data);
                    setTaskFilter(response.data.filter);
                  }
                  setTaskEditLoader(false);
                }
              }}
              taskData={newTask}
              taskRelatedData={activeEditTaskData}
              taskDataHeader={header}
              taskActiveDataHeader={activeHeader}
              // taskActiveDataHeader={
              //   localStorage.getItem("ExpandActiveGridTaskHeaders")
              //     ? JSON.parse(localStorage.getItem("ExpandActiveGridTaskHeaders"))
              //     : activeHeader
              // }
              filterColumns={filterColumns}
              syncTask={syncTask}
              deleteTask={deleteTask}
              onUpdateSuccessOfRow={(value, field, required, whoOrWhatName) => {
                updateFieldData(value, field, true, whoOrWhatName, "Update");
              }}
              updateRecordsOnly={(id) => {
                updateRecords(id);
              }}
              handleAddOrRemoveTaskField={handleAddOrRemoveTaskField}
              taskCompletion={handleTaskCompletion}
              taskActiveCompleteId={activeEditTaskId}
              handleSyncTask={handleSyncTask}
              taskActive={activeEditTask}
              setHandleSyncTask={() => {
                setHandleSyncTask(true);
              }}
              editTask={true}
              taskEditLoader={taskEditLoader}
              expandHeaders={expandHeader}
              titleRowData={titleRowData}
              tilesData={tilesData}
              completedTaskList={allCompletedTaskIds}
              handleCompletedTaskList={handleCompletedTaskList}
              searchTasksStyle={searchTasksStyle}
              setSearchEditTask={() => {
                setSearchEditTask(false);
                setBackArrow(false);
              }}
              referenceName={referenceValue}
              taskViewOnly={true}
            />
          )
        ) : (
          <></>
        )}
        <Toaster
          open={toaster}
          close={() => setToaster(false)}
          error={errorMsg}
        />
      </Box>
    </ClickAwayListener>
  );
}

export default Task;
