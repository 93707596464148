import styled from "@emotion/styled";
import { BiArrowBack } from "react-icons/bi";
import { TbConfetti } from "react-icons/tb";
import {
  Box,
  ClickAwayListener,
  Modal,
  Popover,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import demoVideo from "../../Assets/Videos/demo-video.mp4";
import Time from "../../Components/Time";
import {
  addForeignTableFields,
  addLead,
  fieldTest,
  getImageData,
  getKeyData,
  getNewAccessToken,
  login,
  setDefaultOverviewTileData,
  sync_tile,
  tableKeyPrefixRecords,
} from "../Services/tableApi";
import { ReactComponent as TrainsSVG } from "../../Assets/SVG/Trains.svg";
import { ReactComponent as GiftSVG } from "../../Assets/SVG/Gift.svg";
import Loader from "../../Components/Loader";
import WishView from "../../Components/FooterChildComponents/WishView";
import { WishBox } from "../../Components/Footer";

const CardBox = styled(Box)({
  boxSizing: "border-box",
  width: "569px",
  height: "473px",
  background: "#FFFFFF",
  border: "1px solid #EBEBEB",
  backdropFilter: "blur(48px)",
  borderRadius: "16px",
  padding: "20px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "8px",
});

const CardBoxTypoGraphy = styled(Box)({
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "150%",
  textAlign: "center",
  width: "343px",
  height: "42px",
});

const GoBackToLogin = styled(Link)({
  background: "#00a1e0",
  padding: "12px 24px",
  color: "#fff",
  textAlign: "center",
  textDecoration: "none",
  borderRadius: "8px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "8px",
  marginBottom: "24px",
  width: "160px",
  "&:hover": {
    textDecoration: "underline",
  },
});

const ErrorTitle = styled(Typography)({
  fontSize: "24px",
  fontWeight: 500,
  marginBottom: "8px",
});

const ErrorText = styled(Typography)({
  fontSize: "16px",
  fontWeight: 400,
  marginBottom: "24px",
});

const ReachOutText = styled(Typography)({
  fontSize: "16px",
  fontWeight: 400,
  color: "#626161",
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 484,
  height: 200,
  bgcolor: "background.paper",
  boxShadow: "0px 35px 31px rgba(64, 96, 114, 0.11)",
  p: 8,
  borderRadius: "8px",
  outline: "none !important",
  alignItems: "center",
  paddingBottom: "40px",
  paddingTop: "40px",
};

function Home({ setIsLoggedIn, isLoggedIn, setAppShortCut, appShortCut }) {
  const [queryParams] = useSearchParams();
  const [hasAccess, setAccess] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [wishPopup, setWishPopup] = useState(false);
  const [wasFeedbackSubmitted, setWasFeedbackSubmitted] = useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    const code = queryParams.get("code");
    if (code) {
      // login from code
      localStorage.setItem("code", code);
      localStorage.setItem("link", window.location.href);
      getCredential();
    } else {
      const access_token = localStorage.getItem("access_token");
      const refresh_token = localStorage.getItem("refresh_token");
      if (!access_token && !refresh_token) {
        window.location = "/login";
      } else if (!access_token && refresh_token) {
        getNewToken(refresh_token);
      }
      // getCredential();
      //  else if (!access_token && refresh_token) {
      //   getNewToken(refresh_token);
      // }
    }
  }, []);

  const getNewToken = async (r_token) => {
    const res = await getNewAccessToken(
      JSON.stringify({
        refresh_token: r_token,
      })
    );
    if (res.data.status === 200) {
      document.cookie
        .split(";")
        .forEach(
          (cookie) =>
            (document.cookie = cookie
              .replace(/^ +/, "")
              .replace(/=.*/, `=;expires=${new Date(0).toUTCString()};path=/`))
        );
      const credentials = res.data.credentials;
      Object.keys(credentials).forEach((credential) => {
        localStorage.setItem(credential, credentials[credential]);
      });
      res?.data?.user_name &&
        localStorage.setItem("user_name", res?.data?.user_name);

      const imageResponse = await getImageData({
        url: "/get_iamage_link/",
      });
      console.log(res);
      if (imageResponse.data.status === 200) {
        const {
          image_link,
          location,
          photographer_name,
          photographer_user_link,
        } = imageResponse.data.data;
        const data = {
          url: `url("${image_link}&w=auto")`,
          location: location,
          pgName: photographer_name,
          pgProfileUrl: photographer_user_link,
        };

        document.cookie = `name=${JSON.stringify(data)};expires=${new Date(
          Date.now() + 86400e3
        ).toUTCString()};`;
      }
      setIsLoggedIn(true);
    }
  };

  const getCredential = async () => {
    setLoading(true);
    const dataParams = {
      url: window.location.href,
    };
    const JSONdataParams = JSON.stringify(dataParams);
    const res = await login(JSONdataParams);
    if (res && res?.data?.status === 200) {
      setAccess(true);
      navigate("/");
      const credentials = res.data.credentials;
      Object.keys(credentials).forEach((credential) => {
        localStorage.setItem(credential, credentials[credential]);
      });

      res?.data?.user_name &&
        localStorage.setItem("user_name", res?.data?.user_name);
      setIsLoggedIn(true);
      const fieldResponse = await fieldTest();
      if (fieldResponse && fieldResponse?.data?.status === 200) {
        addLead();
        const getData = await getKeyData(
          JSON.stringify({
            key: "idFieldVisibility",
          })
        );

        localStorage.setItem(
          "idFieldVisibility",
          getData.data.value ? getData.data.value : false
        );
        const addForeignTableField = await addForeignTableFields();
        if (addForeignTableField.data.status === 200) {
          const tableKeyPrefixRecordsResponse = await tableKeyPrefixRecords();
          if (tableKeyPrefixRecordsResponse.data.status === 200) {
            const addOverviewResponse = await setDefaultOverviewTileData();
            if (addOverviewResponse.data.status === 200) {
              sync_tile();
            }
          }
        }
      }
    } else if (res?.data?.status === 401) {
      setAccess(false);
      localStorage.clear();
    } else if (
      res.data.status === 400 &&
      localStorage.getItem("refresh_token")
    ) {
      getNewToken(localStorage.getItem("refresh_token"));
    } else {
      navigate("/login");
    }
    setLoading(false);
    //  else if (
    //   res.data.status === 401 &&
    //   localStorage.getItem("refresh_token")
    // ) {
    //   getNewToken(localStorage.getItem("refresh_token"));
    // } else {
    //   navigate("/login");
    // }
  };

  return isLoggedIn ? (
    <Box
      container
      display="flex"
      justifyContent="space-around"
      alignItems="center"
      height="100%"
    >
      <Time appShortCut={appShortCut} setAppShortCut={setAppShortCut} />
      {/* <CardBox>
        <TrainsSVG />
        <CardBoxTypoGraphy>
          Which country is all set to become the first one to electrify
          mobility?
        </CardBoxTypoGraphy>
      </CardBox> */}
      {/* <video
        style={{ height: "calc(100vh - 286px)", marginInline: "100px" }}
        autoPlay
        loop
        muted
      >
        <source src={demoVideo} />
      </video> */}
    </Box>
  ) : (
    <>
      {isLoading && (
        <Box
          opacity={0.8}
          width={"100vw"}
          height={"80vh"}
          display={"grid"}
          placeItems={"center"}
        >
          <Loader />
        </Box>
      )}
      <Modal
        open={!hasAccess && !isLoading}
        aria-labelledby="access-modal"
        aria-describedby="access-modal-description"
      >
        <>
          <Box
            sx={style}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
          >
            <ErrorTitle as="h1">Salesforce Profile Unsupported!</ErrorTitle>
            <ErrorText id="access-modal-description">
              Try signing in with a different user name
            </ErrorText>
            <GoBackToLogin to="/login">Sign in</GoBackToLogin>
            {!wasFeedbackSubmitted ? (
              <Box display={"flex"} alignItems={"center"} gap={2}>
                <ReachOutText>
                  Reach out to us with questions or feedback
                </ReachOutText>
                <GiftSVG
                  stroke="#00A1E0"
                  onClick={() => setWishPopup(true)}
                  style={{
                    cursor: "pointer",
                  }}
                />
              </Box>
            ) : (
              <Box display={"flex"} alignItems={"center"} gap={1}>
                <TbConfetti size={22} />
                <Typography>
                  Thanks for reaching out to us, we will get in touch with you
                </Typography>
              </Box>
            )}
          </Box>
          {wishPopup && (
            <ClickAwayListener
              onClickAway={() => {
                if (wishPopup) {
                  setWishPopup(false);
                }
              }}
            >
              <WishBox
                sx={{
                  position: "absolute",
                  bottom: "5%",
                  right: "2%",
                }}
              >
                <WishView
                  onClose={() => setWishPopup(false)}
                  isEmail
                  handleSuccessWish={() => {
                    console.log("WishPopup Clicked!");
                    setWishPopup(false);
                    setWasFeedbackSubmitted(true);
                  }}
                />
              </WishBox>
            </ClickAwayListener>
          )}
        </>
      </Modal>
    </>
  );
}

export default Home;
