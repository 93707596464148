//
import React, { useEffect, useRef, useState } from "react";
import dummyData from "../../Assets/Dummy/response.json";
import LockIcon from "@mui/icons-material/Lock";
import { DataGrid } from "@mui/x-data-grid";
import { Box, styled } from "@mui/system";
import moment from "moment";
import EditCell from "../../Components/Table/EditCell";
import EditPopup from "../../Components/Table/EditPopup";
import ViewCell from "../../Components/Table/ViewCell";
import Loader from "../../Components/Loader";
import { Add } from "@mui/icons-material";
import {
  Backdrop,
  Button,
  Checkbox,
  Fade,
  FormControlLabel,
  FormGroup,
  Paper,
  Popover,
  Popper,
} from "@mui/material";
import FilterColumn from "../../Components/Table/FilterColumn";
import useComponentVisible from "../../Hooks/useComponentVisible";
import {
  getTableDataApi,
  getTableHeadersApi,
  handleAddFieldInTable,
  getKeyData,
  createOrUpdateKey,
} from "../../Pages/Services/tableApi";
import FilterOpportunity from "../../Components/Table/FilterOpportunity";
import { ReactComponent as ExpandSVG } from "../../Assets/SVG/Expand.svg";
import { ReactComponent as SalesForceLinkSVG } from "../../Assets/SVG/SalesForceLink.svg";
import { ReactComponent as OpportunitySVG } from "../../Assets/SVG/CreateOpportunity.svg";
import { ReactComponent as AccountSVG } from "../../Assets/SVG/CreateAccount.svg";
import { ReactComponent as ContactSVG } from "../../Assets/SVG/CreateContact.svg";
import { ReactComponent as TableLeadSVG } from "../../Assets/SVG/TableLead.svg";
import { ReactComponent as EventSVG } from "../../Assets/SVG/CreateEvent.svg";
import OpportunityView from "../../Components/ExpandView/ExpandOpportunityView";
import { useSelector, useDispatch } from "react-redux";
import Actions from "../../redux/actions";
import { useNotes } from "../../Context/NotesContext";

let value = "";
export const CustomDataGrid = styled(DataGrid)({
  "&.MuiDataGrid-root": {
    scrollBehavior: "smooth",
    fontFamily: "Inter",
    "::-webkit-scrollbar": {
      width: 0,
      marginRight: "4px",
    },
    ".MuiDataGrid-row": {
      background: "none",
      fontWeight: 400,
    },
    ".MuiDataGrid-row:hover": {
      background: "none",
    },
    ".MuiDataGrid-withBorder": {
      wordBreak: "break-word",
      width: "100%",
      whiteSpace: "normal",
    },
    ".MuiDataGrid-withBorder:focus": {
      outline: "2px solid #1976d2",
    },
    ".MuiDataGrid-cell": {
      wordBreak: "break-word",
      whiteSpace: "normal",
      overflow: "visible !important",
    },
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "#D7EBF7",
      fontWeight: 600,
      fontSize: "16px",
    },
  },
});

export const ExpandViewPopover = styled(Popover)({
  background: "rgba(0, 0, 0, 0.3)",
  ".MuiPaper-root": {
    borderRadius: "12px",
    boxShadow:
      "20px 28px 24px rgba(0, 0, 0, 0.1), inset -2px -2px 4px rgba(0, 0, 0, 0.06)",
    height: "800px",
    width: "625px",
    overflow: "visible",
    paddingBottom: "1rem",
  },
});
//

function NotesExtendedView({ showNotesExtedned, setShowNotesExtended }) {
  const { stateNotes, dispatchNotes } = useNotes();
  const { salesforceObject } = stateNotes;

  //
  // const { rows, headers } = dummyData;
  const dispatch = useDispatch();
  const [tableHeaders, setTableHeaders] = useState([]);
  const [columns, setColumns] = useState([]);
  const [pageData, setPageData] = useState(null);
  const [activeTab, setActiveTab] = useState(salesforceObject.type); // for setting the data as per tab click
  const [tab, setTab] = useState(
    JSON.parse(localStorage.getItem("getAllSelectedTab")) || ["Opportunities"]
  ); // Setting tab as per tabViewDropdown
  const [update, setUpdate] = useState(false);
  const [initialDataLoader, setInitialDataLoader] = useState(false);
  const [newDataLoader, setNewDataLoader] = useState(false);
  const [filterLoader, setFilterLoader] = useState(false);
  const [onHoverRowDataId, setOnHoverRowDataId] = useState("");
  const [relatedFields, setRelatedFields] = useState([]);
  const [cachingFilter, setCachingFilter] = useState(false);
  const [sort, setSort] = useState({ sort: "desc", field: "xxx_xxx_xxx" });

  useEffect(() => {
    setActiveTab(salesforceObject.type);
    // console.log(
    //   "notes extended view",
    //   { salesforceObject },
    //   "active tab",
    //   activeTab
    // );
  }, [salesforceObject.salesforceId]);

  useEffect(() => {
    // console.log("active tab", activeTab);
  }, [activeTab]);

  const extendedViewRef = useRef();

  // useEffect(() => {
  //   const checkIfClickedOutside = (e) => {
  //     if (
  //       showNotesExtedned &&
  //       extendedViewRef.current &&
  //       !extendedViewRef.current.contains(e.target)
  //     ) {
  //       setShowNotesExtended(false);
  //     }
  //   };
  //   document.addEventListener("mousedown", checkIfClickedOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", checkIfClickedOutside);
  //   };
  // }, [showNotesExtedned]);

  const [lockCSS, setLockCSS] = useState({
    left: 0,
    top: 0,
    showLock: false,
  });
  const [popCSS, setPopCSS] = useState({
    left: 0,
    top: 0,
  });
  const {
    isComponentVisible: tabViewDropdown,
    setIsComponentVisible: setTabViewDropdown,
    ref,
  } = useComponentVisible(false);

  const [expandAnchorEl, setExpandAnchorEl] = React.useState(null);

  const handleExpandView = (event) => {
    setExpandAnchorEl(document.getElementById("root"));
  };
  const handleExpandViewClose = () => {
    localStorage.setItem(`${handleTable(activeTab)}TilesApiData`, null);
    localStorage.setItem("manageHeader", "Overview");
    setExpandAnchorEl(null);
  };

  const expandViewOpen = Boolean(expandAnchorEl);
  const id = expandViewOpen ? "simple-popover" : undefined;

  const allTab = ["Opportunities", "Accounts", "Contacts", "Leads", "Events"];

  const handleTable = (activeTab) => {
    let table_name;
    switch (activeTab) {
      case "Opportunity":
        table_name = "Opportunity";
        break;
      case "Account":
        table_name = "Account";
        break;
      case "Contact":
        table_name = "Contact";
        break;
      case "Lead":
        table_name = "Lead";
        break;
      case "Event":
        table_name = "Event";
        break;
      default:
        table_name = "Opportunity";
        break;
    }
    return table_name;
  };
  // console.log("------------------------------", table_name, activeTab);

  const tableHeaderField = useSelector((state) => {
    if (handleTable(activeTab) === "Opportunity") {
      return state.tableField.opportunityFieldData;
    }
    if (handleTable(activeTab) === "Account") {
      return state.tableField.accountFieldData;
    }
    if (handleTable(activeTab) === "Lead") {
      return state.tableField.leadFieldData;
    }
    if (handleTable(activeTab) === "Contact") {
      return state.tableField.contactFieldData;
    }
    if (handleTable(activeTab) === "Event") {
      return state.tableField.eventFieldData;
    }
  });
  const tableRecordsData = useSelector((state) => {
    if (handleTable(activeTab) === "Opportunity") {
      return state.tableData.opportunityData;
    }
    if (handleTable(activeTab) === "Account") {
      return state.tableData.accountData;
    }
    if (handleTable(activeTab) === "Lead") {
      return state.tableData.leadData;
    }
    if (handleTable(activeTab) === "Contact") {
      return state.tableData.contactData;
    }
    if (handleTable(activeTab) === "Event") {
      return state.tableData.eventData;
    }
  });

  // Handling table data on field update
  useEffect(() => {
    if (update) {
      const tableName = handleTable(activeTab);
      const filterData = JSON.parse(localStorage.getItem("filterData"));
      getTableData(tableName, filterData ? filterData : []);
    }
    // setTimeout(() => {
    //   [...document.getElementsByClassName("MuiDataGrid-cell")].forEach(
    //     (ele) => {
    //       ele.addEventListener("focus", focus, true);
    //     }
    //   );
    // }, 1000);
  }, [update]);

  //--- On Caching Getting Filter Of Table ---//
  const getInitialFilter = async (tableName) => {
    const JSONdataParams = JSON.stringify({
      key: `${tableName}Filter`,
    });
    const response = await getKeyData(JSONdataParams);
    if (response.data.status === 200) {
      const Opportunity = response.data.value && response.data.value[0];
      localStorage.setItem(
        `checkOpportunity`,
        Opportunity.all_user || Opportunity.only_user
      );
      localStorage.setItem("filterData", JSON.stringify(response.data.value));
      setCachingFilter(!cachingFilter);
    } else if (response.data.status === 404) {
      localStorage.setItem(`checkOpportunity`, `All ${tableName}`);
      localStorage.setItem(
        "filterData",
        JSON.stringify([
          {
            all_user: `All ${tableName}`,
          },
        ])
      );
      setCachingFilter(!cachingFilter);
    }
  };

  // Handling table data on tab change
  useEffect(() => {
    if (activeTab) {
      setInitialDataLoader(true);
      const tableName = handleTable(activeTab);
      if (tableHeaderField?.length && tableRecordsData?.rows?.length) {
        setInitialDataLoader(false);
        getInitialFilter(tableName);
      } else {
        getTableHeader(tableName);
      }
    } else {
      filterColumns([]);
      // setPageData([]);
    }
    // getData();
  }, [activeTab]);

  // For Infinite Scroll Handling
  useEffect(() => {
    if (tableRecordsData) {
      setTimeout(() => {
        const dataGridScroller = document.getElementsByClassName(
          "MuiDataGrid-virtualScroller"
        );
        if (dataGridScroller.length) {
          dataGridScroller[0].addEventListener("scroll", getNewRowsOnScroll);
        }
      }, []);
      return () => {
        const dataGridScroller = document.getElementsByClassName(
          "MuiDataGrid-virtualScroller"
        );
        if (dataGridScroller.length) {
          dataGridScroller[0].removeEventListener("scroll", getNewRowsOnScroll);
        }
      };
    }
  }, [tableRecordsData]);

  useEffect(() => {
    localStorage.setItem("activeTab", activeTab);
    localStorage.setItem("checkOpportunity", `All ${activeTab}`);
    localStorage.setItem("getAllSelectedTab", JSON.stringify(tab));
    localStorage.setItem("manageHeader", "Overview");
    localStorage.setItem("ApiCall", null);
    localStorage.setItem("OpportunityTilesApiData", null);
    localStorage.setItem("AccountTilesApiData", null);
    localStorage.setItem("ContactTilesApiData", null);
    localStorage.setItem("LeadTilesApiData", null);
    localStorage.setItem("EventTilesApiData", null);
  }, []);

  const getNewRowsOnScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (scrollTop + clientHeight === scrollHeight) {
      const newScrollData = true;
      setNewDataLoader(true);
      const filterData = JSON.parse(localStorage.getItem("filterData"));
      getTableData(
        handleTable(activeTab),
        filterData ? filterData : [],
        newScrollData
      );
    }
  };

  const getTableHeader = async (tableName) => {
    const dataParams = {
      table_name: tableName,
    };
    const JSONdataParams = JSON.stringify(dataParams);
    const res = await getTableHeadersApi(JSONdataParams);
    if (res && res.data.status === 200) {
      setRelatedFields(res.data.related_sobjects);
      res.data.related_sobjects.length &&
        localStorage.setItem(
          `Expand${handleTable(activeTab)}RelatedList`,
          JSON.stringify(res.data.related_sobjects)
        );
      localStorage.setItem(
        `ExpandActive${handleTable(activeTab)}RelatedList`,
        JSON.stringify(
          res.data.related_sobjects.length &&
            res.data.related_sobjects.filter((list) => list.selected !== false)
        )
      );
      // setTableHeaders(res.data.headers);
      switch (tableName) {
        case "Opportunity":
          dispatch(
            Actions.getTableField.opportunityTableField(res.data.headers)
          );
          break;
        case "Account":
          dispatch(Actions.getTableField.accountTableField(res.data.headers));
          break;
        case "Lead":
          dispatch(Actions.getTableField.leadTableField(res.data.headers));
          break;
        case "Contact":
          dispatch(Actions.getTableField.contactTableField(res.data.headers));
          break;
        case "Event":
          dispatch(Actions.getTableField.eventTableField(res.data.headers));
          break;
        default:
          break;
      }
      const JSONdataParams = JSON.stringify({
        key: `${tableName}Filter`,
      });
      const response = await getKeyData(JSONdataParams);
      if (response.data.status === 200) {
        localStorage.setItem("filterData", JSON.stringify(response.data.value));
        getTableData(tableName, response.data.value);
      } else if (response.data.status === 404) {
        localStorage.setItem("filterData", JSON.stringify([]));
        getTableData(tableName, []);
      }
    }
  };

  const getTableData = async (tableName, filterData, newScrollData) => {
    const setInitialFilterOnTableChange = [
      {
        all_user: `All ${tableName}`,
      },
    ];
    if (filterData.length) {
      const Opportunity = filterData && filterData[0];
      localStorage.setItem(
        `checkOpportunity`,
        Opportunity.all_user || Opportunity.only_user
      );
      localStorage.setItem("filterData", JSON.stringify(filterData));
    } else {
      const activeTable = {
        Opportunity: "Opportunities",
        Account: "Accounts",
        Lead: "Leads",
        Event: "Events",
        Contact: "Contacts",
      };

      localStorage.setItem(`checkOpportunity`, `All ${activeTable[tableName]}`);
      localStorage.setItem(
        "filterData",
        JSON.stringify(setInitialFilterOnTableChange)
      );
    }
    setFilterLoader(true);
    const dataParams = {
      filter: filterData.length ? filterData : setInitialFilterOnTableChange,
      table_name: tableName,
      limit: newScrollData
        ? tableRecordsData?.rows?.length + 10
        : tableRecordsData?.rows?.length || 20,
      offset: 0,
    };

    const JSONdataParams = JSON.stringify(dataParams);
    const sortParams = {
      sort: sort.field,
      dir: sort.sort,
    };
    const res = await getTableDataApi(JSONdataParams, sortParams);
    if (res.data.status === 200) {
      switch (tableName) {
        case "Opportunity":
          dispatch(Actions.getTableData.opportunityTableData(res.data));
          break;
        case "Account":
          dispatch(Actions.getTableData.accountTableData(res.data));
          break;
        case "Lead":
          dispatch(Actions.getTableData.leadTableData(res.data));
          break;
        case "Contact":
          dispatch(Actions.getTableData.contactTableData(res.data));
          break;
        case "Event":
          dispatch(Actions.getTableData.eventTableData(res.data));
          break;
        default:
          break;
      }
      const sequentialHeader = await getKeyData(
        JSON.stringify({
          key: `${handleTable(activeTab)}Headers`,
        })
      );

      // JSON.parse(localStorage.getItem(`${handleTable(activeTab)}Headers`));
      const columns = sequentialHeader?.data?.value?.length
        ? sequentialHeader?.data.value.filter((data) => data.isVisible)
        : [];
      if (columns.length) {
        filterColumns(columns);
      } else {
        filterColumns(res.data.headers, true);
      }
      // setPageData(res.data);
      // setFilterLoader(false);
      setUpdate(false);
    }
    setFilterLoader(false);
    setInitialDataLoader(false);
    setNewDataLoader(false);
  };

  //--- Table Records Based On Filter ---//
  const handleFilterData = async (dataParams) => {
    // if (dataParams.value.length) {
    //   setFilterLoader(true);
    // }
    // const data = dataParams.value
    const JSONdataParams = JSON.stringify(dataParams);
    const res = await createOrUpdateKey(JSONdataParams); // create_key_Api
    if (res.data.status === 200) {
      // key and value creation success
      getTableData(handleTable(activeTab), dataParams.value);
    }
  };
  //--------//

  const addFieldInTable = async (fields, headerFlag) => {
    setFilterLoader(true);
    const tableName = handleTable(activeTab);
    const dataParams = JSON.stringify({
      field: fields,
      sobject: tableName,
      value: headerFlag,
    });
    const res = await handleAddFieldInTable(dataParams);
    if (res.data.status === 200) {
      const filterData = JSON.parse(localStorage.getItem("filterData"));
      getTableData(tableName, filterData ? filterData : []);
    }
  };

  const filterColumns = (header, saveAll) => {
    let temp = [];
    temp = header.map((rawData, index) => {
      return {
        field: rawData.metric_name,
        headerName: (
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            {!rawData.editable && (
              <LockIcon
                sx={{
                  fontSize: "17px",
                  color: "#9E9E9E",
                  marginRight: "0.6rem",
                }}
              />
            )}
            {rawData.metric_display_name}
          </span>
        ),
        description: rawData.metric_display_name,
        // minWidth: 180,
        width:
          rawData.metric_datatype === "textarea"
            ? 300
            : rawData.metric_datatype === "boolean" ||
              rawData.metric_datatype === "currency" ||
              rawData.metric_datatype === "percent"
            ? 150
            : rawData.metric_datatype === "datetime"
            ? 210
            : rawData.metric_datatype === "id"
            ? 190
            : rawData.metric_display_name.length > 15
            ? 250
            : 160,
        // flex: 1,
        sortable: true,
        renderCell: (cellData) => renderCell(cellData, rawData),
      };
    });
    temp = !saveAll
      ? temp.filter(({ field }) =>
          tableRecordsData?.headers
            ?.map(({ metric_name }) => metric_name)
            .includes(field)
        )
      : temp;
    const indexColumn = {
      field: " ",
      headerName: " ",
      description: " ",
      // minWidth: 180,
      width: 100,
      renderCell: (cellData) => renderCell(cellData),
    };
    temp.unshift(indexColumn);
    setColumns(temp);
  };

  const renderCell = (cellData, rawData, event) => {
    if (rawData) {
      if (rawData.editable) {
        // if (cellData.hasFocus) {
        //   setLockCSS({ ...lockCSS, showLock: false });
        // }
        if (cellData.field === "notes") {
          const props = { value: "", metadata: "" };
          if (cellData.value) {
            props.value = cellData.value?.msg;
            props.metadata = `
     ${cellData.value.sender_first_name} ${cellData.value.sender_last_name} ${
              cellData.value.created_at
                ? `${moment(cellData.value.created_at).format("h:mm a")}
       ${moment(cellData.value.created_at).format("D MMM YYYY")}`
                : ""
            }`;
          } else {
            props.value = "No Comment";
          }
          return (
            <EditPopup
              metaKey={cellData.field}
              opportunity_id={cellData.row.Opportunity_Id}
              {...props}
              type={rawData.metric_datatype}
              options={rawData.values}
            />
          );
        }
        return (
          <EditCell
            expandViewOpen={expandViewOpen}
            onUpdateSuccess={() => {
              setUpdate(true);
            }}
            rawData={rawData}
            cellData={cellData}
            tableName={handleTable(activeTab)}
          />
        );
      }
      // focus();
      // if (cellData.hasFocus) {
      //   setTimeout(() => {
      //     console.log(document.getElementById("focused-none-editable-cell"));
      //     // focus(document.getElementById("focused-none-editable-cell"));
      //   }, []);
      // }
      return typeof cellData.value === "object" ? (
        ""
      ) : (
        <Box
          position="relative"
          width="100%"
          height="100%"
          display="flex"
          alignItems={"center"}
          id={cellData.hasFocus ? "focused-none-editable-cell" : ""}
        >
          <ViewCell
            value={cellData.value}
            cellData={cellData}
            dataType={rawData.metric_datatype}
          />
        </Box>
      );
    } else {
      if (cellData.field === " ") {
        return (
          <>
            <Box
              width="100%"
              display={cellData.id === value ? "flex" : "none"}
              alignItems={"center"}
              gap={"15px"}
              position={"relative"}
              id={`${cellData.id}`}
            >
              <ExpandSVG
                sx={{
                  stroke: "#032E61",
                  ":hover": {
                    stroke: "#00A1E0",
                  },
                }}
                aria-describedby={id}
                onClick={handleExpandView}
                cursor={"pointer"}
                title={"Expand View"}
              />
              <SalesForceLinkSVG
                onClick={() => {
                  window.open(cellData.row.salseforce_ui_url, "_blank");
                }}
                cursor={"pointer"}
                title={"View In Salesforce"}
              />
            </Box>
          </>
        );
      }
    }
  };

  // const LockSVG = () => {
  //   return lockCSS.showLock ? (
  //     <svg
  //       data-v-4fb69c3d=""
  //       aria-hidden="true"
  //       focusable="false"
  //       data-prefix="fas"
  //       data-icon="lock-alt"
  //       role="img"
  //       xmlns="http://www.w3.org/2000/svg"
  //       viewBox="0 0 512 512"
  //       style={{
  //         position: "absolute",
  //         top: lockCSS.top,
  //         outline: "none",
  //         display: "block",
  //         left: lockCSS.left,
  //         fontSize: "16px",
  //         backgroundColor: "#fff",
  //         borderRadius: "50%",
  //         color: "#0065FF",
  //         width: "1rem",
  //         zIndex: 1000,
  //       }}
  //       class="pui-icon icon-readonly svg-inline--fa fa-lock-alt fa-w-16 has-tooltip"
  //       data-original-title="null"
  //     >
  //       <defs class="">
  //         <clipPath id="clip-gPx4xluDEV2w" class="">
  //           <path
  //             fill="currentColor"
  //             d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
  //             class=""
  //           ></path>
  //         </clipPath>
  //         <mask
  //           x="0"
  //           y="0"
  //           width="100%"
  //           height="100%"
  //           id="mask-O5cQG7pQAI1A"
  //           maskUnits="userSpaceOnUse"
  //           maskContentUnits="userSpaceOnUse"
  //           class=""
  //         >
  //           <rect
  //             x="0"
  //             y="0"
  //             width="100%"
  //             height="100%"
  //             fill="white"
  //             class=""
  //           ></rect>
  //           <g transform="translate(256 256)" class="">
  //             <g
  //               transform="translate(0, 0)  scale(0.5625, 0.5625)  rotate(0 0 0)"
  //               class=""
  //             >
  //               <path
  //                 fill="black"
  //                 d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zM264 392c0 22.1-17.9 40-40 40s-40-17.9-40-40v-48c0-22.1 17.9-40 40-40s40 17.9 40 40v48zm32-168H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z"
  //                 transform="translate(-224 -256)"
  //                 class=""
  //               ></path>
  //             </g>
  //           </g>
  //         </mask>
  //       </defs>
  //       <rect
  //         fill="currentColor"
  //         clip-path="url(#clip-gPx4xluDEV2w)"
  //         mask="url(#mask-O5cQG7pQAI1A)"
  //         x="0"
  //         y="0"
  //         width="100%"
  //         height="100%"
  //         class=""
  //       ></rect>
  //     </svg>
  //   ) : (
  //     <></>
  //   );
  // };

  const handleTabCheckbox = (value) => {
    if (tab[0] !== value && tab.length >= 1) {
      !tab.includes(value)
        ? setTab([...tab, value])
        : setTab(tab.filter((tab) => tab !== value));

      localStorage.setItem(
        "getAllSelectedTab",
        JSON.stringify(
          !tab.includes(value)
            ? [...tab, value]
            : tab.filter((tab) => tab !== value)
        )
      );
    } else if (tab.length > 1) {
      setTab(tab.filter((tab) => tab !== value));
      localStorage.setItem(
        "getAllSelectedTab",
        JSON.stringify(tab.filter((tab) => tab !== value))
      );
    }
    setTabViewDropdown(false);
  };

  const handleDropdownActiveTab = (value) => {
    activeTab !== value
      ? setActiveTab(tab.includes(value) ? activeTab : value)
      : setActiveTab(tab[0] === activeTab && tab.length > 1 ? tab[1] : tab[0]);
    localStorage.setItem(
      "activeTab",
      activeTab !== value
        ? tab.includes(value)
          ? activeTab
          : value
        : tab[0] === activeTab && tab.length > 1
        ? tab[1]
        : tab[0]
    );
    localStorage.setItem(
      `checkOpportunity`,
      activeTab !== value
        ? tab.includes(value)
          ? activeTab
          : value
        : tab[0] === activeTab && tab.length > 1
        ? tab[1]
        : tab[0]
    );
  };

  const handleActiveTab = (e) => {
    setActiveTab(activeTab !== e.target.value ? e.target.value : activeTab);
    localStorage.setItem(
      "activeTab",
      activeTab !== e.target.value ? e.target.value : activeTab
    );
    localStorage.setItem(
      "checkOpportunity",
      activeTab !== e.target.value ? e.target.value : activeTab
    );
  };

  //---Row Hover Expand view---//
  const popperRef = React.useRef();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handlePopperOpen = (event) => {
    const id = event.currentTarget.dataset.id;
    if (!newDataLoader) {
      value = id;
      setAnchorEl(event.currentTarget);
      setOnHoverRowDataId(id);
    }
  };
  const handlePopperClose = (event) => {
    if (
      anchorEl == null ||
      popperRef.current.contains(event.nativeEvent.relatedTarget)
    ) {
      return;
    }
    setAnchorEl(null);
  };
  //---------------//

  //--- column field header handling for expand view (basedOnTable) ---//
  const expandHeaders = (async () => {
    return await getKeyData(
      JSON.stringify({
        key: `ExpandOverview${handleTable(activeTab)}Headers`,
      })
    );
  })();

  // localStorage.getItem(`ExpandOverview${handleTable(activeTab)}Headers`);
  // const expandActiveHeaders = localStorage.getItem(
  //   `ExpandOverview${handleTable(activeTab)}Headers`
  // );
  //-------//

  //--- tab Dropdown popover ---//
  const [tabAnchorEl, setTabAnchorEl] = React.useState(null);
  const handleTabView = (event) => {
    setTabAnchorEl(event.currentTarget);
  };
  const handleTabViewClose = () => {
    setTabAnchorEl(null);
  };
  const tabViewOpen = Boolean(tabAnchorEl);
  const tabId = tabViewOpen ? "simple-popover" : undefined;
  // ----- //

  const getSvgOfTable = (table) => {
    let svg;
    switch (table) {
      case "Opportunities":
        svg = <OpportunitySVG />;
        break;
      case "Accounts":
        svg = <AccountSVG />;
        break;
      case "Contacts":
        svg = <ContactSVG />;
        break;
      case "Leads":
        svg = <TableLeadSVG />;
        break;
      case "Events":
        svg = <EventSVG />;
        break;

      default:
        break;
    }
    return svg;
  };

  //

  return (
    <div
      style={{
        backgroundColor: "white",
        width: "35rem",
      }}
      ref={extendedViewRef}
    >
      {/* NotesExtendedView */}
      <OpportunityView
        expandView={() => handleExpandViewClose()}
        header={
          expandHeaders?.data?.value
            ? expandHeaders.data.value
            : tableHeaderField
        }
        activeHeader={
          expandHeaders?.data?.value
            ? expandHeaders?.data?.value.filter(
                ({ isVisible, selected }) => isVisible ?? selected
              )
            : tableRecordsData.headers
        }
        // tableName={handleTable(activeTab)}
        tableName={salesforceObject.type}
        rowId={salesforceObject.salesforceId}
        onUpdateSuccess={() => {
          setUpdate(true);
        }}
        addOrRemoveFieldInColumn={(field, headerFlag) =>
          addFieldInTable(field, headerFlag)
        }
        relatedFields={relatedFields}
        expandViewFirst={true}
        setShowNotesExtended={setShowNotesExtended}
        notesExpandedView={true}
      />
      {/* end */}
    </div>
  );
}

export default NotesExtendedView;
