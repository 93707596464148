/* eslint-disable no-nested-ternary */
import { Button, IconButton, Tooltip } from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import useComponentVisible from "../../Hooks/useComponentVisible";
import SimpleDropDown from "../SimpleDropDown";
import { MetricDataType } from "./EditCell";
// import styles from './EditablePopup.module.less';

function EditablePopup({
  value,
  opportunity_id,
  onUpdateSuccess,
  metadata,
  type,
  metaKey,
  options,
  displayNode,
}) {
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const [editMode, setEditMode] = useState(false);

  const [loader, setLoader] = useState(false);

  const [error, setError] = useState(undefined);

  const initialFieldState =
    typeof value === "number" ? value : value.split("T")[0];
  const [field, setField] = useState(initialFieldState);

  useEffect(() => {
    setEditMode(false);
    setField(initialFieldState);
  }, [isComponentVisible]);

  const handleUpdate = () => {
    if (field) {
      setLoader(true);
      if (type === "notes") {
        // OpportunitiesAPI.sendNote({
        //   opportunity_id,
        //   msg: field ,
        // })
        // .then(() => {
        onUpdateSuccess();
        setTimeout(() => {
          setLoader(false);
          setIsComponentVisible((prev) => !prev);
        }, 1000);
        // })
        // .catch(() => {
        //   setLoader(false);
        //   setField(initialFieldState);
        //   toast.error('Error occured while updating this field');
        // });
      } else {
        const arr = metaKey.split("_");
        const sobject = arr[0];
        arr.shift();
        // OpportunitiesAPI.updateOpportunity({
        //   id: opportunity_id,
        //   value: field,
        //   key: arr.join('_'),
        //   sobject,
        // })
        //   .then((res) => {
        //     if (res.data.success) {
        onUpdateSuccess();
        setTimeout(() => {
          setLoader(false);
          setIsComponentVisible((prev) => !prev);
        }, 1000);
        //   }
        // })
        // .catch(() => {
        //   setLoader(false);
        //   setField(initialFieldState);
        //   toast.error('Error occured while updating this field');
        // });
      }
    }
  };

  const handleMsgChange = (e) => {
    if (type === "notes") {
      if (e.target.value.length > 1000) {
        setError("Maximum character limit reached.");
      } else {
        setError(undefined);
        setField(e.target.value);
      }
    } else {
      setField(e.target.value);
    }
  };

  const renderColumn = () => {
    if (type === "notes") {
      return (
        <textarea
          id="newMsgText"
          style={{
            border: "none",
            width: "100%",
            minHeight: "75px",
            background: "none",
            resize: "none",
            padding: "8px",
            "&:focus": {
              outline: "none",
            },
          }}
          //   className={error ? styles.error : ''}
          onChange={handleMsgChange}
          value={field}
        />
      );
    }
    if (type === MetricDataType.Picklist) {
      return (
        <SimpleDropDown
          value={field.toString()}
          options={options || []}
          onChange={(x) => {
            setField(x);
          }}
        />
      );
    }
    return (
      <input
        style={{
          border: "none",
          width: "100%",
          minHeight: "75px",
          background: "none",
          resize: "none",
          padding: "8px",
          "&:focus": {
            outline: "none",
          },
        }}
        type={type}
        id="newMsgText"
        // className={error ? styles.error : ''}
        onChange={handleMsgChange}
        value={field}
      />
    );
  };

  return (
    <div ref={ref}>
      <Tooltip
        title={value}
        placement="bottom"
        PopperProps={{
          disablePortal: true,
        }}
      >
        <div style={{ display: "flex" }}>
          <span
            // className={styles.noteTxt}
            style={{
              textOverflow: "ellipsis",
              // whiteSpace: "nowrap",
              width: "144px",
              display: "inline-block",
              overflow: "hidden",
            }}
            tabIndex={0}
            role="button"
            onKeyPress={() => {
              setIsComponentVisible((prev) => !prev);
            }}
            onClick={() => {
              setIsComponentVisible((prev) => !prev);
            }}
          >
            {displayNode || field}
          </span>
        </div>
      </Tooltip>
      {isComponentVisible ? (
        <div
          // className={styles.popupEdit}
          style={{
            background: "#fff",
            padding: "12px",
            borderRadius: "8px",
            position: "absolute",
            boxShadow: "0 2px 10px #e5e5e5, 2px -4px 5px #e5e5e5 inset",
            transform: "translate(-50%, 0)",
            color: "#444",
            zIndex: "10",
            width: "350px",
            marginLeft: "180px",
            marginTop: "30px",
          }}
        >
          <span
            // className={styles.closeBtn}
            style={{
              position: "absolute",
              top: "25px",
              right: "25px",
              cursor: "pointer",
              zoom: 0.7,
            }}
            tabIndex={0}
            role="button"
            onKeyPress={() => {
              setIsComponentVisible((prev) => !prev);
            }}
            onClick={() => {
              setIsComponentVisible((prev) => !prev);
            }}
          >
            <GridCloseIcon />
          </span>
          <div
            //   className={styles.cont}
            style={{
              background: "#f5f7f9",
              borderRadius: "5px",
              width: "95%",
              padding: "12px",
              marginBottom: "20px",
              minHeight: "100px",
              display: "flex",
              flexDirection: "column",
              whiteSpace: "normal",
            }}
          >
            {editMode ? renderColumn() : field}
            {error && (
              <span
              //  className={styles.errorMsg}
              >
                {error}
              </span>
            )}
          </div>
          <div
            //   className={styles.bottomBar}
            style={{
              display: "flex",
              alignItems: "center",
              color: "#888",
            }}
          >
            {metadata}
            <Button
              sx={{
                backgroundColor: "#00A1E0",
              }}
              type="button"
              onClick={() =>
                editMode ? handleUpdate() : setEditMode((pre) => !pre)
              }
              disabled={!!(loader || error)}
            >
              {editMode ? (loader ? "Updating" : "Update") : "Edit"}
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default EditablePopup;
