import styled from "@emotion/styled";
import { Popover, Typography } from "@mui/material";
import { Box } from "@mui/system";

export const MainBox = styled(Box)({
  height: "100%",
  width: "100%",
});

export const MainBoxHeader = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "0rem 1.5rem",

  background: "#AFCADF",
  backgroundImage: `url("Mask group.png")`,
  backgroundPosition: "right",
  backgroundRepeat: "no-repeat",

  // borderRadius: "12px 12px 0px 0px",
});

export const MainDataBox = styled(Box)({
  display: "flex",
});

export const OpportunityName = styled(Typography)({
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "18px",
  lineHeight: "22px",
  color: "#FFFFFF",
  cursor: "pointer",
});
export const OpportunityType = styled(Typography)({
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "17px",
  color: "#FFFFFF",
  paddingLeft: "8px",
});

export const TypoGraphy = styled(Typography)({
  fontWeight: 500,
  color: "#444647",
  fontSize: "14px",
  fontFamily: "Inter",
  display: "flex",
  gap: "5px",
  alignItems: "center",
});

export const SalesforceTypoGraphy = styled(Typography)({
  fontFamily: "Inter",
  fontSize: "12px",
  color: "#032E61",

  ":hover": { color: "#00A1E0" },
});

export const CustomBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  flexWrap: "wrap",
  alignItems: "center",
  fontFamily: "Inter",
  marginRight: "1rem",
  gap: "0.5rem",
  marginTop: "1rem",

  width: "100%",
  height: "100%",
});

export const LeadViewPopOver = styled(Popover)({
  background: "rgba(0, 0, 0, 0.3)",
  ".MuiPaper-root": {
    borderRadius: "8px",
    boxShadow:
      "20px 28px 24px rgba(0, 0, 0, 0.1), inset -2px -2px 4px rgba(0, 0, 0, 0.06)",
    height: "740px",
    width: "575px",
    overflow: "visible",
  },
});

export const ConvertLeadViewPopOver = styled(Popover)({
  background: "rgba(0, 0, 0, 0.3)",
  ".MuiPaper-root": {
    borderRadius: "8px",
    boxShadow:
      "20px 28px 24px rgba(0, 0, 0, 0.1), inset -2px -2px 4px rgba(0, 0, 0, 0.06)",
    height: "665px",
    width: "580px",
    overflow: "visible",
  },
});

export const TilesBox = styled(Box)({
  borderRadius: "24px 0px 0px 24px",
  display: "flex",
  alignItems: "center",
  gap: "4px",
  justifyContent: "center",
  cursor: "pointer",

  height: "33px",
  fontWeight: 600,
  padding: "4px",
  fontSize: "14px",
  lineHeight: "17px",
});

export const ConvertLeadButton = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "4px",
  justifyContent: "center",
  cursor: "pointer",

  height: "26px",
  fontWeight: 600,
  padding: "4px",
  fontSize: "14px",
  lineHeight: "17px",

  color: "rgb(0, 161, 224)",
  border: "1px solid rgb(231, 231, 231)",
  borderRadius: "8px",
  backgroundColor: "white",
  paddingRight: "8px",

  ":hover": {
    backgroundColor: "rgba(25, 118, 210, 0.04)",
  },
});

export const ScrollableBox = styled(Box)({
  overflow: "auto",
  // height: "490px",
  // width: "100%",
  overflowY: "scroll",
  padding: "1.25rem",
  scrollBehavior: "smooth",
  "&::-webkit-scrollbar": {
    width: "0.5em",
    marginRight: "4px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(181,188,194,0.7)",
    borderRadius: "10px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "rgba(181,188,194,0.9)",
  },
});

export const LoaderBox = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  background: "rgba(0,0,0,0.1)",
  height: "100%",
  width: "100%",
});

export const ViewCellBox = styled(Box)({
  position: "relative",
  width: "100%",
  height: "100%",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  display: "flex",
  alignItems: "center",
  overflow: "hidden",
});

export const SalesForceButton = styled(Box)({
  // width: "97px",
  display: "flex",
  alignItems: "center",
  gap: "8px",
  borderRadius: "8px",
  padding: "8px",
  // background: "#E2EFFA",
  cursor: "pointer",
});

export const ExpandViewSideMenu = styled(Box)({
  // height: 532px;
  height: "698px",
  background: "#F5F8FA",
  borderRadius: " 0px 0px 0px 12px",
  padding: "1rem 0.5rem",
});

export const AllTilesBox = styled(Box)({
  display: "flex",
  gap: "8px",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "8px",
  border: "1px solid  #E9EEF0",
  height: "34px",
  width: "130px",
  cursor: "pointer",
});
