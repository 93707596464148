import styled from "@emotion/styled";
import { CloseRounded } from "@mui/icons-material";
import { Box, Divider, Input, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ReactComponent as SearchSVG } from "../../Assets/SVG/Search.svg";
import { all_matched_records } from "../../Pages/Services/tableApi";
import Loader from "../Loader";
import useDebounce from "../../Hooks/useDebounce";
import { ReactComponent as AccountSVG } from "../../Assets/SVG/CreateAccount.svg";
import { ReactComponent as ContactSVG } from "../../Assets/SVG/CreateContact.svg";
import { ReactComponent as EventSVG } from "../../Assets/SVG/CreateEvent.svg";
import { ReactComponent as LeadSVG } from "../../Assets/SVG/CreateLead.svg";
import { ReactComponent as NoteSVG } from "../../Assets/SVG/CreateNote.svg";
import { ReactComponent as OpportunitySVG } from "../../Assets/SVG/CreateOpportunity.svg";
import { ReactComponent as TaskSVG } from "../../Assets/SVG/CreateTask.svg";
import SettingView from "../SettingView/SettingView";
import { SettingPopover } from "../Footer";
import { useLocation, useNavigate } from "react-router-dom";

const SearchText = styled(Input)({
  width: "100%",
  fontSize: "14px",
  padding: "4px 16px",
  position: "sticky",
  top: 0,
  background: "white",
  fontFamily: "Inter",
});
const TypoGraphyText = styled(Typography)({
  fontFamily: "Inter",
  fontStyle: "normal",
  fontHeight: 400,
  fontSize: "14px",
  lineHeight: "15px",
  color: "#ABADB3",
  padding: "0rem 1.5rem",
});
const ScrollBox = styled(Box)({
  width: "100%",
  height: "486px",
  overflowY: "scroll",
  scrollBehavior: "smooth",
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  "&::-webkit-scrollbar": {
    width: "0.5em",
    marginRight: "4px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(181,188,194,0.7)",
    borderRadius: "10px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(181,188,194,0.7)",
    borderRadius: "10px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    backgroundColor: "rgba(181,188,194,0.9)",
  },
});

function SearchView({
  searchView,
  recentSearches,
  toggleDrawer,
  onClickRow,
  showOrHide,
  showOrHideFields,
  setAppShortCut,
  appShortCut,
  setBookmark,
  manageShortCut,
}) {
  const location = useLocation();
  let navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [matchRecords, setMatchRecords] = useState(null);
  const [searchLoader, setSearchLoader] = useState(false);
  const [helpPopUp, setHelpPopUp] = useState(false);
  const searchDebounceText = useDebounce(search, 1000);
  const [SettingAnchorEl, setSettingAnchorEl] = useState(null);
  const [toasterState, setToasterState] = useState({
    open: false,
  });
  const handleSettingClick = () => {
    setSettingAnchorEl(document.getElementById("root"));
  };

  const handleSettingClose = () => {
    setHelpPopUp(false);
    setSettingAnchorEl(null);
    if (!localStorage.getItem("access_token")) {
      window.location = "/login";
    }
  };
  const open = Boolean(SettingAnchorEl);
  const settingId = open ? "simple-popover" : null;
  // --- //

  const handleClose = () => {
    setToasterState({ ...toasterState, open: false });
  };
  const commands = [
    {
      tableName: "Note",
      commandName: "Create new note",
      anchor: "right",
      svg: <NoteSVG />,
    },
    {
      tableName: "Task",
      commandName: "Create new task",
      anchor: "right",
      svg: <TaskSVG />,
    },
    {
      tableName: "Opportunity",
      commandName: "Create new opportunity",
      anchor: "right",
      svg: <OpportunitySVG />,
    },
    {
      tableName: "Account",
      commandName: "Create new account",
      anchor: "right",
      svg: <AccountSVG />,
    },
    {
      tableName: "Contact",
      commandName: "Create new contact",
      anchor: "right",
      svg: <ContactSVG />,
    },
    {
      tableName: "Lead",
      commandName: "Create new lead",
      anchor: "right",
      svg: <LeadSVG />,
    },
    {
      tableName: "Event",
      commandName: "Create new event",
      anchor: "right",
      svg: <EventSVG />,
    },
    {
      tableName: "Tasks",
      commandName: "Tasks",
      anchor: "right",
      svg: <TaskSVG />,
    },
    {
      tableName: "Help",
      commandName: "Help",
      anchor: "right",
      svg: <NoteSVG />,
    },
  ];

  const searchAllRecords = async (searchText) => {
    const d = new Date();
    const month = d.getMonth() < 9 ? `0${d.getMonth() + 1}` : d.getMonth() + 1;
    const day = d.getDate() < 10 ? `0${d.getDate()}` : d.getDate();
    const date = `${d.getFullYear()}-${month}-${day}`;

    setSearchLoader(true);
    const dataParams = {
      search_value: searchText,
      date: date,
      limit: 200,
      offset: 0,
    };
    const JSONdataParams = JSON.stringify(dataParams);
    const response = await all_matched_records(JSONdataParams);
    if (response?.data?.status === 200) {
      setMatchRecords(response.data.data);
      setSearchLoader(false);
    }
    setSearchLoader(false);
  };

  useEffect(() => {
    if (searchDebounceText && searchDebounceText.length > 1)
      searchAllRecords(searchDebounceText);
    if (!searchDebounceText) setMatchRecords(null);
  }, [searchDebounceText]);

  return (
    <Box width={"100%"}>
      <Box
        position={"sticky"}
        top={"0px"}
        left={"0px"}
        sx={{ background: "#ffffff" }}
      >
        <Box
          height={"8%"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          margin={"0rem 1.5rem"}
        >
          <Box paddingTop={"0.2rem"}>
            {!searchLoader ? (
              <SearchSVG />
            ) : (
              <Box height={"6%"}>
                <Loader handleWidthOrHeight={true} handleMargin={true} />
              </Box>
            )}
          </Box>
          <SearchText
            size="normal"
            value={search}
            placeholder="Search"
            disableUnderline
            onChange={(e) => {
              const inputValue = e.target.value;
              // Regular expression to match the characters #, \, &
              const forbiddenChars = /[#\\&]/;
              // Check if the input contains any forbidden characters
              if (!forbiddenChars.test(inputValue)) {
                setSearch(inputValue);
              } else {
                console.warn("Input contains forbidden characters: #, \\, &");
              }
            }}
            autoFocus
          />
          <CloseRounded
            onClick={() => searchView()}
            sx={{ fontSize: "16px", cursor: "pointer" }}
          />
        </Box>
        <Divider sx={{ border: "0.6px solid #E6E6E6" }} />
      </Box>

      {matchRecords ? (
        <ScrollBox>
          {Object.keys(matchRecords).map((_tableName, index) => {
            return (
              <Box
                key={index}
                display={"flex"}
                gap={"10px"}
                flexDirection={"column"}
                paddingTop={"1rem"}
              >
                <TypoGraphyText>{_tableName}</TypoGraphyText>
                <Box>
                  {matchRecords[_tableName]?.length ? (
                    matchRecords[_tableName]?.map(
                      (allIndividualData, index) => {
                        if (index < 5)
                          return (
                            <Box
                              height={"33px"}
                              padding={"0rem 1.5rem"}
                              display={"flex"}
                              alignItems={"center"}
                              sx={{
                                "&:hover": {
                                  background: "#F2F7FC",
                                  cursor: "pointer",
                                },
                              }}
                              onClick={() => {
                                onClickRow(
                                  _tableName,
                                  allIndividualData.Id ?? allIndividualData.id,
                                  allIndividualData
                                );
                                handleSettingClick();
                              }}
                            >
                              {_tableName === "Task" || _tableName === "Event"
                                ? allIndividualData.Subject
                                : _tableName === "Notes"
                                ? allIndividualData.title
                                : allIndividualData.Name}
                            </Box>
                          );
                      }
                    )
                  ) : (
                    <Box height={"33px"} padding={"0rem 1.5rem"}>
                      No Results
                    </Box>
                  )}
                </Box>
                <Divider />
              </Box>
            );
          })}
        </ScrollBox>
      ) : (
        <ScrollBox
          style={{
            overflowY: "hidden",
            scrollBehavior: "auto",
            gap: "2px",
          }}
        >
          <Box
            paddingTop={"8px"}
            display={"flex"}
            flexDirection={"column"}
            gap={"8px"}
          >
            <TypoGraphyText>Recent Searches</TypoGraphyText>
            {recentSearches?.length ? (
              <Box width={"100%"} display={"flex"} flexDirection={"column"}>
                {recentSearches.slice(0, 3).map((data, index) => {
                  return (
                    <Box
                      key={index}
                      height={"33px"}
                      padding={"0rem 1.5rem"}
                      display={"flex"}
                      alignItems={"center"}
                      sx={{
                        "&:hover": {
                          backgroundColor: "#F2F7FC",
                          color: "#00A1E0",
                          cursor: "pointer",
                        },
                      }}
                      onClick={(e) => {
                        setSearch(e.target.innerText);
                      }}
                    >
                      {data}
                    </Box>
                  );
                })}
              </Box>
            ) : (
              <Box height={"33px"} padding={"0rem 1.5rem"}>
                No Recent Searches
              </Box>
            )}
          </Box>
          <Divider sx={{ border: "0.6px solid #E6E6E6" }} />
          <Box
            paddingTop={"8px"}
            display={"flex"}
            flexDirection={"column"}
            gap={"8px"}
          >
            <TypoGraphyText>Commands</TypoGraphyText>
            <Box width={"100%"} display={"flex"} flexDirection={"column"}>
              {commands.map((command, index) => {
                return (
                  <>
                    <Box
                      key={index}
                      id={`${command.tableName}`}
                      height={"33px"}
                      padding={"0rem 1.5rem"}
                      display={"flex"}
                      alignItems={"center"}
                      gap={"10px"}
                      sx={{
                        "&:hover": {
                          backgroundColor: "#F2F7FC",
                          color: "#00A1E0",
                          cursor: "pointer",
                        },
                      }}
                      // onClick={() => {

                      //   if (command.commandName === "Help") {
                      //     handleSettingClick();
                      //   } else {
                      //     toggleDrawer(command.anchor, true, command.tableName);
                      //   }
                      // }}
                      onClick={
                        command.commandName === "Help"
                          ? () => {
                              handleSettingClick();
                              setHelpPopUp(true);
                            }
                          : command.tableName === "Note"
                          ? () => {
                              searchView();
                              localStorage.setItem(
                                "searchAddNoteLocation",
                                location.pathname
                              );
                              localStorage.setItem("searchAddNote", true);
                              navigate(`/Notes/1`);
                            }
                          : toggleDrawer(
                              command.anchor,
                              true,
                              command.tableName
                            )
                      }
                    >
                      {command.svg}
                      {command.commandName}
                    </Box>
                  </>
                );
              })}
            </Box>
          </Box>
        </ScrollBox>
      )}

      {helpPopUp && (
        <SettingPopover
          id={settingId}
          open={open}
          anchorEl={SettingAnchorEl}
          onClose={handleSettingClose}
          anchorOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
        >
          <SettingView
            settingViewClose={() => handleSettingClose()}
            manageShortCut={manageShortCut}
            showOrHideFields={showOrHideFields}
            setAppShortCut={setAppShortCut}
            appShortCut={appShortCut}
            setBookmark={setBookmark}
            openUrlName={"Help"}
          />
        </SettingPopover>
      )}
    </Box>
  );
}

export default SearchView;
