import { default as React, useEffect, useRef, useState } from "react";

import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import List from "@editorjs/list";
import Marker from "@editorjs/marker";
import Underline from "@editorjs/underline";
import Alert from "editorjs-alert";
import Paragraph from "@editorjs/paragraph";
import DragDrop from "editorjs-drag-drop";

import { useNotes } from "../../Context/NotesContext";

import { editNote } from "../../Pages/Services/notesApi";

import styled from "@emotion/styled";

const EditorContainer = styled.div({
  width: "100%",
  // maxWidth:"60rem",
  // maxHeight: "90%",
  height: "100%",
  overflow: "auto",
  scrollbarGutter: "stable",
  fontFamily: "'Inter', sans-serif",
  textAlign: "left",

  "& .ce-toolbar__content": {
    maxWidth: "100%",
  },

  "& .ce-toolbar__actions": {
    position: "absolute",
    left: "0px",

    // "& :hover": {
    //   backgroundColor: "#F2F7FC",
    //   color: "#00A1E0",
    // },
  },

  "& .codex-editor__redactor": {
    // paddingBottom: " max(100px, 15%) !important",
    paddingBottom: "0 !important",
  },

  "& .ce-block__content": {
    maxWidth: "88%",
  },

  "& .ce-settings": {
    border: "0 !important",
    width: "fit-content !important",
  },

  "& .ce-settings__default-zone": {
    display: "none !important",
  },

  "& .cdx-list-settings": {
    "& :hover": {
      backgroundColor: "#F2F7FC",
      color: "#00A1E0",
    },
  },

  "& .cdx-settings-button--active": {
    color: "white",
    backgroundColor: "#00A1E0",
  },

  "& .ce-popover__items": {
    "& :hover": {
      backgroundColor: "#F2F7FC",
      color: "#00A1E0",
    },
  },

  "& .ce-inline-toolbar__dropdown": {
    ":hover": {
      backgroundColor: "#F2F7FC",
      color: "#00A1E0",
    },
  },

  "& .ce-inline-toolbar__buttons": {
    "& :hover": {
      backgroundColor: "#F2F7FC",
      color: "#00A1E0",
    },
  },

  "& .ce-toolbar__settings-btn": {
    ":hover": {
      backgroundColor: "#F2F7FC",
      color: "#00A1E0",
    },
  },

  "& .ce-toolbar__plus": {
    ":hover": {
      backgroundColor: "#F2F7FC",
      color: "#00A1E0",
    },
  },

  "& .ce-conversion-toolbar__tools": {
    "& :hover": {
      backgroundColor: "#F2F7FC",
      color: "#00A1E0",
    },
  },
});

const EditorMain = styled.div({
  // height: "90%",
});

const EDITTOR_HOLDER_ID = "editorjs";

const Editor = ({ content, id, template, showNotesExtedned }) => {
  const { stateNotes, dispatchNotes } = useNotes();
  const { singleNote } = stateNotes;

  const ejInstance = useRef();

  const [tempId, setTempId] = useState("temp");
  useEffect(() => {
    if (tempId !== id) {
      setTempId(id);
    }
  }, [id]);

  // ejInstance.current = null;
  // useEffect(() => {
  //   return () => {
  //     ejInstance.current?.destroy();
  //     ejInstance.current = null;
  //   };
  // }, []);

  useEffect(() => {
    if (!ejInstance?.current && ejInstance.current !== undefined) {
      initEditor();
    }
    return () => {
      ejInstance.current?.destroy();
      ejInstance.current = null;
    };
  }, [tempId, template]);
  //general function for event listener
  function detectTags(e) {
    if (e.target.tagName.toLowerCase() === "a") {
      window.open(e.target.href, "_blank");
    }
  }
  const clickHrefs = () => {
    //detect all blocks with classname
    const elemental = document.getElementsByClassName("ce-block");
    for (let j = 0, jl = elemental.length; j < jl; j++) {
      //selects <a> within the blocks
      const links = elemental[j].getElementsByTagName("a");
      for (let i = 0, il = links.length; i < il; i++) {
        links[i].removeEventListener("click", detectTags);
        links[i].addEventListener("click", detectTags);
      }
    }
  };
  const initEditor = () => {
    const editor = new EditorJS({
      holder: EDITTOR_HOLDER_ID,
      // logLevel: "ERROR",
      data: content,
      placeholder: "Start Writing Here",
      onReady: () => {
        ejInstance.current = editor;
        new DragDrop(editor);
        clickHrefs();
      },
      onChange: async () => {
        try {
          const savedData = await editor.save();
          bodyChangeHandler(savedData);
          clickHrefs();
        } catch (error) {
          console.log("failed", error);
        }
      },
      tools: {
        paragraph: {
          class: Paragraph,
          inlineToolbar: true,
          config: {
            preserveBlank: true,
          },
        },
        header: {
          class: Header,
          config: {
            placeholder: "Enter a header",
            levels: [1, 2, 3],
            defaultLevel: 2,
          },
        },
        alert: {
          class: Alert,
          inlineToolbar: true,
          shortcut: "CMD+SHIFT+A",
          config: {
            defaultType: "primary",
            messagePlaceholder: "Enter something",
          },
        },

        // textVariant: TextVariantTune,

        list: {
          class: List,
          inlineToolbar: true,
          config: {
            defaultStyle: "unordered",
          },
        },

        Marker: {
          class: Marker,
          // shortcut: "CMD+SHIFT+M",
        },

        underline: Underline,
      },

      // tunes: ["textVariant"],
    });
  };

  const bodyChangeHandler = (newBody) => {
    const newSingleNote = { ...singleNote, content: newBody };

    editNote(dispatchNotes, newSingleNote);
  };

  return (
    <EditorContainer
      style={{ maxWidth: showNotesExtedned ? "30rem" : "60rem" }}
    >
      <EditorMain id={EDITTOR_HOLDER_ID}> </EditorMain>
    </EditorContainer>
  );
};

export default Editor;
