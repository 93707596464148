import getTableData from "./getData";
import getTableField from "./getTableField";
import getAsyncTask from "./getAsynvTask";
import getAsyncTable from "./getTableDataSync";
import toggleWishModal from "./getToggleWishModal";
import getHasAccess from "./getHasAccess";

const Actions = {
  getTableData,
  getTableField,
  getAsyncTask,
  getAsyncTable,
  toggleWishModal,
  getHasAccess,
};
export default Actions;
