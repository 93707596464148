import { Divider, Hidden } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useState } from "react";
import { SideBarSubContentBox, SideBarSubContentHeader } from "../SettingView";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

import { ShortCut } from "../../Time";

import mainShortCutContext from "../../../Context/Context";
import { createOrUpdateKey } from "../../../Pages/Services/tableApi";

const data = {
  id: "",
  Name: "",
  Url: "",
};
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: "60px",
  height: "31px",
  padding: 0,

  "& .MuiSwitch-switchBase": {
    padding: "6px 8px",
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(25px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "bule",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33CF4D",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: "19.2px",
    height: "19.2px",
  },
  "& .MuiSwitch-track": {
    borderRadius: "37px",
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const DialogTitleBox = styled(DialogTitle)({
  width: "45px",
  height: "19px",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "16px",
  lineHeight: "19px",
  color: "#444647",
  padding: "0px 0px 4px",
});

const DialogTextFiled = styled(TextField)({
  background: "#F7F7F7",
  borderRadius: "8px",
  width: "424px",
  height: "31px",
  ".MuiOutlinedInput-notchedOutline": {
    border: "none",
    outline: "none",
  },
  ".MuiOutlinedInput-input": {
    padding: "0px 0px 0px 8px",
    fontFamily: "Inter",
    color: "#798EA3",
    height: "100%",
  },
  ".MuiOutlinedInput-input:focus": {
    padding: "0px 0px 0px 8px",
    fontFamily: "Inter",
    color: "#798EA3",
    height: "100%",
  },
  ".MuiOutlinedInput-root": {
    height: "100%",
  },
  justifyContent: "center",
});

function ManageShortcuts({ shortCut, manageShortCuts, setAppShortCut }) {
  const { showOrHide } = useContext(mainShortCutContext);

  const [shortCutData, setShortCutsData] = useState("");
  const [editData, setEditData] = useState(data);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setEditData(data);
    setOpen(false);
  };

  const storeUpdatedShortCutData = async (_shortCutData) => {
    const JSONdataParams = {
      shortcut: localStorage.getItem("ShowOrHide") || false,
      shortCutData: _shortCutData,
      timeFormat: localStorage.getItem("TimeFormat") || "12",
    };
    await createOrUpdateKey(
      JSON.stringify({
        key: `settings`,
        value: JSONdataParams,
      })
    );
  };

  const editHandleChange = (e, i) => {
    setOpen(true);
    let allData = JSON.parse(localStorage.getItem("shortCut"));
    let newData = allData.splice(i, 1);
    setEditData({
      ...editData,
      id: i,
      Name: newData[0].name,
      Url: newData[0].url,
    });
  };
  const openInsertHandleChange = () => {
    setOpen(true);
  };

  const deleteHandleChange = (e, i) => {
    let allData = JSON.parse(localStorage.getItem("shortCut"));
    allData.splice(i, 1);
    storeUpdatedShortCutData(allData);
    setShortCutsData(allData);
    setAppShortCut();
    localStorage.setItem("shortCut", JSON.stringify(allData));
  };

  const updateDataHandle = () => {
    const newData = {
      url: editData.Url,
      name: editData.Name,
      metaData: {
        url: `https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${
          editData?.Url?.includes("https://")
            ? editData?.Url
            : `https://${editData?.Url}`
        }&size=12`,
      },
    };
    let allData = JSON.parse(localStorage.getItem("shortCut"));
    allData.splice(editData.id, 1, newData);

    storeUpdatedShortCutData(allData);
    localStorage.setItem("shortCut", JSON.stringify(allData));
    setAppShortCut();
    setEditData(data);
    setOpen(false);
  };

  const dataHandleChange = (e) => {
    setEditData({
      ...editData,
      [e.target.name]: e.target.value,
    });
  };

  const insertHandleChange = () => {
    const newData = {
      url: editData.Url,
      name: editData.Name,
      metaData: {
        url: `https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${
          editData?.Url?.includes("https://")
            ? editData?.Url
            : `https://${editData?.Url}`
        }&size=12`,
      },
    };

    if (!editData.Url) return;

    let allData = localStorage.getItem("shortCut")
      ? JSON.parse(localStorage.getItem("shortCut"))
      : [];

    allData.push(newData);

    storeUpdatedShortCutData(allData);
    localStorage.setItem("shortCut", JSON.stringify(allData));
    setAppShortCut();
    setEditData(data);
    setOpen(false);
  };

  return (
    <SideBarSubContentBox>
      <SideBarSubContentHeader>Shortcuts</SideBarSubContentHeader>
      <p style={{ color: "#6A727A", margin: "2px 0px" }}>
        Manage yours Shortcuts
      </p>

      <Divider
        sx={{
          marginTop: "1rem",
        }}
      ></Divider>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          position: "relative",
          width: "100%",
          height: "40px",
          justifyContent: "space-between",
          marginTop: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              width: "max-content",
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "16px",
              lineHeight: "19px",
              color: "#444647",
            }}
          >
            Show shortcuts
            <Box
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "17px",
                color: " #798EA3",
              }}
            >
              Quick access to your favorite shortcuts.
            </Box>
          </Box>
        </Box>
        <Box>
          <IOSSwitch onChange={manageShortCuts} defaultChecked={showOrHide} />
        </Box>
      </Box>
      <Divider
        sx={{
          marginTop: "1rem",
        }}
      />
      <Dialog
        open={open}
        sx={{
          "& .MuiPaper-root": {
            width: "488px",
            height: "326px",
            background: "#FFFFFF",
            border: "1px solid #EFF5F8",
            borderRadius: "16px",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          },
        }}
        onBackdropClick={() => setOpen(false)}
      >
        <DialogTitle
          sx={{
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "16px",
            color: "#ABADB3",
          }}
        >
          Add Shortcut
        </DialogTitle>
        <DialogContent
          sx={{
            overflowY: "hidden",
            padding: "0px 24px",
            flex: 1,
          }}
        >
          <DialogTitleBox>Name</DialogTitleBox>
          <DialogTextFiled
            autoFocus
            fullWidth
            margin="dense"
            id="name"
            type="text"
            variant="outlined"
            name="Name"
            value={editData.Name}
            onChange={dataHandleChange}
          />
        </DialogContent>
        {/* <DialogTitle>Url</DialogTitle> */}
        <DialogContent
          sx={{
            overflowY: "hidden",
            padding: "0px 24px",
            flex: 1,
          }}
        >
          <DialogTitleBox>Url</DialogTitleBox>
          <DialogTextFiled
            autoFocus
            margin="dense"
            id="name"
            type="text"
            name="Url"
            fullWidth
            variant="outlined"
            value={editData.Url}
            onChange={dataHandleChange}
          />
        </DialogContent>
        <DialogActions
          sx={{
            padding: "8px 24px 8px 8px",
          }}
        >
          <Button
            onClick={handleClose}
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: "0.875rem",
              lineHeight: 1.75,
              letterSpacing: "0.02857em",
              color: "#798EA3",
              padding: "6px 16px",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={editData.id === "" ? insertHandleChange : updateDataHandle}
            variant="contained"
            sx={{ borderRadius: "8px", backgroundColor: "#00A1E0" }}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {localStorage.getItem("shortCut") &&
        showOrHide &&
        JSON.parse(localStorage.getItem("shortCut")).map(
          ({ url, name, metaData }, i) => {
            return (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  position: "relative",
                  width: "100%",
                  height: "40px",
                  alignItems: "center",
                  marginTop: "1rem",
                }}
              >
                <ShortCut
                  sx={{}}
                  bgcolor="white"
                  position="relative"
                  onClick={() => window.open(url, "_blank")}
                  display="flex"
                  gap="4px"
                  flexDirection="row"
                >
                  <img
                    src={metaData.url ?? ""}
                    style={{
                      position: "absolute",
                    }}
                  />
                </ShortCut>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box
                    sx={{
                      width: "max-content",
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "16px",
                      lineHeight: "19px",
                      color: "#444647",
                    }}
                  >
                    {name}
                    <Box
                      sx={{
                        fontFamily: "Inter",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "14px",
                        lineHeight: "17px",
                        color: " #798EA3",

                        // //////////////
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        width: "25rem",
                        height: "1.2em",
                        whiteSpace: "nowrap",
                        margin: "4px",
                      }}
                    >
                      {url}
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    marginLeft: "auto",
                    display: "flex",
                    alignItems: "center",
                    gap: "16px",
                  }}
                >
                  <Button
                    sx={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: 500,
                      fontSize: "0.875rem",
                      lineHeight: 1.75,
                      letterSpacing: "0.02857em",
                      color: "#798EA3",
                      padding: "6px 16px",
                      textTransform: "capitalize",
                      ".MuiButtonBase-root :hover": {
                        background: "none",
                      },
                    }}
                    variant="text"
                    onClick={(e) => deleteHandleChange(e, i)}
                  >
                    Remove
                  </Button>
                  <Button
                    sx={{
                      background: "#F5F8FA",
                      borderRadius: "8px",
                      textTransform: "capitalize",
                      border: "none",
                    }}
                    variant="outlined"
                    onClick={(e) => editHandleChange(e, i)}
                  >
                    Edit
                  </Button>
                </Box>
              </Box>
            );
          }
        )}
      {showOrHide && (
        <Box
          Box
          sx={{
            width: "100%",
            marginTop: "24px",
            padding: "4px",
            background: "#F5F8FA",
            borderRadius: "8px",
          }}
        >
          <Button
            variant="text"
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "14px",
              lineHeight: "17px",
              background: "#F5F8FA",
            }}
            onClick={openInsertHandleChange}
          >
            + Add new shortcut
          </Button>
        </Box>
      )}
    </SideBarSubContentBox>
  );
}

export default ManageShortcuts;
