import secureAxiosInstance from "./apiInterceptors";

export const getAllNotes = async (dispatchNotes, checkShare) => {
  try {
    const res = await secureAxiosInstance({
      method: "GET",
      url: "/api/v1/filter/notes/?q=ordering",
    });

    if (checkShare) {
      dispatchNotes({ type: "LOAD_ALL_NOTES", payload: res.data });
    } else {
      dispatchNotes({
        type: "LOAD_ALL_NOTES_WITH_SINGLE_NOTE",
        payload: res.data,
      });
    }
  } catch (e) {
    console.log("error occured: ", e);
  }
};

export const getAllNotesWithNewNote = async (dispatchNotes) => {
  try {
    const NewNote = {
      parent: null,
      title: "",
      content: {
        blocks: [],
        time: Date.now(),
        version: "2.25.0",
      },
    };
    const res = await secureAxiosInstance({
      method: "POST",
      url: "/api/v1/get_create_new_notes/?q=ordering",
      data: { ...NewNote },
    });

    dispatchNotes({
      type: "LOAD_NOTE_WITH_NEW_NOTE",
      payload: res.data,
    });
  } catch (e) {
    console.log("error occured: ", e);
  }
};

export const getAllTrashNotes = async (dispatchNotes) => {
  try {
    const res = await secureAxiosInstance({
      method: "GET",
      url: "/api/v1/trash/notes/",
    });

    dispatchNotes({ type: "LOAD_TRASH_NOTES", payload: res.data });
  } catch (e) {
    console.log("error occured: ", e);
  }
};

export const postNote = async (dispatchNotes, NewNote) => {
  try {
    const res = await secureAxiosInstance({
      method: "POST",
      url: "/api/v1/post/notes/",
      data: { ...NewNote },
    });

    if (NewNote.parent) {
      dispatchNotes({ type: "ADD_CHILD_NOTE", payload: res.data });
    } else {
      dispatchNotes({ type: "ADD_NOTE", payload: res.data });
    }
  } catch (e) {
    console.log("error occured: ", e);
  }
};

export const editNote = async (dispatchNotes, NewNote) => {
  const {
    id,
    title,
    content,
    is_template,
    template,
    salesforce_array,
    salesforce_url,
  } = NewNote;

  try {
    const res = await secureAxiosInstance({
      method: "PATCH",
      url: "/api/v1/update/notes/",
      data: {
        note_id: id,
        title: title,
        content: content,
        is_template: is_template,
        template: template,
        salesforce_array: salesforce_array,
        salesforce_url: salesforce_url,
      },
    });

    dispatchNotes({ type: "SET_NOTE", payload: res.data });
  } catch (e) {
    console.log("error occured: ", e);
  }
};

export const editNoteTitle = async (dispatchNotes, NewNote) => {
  const { id, title } = NewNote;

  try {
    const res = await secureAxiosInstance({
      method: "PATCH",
      url: "/api/v1/update/title/notes/",
      data: {
        note_id: id,
        title: title,
      },
    });

    dispatchNotes({ type: "SET_NOTE", payload: res.data });
  } catch (e) {
    console.log("error occured: ", e);
  }
};

export const trashNote = async (dispatchNotes, noteId) => {
  try {
    const res = await secureAxiosInstance({
      method: "POST",
      url: "/api/v1/trash/notes/",
      data: { note_id: noteId },
    });
    getAllTrashNotes(dispatchNotes);
    dispatchNotes({ type: "TRASH_NOTE", payload: res.data });
  } catch (e) {
    console.log("error occured: ", e);
  }
};

export const restoreNote = async (dispatchNotes, noteId) => {
  try {
    const res = await secureAxiosInstance({
      method: "PUT",
      url: "/api/v1/trash/notes/",
      data: { note_id: noteId },
    });

    dispatchNotes({ type: "RESTORE_NOTE", payload: res.data });
  } catch (e) {
    console.log("error occured: ", e);
  }
};

export const deleteNote = async (dispatchNotes, noteId) => {
  try {
    const res = await secureAxiosInstance({
      method: "DELETE",
      url: "/api/v1/delete/notes/",
      data: { note_id: noteId },
    });

    dispatchNotes({ type: "DELETE_NOTE", payload: res.data });
  } catch (e) {
    console.log("error occured: ", e);
  }
};

export const reorderNotes = async (dispatchNotes, showNotes) => {
  try {
    const res = await secureAxiosInstance({
      method: "POST",
      url: "/api/v1/move/notes/",
      data: { reordered_notes: showNotes },
    });

    dispatchNotes({ type: "REARRANBE_NOTES", payload: showNotes });
  } catch (e) {
    console.log("error occured: ", e);
  }
};

export const getAllTemplates = async (dispatchNotes) => {
  try {
    const res = await secureAxiosInstance({
      method: "GET",
      url: `/api/v1/search/notes/?q="template"`,
    });

    dispatchNotes({ type: "LOAD_TEMPLATES", payload: res.data });
  } catch (e) {
    console.log("error occured: ", e);
  }
};

export const searchSalesForce = async (setSalesforceData, searchText) => {
  try {
    const res = await secureAxiosInstance({
      method: "POST",
      url: `/search_records_for_notes/`,
      data: {
        search_value: searchText,
        limit: "200",
        offset: 0,
      },
    });

    if (res.status === 200) {
      setSalesforceData(res?.data.data);
    }
  } catch (e) {
    console.log("error occured: ", e);
  }
};

export const shareNote = async (email, id, title) => {
  try {
    const res = await secureAxiosInstance({
      method: "POST",
      url: "/api/v1/slug/notes/",
      data: {
        email: email,
        slug: id,
        title: title,
      },
    });

    // dispatchNotes({ type: "LOAD_TEMPLATES", payload: res.data });
  } catch (e) {
    console.log("error occured: ", e);
  }
};

export const getShareNote = async (id, dispatchNotes, navigate) => {
  try {
    const res = await secureAxiosInstance({
      method: "GET",
      url: `/api/v1/slug/notes/${id}/`,
    });

    // if (res.status === 200) {
    //   const NewNote = {
    //     parent: null,
    //     title: res.data.title,
    //     content: res.data.content,
    //     children: res.data.children,
    //   };
    //   createdSharedNote(dispatchNotes, NewNote);
    // }

    if (res.status === 200) {
      const NewNote = {
        parent: null,
        title: res.data["note_data"].title,
        content: res.data["note_data"].content,
        children: res.data["note_data"].children,
      };
      if (!res.data["owner"]) {
        createdSharedNote(dispatchNotes, NewNote);
      } else {
        dispatchNotes({ type: "LOAD_NOTE", payload: res.data.note_data });
      }
    }

    navigate("../../notes/1");
  } catch (e) {
    console.log("error occured: ", e);
  }
};

export const createdSharedNote = async (dispatchNotes, NewNote) => {
  try {
    const res = await secureAxiosInstance({
      method: "POST",
      url: "/api/v1/post/bulk/notes/",
      data: { nested: NewNote },
    });

    dispatchNotes({ type: "ADD_SHARE_NOTE", payload: res.data });
  } catch (e) {
    console.log("error occured: ", e);
  }
};

export const syncNote = async (dispatch, note) => {
  try {
    let tempNote = note;
    const res = await secureAxiosInstance({
      method: "POST",
      url: "/api/v1/sync/notes/",
      data: { note: tempNote },
    });

    if (res.status === 200) {
      const newSingleNote = {
        ...tempNote,
        salesforce_url: res.data.salesforce_url,
      };
      editNote(dispatch, newSingleNote);
    }
  } catch (e) {
    console.log("error occured: ", e);
  }
};

export const unsyncNote = async (dispatch, note) => {
  try {
    let tempNote = note;
    const res = await secureAxiosInstance({
      method: "POST",
      url: "/api/v1/unsync/notes/",
      data: { note: tempNote },
    });
  } catch (e) {
    console.log("error occured: ", e);
  }
};

export const downloadPdf = async (id) => {
  try {
    const res = await secureAxiosInstance({
      method: "GET",
      url: `/api/v1/generate/pdf/${id}/`,
    });

    const newUrl = res.config.baseURL + res.config.url;
    window.open(newUrl, "_blank").focus();

    // dispatchNotes({ type: "ADD_SHARE_NOTE", payload: res.data });
  } catch (e) {
    console.log("error occured: ", e);
  }
};
