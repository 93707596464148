import { ExpandMoreRounded } from "@mui/icons-material";
import { Box, ClickAwayListener, Popover, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  DropDownValueEllipsisBox,
  ViewBox,
} from "./FilterOpportunity/SimpleFilterDropDown";

export const DropDown = styled(Popover)({
  "& .MuiPaper-root": {
    transform: "translate(0px, 25px)",
  },
  transform: "translate(0px, 25px)",
  "& .MuiPopover-paper": {
    background: "#ffffff",
    border: "1px solid #EFF5F8",
    boxShadow: "8px 18px 12px rgb(0 0 0 / 5%)",
    borderRadius: "8px",
    maxHeight: "175px",
  },
});

export const DropDownOption = styled(Box)({
  margin: "4px 3px",
  padding: "4px 20px",
  // paddingRight: "50px",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#F2F7FC",

    color: "#00A1E0",
  },
  borderRadius: "8px",
  display: "flex",
  alignItems: "center",
  gap: "10px",
});

function SimpleDropDown({
  value,
  options,
  onChange,
  closeDropdown,
  ref,
  hideDefaultBox = true,
  expandViewCell,
  leadView,
  taskDropDown,
  editTask,
}) {
  //console.log(taskView);
  const [anchorEl, setAnchorEl] = useState(null);
  const [taskAnchorEl, setTaskAnchorEl] = useState(null);
  const [optionState, setOptionState] = useState(true);
  const [taskSortingDropDown, setTaskSortingDropDown] = useState(true);

  const onClickOption = (e, option) => {
    e.stopPropagation();
    taskDropDown ? setTaskSortingDropDown(true) : setOptionState(false);
    onChange(option);
  };

  useEffect(() => {
    if (!optionState) {
      !taskDropDown && closeDropdown();
    }
  }, [optionState]);

  useEffect(() => {
    taskDropDown &&
      setTaskAnchorEl(document.getElementById("taskSortDropdown"));
  }, [value]);

  useEffect(() => {
    setAnchorEl(document.getElementById("dropdown"));
    setTaskAnchorEl(document.getElementById("taskSortDropdown"));
  }, []);

  const open = Boolean(anchorEl);
  const taskSortOpen = Boolean(taskAnchorEl);
  return (
    <Box ref={ref}>
      {hideDefaultBox ? (
        taskDropDown ? (
          <></>
        ) : (
          <Box
            id="dropdown"
            aria-describedby={"simple-popover"}
            marginTop={expandViewCell ? "0px" : "12px"}
          >
            {value ? value : "-"}
          </Box>
        )
      ) : (
        <Box
          id="dropdown"
          aria-describedby={"simple-popover"}
          marginTop={leadView ? "0px" : "12px"}
          style={{
            transform: leadView && "translate(0px, 0px)",
          }}
        ></Box>
      )}
      {taskDropDown ? (
        <ClickAwayListener
          onClickAway={() => {
            setTaskSortingDropDown(true);
          }}
        >
          <Box>
            <ViewBox
              id="taskSortDropdown"
              aria-describedby={"simple-popover"}
              onClick={() => {
                setTaskSortingDropDown(!taskSortingDropDown);
                // !taskSortingDropDown && setTaskAnchorEl(null);
              }}
              bgcolor={!taskSortingDropDown ? "#EDF2FA" : "white"}
              style={{
                width: "150px",
                marginLeft: "16px",
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <DropDownValueEllipsisBox>{value}</DropDownValueEllipsisBox>
                <ExpandMoreRounded
                  style={
                    !taskSortingDropDown
                      ? { transform: "scaleY(-1)" }
                      : { transform: "scaleY(1)" }
                  }
                />
              </Box>
            </ViewBox>

            {!taskSortingDropDown && (
              <DropDown
                open={taskSortOpen}
                id={"simple - popover"}
                anchorEl={taskAnchorEl}
                sx={{
                  top: "15px",
                  "& .MuiPopover-paper": {
                    boxShadow: "0 3px 10px rgb(0 0 0 / 10%)",
                    borderRadius: "8px",
                    width: "166px",
                    // height: "150px",
                  },
                }}
                onBackdropClick={() => setTaskSortingDropDown(true)}
              >
                {options.map((option) => {
                  return (
                    <DropDownOption
                      onClick={(e) => onClickOption(e, option)}
                      sx={{
                        background: value === option && "#eff5fc",
                        fontSize: "14px",
                        borderRadius: "0px",
                      }}
                    >
                      {option}
                    </DropDownOption>
                  );
                })}
              </DropDown>
            )}
          </Box>
        </ClickAwayListener>
      ) : (
        <DropDown
          open={open}
          id={"simple - popover"}
          anchorEl={anchorEl}
          sx={{
            "& .MuiPopover-paper": {
              boxShadow: leadView && "0 3px 10px rgba(0,0,0,0.1)",
              borderRadius: leadView && "4px",
            },
          }}
          style={{
            transform: "translate(0px, 25px)",
          }}
        >
          {(typeof options === "string" ? JSON.parse(options) : options).map(
            (option) => {
              return (
                <DropDownOption
                  onClick={(e) => onClickOption(e, option)}
                  sx={{
                    fontSize: "14px",
                    lineHeight: "16px",
                    fontStyle: "normal",
                    color: "#444647",
                  }}
                >
                  {option}
                </DropDownOption>
              );
            }
          )}
        </DropDown>
      )}
    </Box>
  );
}

export default SimpleDropDown;
